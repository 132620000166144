import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import { Button } from "@mui/material";

import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ClearOutlinedIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';

import SearchPerCapitasBar from '../../../components/SearchPerCapitasBar';

import Collapse from '@mui/material/Collapse';

import api from '../../../services/api';

let rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

  const headCells = [
    {
      id: 'anos',
      numeric: false,
      disablePadding: true,
      label: 'Ano',
      width: '15%',
      align: "center"
    },
    {
      id: 'tiposEnsinosDescricao',
      numeric: false,
      disablePadding: true,
      label: 'Tipo Ensino',
      width: '25%',
      align: "left"
    },
    {
      id: 'percaptasDescricoesDescricao',
      numeric: false,
      disablePadding: true,
      label: 'Tipo PerCapita',
      width: '25%',
      align: "left"
    },
    // {
    //   id: 'valor',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Valor (R$)',
    //   width: '25%',
    //   align: "left"
    // },
    {
      id: 'valor',
      numeric: false,
      disablePadding: true,
      label: 'Valor (R$)',
      width: '25%',
      align: "left"
    },
    // {
    //   id: '',
    //   numeric: false,
    //   disablePadding: true,
    //   label: '',
    //   width: '10%',
    //   align: "center"
    // }
  ];
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         // ...(numSelected > 0 && {
//         //   bgcolor: (theme) =>
//         //     alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//         // }),
//       }}
//     >
//       {/* {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: '1 1 100%' }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : ( */}
//       <Box>
//         <Typography component="div">
//           <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Per Capitas - Listagem</Box>
//         </Typography> 
//       </Box>
//       {/* )} */}

//       {/* {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )} */}
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  const [searchField, setSearchField] = React.useState('');

  const handleChange = async (event) => {
    await setSearchField(event.target.value);
  };


  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const classes = useStyles();

  useEffect(() => {
    const values = api.get("percaptas-valores")
    values.then((response) => {
      // rows = response.data;
      setListValues(response.data);
      setNewListValues(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    //   //Doing filteration on according to select state option
      let newListValue = listValues;
      newListValue = listValues.filter(
        listValues => listValues.percaptasDescricoesDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.tiposEnsinosDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.anos.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      );
    //   if ((searchData) && (searchData[0].regioes.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[0].regioes.includes(listValues.regiao_id)
    //     );
    //   }
    //   if ((searchData) && (searchData[1].bairros.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[1].bairros.includes(listValues.bairro_id)
    //     );
    //   }
    //   if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
    //     );
    //   }

      //set state variable data after filteration
      setNewListValues(newListValue);
      }, [searchField])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newListValues.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const changeValor = (newValue, row) => {
    const newSelecteds = newListValues.map((n) => {
      if (n.id === row.id) {
          return { ...n, 'valor': newValue.replace(',', '.') }
      }
      return n;
    });
    setNewListValues(newSelecteds);
  }

  const handleSelect = (event, row) => {
    // console.log('event: ', event.target.value);
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected, row.id);
    }
    changeValor(event.target.value, row);
    setSelected([...new Set(newSelected)]);
  };

  const saveData = () => {
    // console.log('selected: ', selected);
    // console.log('newListValues: ', newListValues);

    // const data = newListValues.map((item) => {
    //   if (selected.includes(item.id)) {
    //       return {
    //         "id": item.id,
    //         "valor": item.valor
    //       }
    //   }
    // });

    const payload = [];

    newListValues.forEach(item => {
      if (selected.includes(item.id)) {
        payload.push({
          "id": item.id,
          "valor": item.valor
        });
      }
    });

    // console.log('payload: ', payload);


    // const values = api.patch("percaptas-valores")
    //   const values = api.post(`percaptas-valores`, payload)
    //   values.then((response) => {
    //     setSubmitting(false);
    //     console.log("submit: ", data);
    //   })
    //   .catch((err) => {
    //     setSubmitting(false);
    //     console.error("ops! ocorreu um erro" + err);
    //   }

    // console.log('values:' , 'values');

    //   const payload = {
    //     // @ts-ignore
    //     'id': data.caixasEscolaresId,
    //     // @ts-ignore
    //     'nome': data.caixa_escolar_nome,
    //     // @ts-ignore
    //     'termo': data.termo,
    //     // @ts-ignore
    //     'codigo_credor': data.codigo_credor
    //   }
    //   // @ts-ignore
    setBackdrop(true);
    const values = api.patch(`percaptas-valores`, payload)
    values.then((response) => {
      setBackdrop(false);
      handleClickAlert();
      setSelected([]);
    })
    .catch((err) => {
      // setSubmitting(false);
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();

      console.error("ops! ocorreu um erro" + err);
    })
  }

  const [searchData, setSearchData] = React.useState([
    {anos: []}, {tiposEnsinos: []}, {tiposPercapitas: []}
  ]);

  useEffect(() => {
    setNewSearch();
  }, [searchData]);

  const handleDataSearch = async (data) => {
    // console.log('dataaaaaaaaaaaaaaaa: ', data);
    setSearchData(data);
   };
   
   const [showHideSearchBar, setShowHideSearchBar] = React.useState(false);

   const showSearchBar = () => {
    setShowHideSearchBar(!showHideSearchBar)
  }

   const setNewSearch = () => {
    //Doing filteration on according to select state option
    let newListValue = listValues;
    // newListValue = listValues.filter(
    //   listValues => listValues.nomeCompleto.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
    //   listValues.caixaEscolarNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
    //   listValues.presidenteNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
    // );
    if ((searchData) && (searchData[0].anos.length > 0)) {
      // console.log("listValuesssssssssssssssssssss", listValues);
      newListValue = newListValue.filter(
        newListValue => searchData[0].anos.includes(newListValue.anosId)
      );
    }
    if ((searchData) && (searchData[1].tiposEnsinos.length > 0)) {
      newListValue = newListValue.filter(
        newListValue => searchData[1].tiposEnsinos.includes(newListValue.tiposEnsinosId)
      );
    }
    if ((searchData) && (searchData[2].tiposPercapitas.length > 0)) {
      newListValue = newListValue.filter(
        newListValue => searchData[2].tiposPercapitas.includes(newListValue.percaptasDescricoesId)
      );
    }
    // if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
    //   newListValue = listValues.filter(
    //     listValues => searchData[2].tiposEnsinos
    //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
    //       searchData[2].tiposEnsinos
    //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
    //       searchData[2].tiposEnsinos
    //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
    //       searchData[2].tiposEnsinos
    //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
    //   );
    // }

    // if ((searchData) && searchData[3]  && searchData[3].situacaoesMandato && (searchData[3].situacaoesMandato.length > 0)) {
    //   newListValue = listValues.filter(
    //     listValues => searchData[3].situacaoesMandato.includes(listValues.presidenteSituacaoMandatoId)
    //   );
    // }

      //set state variable data after filteration
      setNewListValues(newListValue);
      // console.log(searchData)
    }

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newListValues.length) : 0;

  return (
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>

          {/* <Paper sx={{ m: 4 }}> */}
            
          <SpinnerCarregando open={backdrop} />

          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
          
          {/* <pre>{JSON.stringify(selected, null, 2)}</pre> */}
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

            
            <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 4 }}>

              <Grid item xs={7}>
                <Box>
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Per Capitas - Listagem</Box>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={5}>
                <Box
                  component="div"
                  className={`${classes.bottomRightBox} ${classes.box}`}>

                  {/*<div style={{ paddingRight: 20 }}>
                    <Paper
                      component="form"
                      sx={{ display: 'flex', alignItems: 'center', width: 400 }}
                    >
                      <IconButton sx={{ p: '6px' }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        size="small"
                        placeholder="Pesquisar ..."
                        inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                        onChange={handleChange}
                        value={searchField}
                      />
                      { searchField.length > 0 ? 
                        <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                          <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                        </IconButton>
                        : null }
                    </Paper>
                      </div> */}


                    <div style={{ paddingRight: 35 }}>
                      <Button onClick={showSearchBar} variant="text" color="primary"  endIcon={<SearchOutlinedIcon />} size="small" style={{ height: 35 }}>
                        Pesquisar
                      </Button>
                    </div>

                  <div style={{ paddingRight: 20 }}>
                    <Paper
                      component="form"
                      sx={{ display: 'flex', alignItems: 'center', width: 100 }}
                    >
                      <TextField
                        // className={classes.input}
                        size="small"
                        id="total"
                        color="primary"
                        label="Total"
                        variant="outlined"
                        value={newListValues ? String(newListValues.length).padStart(2, '0') : '00'}
                        inputProps={{ style: { textAlign: "center" } }}
                      />
                    </Paper>
                  </div>
                  {/* <div>
                    <Box sx={{ pr: 1}}>
                      <AutoCompleteSelectComponent nLabel="Ano" newWidth="250" variant="outlined" apiConst="anos" onSelectData={handleDataAnos} />
                    </Box>
                  </div> */}
                  <div>
                    <Button type="submit" variant="outlined" color="primary" size="small" style={{ height: 35, }} onClick={saveData} disabled={!selected.length > 0}>
                      GRAVAR
                    </Button>
                  </div>
                </Box>
              </Grid>

              
              <Grid item xs={12} sx={{ flex: '100%', pt: 2, pb: 0 }}>
                <Collapse in={showHideSearchBar}>
                  <div><SearchPerCapitasBar onSelectData={handleDataSearch} /></div>
                </Collapse>
              </Grid>
            </Grid>

            <TableContainer sx={{ maxHeight: 440, px: 4 }}>
              <Table
                stickyHeader 
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                // size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    newListValues.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(newListValues, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox" key={row.id}>
                          <IconButton onClick={() => setIsExpandedData(row.id)}>
                            {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell> */}
                        {/* <TableCell component="th" id={labelId} scope="row" padding="normal" sx={{  fontSize: 13 }}>
                          {row.anos}
                        </TableCell> */}

                          <TableCell  size="small" padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>


                        <TableCell size="small" align="left" sx={{  fontSize: 13 }}>{row.anos}</TableCell>
                        <TableCell size="small" align="left" sx={{  fontSize: 13 }}>{row.tiposEnsinosDescricao}</TableCell>
                        <TableCell size="small" align="left" sx={{  fontSize: 13 }}>{row.percaptasDescricoesDescricao}</TableCell>
                        {/* <TableCell align="left" sx={{  fontSize: 13 }}>{row.valor}</TableCell> */}
                        <TableCell size="small" align="left" sx={{  fontSize: 13 }}>
                          {/* {row.valor} */}
                          <TextField
                            id="outlined-size-small"
                            size="small"
                            style={{ width: 100 }}
                            defaultValue={row.valor}
                            onChange={(event) => handleSelect(event, row)}
                            />

                          {/*<CurrencyTextField
                              style={{ width: 150 }}
                              size="small"
                              variant="outlined"
                              // value={value}
                              outputFormat="string"
                              minimumValue="0"
                              currencySymbol=""
                              decimalCharacter=","
                              digitGroupSeparator="."
                              defaultValue={row.valor}
                              onChange={(event) => handleSelect(event, row)}
                              

                              // onChange={(event, value)=> setValue(value)}
                          />
                          */}




                        </TableCell>
                        {/* <TableCell align="center" sx={{  fontSize: 13 }}><EditPrintMenu id={row.id} path="caixas-escolares-has-cargos-funcoes" /></TableCell> */}
                      </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      // style={{
                      //   height: (dense ? 33 : 53) * emptyRows,
                      // }}
                    >
                      <TableCell size="small" colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={newListValues.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          {/* </Paper> */}
        </Paper>
      </Typography>
    </Container>
  );
}
