import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const initialValues = {
  acoesMdeAcao: "",
  acoesMdeDias: "",
  acoesMdeValor: "0,00"
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      // prefix="R$ "
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function EditDialog(props) {
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [values, setValues] = React.useState(initialValues);
  const [title, setTitle] = React.useState("");
  const [disableGravarButton, setDisableGravarButton] = React.useState(false);

  const handleClickConfirm = () => {
    props.handleClickConfirm(values);
  };

  const handleClose = () => {
    props.handleCloseEditDialog();
  };

  const handleInputChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    // alert(JSON.stringify(values, null, 2));
    setDisableGravarButton(false);
    if (
        values.acoesMdeAcao === "" ||
        values.acoesMdeDias === "" ||
        values.acoesMdeValor === ""
      ) {
        setDisableGravarButton(true);
    }
  }, [values]);

  useEffect(() => {
    setValues(props.selectedRow);
    props.selectedRow.id === "" ? setTitle("NOVO REGISTRO") : setTitle("EDITAR REGISTRO");
  }, [props.selectedRow]);

  return (
    <Dialog
      open={props.openEditDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

          <Box sx={{ pt: 2 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Ação"
              name="acoesMdeAcao"
              fullWidth
              focused
              inputProps={{ autoComplete:'off', style: { textTransform: "uppercase" }, maxLength: 50 }}
              value={values.acoesMdeAcao || ""}
              onChange={handleInputChange}
            />
          </Box>

          <Box sx={{ pt: 2 }}>
            <TextField
              type="number" 
              id="outlined-multiline-flexible"
              label="Período de Execução (Dias)"
              name="acoesMdeDias"
              fullWidth
              focused
              InputProps={{ autoComplete:'off', inputProps: { min: 0 }, style: { textTransform: "uppercase" } }}
              value={values.acoesMdeDias || ""}
              onChange={handleInputChange}
            />
          </Box>

          <Box sx={{ pt: 2 }}>
          <TextField
            id="outlined-multiline-flexible"
            label="Valor (R$)"
            name="acoesMdeValor"
            fullWidth
            focused
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
            value={values.acoesMdeValor || "0,00"}
            onChange={handleInputChange}
          />
        </Box>

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={handleClickConfirm} disabled={disableGravarButton}>
          Gravar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
