import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

export default function ConfirmAlert(props) {
    // const [openAlert, setOpenAlert] = React.useState(false);
    // const [severity, setSeverity] = React.useState('error');
    // const [message, setMessage] = React.useState('Preencha todos os dados!');
  
    const handleClickAlert = () => {
      // setOpenAlert(true);
    };
  
    // @ts-ignore
    const handleCloseConfirmAlert = (event, reason) => {
    //   if (reason === 'clickaway') {
    //     return;
    //   }
      props.handleCloseConfirmAlert();
    };
  
    const Alert = React.forwardRef(function Alert(props, ref) {
      // @ts-ignore
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  return (

    <Snackbar
        open={props.openAlert}
        autoHideDuration={2000}
        onClose={handleCloseConfirmAlert}
        anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}
        >
        <Alert icon={<CheckIcon fontSize="inherit" />} onClose={handleCloseConfirmAlert} severity={props.severity}>
            {props.message}
        </Alert>
    </Snackbar>

  );
}
