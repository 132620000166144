import React, { useEffect, useContext } from 'react';
import { styled, useTheme, alpha  } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Badge, InputBase, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import HomeIcon from '@mui/icons-material/Home';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import People from '@mui/icons-material/People';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { BusAlertOutlined } from '@mui/icons-material';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

import { getUserData, userRoles } from '../../utils/functions';

import ListItemButton from '@mui/material/ListItemButton';

import { Context } from '../../Context/AuthContext';

import { Link, useParams, useLocation, useHistory } from "react-router-dom";

import Routes from '../../routes/index';

import { makeStyles } from '@mui/styles';

import { HomeRepairServiceRounded } from '@mui/icons-material';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';


const drawerWidth = 300;

const ano = 'ef997f88-81f6-11ec-b847-00155d6335ac';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

 
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  teste: {
    color: '#4a89c8'
  },
  newItem: {
    minHeight: 32, color: '#4a89c8',
    '& svg': {
      color: 'inherit',
      transition: '0.2s',
      transform: 'translateX(0) rotate(0)',
    },
    '&:hover, &:focus': {
      bgcolor: '#ede8f3',
       '& svg:last-of-type': {
        right: 0,
        opacity: 1,
      },
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      height: '80%',
      display: 'block',
      left: 0,
      width: '1px',
      bgcolor: 'divider',
    },lignItems: "center"
  }
}));

export default function PersistentDrawerLeft() {
  const { handleLogout } = useContext(Context);

  const query = useParams();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [nome, setNome] = React.useState('');

  const [userData, setUserData] = React.useState({});

  const isMenuOpen = Boolean(anchorElMenu);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    handleMenuClose();
    setAnchorElMenu(event.currentTarget);
    handleLogout();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setUserData(getUserData());
    setAnchorElMenu(null);
    handleMobileMenuClose();
  };

  const handleMenuClick = () => {
    setUserData(getUserData());
    handleDrawerOpen();
  };

  const handleMobileMenuOpen = (event) => {
    setUserData(getUserData());
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setUserData(getUserData());
    setOpen(!open);
  };

  const logout = () => {
    handleMenuClose();
    handleLogout();
  }

 const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorElMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      {/* <MenuItem onClick={logout}>Sair</MenuItem> */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Sair</p>
      </MenuItem>
    </Menu>
  );

  const [searchField, setSearchField] = React.useState('');
  // const handleChange = async (event) => {
  //  await setSearchField(event.target.value);
  //  console.log('aqui: ', event.target.value)
  // };

  const handleDataChange = async (event) => {
    // props.onSelectData(event.target.value); 
    // console.log('Data........: ', event.target.value)
    setSearchField(event.target.value)
  };

  const closeAllMenu = () => {
    handleMenuClose();
    setOpen(false);
  }

  const updateUserData = () => {
    setUserData(getUserData());
  }

  const classes = useStyles();

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateUserData()
    }, 500);
   });

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{
  //       vertical: 'top',
  //       horizontal: 'right',
  //     }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{
  //       vertical: 'top',
  //       horizontal: 'right',
  //     }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
  //     <MenuItem onClick={handleMenuClose}>My account</MenuItem>
  //   </Menu>
  // );


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        { !location.pathname.includes('login') && !location.pathname.includes('Erro404') ? // && !location.pathname === '/' ?
          <Toolbar variant="dense">
          { !userRoles('Demonstrativo', userData.userRoles) ?
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            : <></>}

            <Typography
              variant="overline"
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' } }}>
              <div style={{ wordWrap: 'break-word', lineHeight: '1.3' }}>
                Caixa Escolar Digital
                <br />
                Secretaria Municipal de Educação de Contagem - MG
                {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}
              </div>

            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Typography variant="overline" display="block" gutterBottom sx={{ pt: 1 }}>
                <span>{userData.user}</span>
              </Typography>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            { userRoles('ROLE_ADMIN, ROLE_SEDUC_VISUALIZAR, ROLE_SEDUC_EDITAR', userData.userRoles) ?
              <Link to="/unidades-escolares-list" style={{ textDecoration: 'none', color: 'white' }}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                  <HomeIcon />
                </IconButton>
              </Link>
              : <></>
            }

            { userRoles('ROLE_PRESIDENTE, ROLE_PRESTADOR_DE_CONTAS', userData.userRoles) ?
              <Link to={`/usuario/${userData.userId}`} style={{ textDecoration: 'none', color: 'white' }}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                  <HomeIcon />
                </IconButton>
              </Link>
              : <></>
            }

              {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>

              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit">
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit">
                {/* <AccountCircle /> */}
                <LogoutOutlinedIcon />
              </IconButton>

            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        : <> </> }
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}>
        <Toolbar variant="dense" />
        <List>
        { userRoles('Caixas Escolares - Admin', userData.userRoles) ?
          <ListItemButton
            key={'usuario'}
            sx={{ spy: 0, pt: 1 , pb: 1 }}
            onClick={() => {
              handleMenuClick();
              history.push(`/usuario/${userData.userId}`)
            }}
            className={`${classes.newItem}`}
          >
            <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Meus Dados'}
              primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
            />
          </ListItemButton>
            : <></>
        }

          { userRoles('Caixas Escolares - Admin', userData.userRoles) ?
            <ListItemButton
              key={'caixas-escolares-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/caixas-escolares-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <AccountBalanceOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Caixas Escolares'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Unidades Escolares - Admin', userData.userRoles) ?
            <ListItemButton
              key={'unidades-escolares-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/unidades-escolares-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <LocationCityOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Unidades Escolares'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Conselho Fiscal e Escolar - Admin', userData.userRoles) ?
            <ListItemButton
              key={'conselho-fiscal-escolar-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/conselho-fiscal-escolar-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Conselho Fiscal e Escolar'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Conselho Fiscal e Escolar - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'conselho-fiscal-escolar-list-por-caixa-escolar'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/conselho-fiscal-escolar-list/por-caixa-escolar/${userData.caixasEscolaresId}`)
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Conselho Fiscal e Escolar'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Demonstrativo Anual - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'demonstrativo-anual-caixa'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/demonstrativo-gerencial-anual-edit/${userData.caixasEscolaresId}`);
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <FeedOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Demonstrativo Anual'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Demonstrativo Anual - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'assembleia-escolar-menu-opcoes'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/assembleia-escolar-menu-opcoes/${ano}/${userData.caixasEscolaresId}`);
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <AccountBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Assembleia Escolar'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Planejamento Financeiro Anual - Caixax', userData.userRoles) ?
          <ListItemButton
            key={'planejamento-financeiro-anual'}
            sx={{ py: 0, pt: 1 , pb: 1 }}
            onClick={() => {
              handleMenuClick();
              history.push(`/planejamento-financeiro-anual-edit-form/${ano}/${userData.caixasEscolaresId}`);
            }}
            className={`${classes.newItem}`}
          >
            <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
              <StickyNote2OutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={'Planejamento das Despesas'}
              primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
            />
          </ListItemButton>
          : <></>
        }
        
          { userRoles('Presidentes Caixas Escolares - Admin', userData.userRoles) ?
            <ListItemButton
              key={'presidentes-caixas-escolares-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/presidentes-caixas-escolares-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <People />
              </ListItemIcon>
              <ListItemText
                primary={'Presidentes Caixas Escolares'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Termos de Compromisso - Admin', userData.userRoles) ?
            <ListItemButton
              key={'termos-de-cooperacao-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/termos-de-cooperacao-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <StickyNote2OutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Termos de Compromisso'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Planejamentos de Despesas - Admin', userData.userRoles) ?
            <ListItemButton
              key={'planejamento-de-despesas-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/planejamento-de-despesas-list/${ano}`)
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <StickyNote2OutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Planejamentos de Despesas'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Termos de Compromisso - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'termos-de-cooperacao-list-por-caixa-escolar'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/termos-de-cooperacao-list/${userData.caixasEscolaresId}`);
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <StickyNote2OutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Termos de Compromisso'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Gráficos - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'graficos-verba-caixa'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/graficos-verba-caixa/xx/xx`);
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <SignalCellularAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Gráficos'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Dados Bancarios - Admin', userData.userRoles) ?
            <ListItemButton
              key={'dados-bancarios-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/dados-bancarios-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <MonetizationOnOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Dados Bancários'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Dados Bancarios - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'dados-bancarios-por-caixa-escolar'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/dados-bancarios-list/${userData.caixasEscolaresId}`);
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <MonetizationOnOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Dados Bancários'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Quantitativo de Estudantes - Admin', userData.userRoles) ?
            <ListItemButton
              key={'quantitativo-estudantes-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/quantitativo-estudantes-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <SchoolOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Quantitativo de Estudantes'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Quantitativo de Estudantes - Unidades - Admin', userData.userRoles) ?
            <ListItemButton
              key={'quantitativo-estudantes-unidades-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/quantitativo-estudantes-unidades-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <ApartmentOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Quantitativo de Estudantes - Unidades'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Fornecedores Credores - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'fornecedores-credores-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/fornecedores-credores-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <ShoppingCartOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Fornecedores Credores'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Per Capitas - Admin', userData.userRoles) ?
            <ListItemButton
              key={'percapitas-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/percapitas-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <AttachMoneyOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Per Capitas'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Comprovantes de Despesas - Caixax', userData.userRoles) ?
            <ListItemButton
              key={'comprovantes-de-despesas-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/comprovantes-de-despesas-list')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <FeedOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Comprovantes de Despesas'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Correção de Prestações - Admin', userData.userRoles) ?
            <ListItemButton
              key={'parcelas/correcao/xxxx1'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/parcelas/correcao/xxxx"')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <FactCheckOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Correção de Prestações'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Além dos Muros - Admin', userData.userRoles) ?
            <ListItemButton
              key={'parcelas/correcao/xxxx2'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push('/parcelas/correcao/xxxx')
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <BusAlertOutlined />
              </ListItemIcon>
              <ListItemText
                primary={'Projeto Além dos Muros'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

          { userRoles('Além dos Muros - Caixa', userData.userRoles) ?
            <ListItemButton
              key={'projeto-alem-dos-muros-list'}
              sx={{ py: 0, pt: 1 , pb: 1 }}
              onClick={() => {
                handleMenuClick();
                history.push(`/projeto-alem-dos-muros-list/${ano}/${userData.caixasEscolaresId}`);
              }}
              className={`${classes.newItem}`}
            >
              <ListItemIcon sx={{ color: 'inherit', mx: 0 }}>
                <BusAlertOutlined />
              </ListItemIcon>
              <ListItemText
                primary={'Projeto Além dos Muros'}
                primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
              />
            </ListItemButton>
            : <></>
          }

        </List>

        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div onClick={closeAllMenu}>
          <Routes searchData={searchField}/>
        </div>
      </Main>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

