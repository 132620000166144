import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Print from '@mui/icons-material/PrintOutlined';
import Edit from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';

import SpinnerCarregando from '../SpinnerCarregando'

import AlertDeleteDialog from '../AlertDeleteDialog';

import api from "../../services/api";

import { useHistory, withRouter } from "react-router-dom";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 225,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados excluídos com sucesso!');
  const [backdrop, setBackdrop] = React.useState(false);

  const handleClickAlert = () => {
    // setOpenAlert(true);
  };

  // @ts-ignore
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleClick = (event) => {
    // console.log('id: ', props.id);
    setAnchorEl(event.currentTarget);
  };

  const handleOnClick = (type) => {
    handleClose();

    switch (type) {
      case 'edit':
        history.push(`/planejamento-financeiro-anual-edit-form/${props.row.anoId}/${props.row.caixasEscolaresId}`);
      break;

      default:
        break;
    }

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <SpinnerCarregando open={backdrop} />
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <Paper sx={{ width: 150, maxWidth: '100%' }}>
          <MenuList>

            <MenuItem onClick={() => handleOnClick('edit')}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Editar</ListItemText>
            </MenuItem>

          </MenuList>
        </Paper>
      </StyledMenu>
    </div>
  );
}