import * as React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
// import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
// import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
// import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
// import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
// import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';

const style = {
  pt: 5,
  px: { xs: 5, sm: 5, md: 20, xl: 20 },
  alignContent: 'center',
  display: 'flex'
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.subtitle2,
  padding: theme.spacing(3),
  textAlign: 'center',
 color: theme.palette.text.secondary,
  cursor: 'pointer',
  height: 170
}));

export default function ResponsiveGrid() {
  const history = useHistory();
  const query = useParams();

  return (
    <Box sx={{ ...style, flexGrow: 1 }}>
      <Grid container spacing={{ xs: 4, sm: 4, md: 4, xl: 4 }}>

        {/*<Grid item xs={12} sm={6} md={3} xl={3} key={1}>
          <Item onClick={() => history.push(`/edital-convocacao-assembleia-caixa-escolar/${query.ano}/${query.id}`)}>
            <div><ListAltOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>EDITAL DE CONVOCAÇÃO</div><div>CAIXA ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={2}>
          <Item onClick={() => history.push(`/edital-convocacao-assembleia-conselho-escolar/${query.ano}/${query.id}`)}>
            <div><StickyNote2OutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>EDITAL DE CONVOCAÇÃO</div><div>CONSELHO ESCOLAR</div>
          </Item>
        </Grid>
      */}
    
        <Grid item  xs={12} sm={6} md={3} xl={3} key={3}>
          <Item onClick={() => history.push(`/ata-caixa-escolar-opcoes/${query.ano}/${query.id}`)}>
            <div><FeedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>ATA</div><div>CAIXA ESCOLAR</div>
          </Item>
        </Grid>

        {/*
        <Grid item  xs={12} sm={6} md={3} xl={3} key={4}>
          <Item onClick={() => history.push(`/ata-conselho-escolar-opcoes/${query.ano}/${query.id}`)}>
            <div><BallotOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>ATA</div><div>CONSELHO ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={5}>
          <Item onClick={() => history.push(`/lista-de-presença-caixa-escolar-opcoes/${query.ano}/${query.id}`)}>
            <div><FormatListNumberedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>LISTA DE PRESENÇA</div><div>CAIXA ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={6}>
          <Item onClick={() => history.push(`/lista-de-presença-conselho-escolar-opcoes/${query.ano}/${query.id}`)}>
            <div><FormatListBulletedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>LISTA DE PRESENÇA</div><div>CONSELHO ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={7}>
          <Item onClick={() => history.push(`/formulario-crc-impressao/${query.ano}/${query.id}`)}>
            <div><FactCheckOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>FORMULÁRIO</div><div>CRC</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={8}>
          <Item onClick={() => history.push(`/formulario-dbe-impressao/${query.ano}/${query.id}`)}>
            <div><AssessmentOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>FORMULÁRIO</div><div>DBE</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={9}>
          <Item onClick={() => history.push(`/formulario-requerimento-cartorio-impressao/${query.ano}/${query.id}`)}>
            <div><SummarizeOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>REQUERIMENTO DO CARTÓRIO</div>
          </Item>
        </Grid>

        */}

      </Grid>
    </Box>
  );
}
