import React, { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { set, parseISO, format } from "date-fns";
import { convertToLocalTime } from 'date-fns-timezone';
import { formatCnpj, zeroPad, formatDateEnUs2, getUserData, userRoles } from '../../../../utils/functions';

export default function BasicDatePicker(props) {
  const [value, setValue] = React.useState(null);


  useEffect(() => {
    setValue(props.newData);
  }, [props.newData]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
      {/* <pre>{JSON.stringify(props.errorData, null, 2)}</pre> */}
      <DatePicker
        label="Data da Deliberação"
        value={parseISO(value) || null}
        inputProps={{ autoComplete: 'off', placeholder: "dd/mm/aaaa" }}
        readOnly={props.dataDaLiberacaoReadyOnly}
        onChange={(newValue) => {
          // setValue(formatDateEnUs2(newValue));
          props.handleDataDeliberacao(formatDateEnUs2(newValue));
        }}
        renderInput={(params) =>
          <TextField
          {...params}
          size="small"
          fullWidth
          focused
          required
          error={props.errorData}
          helperText={props.errorData ? props.errorText : ''}
        />}
      />
    </LocalizationProvider>
  );
}
