import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select } from "@mui/material";
import { Formik, Form, } from "formik";
import FormikField from '../../../components/Controls/FormikField';

import TelefonesEditPostTable from "../../../components/TelefonesEditPostTable";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function App() {
  const query = useParams();
  const history = useHistory();

  const [tipo, setTipo] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState([]);
  const [initialTipos, setInitialTipos] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    // @ts-ignore
    const values = api.get(`unidades-escolares/${query.id}`)
    values.then((response) => {
      setInitialValues(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
   const values = api.get("tipos-unidades-escolares")
    values.then((response) => {
      setInitialTipos(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    // @ts-ignore
      const result = initialTipos.find(item => item.value === initialValues.tipoId);
      if (result) {
        // console.log('result 1: ', result);
        setTipo(result);
      }
   }, [initialValues]);

   useEffect(() => {
     // @ts-ignore
    const result = initialTipos.find(item => item.value === initialValues.tipoId);
    if (result) {
      // console.log('result 2: ', result);
      setTipo(result);
    }
   }, [initialTipos]);

   return (initialValues ? 
    <Container maxWidth="xl">
    <Typography component="div" style={{ height: '100vh - 48px' }}>
    <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          // console.log('data: ', data)
          const payload = {
            // @ts-ignore
            'id': data.id,
            // @ts-ignore
            'nome': data.nome,
            // @ts-ignore
            'termo': data.termo,
            // @ts-ignore
            'codigo_credor': data.codigo_credor,
            // @ts-ignore
            'tipo': data.tipoId
          }
          // @ts-ignore
          const values = api.put(`caixas-escolares/${data.id}`, payload)
          values.then((response) => {
            setSubmitting(false);
            // console.log("submit: ", data);
          })
          .catch((err) => {
            setSubmitting(false);
            console.error("ops! ocorreu um erro" + err);
          })
        }}>
        {({ values, errors, isSubmitting }) => (
          <Form>
            
            <Grid container spacing={2 }  sx={{ mt: 3 }}>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ ml: 2 }}
            >
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Cadastro de Unidade Escolar</Box>
                </Typography> 
              </Box>
              <Box
                component="div"
                className={`${classes.bottomRightBox} ${classes.box}`}>
                  <div>
                    <Button onClick={history.goBack} type="submit" variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20}}>
                      Cancelar
                    </Button>
                  </div>

                  <div>
                    <Button type="submit" variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                      Gravar
                    </Button>
                  </div>
              </Box>
            </Grid>
            
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <div>
                <Autocomplete
                  sx={{ mt: 1.25 }}
                  id="controlled-demo"
                  value={tipo}
                  options={initialTipos}
                  onChange={(event, newValue) => {
                    // @ts-ignore
                    setTipo(newValue);
                    // console.log('newValue: ', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipo Unidade Escolar 1" variant="outlined" size="small" fullWidth focused required />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
              <FormikField name="nome" label="Nome" required />
            </Grid>


            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <FormikField name="caixa_escolar_nome" label="Caixa Escolar" />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <FormikField name="cnpj" label="CNPJ" />
              </Grid>

              
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <FormikField name="termo" label="Termo" required />
              </Grid>

              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <FormikField name="codigo_credor" label="Código Credor" required />
              </Grid>

              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <FormikField name="cep" label="CEP" required />
              </Grid>

              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <FormikField name="logradouro" label="Rua/Av." required />
              </Grid>

              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <FormikField name="numero" label="Número" required />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <FormikField name="complementos" label="Complemento" />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <FormikField name="bairro" label="Bairro" required />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <FormikField name="regiao" label="Região" required />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <FormikField name="cidade" label="Cidade" required />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <FormikField name="estado" label="Estado" required />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormikField name="email" label="E-mail" required />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box>
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Telefone(s)</Box>
                  </Typography> 
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TelefonesEditPostTable />
              </Grid>
            </Grid>

            {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
      </Paper>
      </Typography>
      </Container>
    :
    <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando ...</span></Typography>
  );
}
