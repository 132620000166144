import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { set, parseISO, format } from "date-fns";
import { convertToLocalTime } from 'date-fns-timezone';
import { formatCnpj, zeroPad, formatDateEnUs2, getUserData, userRoles, formatDatePtBr } from '../../../../utils/functions';
import CircularProgress from '@mui/material/CircularProgress';
import api from "../../../../services/api";

import OrcamentosTable from '../OrcamentosTable';

import DataDaDeliberacao from '../DataDaDeliberacao';
import AlertShowErrorsDeliberacao from "../AlertShowErrorsDeliberacao";
import ConfirmAlert from '../ConfirmAlert';

import OrcamentosEditForm from '../OrcamentosEditForm';

const style = {
  pt: 5,
  px: { xs: 5, sm: 5, md: 20, xl: 20 },
  alignContent: 'center',
  display: 'flex'
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.subtitle2,
  padding: theme.spacing(3),
  textAlign: 'center',
 color: theme.palette.text.secondary,
  cursor: 'pointer',
  height: 170
}));

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  negativeItem: {
    color: "#c15165"
  },
  positiveItem: {
    color: "#4a89c8"
  }
}));

export default function ResponsiveGrid() {
  const query = useParams();
  const history = useHistory();
  const location = useLocation();
  const [showGravarParcialmente, setShowGravarParcialmente]  = React.useState(false);
  const [showGravarFinalizar, setShowGravarFinalizar]  = React.useState(false);
  const [showImprimir, setShowImprimir]  = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorDataDeliberacao, setErrorDataDeliberacao] = React.useState(false);
  const [errorText, setErrorText] = React.useState('Campo obrigatório!');
  const [valorTotal, setValorTotal] = React.useState(0.00);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const [errors, setErrors]= React.useState([]);

  const classes = useStyles();

  const [titulo, setTitulo] = React.useState("TERMO DE COMPROMISSO");
  const [newData, setNewData] = React.useState("");

  const [data, setData] = React.useState({
    termosDeCooperacaoAno: "",
    termosDeCooperacaoNumero: "",
    dataDeliberacao: "",
    unidadesEscolaresTiposAbreviacao: "",
    unidadesEscolaresNome: "",
    caixasEscolaresNome: "",
    tipoRepasse: "",
    dataCredito: "",
    numeroTermo: "",
    anoTermo: "",
    valorParcela: "",
    bancoNome: "",
    bancoCodigo: "",
    agenciaNome: "",
    agenciaCodigo: "",
    contaBancariaNumero: "",
    mesaDiretora: {
      vigenciaMandato: "",
      numeroDeRegistroEmCartorio: "",
      membros: {
        presidente: "",
        tesoureiro: "",
        secretario: "",
        conselhoFiscalMagisterio: "",
        conselhoFiscalAdministrativo: "",
        conselhoFiscalPais: ""
      }
    }
  });

  const handleDataDeliberacao = (date) => {
    console.log(date);
    setNewData(date);
    checkDate(date);
  }

  const handleCloseDialog = () => {
    setOpenAlertDialog(false);
  }

  const checkDate = (date) => {
    setErrorDataDeliberacao(false);
    if (date && date.length === 10 ) {
      if (date < data.dataPagamento) {
        setErrorDataDeliberacao(true);
        setErrorText('Data anterior ao depósito da verba!');
        return false;
      } else if (date > data.dataPrevistaFinal) {
        setErrorDataDeliberacao(true);
        setErrorText('Data posterior a data de prestação de contas!');
        return false;
      }
    }
    return true;
  }

  const checkDateSize = (date) => {
    if (!date || date.length < 10 ) {
      setErrorDataDeliberacao(true);
      return false;
    }
    return true;
  }

  const handleValorTotal = (value) => {
    setValorTotal(value);
  }

  const saveData  = (finalizado) => {
      setIsLoading(true);
      const payload = {
        id: data.id,
        data: newData,
        finalizado: finalizado
      }
      api.put(`/deliberacoes-conselho/${data.id}`, payload)
      .then((response) => {
        getData();
        setOpenAlert(true);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
        setIsLoading(false);
      })
  }

  const checkErrors = (finalizado) => {
    let errors = false;
    let errorsText = [];
    setErrors(errorsText);
    if (!checkDate(newData) || !checkDateSize(newData)) {
      errors = true;
      errorsText.push({id: 1, label: 'DATA DA REUNIÃO DA LIBERAÇÃO'});
    }

    if ((parseFloat(valorTotal) !== parseFloat(data.verbaTc)) && finalizado) {
      errors = true;
      errorsText.push({id: 2, label: 'VALOR TOTAL DAS AÇÕES - MDE'});
    }

    if (!errors) {
      saveData(finalizado);
    } else {
      setErrors(errorsText);
      setOpenAlertDialog(true);
    }
  }

  const handleCloseConfirmAlert = () => {
    setOpenAlert(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    setShowImprimir(false);
    setShowGravarParcialmente(false);
    setShowGravarFinalizar(false);
    if (query.id) {
      api.get(`/deliberacoes-conselho/por-caixa-escolar-por-parcela/${query.id}`)
      .then((response) => {
        const data = response.data;
        setData(data)
        const titulo = "NÚMERO: " + zeroPad(data.termosDeCooperacaoNumero, 3) + "/" + data.termosDeCooperacaoAno;
        setNewData(data.data);
        setTitulo(titulo);
        setIsLoading(false);
        if (data.finalizado === 1) {
          setShowImprimir(true);
        } else {
          setShowGravarParcialmente(true);
          setShowGravarFinalizar(true);
        }
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
        setIsLoading(false);
      })
    }
  }

  return (
    <Box sx={{ ...style, flexGrow: 1 }}>
    {
      isLoading
      ?
      <>

        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress/>
        </Box>

      </>
      :
      <Grid container spacing={{ xs: 4, sm: 4, md: 4, xl: 4 }}>
        <AlertShowErrorsDeliberacao
          openAlertDialog={openAlertDialog}
          handleCloseDialog={handleCloseDialog}
          errors={errors}
        />

        <ConfirmAlert
        openAlert={openAlert}
        severity={severity}
        message={message}
        handleCloseConfirmAlert={handleCloseConfirmAlert}
      />


        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 0, pb: 5 }}>
          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            <Grid item xs={7}>
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
                    <div>
                      ORÇAMENTOS
                    </div>
                    <div>
                      {data.unidadesEscolaresTiposAbreviacao} {data.unidadesEscolaresNome}
                    </div>
                    <div>
                      <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9', pt: 0, pl: 0 }}>
                        {data.caixasEscolaresNome} - CNPJ: {formatCnpj(data.caixasEscolaresCnpj)}
                      </Box>
                    </div>
                  </Box>
                </Typography>
              </Box>
            </Grid>

          <Grid item xs={5} sx={{ pr: 0 }}>
            <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}
              sx={{ pr: 4 }}>

              {
                showGravarParcialmente ?
                <div style={{ paddingRight: 20 }}>
                  <Button onClick={() => {checkErrors(null)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                    Gravar Parcialmente
                  </Button>
                </div>
                :
                <></>
              }

              {
                showGravarFinalizar ?
                <div style={{ paddingRight: 20 }}>
                  <Button onClick={() => {checkErrors(1)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                    Gravar Finalizar
                  </Button>
                </div>
                :
                <></>
              }

              {
                showImprimir ?
                <div style={{ paddingRight: 20 }}>
                <Button onClick={() => {history.push(`/parcelas/deliberacao-conselho-impressao/${data.parcelasId}`)}} variant="outlined" color="primary" size="small" sx={{ px: 2 }} style={{ height: 35 }}>
                  Imprimir
                  </Button>
                </div>
                :
                <></>
              }

              <div>
                <Button onClick={() => {history.push(`/parcelas/prestacao-de-contas/${data.parcelasId}`)}} variant="outlined" color="primary" size="small" sx={{ px: 2 }} style={{ height: 35 }}>
                  Voltar
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>

          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <TextField
              id="outlined-multiline-flexible"
              label="Termo de Compromisso"
              name="termo"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={titulo}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <TextField
              id="outlined-multiline-flexible"
              label="Parcela"
              name="parcela"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.numerosParcelasDescricao}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <TextField
              id="outlined-multiline-flexible"
              label="Parcela"
              name="parcela"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.tiposTermosDescricao + " - " + data.termosTiposDescricao}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={2} xl={2} sx={{ flex: '100%' }}>
            <DataDaDeliberacao
              newData={newData}
              errorData={errorDataDeliberacao}
              errorText={errorText}
              handleDataDeliberacao={handleDataDeliberacao}
              dataDaLiberacaoReadyOnly={true}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="outlined-multiline-flexible"
              label="Descrição"
              name="descricao"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.detalhamentosDasDespesasDescricao}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="outlined-multiline-flexible"
              label="Origem dos Recursos"
              name="origemDosRecursos"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.origemDosRecursosDescricao + " - " + data.origemDosRecursosAbreviacao}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 4 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <TextField
              id="outlined-multiline-flexible"
              label="Valor da Parcela"
              name="valorDaParcela"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.verbaTc ? 'R$ ' + parseFloat(data.verbaTc).toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "0,00"}
            />
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <TextField
              id="outlined-multiline-flexible"
              label="Data do Crédito"
              name="dataDoCredito"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={formatDatePtBr(data.dataPagamento)}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <TextField
              id="outlined-multiline-flexible"
              label="Banco"
              name="banco"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.bancosNome + "/" + data.bancosCodigo}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <TextField
              id="outlined-multiline-flexible"
              label="Agência"
              name="agencia"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.dadosBancariosNomeAgencia + "/" + data.dadosBancariosNumeroAgencia}
            />
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <TextField
              id="outlined-multiline-flexible"
              label="Conta Bancária"
              name="contaBancaria"
              fullWidth
              focused
              size="small"
              inputProps={{  }}
              value={data.dadosBancariosNumeroContaCorrente}
            />
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pt: 1, pb: 1 }}>
          <OrcamentosTable
            data={data}
            handleValorTotal={handleValorTotal}
          />
        </Grid>

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pt: 1, pb: 1 }}>
          <OrcamentosEditForm />
        </Grid>

        
      </Grid>
    }
    </Box>
  );
}
