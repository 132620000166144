import { Box } from "@mui/system";
import React from "react";

// import Regional from "../Regional";
// import StatusCaixasEscolares from "../StatusCaixasEscolares";
import TiposEnsino from "../TiposEnsino";
// import SelectComponent from "../Controls/SelectComponent";
import AutoCompleteNewSelect from "../Controls/AutoCompleteNewSelect";
import AutoCompleteSelect from "../Controls/AutoCompleteSelect";
import api from '../../services/api';

export default function SearchBar(props) {
  const [stateValueTiposEnsinos, setStateValueTiposEnsinos] = React.useState([]);
  const [tiposPercapitas, setTiposPercapitas] = React.useState('');
  const [anos, setAnos] = React.useState([]);

  const handleDataAnos = (value) => {
    // console.log('value: ', value);
    const anos = [];
    value.map(
      (item) => {
        anos.push(item.id)
      }
   )
   setAnos(anos);
  //  console.log('anos:  ', anos);
  }

  const handleDataTiposEnsinos = (value) => {
    const tiposEnsinos = [];
    value.map(
      (item) => {
        tiposEnsinos.push(item.id)
      }
   )
   setStateValueTiposEnsinos(tiposEnsinos);
  //  console.log('tiposEnsinos:  ', tiposEnsinos);
  }

  const handleTiposPercaptas = (value) => {
    const tiposPercapitas = [];
    value.map(
      (item) => {
        tiposPercapitas.push(item.id)
      }
   )
   setTiposPercapitas(tiposPercapitas);
  //  console.log('tiposPercapitas:  ', tiposPercapitas);
  }

  React.useEffect(() => {
    const data = [{'anos': anos}, {'tiposEnsinos': stateValueTiposEnsinos}, {'tiposPercapitas': tiposPercapitas}]
    props.onSelectData(data);
  }, [anos, stateValueTiposEnsinos, tiposPercapitas]);

  return (
      <>
          {/* <Box
              sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap' ,
              alignItems: 'center',
              bgcolor: 'background.paper',
          }}> */}
              <Box
                  sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap' ,
                  // flexGrow: 1,
                  flex: '100%',

                  direction: "row",
                  justifyContent: "space-between",
                  alignItems: "center",

                  pt: 0,
                  pb: 0,
                  // p: 1,
                  // m: 1,
                  bgcolor: 'background.paper',
              }}>
              {/* <pre>{JSON.stringify(anos, null, 2)}</pre> */}
                  <Box sx={{ pr: 1, width: "33%" }}>
                      <AutoCompleteSelect nLabel="Anos" newWidth="450" variant="outlined" apiConst="anos" onSelectData={handleDataAnos} />
                  </Box>

                  <Box sx={{ pr: 1, width: "33%" }}>
                      <AutoCompleteNewSelect nLabel="Tipos Ensinos" newWidth="450" variant="outlined" apiConst="tipos-ensinos" onSelectData={handleDataTiposEnsinos} />
                  </Box>

                  <Box sx={{ pr: 0, width: "33%" }}>
                    <AutoCompleteNewSelect nLabel="Tipos Percapitas" newWidth="450" variant="outlined" apiConst="percaptas-descricoes" onSelectData={handleTiposPercaptas} />
                  </Box>
              </Box> 

          {/* </Box> */}
      </>
  )
}