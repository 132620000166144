import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "yellow"
  }
}));

export default function SpinnerCarregando(props) {
  const [open, setOpen] = React.useState(false);
  // @ts-ignore
  const classes = useStyles();
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const handleToggle = () => {
//     setOpen(!open);
//   };

  return (
    <div>
      <Backdrop
        invisible
        open={props.open}
        // onClick={handleClose}
      >
        <div><CircularProgress color="primary" /></div>
      </Backdrop>
    </div>
  );
}
