import React, { useEffect }  from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { formatDateEnUs } from "../../utils/functions";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { date } from 'yup/lib/locale';

// import api from '../../services/api';

export default function EditDialog(props) {
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // props.tiposDeObservacoes

  const [tipoSelected, setTipoSelected] = React.useState([]);
  const [valueObservacao, setValueObservacao] = React.useState("");
  const [dataCorrecao, setDataCorrecao] = React.useState(null);
  const [situacaoSelected, setSituacaoSelected] = React.useState(null);
  const [dataInsercao, setDataInsercao] = React.useState(null);

  const handleChange = (event) => {
    setValueObservacao(event.target.value);
  };

  const optionsSituacao = [
    {
      "id": "1",
      "value": "1",
      "label": "CORRIGIDO"
    },
    {
      "id": "0",
      "value": "0",
      "label": "PENDENTE"
    }
  ];
  
  useEffect(() => {
    setValueObservacao("");
    setDataCorrecao(null);
    setTipoSelected(null);
    setSituacaoSelected({
      "id": "0",
      "value": "0",
      "label": "PENDENTE"
    })
    const newDate = new Date().toLocaleDateString('en-US', {timeZone: 'UTC'})
    // const newDate = new Date().toISOString();
    console.log(newDate)
    setDataInsercao(newDate);
    // if (props.rowSelected) {
    //   setValueObservacao(props.rowSelected.observacao);
    //   setDataCorrecao(new Date(props.rowSelected.dataCorrecao).toLocaleDateString('en-US', {timeZone: 'UTC'}));
    //   setSituacaoSelected(optionsSituacao[1]);
    //   if (Number(props.rowSelected.corrigido) === 1) {
    //     setSituacaoSelected(optionsSituacao[0]);
    //   }
    // }
  }, [props.open]);

  const createNewRegister = () => {
    props.insertRegister(valueObservacao.toUpperCase(), dataCorrecao, situacaoSelected.id, tipoSelected.id, dataInsercao);
    props.handleCloseInsertDialog();
  }
  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.handleCloseEditDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Inserção"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Observação"
              multiline
              fullWidth
              focused
              maxRows={4}
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={valueObservacao}
              onChange={handleChange}
            />
          </Box>

          <Box sx={{ pt: 2, pb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
              <DatePicker
                label="Data da Correção"
                value={dataCorrecao}
                disabled={situacaoSelected && situacaoSelected.id !== "1"}
                onChange={(newValue) => {
                  setDataCorrecao(null);
                  if (newValue) {
                    setDataCorrecao(newValue);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  size="small"
                  fullWidth
                  focused
                  required
                  disabled={situacaoSelected && situacaoSelected.id !== "1"}
                  error={(situacaoSelected && situacaoSelected.id === "1" && !dataCorrecao) || (situacaoSelected && situacaoSelected.id !== "1" && dataCorrecao)}
                  helperText={situacaoSelected && situacaoSelected.id === "1" && !dataCorrecao ? 'Campo incorreto!' : ''}
                />}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ pt: 2, pb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
              <DatePicker
                label="Data da Inserção"
                value={dataInsercao}
                onChange={(newValue) => {
                  setDataInsercao(null);
                  if (newValue) {
                    console.log(newValue)
                    setDataInsercao(newValue);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  size="small"
                  fullWidth
                  focused
                  required
                  error={!dataInsercao}
                  helperText={!dataInsercao ? 'Campo incorreto!' : ''}
                />}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ pb: 2 }}>
            <Autocomplete
              id="controlled-caixa-escolar"
              value={situacaoSelected}
              options={optionsSituacao}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size="small"
              onChange={(event, newValue) => {
                if (situacaoSelected && situacaoSelected.id !== "1") {
                  setDataCorrecao(null);
                }
                setSituacaoSelected(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Situação"
                  fullWidth
                  focused
                  // style={{ width: 300 }}
                />
              )}
            />
          </Box>

          <Box sx={{ pb: 2 }}>
            <Autocomplete
              id="controlled-caixa-escolar"
              value={tipoSelected}
              options={props.tiposDeObservacoes}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size="small"
              onChange={(event, newValue) => {
                setTipoSelected(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Tipo"
                  fullWidth
                  focused
                  // style={{ width: 300 }}
                />
              )}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseInsertDialog}>Cancela</Button>
          <Button
            onClick={createNewRegister}
            disabled={valueObservacao.length === 0 ||
               !situacaoSelected ||
               (situacaoSelected && situacaoSelected.id === "1" && 
               (!dataCorrecao ||
              (new Date(dataCorrecao).toLocaleDateString("pt-BR").toString().length !== 10))) ||
              !dataInsercao} autoFocus>
            Gravar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}