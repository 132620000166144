import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { set, parseISO, format } from "date-fns";
import { convertToLocalTime } from 'date-fns-timezone';
import { formatDateEnUs2 } from "../../../utils/functions";

import AlertaMembrosConselho from '../../../components/AlertaMembrosConselho';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';

import { formatCNPJ } from "../../../utils/functions";
import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeft: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  center: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRight: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreed: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const paperStyle = {
  pt: 2,
  px: { xs: 8, sm: 8, md: 8, xl: 8 },
  m: { xs: 8, sm: 8, md: 8, xl: 8 },
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column'
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EnhancedTable(props) {
  const history = useHistory();
  const classes = useStyles();
  const query = useParams();
  const [title, setTitle]=useState('ATA CAIXA ESCOLAR OPÇÕES');
  const [subTitle, setSubTitle]=useState('');

  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados exibidos com sucesso!');
  const [backdrop, setBackdrop] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [finalizado, setFinalizado] = React.useState(null);
  const [ano, setAno] = React.useState('');
  const [dataAssembleia, setDataAssembleia] = React.useState(null);
  const [tipoAssembleia, setTipoAssembleia] = React.useState(null);
  const [tiposAssembleiasList, setTiposAssembleiasList] = React.useState([
    {
      value: '54db414f-8bc8-11ec-a3f8-dc41a970c8d9',
      label: 'PRESENCIAL',
    },
    {
      value: 'aed5ef7b-8bc8-11ec-a3f8-dc41a970c8d9',
      label: 'VIRTUAL - GOOGLE MEET',
    },
  ]);
  const [simNaoList, setSimNaoList] = React.useState([
    { value: '0', label: 'NÃO' },
    { value: '1', label: 'SIM' }
  ]);
  const [noNaList, setNoNaList] = React.useState([
    { value: 'NO', label: 'NO' },
    { value: 'NA', label: 'NA' }
  ]);
  const [horasInicioList, setHorasInicioList] = React.useState([
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' }
  ])
  const [minutosInicioList, setMinutosInicioList] = React.useState([
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' }
  ]);
  const [id, setId] = React.useState(null);
  const [vicePresidenteTesoureiro, setVicePresidenteTesoureiro] = React.useState(null);
  const [horaInicio, setHoraInicio] = React.useState(null);
  const [minutosInicio, setMinutosInicio] = React.useState(null);
  const [observacoes, setObservacoes] = React.useState('');
  const [local, setLocal] = React.useState('');
  const [preenchido, setPreenchido] = React.useState('');
  const [errors, setErrors] = React.useState([]);
  const [caixasEscolaresHasCargosFuncoes, setCaixasEscolaresHasCargosFuncoes] = React.useState([]);
  const [noNaLocal, setNoNaLocal] = React.useState('');
  const handleChange = (event) => {
    setObservacoes(event.target.value.toUpperCase());
  };
  const handleChangeLocal = (event) => {
    setLocal(event.target.value.toUpperCase());
  };

  const  goBack = () => {
    history.push(`/assembleia-escolar-menu-opcoes/${query.ano}/${query.id}`);
  }
  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const changeOpenDialog = () => {
    setOpenDialog(!openDialog);
  }

  const checkErrors = (type) => {
    let dados = [];
    const anoAssembleia = dataAssembleia ? Number(dataAssembleia.substring(0,4)) : null;
    const mesAssembleia = dataAssembleia ? dataAssembleia.substring(5,7) : null;
    if (!dataAssembleia) {
      dados.push('dataAssembleia');
    }
    if (anoAssembleia !== Number('2022')) {
      dados.push('dataAssembleia');
    }
    if (mesAssembleia !== '02') {
      dados.push('dataAssembleia');
    }
    if (!tipoAssembleia) {
      dados.push('tipoAssembleia');
    }
    if (!vicePresidenteTesoureiro) {
      dados.push('vicePresidenteTesoureiro');
    }
    if (!noNaLocal) {
      dados.push('noNaLocal');
    }
    if (!local) {
      dados.push('local');
    }
    if (!horaInicio) {
      dados.push('horaInicio');
    }
    if (!minutosInicio) {
      dados.push('minutosInicio');
    }
    setErrors(dados);

    if (dados.length === 0) {
      if (type === 'save') {
        saveData(null);
      } else if (type === 'saveEnd') {
        saveData(1);
      } else if (type === 'printAta') {
        printAta();
      }
    }
  }

  const saveData = (newFinalizado) => {
    const payload = {
      id: id,
      tiposAssembleiasId: tipoAssembleia.value,
      viceTesoureiro: vicePresidenteTesoureiro.value,
      data: dataAssembleia,
      horarioHoras: horaInicio.value,
      horarioMinutos: minutosInicio.value,
      observacoes: observacoes,
      finalizado: newFinalizado,
      noNaLocal: noNaLocal.value,
      local: local
    }

    setBackdrop(true);
    const values = api.put(`/assembleias-escolares/${id}`, payload)
      .then(
        response => {
          setMessage('Dados atualizados com sucesso!');
          setBackdrop(false);
          setSeverity('success');
          handleClickAlert();
          setBackdrop(false);
          getData();
        })
      .catch((err) => {
        setMessage('Erro ao acessar os dados! Verifique!');
        setBackdrop(false);
        setSeverity('error');
        handleClickAlert();
        console.error("ops! ocorreu um erro" + err);
      })

      // const values = api.put(`assembleias-escolares/${id}`, payload)
      // .then((response) => {
      //   setUsuariosSituacoesId(response.data.usuariosSituacoesId);
      //   setMessage('Dados gravados com sucesso!');
      //   setSeverity('success');
      //   selectedCheck(response.data.usuariosSituacoesId);
      //   setBackdrop(false);
      //   handleClickAlert();
      // })
      // .catch((err) => {
      //   setMessage('Erro ao gravar os dados! Verifique!');
      //   setSeverity('error');
      //   setBackdrop(false);
      //   handleClickAlert();
      //   console.error("ops! ocorreu um erro" + err);
      // })

  }

  const printAta = () => {
    history.push(`/ata-caixa-escolar-impressao/${query.ano}/${query.id}`)
  }

  const getData = () => {
    setBackdrop(true);
    const values = api.get(`assembleias-escolares/por-caixa-escolar/${query.ano}/${query.id}`)
      .then(
        response => {
          const data = response.data;
          const cnpj = formatCNPJ(data.caixasEscolaresCnpj);
          setAno(data.ano);
          setTitle(`ATA CAIXA ESCOLAR OPÇÕES - ANO: ${data.ano}`);
          setSubTitle(`${data.caixasEscolaresNome} - CNPJ: ${cnpj}`);

          setId(data.id);
          setFinalizado(data.finalizado);
          setTipoAssembleia(tiposAssembleiasList.find(item => item.value === data.tiposAssembleiasId));
          setHoraInicio(horasInicioList.find(item => item.value === data.horarioHoras));
          setMinutosInicio(minutosInicioList.find(item => item.value === data.horarioMinutos));
          setVicePresidenteTesoureiro(simNaoList.find(item => item.value === data.viceTesoureiro));
          setDataAssembleia(data && data.data ? data.data: null);
          setObservacoes(data.observacoes ? data.observacoes : '');
          setNoNaLocal(noNaList.find(item => item.value === data.noNaLocal));

          setCaixasEscolaresHasCargosFuncoes(data.caixasEscolaresHasCargosFuncoes);

          const newLocal = data.unidadesEscolaresTipoId === '19196c78-1a60-11ec-b3db-00155d3afd23' ? 'PÁTIO DO CEMEI' : 'PÁTIO DA ESCOLA';

          setLocal(data.local ? data.local : newLocal);
          setPreenchido(data.preenchido);
          setBackdrop(false);

          // console.log('data.preenchido: ', data.preenchido);

          if (!data.preenchido) {
            setOpenDialog(true);
          }

        })
      .catch((err) => {
        setMessage('Erro ao acessar os dados! Verifique!');
        setBackdrop(false);
        setSeverity('error');
        handleClickAlert();
        console.error("ops! ocorreu um erro" + err);
      })
  }

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Box sx={{ width: '100%', mt: 5 }}>
      <Paper elevation={3} sx={{ ...paperStyle, flexGrow: 1  }}>

        <AlertaMembrosConselho open={openDialog} changeOpenDialog={changeOpenDialog}/>

        <SpinnerCarregando open={backdrop} />

        <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
          <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
            {message}
          </Alert>
        </Snackbar>

        <Grid
          container
          direction="row"
          className={`${classes.bottomRight} ${classes.box}`}
          sx={{ flex: '100%', px: 4, pb: 1 }}>
          <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{title}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{ pb: 0 }}>
            <Box
              component="div"
              className={`${classes.bottomRight} ${classes.box}`}>
              <div>
                <Button
                  onClick={() => {checkErrors('printAta')}}
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={!finalizado}
                  style={{ height: 35, marginRight: 20}}>
                  Imprimir
                </Button>
              </div>

              { 
                Number(finalizado) !== 1 ?
                <div>
                  <Button
                    onClick={() => {checkErrors('save')}}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ height: 35, marginRight: 20}}>
                    Gravar
                  </Button>
                </div>
              : <></> }

              <div>
                <Button
                  onClick={() => {checkErrors('saveEnd')}}
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={Number(finalizado) === 1}
                  style={{ height: 35, marginRight: 20}}>
                  Gravar/Finalizar
                </Button>
              </div>

              <div>
                <Button
                  onClick={goBack}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ height: 35, marginRight: 20}}>
                  Voltar
                </Button>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{subTitle}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                  <DatePicker
                    label="Data de Assembleia"
                    value={parseISO(dataAssembleia) || null}
                    onChange={(newValue) => {
                      setDataAssembleia(formatDateEnUs2(newValue));
                    }}
                    renderInput={(params) =>
                      <TextField
                      {...params}
                      size="small"
                      fullWidth
                      focused
                      required
                      error={errors.includes('dataAssembleia')}
                      helperText={errors.includes('dataAssembleia') ? 'Campo obrigatório!' : ''}
                    />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ flex: '100%', pb: 2 }}>
              <Autocomplete
                id="hora-inicio"
                value={horaInicio || null}
                options={horasInicioList}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  setHoraInicio(null);
                  if (newValue) {
                    setHoraInicio(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Horas (Início)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    error={errors.includes('horaInicio')}
                    helperText={errors.includes('horaInicio') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ flex: '100%', pb: 2 }}>
              <Autocomplete
                id="minutos-inicio"
                value={minutosInicio || null}
                options={minutosInicioList}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  setMinutosInicio(null);
                  if (newValue) {
                    setMinutosInicio(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Minutos (Início)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    error={errors.includes('minutosInicio')}
                    helperText={errors.includes('minutosInicio') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ flex: '100%', pb: 2 }}>
              <Autocomplete
                id="tipo-assembleia"
                value={tipoAssembleia || null}
                options={tiposAssembleiasList}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  setTipoAssembleia(null);
                  if (newValue) {
                    setTipoAssembleia(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo Assembleia"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    error={errors.includes('tipoAssembleia')}
                    helperText={errors.includes('tipoAssembleia') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ flex: '100%', pb: 2 }}>
              <Autocomplete
                id="vice-presidente-tesoureiro"
                value={vicePresidenteTesoureiro || null}
                options={simNaoList}
                onChange={(event, newValue) => {
                  setVicePresidenteTesoureiro(null);
                  if (newValue) {
                    setVicePresidenteTesoureiro(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vice-presidente é o Tesoureiro(a)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    error={errors.includes('vicePresidenteTesoureiro')}
                    helperText={errors.includes('vicePresidenteTesoureiro') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={1} lg={1} xl={1} sx={{ flex: '100%', pb: 2 }}>
              <Autocomplete
                id="vice-presidente-tesoureiro"
                value={noNaLocal || null}
                options={noNaList}
                onChange={(event, newValue) => {
                  setNoNaLocal(null);
                  if (newValue) {
                    setNoNaLocal(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="No/Na"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    error={errors.includes('noNaLocal')}
                    helperText={errors.includes('noNaLocal') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
          </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ flex: '100%', pb: 2 }}>
              <TextField
                id="outlined-multiline-static"
                label="Local"
                fullWidth
                size="small"
                focused
                value={local}
                onChange={handleChangeLocal}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ flex: '100%', pb: 2 }}>
              <TextField
                id="outlined-multiline-static"
                label="Observações"
                multiline
                fullWidth
                rows={4}
                focused
                value={observacoes}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>MEMBROS DA DIRETORIA/CONSELHO FISCAL</Box>
                </Typography>
              </Box>

              <List>
                {caixasEscolaresHasCargosFuncoes.map(item => (

                  <ListItem disablePadding key={item.id}>
                    <ListItemButton>
                      <ListItemIcon>
                        {
                          item.pessoasNome !== '' && item.telefones.length > 0 ?
                          <CheckOutlinedIcon color="primary" />
                          : <CloseOutlinedIcon color="error" />
                        }
                      </ListItemIcon>
                      {
                        item.pessoasNome !== '' ?
                        <ListItemText primary={`${item.cargosFuncoesDescricao} - ${item.pessoasNome}`} />
                        : <ListItemText primary={`${item.cargosFuncoesDescricao}`} />
                      }
                    </ListItemButton>
                  </ListItem>
                  
                ))}
              </List>
            </Grid>

          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
