import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Box, Grid, Paper, Typography, Button, TextField } from "@mui/material";

import ItensNotasFiscaisRecibos from '../../components/Controls/ItensNotasFiscaisRecibos';

import api from '../../services/api';

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: 'ordem',
    align: 'center',
    disablePadding: false,
    label: 'Ordem',
  },
  {
    id: 'quantidadeComercial',
    align: 'center',
    disablePadding: false,
    label: 'Quantidade',
  },
  {
    id: 'unidadesDeMedidasAbreviacao',
    align: 'center',
    disablePadding: false,
    label: 'Unidade de Medida',
  },
  {
    id: 'descricao',
    align: 'left',
    disablePadding: false,
    label: 'Descricao',
  },
  {
    id: 'valorUnitario',
    align: 'right',
    disablePadding: false,
    label: 'Valor Unitário (R$)',
  },
  {
    id: 'valorTotal',
    align: 'right',
    disablePadding: false,
    label: 'Valor Total (R$)',
  },
  {
    id: 'tiposRecursosDescricao',
    align: 'left',
    disablePadding: false,
    label: 'Tipo de Recurso',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } =
    props;
  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  const handleInsertItem = (itemNotasFiscaisRecibos) => {
    props.handleInsertItem(itemNotasFiscaisRecibos);
  }

  const handleDeleteItem = () => {
    props.handleDeleteItem();
  }

  const handleChangeOrdem = () => {
    props.handleChangeOrdem();
  }

  const handleOrder = () => {
    props.handleOrder();
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        // }),
      }}
    >

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // spacing={2}
        sx={{ mt: 1 }}
        >

        <Grid container justifyContent="space-between">
          <Grid item xs={9}>
            <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Item(ns) Nota Fiscal/Recibo</Box>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button onClick={handleDeleteItem} disabled={Number(numSelected) === 0} color="warning">Excluir</Button>
              </Grid>
              <Grid item xs={6}>
              <Button onClick={handleOrder}>Ordenar</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>



        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mx: 2, mb: 2 }}>
          <Typography component="div">
            <ItensNotasFiscaisRecibos handleInsertItem={handleInsertItem}/>
          </Typography>
        </Grid>

      </Grid>

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {

  const handleChange = (event) => {
    // console.log("event.target.value: ", event.target.value);
  };

  const teste = (row) => {
    // console.log("row: ", row);
  };


  const [order, setOrder] = React.useState('asc');
  // const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  const handleInsertItem = (itemNotasFiscaisRecibos) => {
    props.handleInsertItem(itemNotasFiscaisRecibos);
    setSelected([]);
  }

  const handleDeleteItem = () => {
    props.handleDeleteItem(selected);
    setSelected([]);
  }

  const handleOrder = () => {
    props.handleOrder();
    setSelected([]);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // useEffect(() => {
  //   setSelected([]);
  // }, [props.rows.length]);

  const handleSelect = (row) => {
    // console.log('row: ', event.target.value);
    props.handleChangeOrdem(row);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} handleInsertItem={handleInsertItem} handleDeleteItem={handleDeleteItem} handleOrder={handleOrder} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {props.rows
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} onClick={(event) => handleClick(event, row.id)} />
                      </TableCell>

                      <TableCell padding="checkbox">
                        <TextField
                          value={row.ordem}
                          autoComplete='off'
                          size="small"
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase", width: '4ch', height: '10px', textAlign: "center" }, autoComplete: "off", maxLength: 4 }}
                          onChange={() => handleSelect(row)}
                          // helperText={errorText}
                          // error={!!errorText}
                          // onChange={handleChange, teste(row)}
                        />
                      </TableCell>
                      <TableCell component="th" align="center" id={labelId} scope="row" padding="none">
                        {row.quantidadeComercial}
                      </TableCell>
                      <TableCell align="center">{row.unidadesDeMedidasAbreviacao}</TableCell>
                      <TableCell align="left">{row.descricao}</TableCell>
                      <TableCell align="right">{row.valorUnitario.toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}</TableCell>
                      <TableCell align="right">{row.valorTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}</TableCell>
                      <TableCell align="left">{row.tiposRecursosDescricao}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
