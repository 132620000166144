import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import SearchBar from '../../../components/SearchBar';

import PlanejamentosDeDespesasTable from '../PlanejamentosDeDespesasTable';

import Paper from '@mui/material/Paper';


export default function UnidadesEscolares() {
  return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ p: 1, m: 2, display: 'flex', flexDirection: 'column' }}>
          <PlanejamentosDeDespesasTable />
        </Paper>
      </Typography>
    </Container>
    </React.Fragment>
  );
}
