import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Box, Button, Grid, Paper, Snackbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MuiAlert from '@mui/material/Alert';
import { Worker, Viewer, LocalizationMap } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import pt_PT from '@react-pdf-viewer/locales/lib/pt_PT.json';
import {Buffer} from 'buffer';

// import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';

import { formatCNPJ } from "../../../../utils/functions";
import api from '../../../../services/api';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeft: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  center: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRight: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreed: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const paperStyle = {
  pt: 2,
  px: { xs: 8, sm: 8, md: 8, xl: 8 },
  m: { xs: 8, sm: 8, md: 8, xl: 8 },
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column'
};

export default function EnhancedTable(props) {
  const history = useHistory();
  const classes = useStyles();
  const query = useParams();
  const [title, setTitle]=useState('DELIBERAÇÃO DO CONSELHO FISCAL - IMPRESSÃO');
  const [subTitle, setSubTitle]=useState('');

  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados exibidos com sucesso!');
  const [backdrop, setBackdrop] = React.useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [pdfFile, setPdfFile]=useState(null);
  const [pdfFileError, setPdfFileError]=useState('');
  const [file, setFile]=useState(null);
  const [viewPdf, setViewPdf]=useState(null);

  React.useEffect(() => {
    const url = `${process.env.REACT_APP_HOST_LARAVEL}/api/get-formulario-de-liberacao-do-Conselho-Fiscal/${query.id}`;
    const values = api.get(url, {responseType: 'arraybuffer'})
      .then(
        response => {
          const buffer = Buffer.from(response.data, 'base64');
          setFile(buffer);
        })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
  }, []);

  const getData = () => {
    // setBackdrop(true);
    // const values = api.get(`${process.env.REACT_APP_HOST}/assembleias-escolares/por-caixa-escolar/${query.ano}/${query.id}`)
    //   .then(
    //     response => {
    //       const data = response.data;
    //       const cnpj = formatCNPJ(data.caixasEscolaresCnpj);
    //       setTitle(`ATA ELEIÇÃO/POSSE CAIXA ESCOLAR - IMPRESSÃO - ANO: ${data.ano}`);
    //       setSubTitle(`${data.caixasEscolaresNome} - CNPJ: ${cnpj}`);
    //       setBackdrop(false);
    //     })
    //   .catch((err) => {
    //     setMessage('Erro ao acessar os dados! Verifique!');
    //     setBackdrop(false);
    //     setSeverity('error');
    //     handleClickAlert();
    //     console.error("ops! ocorreu um erro" + err);
    //   })
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Box sx={{ width: '100%', mt: 5 }}>
      <Paper elevation={3} sx={{ ...paperStyle, flexGrow: 1  }}>

        <Grid
          container
          direction="row"
          className={`${classes.bottomRight} ${classes.box}`}
          sx={{ flex: '100%', px: 4, pb: 1 }}>
          <Grid item xs={11}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{title}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={1} sx={{ pb: 0 }}>
            <Box
              component="div"
              className={`${classes.bottomRight} ${classes.box}`}>
                <Button
                  onClick={() => {history.push(`/parcelas/deliberacao-conselho-edit/${query.id}`)}}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ height: 35 }}>
                  Voltar
                </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{subTitle}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
              <div style={{ height: "720px" }}>
                {file && <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} localization={pt_PT} />}
              </div>
            </Worker>
          </Grid>

        </Grid>
      </Paper>
    </Box>
  );
}
