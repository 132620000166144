import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

import api from '../../../services/api';

  export default function ControllableStates(props) {
    const [value, setValue] = React.useState(null);
    const [listValues, setListValues] = React.useState([]);

    const defaultProps = {
      options: listValues
    };

    React.useEffect(() => {
      const values = api.get(props.apiConst)
       values.then((response) => {
         setListValues(response.data);
       })
       .catch((err) => {
         console.error("ops! ocorreu um erro" + err);
       })
     }, []);

    return (
      <div>
          <Autocomplete
            {...defaultProps}
            id="controlled-demo"
            value={value}
            disabled={props.disabled}
            size="small"
            // defaultValue={props.initialValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              // @ts-ignore
              props.onSelectData(newValue);
              setValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.nLabel}
                variant="outlined"
                focused
                size="small"
                required={props.nRequired}
                // defaultValue={props.initialValue}
                error={props.errors && props.errors.includes(props.errorType)}
                helperText={props.errors && props.errors.includes(props.errorType) ? 'Campo obrigatório!' : ''}
              />
            )}
          />
      </div>
    );
  }
