import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import { visuallyHidden } from '@mui/utils';

import ClearOutlinedIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

import { Button } from "@mui/material";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// import SearchBar from '../../../components/SearchBar';
import EditPrintMenu from '../../../components/EditPrintMenu';

import api from '../../../services/api';

const rows = [];

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    cursor: 'pointer'
  },
}));

function descendingComparator(a, b, orderBy) {

  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") < a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return -1;
  }
  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") > a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'ano',
    numeric: false,
    disablePadding: false,
    label: 'Ano',
    width: '5%',
    align: "center"
  },
  {
    id: 'unidadesEscolaresNome',
    numeric: false,
    disablePadding: true,
    label: 'Unidade Escolar',
    width: '',
    align: "left"
  },
  // {
  //   id: 'quantidadeCreche',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Creche (Quant)',
  //   width: '25%',
  //   align: "center"
  // },
  // {
  //   id: 'percapitasValoresCreche',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Creche (Per Capita)',
  //   width: '25%',
  //   align: "center"
  // },
  {
    id: 'percapitasValoresCrecheTotal',
    numeric: false,
    disablePadding: true,
    label: 'Creche',
    width: '10%',
    align: "right"
  },
  // {
  //   id: 'quantidadeEducacaoDeJovensEAdultos',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'EJA (Quant)',
  //   width: '25%',
  //   align: "center"
  // },
  // {
  //   id: 'percapitasValoresEducacaoDeJovensEAdultos',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'EJA (Per Capita)',
  //   width: '25%',
  //   align: "center"
  // },
  {
    id: 'percapitasValoresEducacaoDeJovensEAdultos',
    numeric: false,
    disablePadding: true,
    label: 'EJA',
    width: '10%',
    align: "right"
  },
  // {
  //   id: 'quantidadeEducacaoInfantil',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'EI (Quant)',
  //   width: '25%',
  //   align: "right"
  // },
  // {
  //   id: 'percapitasValoresEducacaoInfantil',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'EI (Per Capita)',
  //   width: '25%',
  //   align: "center"
  // },
  {
    id: 'percapitasValoresEducacaoInfantil',
    numeric: false,
    disablePadding: true,
    label: 'EI',
    width: '10%',
    align: "right"
  },
  // {
  //   id: 'quantidadequantidadeEnsinoFundamental',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'EF (Quant)',
  //   width: '25%',
  //   align: "center"
  // },
  // {
  //   id: 'percapitasValoresquantidadeEnsinoFundamental',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'EF (Per Capita)',
  //   width: '25%',
  //   align: "center"
  // },
  {
    id: 'percapitasValoresquantidadeEnsinoFundamental',
    numeric: false,
    disablePadding: true,
    label: 'EF',
    width: '10%',
    align: "right"
  },
  {
    id: 'totalGeral',
    numeric: false,
    disablePadding: true,
    label: 'Total Geral',
    width: '10%',
    align: "right"
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            size="small"
            width={headCell.width}
            align={headCell.align}
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.caixaEscolarNome ? order : false}
          >
            <TableSortLabel
              width={headCell.width}
              align={headCell.align}
              active={orderBy === headCell.caixaEscolarNome}
              direction={orderBy === headCell.caixaEscolarNome ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.caixaEscolarNome ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <span></span>
        </TableCell>
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable(props) {
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('caixaEscolarNome');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  const [valorTotal, setValorTotal] = React.useState('0.00');

  useEffect(() => {
    getNewData();
  }, []);

  useEffect(() => {
    const total = newListValues.map((n) => n.totalGeral);
    let sum = total.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue
    }, 0)
    
    sum = (sum).toLocaleString(
      'pt-BR',
      { minimumFractionDigits: 2 }
    );

    setValorTotal(sum);

  }, [newListValues]);

  const getNewData = () => {
    const values = api.get("quantitativos-estudantes/unidades-de-ensino/809ca722-7541-4899-921d-7d183a0fbe97")
    values.then((response) => {
      setListValues(response.data);
      setNewListValues(response.data);
    })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const classes = useStyles();

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
     const [searchField, setSearchField] = React.useState('');
     const [searchData, setSearchData] = React.useState([
       {regioes: []}, {bairros: []}, {tiposEnsinos: []}
     ]);
     const handleChange = async (event) => {
      await setSearchField(event.target.value);
     };

     const handleDataSearch = async (data) => {
      setSearchData(data);
     };

     useEffect(() => {
       if (listValues && listValues.length > 0) {
        //Doing filteration on according to select state option
        let newListValue = listValues;
        // newListValue = listValues.filter(
          // listValues => listValues.caixasEscolaresNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          //   .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
          // listValues.caixaEscolarNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            // .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
          // listValues.presidente.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            // .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
        // );
        if ((searchData) && (searchData[0].regioes.length > 0)) {
          newListValue = listValues.filter(
            listValues => searchData[0].regioes.includes(listValues.regiao_id)
          );
        }
        if ((searchData) && (searchData[1].bairros.length > 0)) {
          newListValue = listValues.filter(
            listValues => searchData[1].bairros.includes(listValues.bairro_id)
          );
        }
        if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
          newListValue = listValues.filter(
            listValues => searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
              searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
              searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
              searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
          );
        }

        //set state variable data after filteration
        setNewListValues(newListValue);
          // console.log(searchData)
       }
      }, [searchField, searchData])

      const [isExpanded, setIsExpanded] = React.useState([]);
      const [totalValues, setTotalValues] = React.useState([]);

      const setIsExpandedData = async (id) => {
        let filtered = isExpanded;

        if (isExpanded.includes(id)) {
          filtered = isExpanded.filter(function(value, index, arr){ 
            return value !== id;
          });
        } else {
          filtered.push(id)
        }
        setIsExpanded(filtered)
       };

          useEffect(() => {
         setTotalValues(newListValues.length)
        }, [newListValues]);

  return (
    (listValues ? 
      <Box sx={{ width: '100%', mt: 2 }}>
        {/* <Paper elevation={3}sx={{ px: 1, pb: 5, m: 2, display: 'flex', flexDirection: 'column' }}> */}

          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 4 }}>

            <Grid item xs={7}>
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Quantitativo de Estudantes - Listagem2</Box>
                </Typography> 
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box
                component="div"
                className={`${classes.bottomRightBox} ${classes.box}`}>

                <div style={{ paddingRight: 20 }}>
                  <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', width: 400 }}
                  >
                    <IconButton sx={{ p: '6px' }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      size="small"
                      placeholder="Pesquisar ..."
                      inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                      onChange={handleChange}
                      value={searchField}
                    />
                    { searchField.length > 0 ? 
                      <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                        <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                      : null }
                  </Paper>
                </div>
                <div style={{ paddingRight: 20 }}>
                  <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', width: 150 }}
                  >
                    <TextField
                      // className={classes.input}
                      size="small"
                      id="total"
                      color="primary"
                      label="Valores Totais (R$)"
                      variant="outlined"
                      value={valorTotal}
                      inputProps={{ style: { textAlign: "center" } }}
                    />
                  </Paper>
                </div>
                {/* <div>
                  <Button onClick={() => history.push('/dados-bancarios/new')} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                    Novo
                  </Button>
                </div> */}
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 2 }}>
            <Grid item sx={{ flex: '100%', pt: 2 }}>
              <div><SearchBar onSelectData={handleDataSearch} /></div>
            </Grid>
          </Grid> */}
          
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size='small'
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
              />
              <TableBody>
                {stableSort(newListValues, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.caixaEscolarNome);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.caixasEscolaresNome)}
                          key={row.id}
                        >
                          <TableCell padding="checkbox" key={row.id}>
                            <IconButton onClick={() => setIsExpandedData(row.id)}>
                              {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>

                          <TableCell align="center" sx={{ fontSize: 13 }}>{row.ano}</TableCell>

                          <TableCell align="left" sx={{ fontSize: 13 }}>{row.unidadesEscolaresNome}</TableCell>

                          {/* <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeCreche}</TableCell>
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.percapitasValoresCreche ? row.percapitasValoresCreche.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell> */}
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.percapitasValoresCrecheTotal ? row.percapitasValoresCrecheTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell>

                          {/* <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeEducacaoDeJovensEAdultos}</TableCell>
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.percapitasValoresEducacaoDeJovensEAdulto ? row.percapitasValoresEducacaoDeJovensEAdultos.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell> */}
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.percapitasValoresEducacaoDeJovensEAdultos ? row.percapitasValoresEducacaoDeJovensEAdultosTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell>

                          {/* <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeEducacaoInfantil}</TableCell>
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeEducacaoInfantil ? row.percapitasValoresEducacaoInfantil.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell> */}
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeEducacaoInfantil ? row.percapitasValoresEducacaoInfantilTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell>

                          {/* <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeEnsinoFundamental}</TableCell>
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeEnsinoFundamental ? row.percapitasValoresEnsinoFundamental.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell> */}
                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.quantidadeEnsinoFundamental ? row.percapitasValoresEnsinoFundamentalTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell>

                          <TableCell align="right" sx={{ fontSize: 13 }}>{row.totalGeral ? row.totalGeral.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}</TableCell>

                          <TableCell size="small" align="center" sx={{ minWidth: 50 }}>
                            <EditPrintMenu
                              id={row.id}
                              path="dados-bancarios"
                              optionals={'edit', 'delete', 'print'}
                              getNewData={getNewData}
                              // data1={`${row.caixasEscolaresNome}`}
                              // data2={`Conta Corrente (Número): ${row.numeroContaCorrente}`}
                              // data3={`Banco (Nome): ${row.bancoNome}`}
                              // path="dados-bancarios"
                          />
                          </TableCell>
                        </TableRow>

                        {isExpanded.includes(row.id) && (
                          <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell colSpan={8}>
                              <b>Creche (crianças):</b> {row.quantidadeCreche ? row.quantidadeCreche : '0'}  - <b>Per capita (R$):</b> {row.percapitasValoresCreche ? row.percapitasValoresCreche.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} - <b>Total (R$):</b> {row.percapitasValoresCrecheTotal ? row.percapitasValoresCrecheTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} <b> - Tipo (per capita): </b>{row.percapitasDescricoesCreche}
                              <br/><b>EI (crianças):</b> {row.quantidadeEducacaoInfantil ? row.quantidadeEducacaoInfantil : '0'}  - <b>Per capita (R$):</b> {row.percapitasValoresEducacaoInfantil ? row.percapitasValoresEducacaoInfantil.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} - <b>Total (R$):</b> {row.percapitasValoresEducacaoInfantilTotal ? row.percapitasValoresEducacaoInfantilTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} <b> - Tipo (per capita): </b>{row.percapitasDescricoesEducacaoInfantil}
                              <br/><b>EF (estudantes):</b> {row.quantidadeEnsinoFundamental ? row.quantidadeEnsinoFundamental : '0'}  - <b>Per capita (R$):</b> {row.percapitasValoresEnsinoFundamental ? row.percapitasValoresEnsinoFundamental.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} - <b>Total (R$):</b> {row.percapitasValoresEnsinoFundamentalTotal ? row.percapitasValoresEnsinoFundamentalTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} <b> - Tipo (per capita): </b>{row.percapitasDescricoesEnsinoFundamental}
                              <br/><b>EJA (estudantes):</b> {row.quantidadeEducacaoDeJovensEAdultos ? row.quantidadeEducacaoDeJovensEAdultos : '0'}  - <b>Per capita (R$):</b> {row.percapitasValoresEducacaoDeJovensEAdultos ? row.percapitasValoresEducacaoDeJovensEAdultos.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} - <b>Total (R$):</b> {row.percapitasValoresEducacaoDeJovensEAdultosTotal ? row.percapitasValoresEducacaoDeJovensEAdultosTotal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'} <b> - Tipo (per capita): </b>{row.percapitasDescricoesEducacaoDeJovensEAdultos}
                            </TableCell>
                          </TableRow>
                        )}

                      </>
                      
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>

            {/* <pre>{JSON.stringify(listValues, null, 2)}</pre> */}

          </TableContainer>
        {/* </Paper> */}
      </Box>
          :
      <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando ...</span></Typography>)
  );
}
