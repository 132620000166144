// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Box, Button, Grid, Paper, Snackbar, Typography } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';

import { formatCNPJ } from "../../../utils/functions";
import api from '../../../services/api';

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeft: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  center: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRight: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreed: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const paperStyle = {
  pt: 2,
  px: { xs: 8, sm: 8, md: 8, xl: 8 },
  m: { xs: 8, sm: 8, md: 8, xl: 8 },
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column'
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// @ts-ignore
export default function EnhancedTable(props) {
  const history = useHistory();
  const classes = useStyles();
  const query = useParams();
  const [title, setTitle]=useState('LISTA DE PRESENÇA - IMPRESSÃO');
  const [subTitle, setSubTitle]=useState('');

  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados exibidos com sucesso!');
  const [backdrop, setBackdrop] = React.useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  React.useEffect(() => {
    // const url = `${process.env.REACT_APP_HOST_LARAVEL}/api/get-balancete-anual-final-impressao/${query.ano}/${query.ids}`;
    // const values = api.get(url, {responseType: 'arraybuffer'})
    //   .then(
    //     response => {
    //       const buffer = Buffer.from(response.data, 'base64');
    //       setFile(buffer);
    //     })
    //   .catch((err) => {
            // setMessage('Erro ao acessar os dados! Verifique!');
            // setBackdrop(false);
            // setSeverity('error');
            // handleClickAlert();
    //      console.error("ops! ocorreu um erro" + err);
    //   })
  }, []);

  React.useEffect(() => {
    setBackdrop(true);
    // @ts-ignore
    const values = api.get(`${process.env.REACT_APP_HOST}/demonstrativos-gerenciais-anuais/por-caixa-escolar/${query.id}/${query.ano}`)
      .then(
        response => {
          const data = response.data;
          // @ts-ignore
          const saldoFinal = `R$ ${data.saldoFinal ? data.saldoFinal.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}`;
          const cnpj = formatCNPJ(data.caixaEscolarCnpj);
          setTitle(`LISTA DE PRESENÇA - IMPRESSÃO - ANO: ${data.ano}`);
          setSubTitle(`${data.caixaEscolarNome} - CNPJ: ${cnpj}`);
          setBackdrop(false);
        })
      .catch((err) => {
        setMessage('Erro ao acessar os dados! Verifique!');
        setBackdrop(false);
        setSeverity('error');
        handleClickAlert();
        console.error("ops! ocorreu um erro" + err);
      })
  }, []);

  return (
    <Box sx={{ width: '100%', mt: 5 }}>
      <Paper elevation={3} 
// @ts-ignore
      sx={{ ...paperStyle, flexGrow: 1  }}>

        <SpinnerCarregando open={backdrop} />

        <Snackbar open={openAlert} autoHideDuration={2000}  onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
          <
          // @ts-ignore
          Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose}>
            {message}
          </Alert>
        </Snackbar>

        <Grid
          container
          direction="row"
          className={`${classes.bottomRight} ${classes.box}`}
          sx={{ flex: '100%', px: 4, pb: 1 }}>
          <Grid item xs={11}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{title}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={1} sx={{ pb: 0 }}>
            <Box
              component="div"
              className={`${classes.bottomRight} ${classes.box}`}>
                <Button
                  // @ts-ignore
                  onClick={() => {history.push(`/assembleia-escolar-menu-opcoes/${query.ano}/${query.id}`)}}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ height: 35 }}>
                  Voltar
                </Button>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{subTitle}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <div>
            
            </div>
          </Grid>

        </Grid>
      </Paper>
    </Box>
  );
}
