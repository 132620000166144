import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

export default function ScrollDialog(props) {
//   const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    // setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    // setOpen(false);
    props.closeOrientacoes();
  };
  

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Orientações
        </BootstrapDialogTitle>

        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {`1 – O Formulário de Planejamento das Despesas das Caixas Escolares é um documento que visa auxiliar as Caixas Escolares na identificação e mensuração das despesas comumente incidentes em um ambiente escolar, de modo que o Termo de Compromisso a ser celebrado com cada Caixa Escolar espelhe suas reais necessidades, evitando-se assim a celebração de número excessivo de Termos Aditivos ao longo da execução do aludido Termo de Compromisso firmado;`}
          </DialogContentText>

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {`2 – Além das despesas previamente indicadas no Formulário, a Caixa Escolar poderá especificar e mensurar outras despesas que não estejam elencadas no citado documento, especificamente no campo “Outras despesas” existente no final do Formulário. As indicações financeiras lançadas no Formulário subsidiarão a elaboração do respectivo Plano de Trabalho referente ao ano de 2022, contudo caso as despesas indicadas ultrapassem o valor de referência, serão submetidas à apreciação desta Secretaria;`}
          </DialogContentText>

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {`3 – O Formulário deverá ser preenchido, preferencialmente, através do link de acesso ao Formulário em formato eletrônico ora disponibilizado, buscando dar mais celeridade ao processo. Contudo, caso a Caixa Escolar não consiga fazer o preenchimento pelo link de acesso, poderá fazê-lo no Formulário em formato editável;`}
          </DialogContentText>

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {`4 – O preenchimento do Formulário de Planejamento das Despesas das Caixas Escolares é facultativo, sendo que os repasses para as Caixas Escolares que optarem pelo não preenchimento do Formulário serão calculados considerando o número de estudantes matriculados na unidade escolar (cálculo per capita), neste caso qualquer solicitação posterior de Termo Aditivo será submetida ao processo regular de análise e formalização; `}
          </DialogContentText>

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {`5 – Após o preenchimento do Formulário, seja em formato eletrônico ou formato editável, este deverá ser impresso e assinado pelo(a) Presidente da Caixa Escolar, e posteriormente entregue na Diretoria de Contratos, Convênios e Parcerias desta Secretaria. Caso opte pelo não preenchimento do Formulário, a Caixa Escolar deverá selecionar o item correspondente e providenciar a impressão e assinatura da declaração que será gerada pelo próprio Sistema;`}
          </DialogContentText>

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {`6 – Considerando as necessidades latentes dos Caixas Escolares, bem como buscando dar celeridade ao processo de celebração dos Termos de Compromisso do ano de 2022, solicitamos encarecidamente que tão logo o Formulário esteja preenchido e assinado, seja o mesmo imediatamente entregue na Diretoria de Contratos, Convênios e Parcerias desta Secretaria, pelo que consignamos o prazo de entrega até o dia 14/03/2022;`}
          </DialogContentText>

          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}>
            {`7 – A celebração de Termo de Compromisso específico para execução de despesas atinentes a prestação de serviços de engenharia por dispensa de licitação, cujo limite financeiro atualmente fixado em R$33.000,00 (trinta e três mil reais) por ano (exercício financeiro), conforme legislação vigente, fica condicionada à prévia validação de 3 (três) propostas de empresas com registro no CREA, cuja análise será realizada pelos profissionais engenheiros lotados no Setor de Rede Física desta Secretaria Municipal de Educação.`}
          </DialogContentText>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}