import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import api from '../../services/api';

export default function Total (props) {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    height: 35,
    lineHeight: '35px',
    width: 125
  }));
  return (
  <>
    <Item elevation={2} style={{ backgroundColor: '#e3f2fd' }}>
      <span style={{ color: '#2196f3' }}>
        {props.title}&nbsp;
      </span>
      <span style={{ color: '#2196f3' }}>
        {props.data ? props.data.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}
      </span>
    </Item>
  </>
  );
}
