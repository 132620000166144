import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default function AlertShowErrorsDeliberacao(props) {
  // const [errorsText, setErrorsText] = React.useState([]);
  // const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const handleClose = () => {
    props.handleCloseDialog(false);
  };

  // useEffect(() => {
  //   setErrorsText(props.errors)
  // }, [props.errors]);

  return (
    <Dialog
      open={props.openAlertDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {"GRAVAR DADOS"}
      </DialogTitle>
      <DialogContent>
      {/* <pre>{JSON.stringify(props.errors, null, 2)}</pre> */}
        <DialogContentText id="alert-dialog-description">
          <div>ERRO AO GRAVAR. POR FAVOR VERIFIQUE O(S) ITEM(NS):</div>
        </DialogContentText>
        <List>
        {
          props.errors ?
          props.errors.map(item => (
          <ListItem disablePadding key={item.id}>
            <ListItemButton>
              <ListItemIcon>
                <CloseOutlinedIcon color="error" />
              </ListItemIcon>
              {
                <ListItemText primary={`${item.label}`} />
              }
            </ListItemButton>
          </ListItem>
          
        ))
        : <></>
      }
      </List>












      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
