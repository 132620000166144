import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { FormControl } from '@mui/material';

import api from '../../../services/api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
  const [listValues, setListValues] = React.useState([]);
  const [newClearValues, setNewClearValues] = React.useState(null);

  React.useEffect(() => {
   const values = api.get(props.apiConst)
    values.then((response) => {
      setListValues(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  React.useEffect(() => {
    if (props.clearAll) {
      clearValues();
    }
  }, [props.clearAll]);

  const clearValues = () => {
    setNewClearValues(1);
    // console.log('aqui2');

    const clear = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
    if (clear) {
      clear.click();
    }
  }

  React.useEffect(() => {
    // Take the Reference of Close Button
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
      
    // Add a Click Event Listener to the button

    if (close) {
      close.addEventListener("click", () => {
        console.log("Add your Own Functionality Here...");
      });
    }
  });

  // const [newValue, setNewValue] = React.useState([]);
  // const handleChange = async (value) => {
  //   console.log('value: ', value)
  //   await setNewValue(value);
  //  };

  const handleDataChange = (value) => {
    const arrayValues = [];
    value.map(element => {
        const list = listValues.filter(item => item.label === element);
        arrayValues.push(list[0]);
    });
  props.onSelectData(arrayValues);
  setNewClearValues(null);
  };

  return (
    <FormControl variant={props.variant} sx={{ minWidth: '100%' }}>
    {/*<pre>{JSON.stringify(listValues, null, 2)}</pre>*/}
    <Autocomplete
      size="small"
      multiple
      limitTags={2}
      id="tags-filled"
      options={listValues.map((option) => option.label)}
      freeSolo
      onChange={(event, value) => handleDataChange(value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" color="primary" size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant={props.variant}
          label={props.nLabel}
        />
      )}
    />

    </FormControl>

    )
}


