import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  negativeItem: {
    color: "#c15165"
  },
  positiveItem: {
    color: "#4a89c8"
  }
}));

export default function BasicButtons() {
  const classes = useStyles();
  const [data, setData] = React.useState({
    value1: "",
    tipo: "MATERIAL DE LIMPEZA"
  });
  return (

    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 0, pb: 50 }}>
    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}

      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 0, pb: 2 }}>
        <Grid item xs={7}>
          <Box>
            <Typography component="div">
              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
                <div>
                  {data.tipo}
                </div>
              </Box>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={5} sx={{ pr: 0 }}>
          <Box
            component="div"
            className={`${classes.bottomRightBox} ${classes.box}`}
            sx={{ pr: 4 }}>

              <div style={{ paddingRight: 20 }}>
                <Button onClick={() => {}} variant="outlined" color="primary" size="small" sx={{ px: 2 }} style={{ height: 35 }}>
                  Imprimir
                </Button>
              </div>

              <div>
                <Button onClick={() => {}} variant="outlined" color="primary" size="small" sx={{ px: 2 }} style={{ height: 35 }}>
                  Voltar
                </Button>
              </div>
          </Box>
        </Grid>
      </Grid>


      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 2 }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Typography component="div">
              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
                <div>
                  ORÇAMENTO 01
                </div>
              </Box>
            </Typography>
          </Box>
        </Grid>
      
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <TextField
            id="outlined-multiline-flexible"
            label="CNPJ"
            name="cnpj"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <TextField
            id="outlined-multiline-flexible"
            label="Razão Social/Nome Fantasia"
            name="razaoSocialNomeFantasia"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <TextField
            id="outlined-multiline-flexible"
            label="Valor Total"
            name="valorTotal"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <TextField
            id="outlined-multiline-flexible"
            label="Sócios"
            name="socios"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 2 }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Typography component="div">
              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
                <div>
                  ORÇAMENTO 02
                </div>
              </Box>
            </Typography>
          </Box>
        </Grid>
      
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <TextField
            id="outlined-multiline-flexible"
            label="CNPJ"
            name="cnpj"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <TextField
            id="outlined-multiline-flexible"
            label="Razão Social/Nome Fantasia"
            name="razaoSocialNomeFantasia"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <TextField
            id="outlined-multiline-flexible"
            label="Valor Total"
            name="valorTotal"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <TextField
            id="outlined-multiline-flexible"
            label="Sócios"
            name="socios"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 2 }} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Typography component="div">
              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
                <div>
                  ORÇAMENTO 03
                </div>
              </Box>
            </Typography>
          </Box>
        </Grid>
      
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <TextField
            id="outlined-multiline-flexible"
            label="CNPJ"
            name="cnpj"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <TextField
            id="outlined-multiline-flexible"
            label="Razão Social/Nome Fantasia"
            name="razaoSocialNomeFantasia"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <TextField
            id="outlined-multiline-flexible"
            label="Valor Total"
            name="valorTotal"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <TextField
            id="outlined-multiline-flexible"
            label="Sócios"
            name="socios"
            fullWidth
            focused
            size="small"
            inputProps={{}}
            value={data.value1}
          />
        </Grid>

      </Grid>
    </Grid>

  );
}
