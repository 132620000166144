import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select } from "@mui/material";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DateAdapter from '@mui/lab/AdapterMoment';
// import DateAdapter from '@mui/lab/AdapterDayjs';
// import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';

import { validarCPF, isDate } from '../../../utils/functions'

import schema from '..';
import { useFormik } from 'formik';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando'
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import { styled } from '@mui/material/styles';

import EstatutosAtasUploadForm from '../EstatutosAtasUploadForm';

// Worker
import { Worker, Viewer, LocalizationMap } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";

import EstatutosAtasEditPostForm from '../EstatutosAtasEditPostForm';

import pt_PT from '@react-pdf-viewer/locales/lib/pt_PT.json';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { uuidv4, formatDateEnUs2 } from '../../../utils/functions';

import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function MembrosConselhoFiscal() {
  const query = useParams();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const [errors, setErrors] = React.useState([]);
  const [newRegister, setNewRegister] = React.useState([]);
  const [checkErrors , setCheckErrors] = React.useState([]);

  const [disabledItens, setDisabledItens] = React.useState(false);

  const[caixasEscolaresNome, setCaixasEscolaresNome] = React.useState('');

  const [initialValues, setInitialValues] = React.useState({});

  const [fileName, setFileName] = React.useState('');
  const [file, setFile] = React.useState('');


  const goList = () => {
    //@ts-ignore
    history.push(`/estatutos-atas-list/por-caixa-escolar/${query.id}`);
  }

  const Input = styled('input')({
    display: 'none',
  })

  const handleClickAlert = () => {
    setOpen(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
  
    // for onchange event
    const [pdfFile, setPdfFile]=useState(null);
    const [pdfFileError, setPdfFileError]=useState('');
  
    // for submit event
    const [viewPdf, setViewPdf]=useState(null);

    const [allFilled, setAllFilled]=useState(false);
    const [data, setData]=useState([]);

    useEffect(() => {
    }, []);

    const checkData = (data) => {
      console.log('data: ', data.documentosTiposId);
      setAllFilled(false);
      const newData = {
        initialDate: formatDateEnUs2(data.datas.initialDate),
        finalDate: formatDateEnUs2(data.datas.finalDate),
        dataRegistroCartorio: formatDateEnUs2(data.datas.dataRegistroCartorio),
        documentosTiposId: data.documentosTiposId
      }
      setData(newData);
      if (data && data.errors && data.errors.length === 0 && data.documentosTiposId && data.documentosTiposId.length === 36) {
        setAllFilled(true);
      }
    }
  
    // onchange event
    const fileType=['application/pdf'];
    const handlePdfFileChange=(e)=>{
      let selectedFile=e.target.files[0];

      // console.log('e.target.files[0]: ', e.target.files[0]);
      setFileName('');
      if (selectedFile) {
        if (e.target.files && e.target.files !== 'undefined' && e.target.files[0].size <= 6e5) {
          if (selectedFile && fileType.includes(selectedFile.type)) {
            setFileName(e.target.files[0].name);
            let reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onloadend = (e) =>{
                  setPdfFile(e.target.result);
                  // setFile(e.target.files[0])
                  setPdfFileError('');
                }
          }
          else {
            setPdfFile(null);

            setSeverity('error');
            setMessage('Selecione um arquivo PDF válido!');
            setOpen(true);
            setPdfFileError('Please select valid pdf file');
          }
        } else {
          setSeverity('error');
          setMessage('Tamanho de arquivo inválido!');
          setOpen(true);
        }
      }
      else {
        console.log('select your file');
      }
    }

    const sendFile = () => {

      setViewPdf(pdfFile);
      const formData = new FormData();
      const imagefile = document.querySelector('#file');
      formData.append("file", pdfFile);
      formData.append("id", uuidv4());
      formData.append("initialDate", data.initialDate);
      formData.append("finalDate", data.finalDate);
      formData.append("dataRegistroCartorio", data.dataRegistroCartorio);
      formData.append("documentosTiposId", data.documentosTiposId);
      formData.append("caixasEscolaresId", query.id);


      // api.post('upload_file', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      // })


                // async onChange(e) {
          //   let file = e.target.files[0];
          //   let formData = new FormData();
          //   formData.append('pdf', file);
        
          //   await axios.post(`/materials/upload/${this.material.SKU}`, formData)
          //     .then( res => console.log(res.data) )
          // },

      setBackdrop(true);
      setDisabledItens(false);
      //@ts-ignore
      api.post(`/estatutos-atas/upload/schools-documents/${query.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        setSeverity('success');
        setMessage('Dados gravados com sucesso!');
        setBackdrop(false);
        setOpen(true);
        setDisabledItens(true);
      })
      .catch((err) => {
        setSeverity('error');
        setMessage('Erro ao enviar o arquivo! Verifique!');
        setBackdrop(false);
        setOpen(true);
        setDisabledItens(false);
        console.error("ops! ocorreu um erro" + err);
      })
    }
  
    // form submit
    const handlePdfFileSubmit=(e)=>{
      e.preventDefault();
      if (pdfFile!==null) {
        sendFile();
      }
      else {
        setViewPdf(null);
      }
    }

  return (
    // initialValues ? 
    <Container maxWidth="xl">
    
      {/* Location Path: <b>{location.pathname}</b>
      <p>Location Object: {JSON.stringify(location)}</p> */}

      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
          <SpinnerCarregando open={backdrop} />
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
          <Grid container spacing={2}  sx={{ mt: 3 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ ml: 2 }}
            >
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Estatutos e Atas (Tamanho máximo 500kb)</Box>
                </Typography> 
              </Box>

              <Box
                component="div"
                className={`${classes.bottomRightBox} ${classes.box}`}>
                  <div>
                    <Button onClick={goList} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 15}}>
                      Voltar
                    </Button>
                  </div>
                  {!disabledItens ?
                    <div>
                      <label htmlFor="contained-button-file">
                        <Input id="contained-button-file" multiple type="file" onChange={handlePdfFileChange} accept="application/pdf" />
                        <Button
                          variant="contained"
                          component="span"
                          variant="outlined"
                          color="primary"
                          style={{ height: 35, marginRight: 15}}
                          disabled={!allFilled}>
                          Selecionar
                        </Button>
                      </label>
                    </div>
                    : <></>
                  }

                  {!disabledItens ?
                    <div>
                      <Button
                        onClick={handlePdfFileSubmit}
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ height: 35 }}
                        disabled={fileName.length === 0 || !allFilled}>
                        Enviar
                      </Button>
                    </div>
                    : <></>
                  }
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#4a89c8' }}>{fileName}</Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>
                <EstatutosAtasEditPostForm checkData={checkData} disabledItens={disabledItens} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <div className='container'>

            <br></br>
            
              <form className='form-group' onSubmit={handlePdfFileSubmit}>
                {/*<input type="file" className='form-control'
                  required onChange={handlePdfFileChange}
                />*/}
                {/*pdfFileError&&<div className='error-msg'>{pdfFileError}</div>*/}
                {/*<br></br>
                <button type="submit" className='btn btn-success btn-lg'>
                  Enviar
                </button>*/}
              </form>
              {/*<br></br>
              <h4>View PDF</h4>*/}
              <div className='pdf-container'>
                {/* show pdf conditionally (if we have one)  */}
  
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                <div style={{ height: "80vh" }}>
                  {viewPdf && <Viewer fileUrl={viewPdf} plugins={[defaultLayoutPluginInstance]} localization={pt_PT} />}
                </div>
              </Worker>
        
              {/* if we dont have pdf or viewPdf state is null */}
              {!viewPdf&&<><span>Nenhum arquivo PDF selecionado</span></>}
              </div>
        
            </div>            
            </Grid>

          </Grid>
        </Paper>
      </Typography>
    </Container>
    // :
    // <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando 3...</span></Typography>
  );
}
