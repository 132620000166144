import * as React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
// import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
// import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
// import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
// import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
// import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';

const style = {
  pt: 5,
  px: { xs: 5, sm: 5, md: 20, xl: 20 },
  alignContent: 'center',
  display: 'flex'
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.subtitle2,
  padding: theme.spacing(3),
  textAlign: 'center',
 color: theme.palette.text.secondary,
  cursor: 'pointer',
  height: 170
}));

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  negativeItem: {
    color: "#c15165"
  },
  positiveItem: {
    color: "#4a89c8"
  }
}));

export default function ResponsiveGrid() {
  const history = useHistory();
  const query = useParams();
  const classes = useStyles();

  return (
    <Box sx={{ ...style, flexGrow: 1 }}>
      <Grid container spacing={{ xs: 4, sm: 4, md: 4, xl: 4 }}>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 1 }}>

      <Grid item xs={7}>
        <Box>
          <Typography component="div">
            <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2, pl: 0 }}>Prestação de Contas</Box>
          </Typography> 
        </Box>
      </Grid>

      <Grid item xs={5} sx={{ pr: 0 }}>
      <Box
        component="div"
        className={`${classes.bottomRightBox} ${classes.box}`}
        sx={{ pr: 4 }}>

        <div>
          <Button variant="outlined" color="primary" size="small" style={{ height: 35 }}>
            Voltar
          </Button>
        </div>
        </Box>
        </Grid>

        </Grid>

        {/*<Grid item xs={12} sm={6} md={3} xl={3} key={1}>
          <Item onClick={() => history.push(`/edital-convocacao-assembleia-caixa-escolar/${query.ano}/${query.id}`)}>
            <div><ListAltOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>EDITAL DE CONVOCAÇÃO</div><div>CAIXA ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={2}>
          <Item onClick={() => history.push(`/edital-convocacao-assembleia-conselho-escolar/${query.ano}/${query.id}`)}>
            <div><StickyNote2OutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>EDITAL DE CONVOCAÇÃO</div><div>CONSELHO ESCOLAR</div>
          </Item>
        </Grid>
      */}
    
        <Grid item  xs={12} sm={6} md={3} xl={3} key={1}>
          <Item onClick={() => history.push(`/parcelas/deliberacao-conselho-edit/${query.id}`)}>
            <div><FeedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>&nbsp;</div><div>1 - DELIBERAÇÃO DO</div><div>CONSELHO FISCAL</div>
          </Item>
        </Grid>

        <Grid item  xs={12} sm={6} md={3} xl={3} key={2}>
        <Item onClick={() => history.push(`/parcelas/orcamentos-edit/${query.id}`)}>
          <div><FeedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
          <div>&nbsp;</div><div>2 - ORÇAMENTOS</div>
        </Item>
      </Grid>

      <Grid item  xs={12} sm={6} md={3} xl={3} key={3}>
        <Item onClick={() => history.push('/comprovantes-de-despesas-list')}>
          <div><FeedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
          <div>&nbsp;</div><div>3 - COMPROVANTES DE</div><div>DESPESAS</div>
        </Item>
      </Grid>

      <Grid item  xs={12} sm={6} md={3} xl={3} key={4}>
        <Item onClick={() => history.push(`conciliacao-bancaria`)}>
          <div><FeedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
          <div>&nbsp;</div><div>4 - CONCILIAÇÃO</div><div>BANCÁRIA</div>
        </Item>
      </Grid>

      <Grid item  xs={12} sm={6} md={3} xl={3} key={5}>
        <Item onClick={() => history.push(`/prestacao-de-contas-impressao`)}>
          <div><FeedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
          <div>&nbsp;</div><div>5 - IMPRESSÃO</div>
        </Item>
      </Grid>
        {/*
        <Grid item  xs={12} sm={6} md={3} xl={3} key={4}>
          <Item onClick={() => history.push(`/ata-conselho-escolar-opcoes/${query.ano}/${query.id}`)}>
            <div><BallotOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>ATA</div><div>CONSELHO ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={5}>
          <Item onClick={() => history.push(`/lista-de-presença-caixa-escolar-opcoes/${query.ano}/${query.id}`)}>
            <div><FormatListNumberedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>LISTA DE PRESENÇA</div><div>CAIXA ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={6}>
          <Item onClick={() => history.push(`/lista-de-presença-conselho-escolar-opcoes/${query.ano}/${query.id}`)}>
            <div><FormatListBulletedOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>LISTA DE PRESENÇA</div><div>CONSELHO ESCOLAR</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={7}>
          <Item onClick={() => history.push(`/formulario-crc-impressao/${query.ano}/${query.id}`)}>
            <div><FactCheckOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>FORMULÁRIO</div><div>CRC</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={8}>
          <Item onClick={() => history.push(`/formulario-dbe-impressao/${query.ano}/${query.id}`)}>
            <div><AssessmentOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>FORMULÁRIO</div><div>DBE</div>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6} md={3} xl={3} key={9}>
          <Item onClick={() => history.push(`/formulario-requerimento-cartorio-impressao/${query.ano}/${query.id}`)}>
            <div><SummarizeOutlinedIcon color="primary" sx={{ fontSize: 60 }}/></div>
            <div>REQUERIMENTO DO CARTÓRIO</div>
          </Item>
        </Grid>

        */}

      </Grid>
    </Box>
  );
}
