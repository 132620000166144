import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const rows = [];

export default function TelefonesTable(props) {
  return (
    <TableContainer component={Paper}>
      {/* <pre>{JSON.stringify(props.telefones, null, 2)}</pre> */}
      <h4>Telefone(s)</h4>
      <Table size="small" aria-label="a table">
        <TableHead>
          <TableRow>
          <TableCell align="left">DDD</TableCell>
            <TableCell align="left">Número</TableCell>
            <TableCell align="left">Tipo</TableCell>
            <TableCell align="center">Whatsapp</TableCell>
            <TableCell align="center">#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.telefones.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.ddd}</TableCell>
              <TableCell align="left">{row.numero}</TableCell>
              <TableCell align="left">{row.tipo}</TableCell>
              <TableCell align="center">{row.whatsapp}</TableCell>
              <TableCell align="center">{row.acao}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
