import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import CustomCharts from '../../components/CustomCharts';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import faker from 'faker';

import { Doughnut } from 'react-chartjs-2';

import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const BarData = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '90%', mt: 5, ml: 5, mr: 5 }}>
        <Grid item xs={7}>
            <Box>
            <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2, pl: 0 }}>Verbas - 2022</Box>
            </Typography>
            </Box>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 5 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Receitas" {...a11yProps(0)} />
            <Tab label="Despesas" {...a11yProps(1)} />
            </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
            { value === 0 ? 
            <Grid
                container
                direction="row"
                justify="center"
                spacing={1}
                sx={{ mx: 5 }}
                style={{ display: "flex" }}
            >
                <Grid item xs={4}>
                    <Doughnut data={data}  options={{
                        responsive: true,
                        maintainAspectRatio: true,
                    }}/>
                </Grid>
            </Grid>
            : <></>
            }
        </TabPanel>
        <TabPanel value={value} index={1}>
            { value === 1 ? 
                <Grid
                container
                direction="row"
                justify="center"
                sx={{ mx: 5 }}
                spacing={1}
                style={{ display: "flex" }}
                >
                <Grid item xs={4}>
                    
                <Bar options={BarOptions} data={BarData} />;

                </Grid>
                </Grid>
                : <></>
                    }
        </TabPanel>
    </Box>
  );
}
