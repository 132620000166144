import { Box } from "@mui/system";
import React from "react";

import Regional from "../Regional";
import StatusCaixasEscolares from "../StatusCaixasEscolares";
import TiposEnsino from "../TiposEnsino";
import SelectComponent from "../Controls/SelectComponent";
import AutoCompleteSelectComponent from "../Controls/AutoCompleteSelectComponent";

import api from '../../services/api';

export default function SearchBar(props) {
  const [stateValueRegioes, setStateValueRegioes] = React.useState([]);
  const [stateValueBairros, setStateValueBairros] = React.useState([]);
  const [stateValueTiposEnsinos, setStateValueTiposEnsinos] = React.useState([]);
  const [stateSearchValues, setStateSearchValues] = React.useState('');

  const handleDataRegioes = (value) => {
    const regioes = [];
    value.map(
      (item) => {
        regioes.push(item.id)
      }
   )
   setStateValueRegioes(regioes)
  }

  const handleDataBairros = (value) => {
    const bairros = [];
    value.map(
      (item) => {
        bairros.push(item.id)
      }
   )
   setStateValueBairros(bairros)
  }

  const handleDataTiposEnsinos = (value) => {
    const tiposEnsinos = [];
    value.map(
      (item) => {
        tiposEnsinos.push(item.id)
      }
   )
   setStateValueTiposEnsinos(tiposEnsinos);
  }

  React.useEffect(() => {
    const data = [{'regioes': stateValueRegioes}, {'bairros': stateValueBairros}, {'tiposEnsinos': stateValueTiposEnsinos}]
    props.onSelectData(data);
  }, [stateValueRegioes, stateValueBairros, stateValueTiposEnsinos]);

  return (
      <div style={{ width: '100%' }}>
          {/* <Box
              sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap' ,
              alignItems: 'center',
              bgcolor: 'background.paper',
          }}> */}
              <Box
                  sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap' ,
                  alignItems: 'center',
                  // flexGrow: 1,
                  flex: '100%',

                  direction: "row",
                  justifyContent:"space-between",
                  alignItems: "center",
                  bgcolor: 'background.paper',
              }}>
                  <Box sx={{ pr: 1, width: "33%" }}>
                      <AutoCompleteSelectComponent nLabel="Regionais" newWidth="450" variant="outlined" apiConst="regionais" onSelectData={handleDataRegioes} />
                  </Box>
                  <Box sx={{ pr: 1, width: "33%" }}>
                      <AutoCompleteSelectComponent nLabel="Bairros" newWidth="450" variant="outlined" apiConst="bairros" onSelectData={handleDataBairros} />
                  </Box>
                  <Box sx={{ pr: 0, width: "33%" }}>
                      <AutoCompleteSelectComponent nLabel="Tipos Ensinos" newWidth="450" variant="outlined" apiConst="tipos-ensinos" onSelectData={handleDataTiposEnsinos} />
                  </Box>
              </Box> 

          {/* </Box> */}
      </div>
  )
}