import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Autocomplete } from "@mui/material";

import { Button } from "@mui/material";

import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ClearOutlinedIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando'

import api from '../../../services/api';

let rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

  const headCells = [
    {
      id: 'ano',
      numeric: false,
      disablePadding: false,
      label: 'Ano',
      width: '15%',
      align: "center"
    },
    {
      id: 'unidadesEscolaresNome',
      numeric: false,
      disablePadding: true,
      label: 'Unidade de Ensino',
      width: '30%',
      align: "left"
    },
    {
      id: 'tiposEnsinosDescricao',
      numeric: false,
      disablePadding: false,
      label: 'Tipo de Ensino',
      width: '20%',
      align: "left"
    },
    {
      id: 'percapitasDescricoes',
      numeric: false,
      disablePadding: false,
      label: 'Per Capita',
      width: '20%',
      align: "left"
    },
    {
      id: 'quantidadeEstudantes',
      numeric: false,
      disablePadding: false,
      label: 'Quantidade de Estudantes',
      width: '20%',
      align: "center"
    },
    {
      id: 'percapitasValores',
      numeric: false,
      disablePadding: false,
      label: 'Per Capita (R$)',
      width: '20%',
      align: "center"
    },
    {
      id: 'total',
      numeric: false,
      disablePadding: false,
      label: 'Total (R$)',
      width: '20%',
      align: "center"
    },
    // {
    //   id: '',
    //   numeric: false,
    //   disablePadding: false,
    //   label: '',
    //   width: '10%',
    //   align: "center"
    // }
  ];
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         // ...(numSelected > 0 && {
//         //   bgcolor: (theme) =>
//         //     alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//         // }),
//       }}
//     >
//       {/* {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: '1 1 100%' }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : ( */}
//       <Box>
//         <Typography component="div">
//           <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Per Capitas - Listagem</Box>
//         </Typography> 
//       </Box>
//       {/* )} */}

//       {/* {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )} */}
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  inputCenter: {
    textAlign: "center",
    color: "#c15165"
  }
}));

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  const [searchField, setSearchField] = React.useState('');

  const [valorTotal, setValorTotal] = React.useState('0.00');

  const [quantidadeTotalEstudantes, setQuantidadeTotalEstudantes] = React.useState('0');

  const [perCapitasList, setPerCapitasList] = React.useState([]);

  const [perCapitasValoresList, setPerCapitasValoresList] = React.useState([]);

  const handleChange = async (event) => {
    await setSearchField(event.target.value);
  };


  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const classes = useStyles();

  useEffect(() => {
    const values = api.get("termos-de-cooperacao-has-percaptas-valores")
    values.then((response) => {
      // rows = response.data;
      setListValues(response.data);
      setNewListValues(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    const values = api.get("percaptas-descricoes")
    values.then((response) => {
      setPerCapitasList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    const values = api.get("percaptas-valores")
    values.then((response) => {
      setPerCapitasValoresList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    //   //Doing filteration on according to select state option
      let newListValue = listValues;
      newListValue = listValues.filter(
        listValues => listValues.ano.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.unidadesEscolaresNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.tiposEnsinosDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
          listValues.percapitasDescricoes.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      );

     //   if ((searchData) && (searchData[0].regioes.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[0].regioes.includes(listValues.regiao_id)
    //     );
    //   }
    //   if ((searchData) && (searchData[1].bairros.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[1].bairros.includes(listValues.bairro_id)
    //     );
    //   }
    //   if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
    //     );
    //   }

    //set state variable data after filteration
    setNewListValues(newListValue);
    }, [searchField])

  useEffect(() => {
    // const total = newListValues.map((n) => n.total);
    const total = newListValues.map((n) => {
      if (typeof n.total === 'string') {
        const test = parseFloat((n.total.replace('.', '')).replace(',', '.'));
        // console.log('test: ', test)
        return test;
      }
      return 0;
      // console.log(parseFloat(n.total))
      // n.total
      // parseFloat((n.total.replace(',', '.')))
    })
    let sum = total.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue
    }, 0)
      sum = (sum).toLocaleString(
      'pt-BR',
      { minimumFractionDigits: 2 }
    );
    setValorTotal(sum);

    const quantidadeTotalEstudantes = newListValues.map((n) => Number(n.quantidadeEstudantes));
    let sumQuantidade = quantidadeTotalEstudantes.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue
    }, 0)
    setQuantidadeTotalEstudantes(sumQuantidade)

  }, [newListValues]);


  const percaptaTotal = () => {
    const newValues = newListValues.map((n) => {
      return { ...n, 'total': (Number(n.quantidadeEstudantes) * Number(n.percapitasValores)).toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}
    });
    setNewListValues(newValues);
  }

  useEffect(() => {
    percaptaTotal();
  }, [quantidadeTotalEstudantes]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newListValues.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const changeValor = (newValue, row) => {
    const newSelecteds = newListValues.map((n) => {
      if (n.id === row.id) {
        // console.log('row: ', row);
          return { ...n, 'quantidadeEstudantes': newValue }
      }
      return n;
    });
    setNewListValues(newSelecteds);
    // console.log('newSelecteds: ', newSelecteds);
  }

  const handleSelect = (event, row) => {
    // console.log('event: ', event.target.value);
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected, row.id);
    }
    // console.log('Value: ', row);
    changeValor(event.target.value, row);
    setSelected([...new Set(newSelected)]);
  };

  const perCapitasChange = (row, newValue) => {
    // console.log('row: ', row);
    // console.log('row.id: ', row.id);
    // console.log('newValue:', newValue);
    // console.log('row.tiposEnsinosId: ', row.tiposEnsinosId);

    let perCapitasValores = null;
    let perCapitasV = [];
    if (newValue) {
      const perCaptas = perCapitasValoresList;
      // console.log('perCaptas: ', perCaptas);
      perCapitasV = perCapitasValoresList.filter(
        perCaptas => perCaptas.tiposEnsinosId === row.tiposEnsinosId && perCaptas.percaptasDescricoesId === newValue.id && perCaptas.anos === row.ano
      );
      // console.log('row.tiposEnsinosId: ', row.tiposEnsinosId);
      // console.log('perCapitasV: ', perCapitasV);
    }

    // "percaptasDescricoesId": "12f5008a-1ba8-11ec-b3db-00155d3afd23",
    // "percaptasDescricoesDescricao": "INTEGRAL",
    // "percaptasDescricoesAbreviacao": "INT",
    // "tiposEnsinosId": "db06a7f9-122c-11ec-833c-dc41a970c8d9",

    const list = [...listValues];
    const objIndex = list.findIndex((obj => obj.id == row.id));
    list[objIndex].percaptasDescricoesItem = newValue;
    list[objIndex].percapitasValores = perCapitasV[0] && perCapitasV[0].valor ? perCapitasV[0].valor : 0;
    list[objIndex].percapitasValoresId = perCapitasV[0] && perCapitasV[0].id ? perCapitasV[0].id : null;
    setListValues(list);

    // console.log('list: ', list);

    // console.log('perCapitasV[0] && perCapitasV[0].valor ? perCapitasV[0].valor : 0', perCapitasV[0] && perCapitasV[0].valor ? perCapitasV[0].valor : 0);

    // console.log(perCapitasValoresList);

    const newList = [...newListValues];
    const objIndex2 = newList.findIndex((obj => obj.id == row.id));
    newList[objIndex2].percaptasDescricoesItem = newValue;
    newList[objIndex2].percapitasValores = perCapitasV[0] && perCapitasV[0].valor ? perCapitasV[0].valor : 0;
    newList[objIndex2].percapitasValoresId = perCapitasV[0] && perCapitasV[0].id ? perCapitasV[0].id : null;
    setNewListValues(newList);

    // console.log('perCapitasV[0] && perCapitasV[0].valor ? perCapitasV[0].id : null: ', perCapitasV[0] && perCapitasV[0].valor ? perCapitasV[0].id : null);

    percaptaTotal();
  }

  const saveData = () => {
    // console.log('selected: ', selected);
    // console.log('newListValues: ', newListValues);

    // const data = newListValues.map((item) => {
    //   if (selected.includes(item.id)) {
    //       return {
    //         "id": item.id,
    //         "valor": item.valor
    //       }
    //   }
    // });

    const payload = [];
    newListValues.forEach(item => {
      if (selected.includes(item.id)) {
        payload.push({
          "id": item.id,
          "percaptasValoresId":item.percapitasValoresId,
        });
      }
    });
    setBackdrop(true);
    const values = api.patch(`termos-de-cooperacao-has-percaptas-valores`, payload)
    values.then((response) => {
      setBackdrop(false);
      handleClickAlert();
      // setSelected([]);
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })

  const newPayload = [];
  newListValues.forEach(item => {
    if (selected.includes(item.id)) {
      newPayload.push({
        "id": item.quantitativoEstudantesId,
        "quantidade": item.quantidadeEstudantes
      });
    }
  });
  setBackdrop(true);
  const newValues = api.patch(`quantitativos-estudantes`, newPayload)
  values.then((response) => {
    setBackdrop(false);
    handleClickAlert();
    setSelected([]);
  })
  .catch((err) => {
    setMessage('Erro ao gravar os dados! Verifique!');
    setBackdrop(false);
    setSeverity('error');
    handleClickAlert();
    console.error("ops! ocorreu um erro" + err);
  })
}

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newListValues.length) : 0;

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      {/* <Paper sx={{ m: 4 }}> */}
        
      <SpinnerCarregando open={backdrop} />

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      
      {/* <pre>{JSON.stringify(selected, null, 2)}</pre> */}
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

        
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 4 }}>

          <Grid item xs={7}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Quantitativo de Estudantes - Listagem1</Box>
              </Typography> 
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}>

              <div style={{ paddingRight: 20 }}>
                <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 400 }}
                >
                  <IconButton sx={{ p: '6px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    size="small"
                    placeholder="Pesquisar ..."
                    inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                    onChange={handleChange}
                    value={searchField}
                  />
                  { searchField.length > 0 ? 
                    <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                      <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                    : null }
                </Paper>
              </div>
              <div style={{ paddingRight: 20 }}>
                <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 150 }}
                >
                  <TextField
                    // className={classes.input}
                    size="small"
                    id="quantidadeTotalEstudantes"
                    color="primary"
                    label="Quant. de Estudantes"
                    variant="outlined"
                    value={quantidadeTotalEstudantes}
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </Paper>
              </div>
              <div style={{ paddingRight: 20 }}>
                <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 150 }}
                >
                  <TextField
                    // className={classes.input}
                    size="small"
                    id="total"
                    color="primary"
                    label="Valores Totais (R$)"
                    variant="outlined"
                    value={valorTotal}
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </Paper>
              </div>
              {/* <div>
                <Box sx={{ pr: 1}}>
                  <AutoCompleteSelectComponent nLabel="Ano" newWidth="250" variant="outlined" apiConst="anos" onSelectData={handleDataAnos} />
                </Box>
              </div> */}
              <div>
                <Button type="submit" variant="outlined" color="primary" size="small" style={{ height: 35, }} onClick={saveData} disabled={!selected.length > 0}>
                  GRAVAR
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader 
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 newListValues.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(newListValues, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {/* <TableCell padding="checkbox" key={row.id}>
                      <IconButton onClick={() => setIsExpandedData(row.id)}>
                        {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell> */}
                    {/* <TableCell component="th" id={labelId} scope="row" padding="normal" sx={{  fontSize: 13 }}>
                      {row.anos}
                    </TableCell> */}

                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>

                    <TableCell align="left" sx={{ fontSize: 13 }}>{row.ano}</TableCell>
                    <TableCell align="left" sx={{ fontSize: 13 }}>{row.unidadesEscolaresNome}</TableCell>
                    <TableCell align="left" sx={{ fontSize: 13 }}>{row.tiposEnsinosDescricao}</TableCell>
                    {/* <TableCell align="left" sx={{  fontSize: 13 }}>{row.percapitasDescricoes}</TableCell> */}
                    <TableCell align="left">
                      <Autocomplete
                        id="controlled-caixa-escolar"
                        style={{ width: 350 }}
                        value={row.percaptasDescricoesItem}

                        options={perCapitasList}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        size="small"
                        onChange={(event, newValue) => {
                          handleSelect(event, row)
                          // @ts-ignore
                          perCapitasChange(row, null);
                          if (newValue) {
                            perCapitasChange(row, newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                          />
                        )}
                      />

                    </TableCell>
                  
                    {/* <TableCell align="left" sx={{  fontSize: 13 }}>{row.valor}</TableCell> */}
                    <TableCell align="left" sx={{  fontSize: 13 }}>
                      {/* {row.valor} */}
                      {/* <TextField id="outlined-size-small" size="small" style={{ width: 120 }} defaultValue={row.valor} /> */}

                      {/*
                      <CurrencyTextField
                          style={{ width: 120 }}
                          size="small"
                          variant="outlined"
                          // value={value}
                          outputFormat="string"
                          minimumValue="0"
                          currencySymbol=""
                          decimalCharacter=","
                          decimalPlaces={0}
                          digitGroupSeparator="."
                          defaultValue={row.quantidadeEstudantes}
                          onChange={(event) => handleSelect(event, row)}
                          textAlign="center"
                          // onChange={(event, value)=> setValue(value)}
                      />
                        */}
                    </TableCell>
                    {/* <TableCell align="center" sx={{  fontSize: 13 }}><EditPrintMenu id={row.id} path="caixas-escolares-has-cargos-funcoes" /></TableCell> */}

                    <TableCell align="left" sx={{  fontSize: 13 }}>
                      {/* {row.valor} */}
                      {/* <TextField
                        id="outlined-size-small"
                        className="inputCenter"
                        disabled
                        size="small" style={{ width: 120 }}
                        value={row.percapitasValores.toLocaleString('pt-BR',{ minimumFractionDigits: 2 })}
                        style={{ width: 120, backgroundColor : '#F5F5F5' }}
                        // InputProps={{  readOnly: true }}
                      /> */}

                      {/*
                      <CurrencyTextField
                        disabled
                        style={{ width: 120, backgroundColor : '#F5F5F5' }}
                        size="small"
                        variant="outlined"
                        value={row.percapitasValores}
                        outputFormat="string"
                        minimumValue="0"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        // defaultValue={row.percapitasValores}
                        // onChange={(event) => handleSelect(event, row)}
                        textAlign="center"
                        // onChange={(event, value)=> setValue(value)}
                      />
                    */}
                    </TableCell>

                    <TableCell align="left" sx={{  fontSize: 13 }}>
                      {/* {row.valor} */}
                      {/* <TextField id="outlined-size-small" size="small" style={{ width: 120 }} defaultValue={row.valor} /> */}

                      {/*
                      <CurrencyTextField
                          disabled
                          style={{ width: 120, backgroundColor : '#F5F5F5' }}
                          size="small"
                          variant="outlined"
                          value={row.total}
                          outputFormat="string"
                          minimumValue="0"
                          currencySymbol=""
                          decimalCharacter=","
                          digitGroupSeparator="."
                          defaultValue={row.total}
                          // onChange={(event) => handleSelect(event, row)}
                          textAlign="center"
                          // onChange={(event, value)=> setValue(value)}
                      />
                    */}
                    </TableCell>
                    
                  </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  // style={{
                  //   height: (dense ? 33 : 53) * emptyRows,
                  // }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={newListValues.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      {/* </Paper> */}
    </Box>
  );
}
