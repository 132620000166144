import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import { visuallyHidden } from '@mui/utils';

import ClearOutlinedIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

import { Button } from "@mui/material";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import SearchBar from '../../components/SearchBar';
import EditPrintMenu from '../../components/EditPrintMenu';

import { useParams, useHistory } from "react-router-dom";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import DocumentosTable from '../../components/DocumentosTable'

import api from '../../services/api';

const rows = [];

function descendingComparator(a, b, orderBy) {

  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") < a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return -1;
  }
  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") > a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'pessoaCargoFuncao',
    numeric: false,
    disablePadding: false,
    label: 'Função',
    width: '20%',
    align: "left"
  },
  {
    id: 'pessoaNome',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
    width: '35%',
    align: "left"
  },
  {
    id: 'caixasEscolaresNome',
    numeric: false,
    disablePadding: false,
    label: 'Caixa Escolar',
    width: '40%',
    align: "left"
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            size="small"
            width={headCell.width}
            align={headCell.align}
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.pessoaCargoFuncao ? order : false}
          >
            <TableSortLabel
              width={headCell.width}
              align={headCell.align}
              active={orderBy === headCell.pessoaCargoFuncao}
              direction={orderBy === headCell.pessoaCargoFuncao ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.pessoaCargoFuncao ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <span></span>
        </TableCell>
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable(props) {
  const query = useParams();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('pessoaCargoFuncao');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   const values = !query.id ? api.get("caixas-escolares-has-cargos-funcoes") : api.get(`caixas-escolares-has-cargos-funcoes/por-caixa-escolar/${query.id}`);
  //   values.then((response) => {
  //     setListValues(response.data);
  //     setNewListValues(response.data);
  //   })
  //   .catch((err) => {
  //     console.error("ops! ocorreu um erro" + err);
  //   })
  // }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleCloseDeleteDialog = () => {
  //   setOpenDeleteDialog(false);
  // }
  
  const newDeleteRegister = () => {
    alert('deleteRegister Table');
    // handleCloseDeleteDialog();
    // console.log('rowwww: ', rowSelected.id)
    // // @ts-ignore
    // api.delete(`telefones-unidades-escolares/${rowSelected.id}`)
    // .then((response) => {
    //   setMessage('Dados excluídos com sucesso!');
    //   // props.updateData();
    //   setSelected([]);
    //   setBackdrop(false);
    //   handleClickAlert();
    //   getData();
    //   handleCloseDeleteDialog();
    // })
    // .catch((err) => {
    //   setMessage('Erro ao excluir os dados! Verifique!');
    //   // props.updateData();
    //   setSelected([]);
    //   setBackdrop(false);
    //   setSeverity('error');
    //   handleClickAlert();
    //   console.error("ops! ocorreu um erro" + err);
    // })
  }
  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const setNewRegister = () => {
    if (query.id) {
      //@ts-ignore
      history.push(`/conselho-fiscal-escolar/new/${query.id}`)
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
     const [searchField, setSearchField] = React.useState('');
     const [searchData, setSearchData] = React.useState([
       {regioes: []}, {bairros: []}, {tiposEnsinos: []}
     ]);
     const handleChange = async (event) => {
      await setSearchField(event.target.value);
     };

     const handleDataSearch = async (data) => {
      setSearchData(data);
     };

     useEffect(() => {
      //Doing filteration on according to select state option
      let newListValue = listValues;
      newListValue = listValues.filter(
        listValues => listValues.caixasEscolaresNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.pessoaNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
          listValues.pessoaCargoFuncao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      );
      if ((searchData) && (searchData[0].regioes.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[0].regioes.includes(listValues.unidadeEscolarRegionalId)
        );
      }
      if ((searchData) && (searchData[1].bairros.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[1].bairros.includes(listValues.unidadeEscolarBairroId)
        );
      }
      if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
        );
      }

      //set state variable data after filteration
      setNewListValues(newListValue);
        // console.log(searchData)
      }, [searchField, searchData])

      const [isExpanded, setIsExpanded] = React.useState([]);
      const [totalValues, setTotalValues] = React.useState([]);

      const Alert = React.forwardRef(function Alert(props, ref) {
        // @ts-ignore
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

      const setIsExpandedData = async (id) => {
        let filtered = isExpanded;

        if (isExpanded.includes(id)) {
          filtered = isExpanded.filter(function(value, index, arr){ 
            return value !== id;
          });
        } else {
          filtered.push(id)
        }
        setIsExpanded(filtered)
      };

      useEffect(() => {
        setTotalValues(newListValues.length)
      }, [newListValues]);

  return (
    <Box sx={{ width: '100%', mt: 2  }}>

    <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>


      <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 2 }}>

          <Grid item xs={7}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Estatutos e Ata</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={5} sx={{ pb: 4 }}>
              <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}>
                <div style={{ paddingRight: 15 }}>
                  <Button onClick={() => {history.push(`/estatutos-atas-insert/por-caixa-escolar/${query.id}`)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                    Novo
                  </Button>
                </div>
                <div>
                  <Button onClick={() => {history.push(`/caixas-escolares-list`)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                    Voltar
                  </Button>
                </div>
              </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <DocumentosTable caixasEscolaresId={query.id} />
          </Grid>

        </Grid>
      </Paper>
    </Box>
  );
}


