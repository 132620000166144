import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import { visuallyHidden } from '@mui/utils';

import ClearOutlinedIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

import { Button } from "@mui/material";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// import SearchBar from '../../../components/SearchBar';
import EditPrintMenu from '../../../components/EditPrintMenu';

import api from '../../../services/api';

const rows = [];

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    cursor: 'pointer'
  },
}));

function descendingComparator(a, b, orderBy) {

  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") < a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return -1;
  }
  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") > a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'caixasEscolaresNome',
    numeric: false,
    disablePadding: true,
    label: 'Caixa Escolar',
    width: '25%',
    align: "left"
  },
  // {
  //   id: 'unidadesEscolaresNomeCompleto',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Unidade Escolar',
  //   width: '25%',
  //   align: "left"
  // },
  {
    id: 'contasBancariasTiposVerbasDescricao',
    numeric: false,
    disablePadding: false,
    label: 'Tipo de Verba',
    width: '25%',
    align: "left"
  },
  {
    id: 'bancoNome',
    numeric: false,
    disablePadding: false,
    label: 'Banco (Nome)',
    width: '25%',
    align: "center"
  },
  {
    id: 'bancoCodigo',
    numeric: false,
    disablePadding: false,
    label: 'Banco (Código)',
    width: '10%',
    align: "center"
  },
  {
    id: 'nomeAgencia',
    numeric: false,
    disablePadding: false,
    label: 'Agência (Nome)',
    width: '10%',
    align: "center"
  },
  {
    id: 'numeroAgencia',
    numeric: false,
    disablePadding: false,
    label: 'Agência (Número)',
    width: '10%',
    align: "center"
  },
  {
    id: 'numeroContaCorrente',
    numeric: false,
    disablePadding: false,
    label: 'Conta Corrente (Número)',
    width: '10%',
    align: "center"
  },
  {
    id: 'statusContaBancariaDescricao',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    width: '6%',
    align: "left"
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '#',
    width: '6%',
    align: "center"
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            size="small"
            width={headCell.width}
            align={headCell.align}
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.caixaEscolarNome ? order : false}
          >
            <TableSortLabel
              width={headCell.width}
              align={headCell.align}
              active={orderBy === headCell.caixaEscolarNome}
              direction={orderBy === headCell.caixaEscolarNome ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.caixaEscolarNome ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <span></span>
        </TableCell>
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable(props) {
  const query = useParams();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('caixaEscolarNome');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  useEffect(() => {
    getNewData();
  }, []);

  const getNewData = () => {
    //@ts-ignore
    const values = query.id ? api.get(`dados-bancarios/por-caixa-escolar/${query.id}`) : api.get("dados-bancarios");
    values.then((response) => {
      setListValues(response.data);
      setNewListValues(response.data);
    })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const classes = useStyles();

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
     const [searchField, setSearchField] = React.useState('');
     const [searchData, setSearchData] = React.useState([
       {regioes: []}, {bairros: []}, {tiposEnsinos: []}
     ]);
     const handleChange = async (event) => {
      await setSearchField(event.target.value);
     };

     const handleDataSearch = async (data) => {
      setSearchData(data);
     };

     useEffect(() => {
       if (listValues && listValues.length > 0) {
        //Doing filteration on according to select state option
        let newListValue = listValues;
        newListValue = listValues.filter(
          listValues => listValues.caixasEscolaresNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
          listValues.unidadesEscolaresNomeCompleto.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
          listValues.bancoNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
            listValues.contasBancariasTiposVerbasDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
        );
        if ((searchData) && (searchData[0].regioes.length > 0)) {
          newListValue = listValues.filter(
            listValues => searchData[0].regioes.includes(listValues.regiao_id)
          );
        }
        if ((searchData) && (searchData[1].bairros.length > 0)) {
          newListValue = listValues.filter(
            listValues => searchData[1].bairros.includes(listValues.bairro_id)
          );
        }
        if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
          newListValue = listValues.filter(
            listValues => searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
              searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
              searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
              searchData[2].tiposEnsinos
              .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
          );
        }

        //set state variable data after filteration
        setNewListValues(newListValue);
          // console.log(searchData)
       }
      }, [searchField, searchData])

      const [isExpanded, setIsExpanded] = React.useState([]);
      const [totalValues, setTotalValues] = React.useState([]);

      const setIsExpandedData = async (id) => {
        let filtered = isExpanded;

        if (isExpanded.includes(id)) {
          filtered = isExpanded.filter(function(value, index, arr){ 
            return value !== id;
          });
        } else {
          filtered.push(id)
        }
        setIsExpanded(filtered)
       };

          useEffect(() => {
         setTotalValues(newListValues.length)
        }, [newListValues]);

  return (
    (listValues ? 
      <Box sx={{ width: '100%', mt: 2 }}>
        {/* <Paper elevation={3}sx={{ px: 1, pb: 5, m: 2, display: 'flex', flexDirection: 'column' }}> */}

          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 4 }}>

            <Grid item xs={7}>
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Dados Bancários - Listagem</Box>
                </Typography> 
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Box
                component="div"
                className={`${classes.bottomRightBox} ${classes.box}`}>

                <div style={{ paddingRight: 20 }}>
                  <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', width: 400 }}
                  >
                    <IconButton sx={{ p: '6px' }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      size="small"
                      placeholder="Pesquisar ..."
                      inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                      onChange={handleChange}
                      value={searchField}
                    />
                    { searchField.length > 0 ? 
                      <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                        <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                      : null }
                  </Paper>
                </div>
                <div style={{ paddingRight: 20 }}>
                  <Paper
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', width: 100 }}
                  >
                    <TextField
                      // className={classes.input}
                      size="small"
                      id="total"
                      color="primary"
                      label="Total"
                      variant="outlined"
                      value={totalValues ? String(totalValues).padStart(2, '0') : '00'}
                      inputProps={{ style: { textAlign: "center" } }}
                    />
                  </Paper>
                </div>
                <div>
                  <Button onClick={() => history.push('/dados-bancarios/new')} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                    Novo
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>

          {/* <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 2 }}>
            <Grid item sx={{ flex: '100%', pt: 2 }}>
              <div><SearchBar onSelectData={handleDataSearch} /></div>
            </Grid>
          </Grid> */}
          
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size='small'
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
              />
              <TableBody>
                {stableSort(newListValues, getComparator(order, orderBy))
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.caixaEscolarNome);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.caixasEscolaresNome)}
                          key={row.id}
                        >
                          <TableCell padding="checkbox" key={row.id}>
                            <IconButton onClick={() => setIsExpandedData(row.id)}>
                              {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>

                          {/*<TableCell size="small" align="left" sx={{ minWidth: 180, fontSize: 11 }}>{row.caixasEscolaresNome}</TableCell>

                          <TableCell size="small" align="left" sx={{ minWidth:180, fontSize: 11 }}>{row.unidadesEscolaresNomeCompleto}</TableCell>*/}



                          <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          sx={{ fontSize: 13 }}
                        >
                          <span align="left" style={{ fontSize: 12 }} sx={{ minWidth: 125, padding: 0 }}>
                            {row.caixasEscolaresNome}
                          </span>
                          <br />
                          <span style={{ fontSize: 12, color: '#BEBEBE' }}>{row.unidadesEscolaresNomeCompleto}&nbsp;</span>
                        </TableCell>








                          <TableCell size="small" align="left" sx={{ minWidth: 180, fontSize: 11 }}>{row.contasBancariasTiposVerbasDescricao}</TableCell>
                          <TableCell size="small" align="center" sx={{ minWidth: 180, fontSize: 11 }}>{row.bancoNome}</TableCell>
                          <TableCell size="small" align="center" sx={{ minWidth: 70, fontSize: 11 }}>{row.bancoCodigo}</TableCell>
                          <TableCell size="small" align="center" sx={{ minWidth: 70, fontSize: 11 }}>{row.nomeAgencia}</TableCell>
                          <TableCell size="small" align="center" sx={{ minWidth: 70, fontSize: 11 }}>{row.numeroAgencia}</TableCell>
                          <TableCell size="small" align="center" sx={{ minWidth: 70, fontSize: 11 }}>{row.numeroContaCorrente}</TableCell>
                          <TableCell size="small" align="center" sx={{ minWidth: 70, fontSize: 11 }}>{row.statusContaBancariaDescricao}</TableCell>






                          <TableCell size="small" align="center" sx={{ minWidth: 50 }}>
                          <EditPrintMenu
                              id={row.id}
                              path="dados-bancarios"
                              optionals={'edit', 'delete', 'print'}
                              data1={`${row.caixasEscolaresNome}`}
                              data2={`Conta Corrente (Número): ${row.numeroContaCorrente}`}
                              data3={`Banco (Nome): ${row.bancoNome}`}
                              getNewData={getNewData} />
                          </TableCell>
                        </TableRow>

                        {isExpanded.includes(row.id) && (
                          <TableRow>
                            <TableCell padding="checkbox" />
                            <tr><div>Teste</div></tr>
                          </TableRow>
                        )}

                      </>
                      
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        {/* </Paper> */}
      </Box>
          :
      <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando ...</span></Typography>)
  );
}
