import React, { useContext, useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';

export default function PaginaInicial() {
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#4a89c8'
        },
        secondary: {
          main: '#de4993'
        }
      }
    }
  );
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        Página Inicial
        </Box>
      </Container>
    </ThemeProvider>
  );
}
