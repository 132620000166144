import { useState, useEffect, useCallback } from 'react';

import api from '../../services/api';
import history from '../../history';

import { getUserData, userRoles } from '../../utils/functions';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setAuthenticated(false);
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      setAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const userLogged = useCallback(() => {
    const token = localStorage.getItem("token");
    if (token) {
      return true;
    }
    return false;
  }, [] );

  async function handleLogin(email, password) {
    // console.log('newEmail: ', email.toLowerCase());
    // console.log('newPassword: ', password);
    const payload = {
      email: email.toLowerCase(),
      password: password
    }
    localStorage.clear();
    // const { data: { token } } = await api.post('auth/login', payload);

    const data = await api.post(`auth/login`, payload)
    .then((response) => {

      // console.log ('response.dataaaaaaaaaaaaaaaaaaa: ', response.data);
      setToken(response.data.token);
      setNome(response.data.nome);
      setEmail(response.data.email);
      setRoles(response.data.roles);
      localStorage.setItem('token', JSON.stringify(response.data.token));
      // localStorage.setItem('nome', JSON.stringify(response.data.nome));
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      setAuthenticated(true);

      const userData = getUserData();
      // console.log('getUserData:', getUserData());
      
      if (userRoles('Login', userData.userRoles)) {
        history.push('/caixas-escolares-list');
      } else if (userRoles('Demonstrativo', userData.userRoles)) {
        history.push(`/demonstrativo-gerencial-anual-edit/${userData.caixasEscolaresId}`);
      } else {
        history.push(`projeto-alem-dos-muros-list/ef997f88-81f6-11ec-b847-00155d6335ac/${userData.caixasEscolaresId}`);
        // history.push(`graficos-verba-caixa/${userData.userId}/${userData.caixasEscolaresId}`);
      }
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = undefined;
    history.push('/login');
  }

  return { authenticated, loading, handleLogin, handleLogout, userLogged, nome, email, roles };
}
