import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import FormControl from '@mui/material/FormControl';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando'
import Backdrop from '@mui/material/Backdrop';
import TelefonesTable from '../../../components/TelefonesTable';

import api from "../../../services/api";

import { uuidv4, replaceAll, formatCnpj, validarCNPJ, validaChaveDeAcessoNotaFiscal } from '../../../utils/functions';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import Divider from '@mui/material/Divider';

import SelectComponent from '../../../components/Controls/SelectComponent';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import NumberFormat from 'react-number-format';

import ButtomMenuDemonstrativo from '../../../components/ButtomMenuDemonstrativo';

import { formatDateEnUs2 } from "../../../utils/functions";

import { Plus, Minus } from 'mdi-material-ui/light'




// import ItensNotasFiscaisRecibos from '../../components/Controls/ItensNotasFiscaisRecibos';

// import ItensNotasFiscaisRecibosList  from '../../components/ItensNotasFiscaisRecibosList';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  valorTotal: {
    backgroundColor : "#F5F5F5",
    borderColor: "primary"
  }
}));



const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix="R$ "
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
};

const options = [
  "Totais Gerais",
  "Totais Parciais",
  "Não Preencher",
  "Preencher"
];

export default function DataFunction () {
  const query = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const showFields = React.useState({
    paraPreencher: true,
    preenchidos: true,
    totaisGerais: true
  });

  const [selectedOptions, setSelectedOptions] = React.useState(options);

  const handleChangeShow = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [menuOptions, setMenuOptions] = React.useState([]);
  // const [menusOptions, setMenusOptions] = React.useState(['menuGravar', 'menuGravarFinalizar', 'menuImprimir']);

  const [caixaEscolar, setCaixaEscolar] = React.useState({
    nome: '',
    unidadeEscolarTipoAbreviacao: '',
    unidadeEscolarNome: '',
    cnpj: ''
  });

  const [finalizado, setFinalizado] = React.useState(0);

  const [inputs, setInputs] = React.useState({

    demonstrativosGerenciaisAnuaisId: null,

    saldosCaixa: "0.0",

    saldosContaMunicipalCusteio: "0.0", 
    saldosContaMunicipalCapital: "0.0",
    saldosContaMunicipalTotal: "0.0",

    saldosContaFederalFndeCusteio: "0.0",
    saldosContaFederalFndeCapital: "0.0",
    saldosContaFederalFndeTotal: "0.0",

    saldosTotal: "0.0",

    receitasVerbasFndeCusteio: "0.0",
    rendimentosFederalCusteio: "0.0",
    receitasVerbasFndeCusteioTotal: "0.0",

    receitasVerbasFndeCapital: "0.0",
    rendimentosFederalCapital: "0.0",
    receitasVerbasFndeCapitalTotal: "0.0",

    receitasVerbasFndeTotal: "0.0",

    receitasVerbasMunicipaisCusteio: "0.0",
    rendimentosVerbasMunicipaisCusteio: "0.0",
    receitasVerbasMunicipaisCusteioTotal: "0.0",

    receitasVerbasMunicipaisCapital: "0.0",
    rendimentosVerbasMunicipaisCapital: "0.0",
    receitasVerbasMunicipaisCapitalTotal: "0.0",

    receitasVerbasMunicipaisTotal: "0.0",

    receitasRecursosProprios: "0.0",
    receitasDoacoes: "0.0",
    receitasPromocoesSociais: "0.0",
    receitasOutras: "0.0",

    receitasTotal: "0.0",

    formacaoProfissionaisEdInfantilCusteio: "0.0",
    formacaoProfissionaisEdInfantilCapital: "0.0",
    formacaoProfissionaisEnsinoFundamentalCusteio: "0.0",
    formacaoProfissionaisEnsinoFundamentalCapital: "0.0",
    formacaoProfissionaisEdInfantilEdEspecialCusteio: "0.0",
    formacaoProfissionaisEdInfantilEdEspecialCapital: "0.0",
    formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio: "0.0",
    formacaoProfissionaisEnsinoFundamentalEdEspecialCapital: "0.0",
    conservacaoInstalacoesFisicasCusteio: "0.0",
    conservacaoInstalacoesFisicasCapital: "0.0",
    manutencaoEquipamentosCusteio: "0.0",
    manutencaoEquipamentosCapital: "0.0",
    materialExpedienteCusteio: "0.0",
    materialExpedienteCapital: "0.0",
    materialLimpezaCusteio: "0.0",
    materialLimpezaCapital: "0.0",
    projetosEducacionaisCusteio: "0.0",
    projetosEducacionaisCapital: "0.0",
    materialEsportivoCusteio: "0.0",
    materialEsportivoCapital: "0.0",
    brinquedosCusteio: "0.0",
    brinquedosCapital: "0.0",
    materialUsoEstProfessoresEdInfantilCusteio: "0.0",
    materialUsoEstProfessoresEdInfantilCapital: "0.0",
    materialUsoEstProfessoresEdInfantilEdEspecialCusteio: "0.0",
    materialUsoEstProfessoresEdInfantilEdEspecialCapital: "0.0",
    materialUsoEstProfessoresEnsinoFundamentalCusteio: "0.0",
    materialUsoEstProfessoresEnsinoFundamentalCapital: "0.0",
    materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio: "0.0",
    materialUsoEstProfessoresEnsFundamentalEdEspecialCapital: "0.0",
    bensPermanentesEdInfantil: "0.0",
    bensPermanentesEnsinoFundamental: "0.0",
    bensPermanentesEdEspecial: "0.0",
    registrosDeCartorio: "0.0",



    despesasCusteioFnde: "0.0",
    despesasCapitalFnde: "0.0",
    despesasFndeTotal: "0.0",

    despesasCusteioMunicipal: "0.0",
    despesasCapitalMunicipal: "0.0",
    despesasMunicipalTotal: "0.0",

    despesasTarifasBancarias: "0.0",
    devolucaoRecursosGea: "0.0",

    despesasTotal: "0.0",

    consolidadoSaldoAnteriorReceitas: "0.0",
    consolidadoDespesas: "0.0",
    consolidadoSaldoFinalGeral: "0.0",

    saldoFinalEmCaixa: "0.0",
    saldoContaMunicipal: "0.0",
    saldoVerbasFnde: "0.0",

    saldoFinalTotal: "0.0"
  });

  const [showSaldosData, setShowSaldosData] = React.useState(true);
  const [showReceitasData, setShowReceitasData] = React.useState(true);
  const [showDespesasData, setShowDespesasData] = React.useState(true);
  const [showConsolidadoData, setShowConsolidadoData] = React.useState(true);
  const [showSaldoFinalData, setShowSaldoFinalData] = React.useState(true);
  const [showFndeDebitos, setShowFndeDebitos] = React.useState(true);

  const getShowSaldosData = () => {
    setShowSaldosData(!showSaldosData);
  }
  const getShowReceitasData = () => {
    setShowReceitasData(!showReceitasData);
  }
  const getShowDespesasData = () => {
    setShowDespesasData(!showDespesasData);
  }
  const getShowConsolidadoData = () => {
    setShowConsolidadoData(!showConsolidadoData);
  }
  const getShowSaldoFinalData = () => {
    setShowSaldoFinalData(!showSaldoFinalData);
  }
  const getShowFndeDebitos = () => {
    setShowFndeDebitos(!showFndeDebitos);
  }

  const handleInputsChanges = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const updateValues = () => {

    const saldosContaMunicipalTotal = String(parseFloat(inputs.saldosContaMunicipalCusteio || 0) + parseFloat(inputs.saldosContaMunicipalCapital || 0));
    const saldosContaFederalFndeTotal = String(parseFloat(inputs.saldosContaFederalFndeCusteio || 0) + parseFloat(inputs.saldosContaFederalFndeCapital || 0));
    const saldosTotal = String(parseFloat(inputs.saldosCaixa || 0) + parseFloat(saldosContaMunicipalTotal || 0) + parseFloat(inputs.saldosContaFederalFndeTotal || 0));

    const receitasVerbasFndeCusteioTotal = String(parseFloat(inputs.receitasVerbasFndeCusteio || 0) + parseFloat(inputs.rendimentosFederalCusteio || 0));
    const receitasVerbasFndeCapitalTotal = String(parseFloat(inputs.receitasVerbasFndeCapital || 0) + parseFloat(inputs.rendimentosFederalCapital || 0));
    const receitasVerbasFndeTotal = String(parseFloat(receitasVerbasFndeCusteioTotal || 0) + parseFloat(receitasVerbasFndeCapitalTotal || 0));
    const receitasVerbasMunicipaisCusteioTotal = String(parseFloat(inputs.receitasVerbasMunicipaisCusteio || 0) + parseFloat(inputs.rendimentosVerbasMunicipaisCusteio || 0));
    const receitasVerbasMunicipaisCapitalTotal= String(parseFloat(inputs.receitasVerbasMunicipaisCapital || 0) + parseFloat(inputs.rendimentosVerbasMunicipaisCapital || 0));
    const receitasVerbasMunicipaisTotal = String(parseFloat(receitasVerbasMunicipaisCusteioTotal || 0) + parseFloat(receitasVerbasMunicipaisCapitalTotal || 0));
    const receitasTotal = String(parseFloat(receitasVerbasFndeTotal || 0) + parseFloat(receitasVerbasMunicipaisTotal || 0) + parseFloat(inputs.receitasRecursosProprios || 0) + parseFloat(inputs.receitasDoacoes || 0) + parseFloat(inputs.receitasPromocoesSociais || 0) + parseFloat(inputs.receitasOutras || 0));
    // const despesasFndeTotal = String(parseFloat(inputs.despesasCusteioFnde) + parseFloat(inputs.despesasCapitalFnde));

  const despesasCusteioFnde = String(parseFloat(inputs.formacaoProfissionaisEdInfantilCusteio || 0) + parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalCusteio || 0) +  parseFloat(inputs.formacaoProfissionaisEdInfantilEdEspecialCusteio || 0) +  parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio || 0) + parseFloat(inputs.conservacaoInstalacoesFisicasCusteio || 0) + parseFloat(inputs.manutencaoEquipamentosCusteio || 0) + parseFloat(inputs.materialExpedienteCusteio || 0) + parseFloat(inputs.materialLimpezaCusteio || 0) + parseFloat(inputs.projetosEducacionaisCusteio || 0) + parseFloat(inputs.materialEsportivoCusteio || 0) + parseFloat(inputs.brinquedosCusteio || 0) + parseFloat(inputs.materialUsoEstProfessoresEdInfantilCusteio || 0) + parseFloat(inputs.materialUsoEstProfessoresEdInfantilEdEspecialCusteio || 0) + parseFloat(inputs.materialUsoEstProfessoresEnsinoFundamentalCusteio || 0) + parseFloat(inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio || 0) + parseFloat(inputs.registrosDeCartorio || 0));

  const despesasCapitalFnde = String(parseFloat(inputs.formacaoProfissionaisEdInfantilCapital || 0) + parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalCapital || 0) + parseFloat(inputs.formacaoProfissionaisEdInfantilEdEspecialCapital || 0) + parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCapital || 0) + parseFloat(inputs.conservacaoInstalacoesFisicasCapital || 0) + parseFloat(inputs.manutencaoEquipamentosCapital || 0) + parseFloat(inputs.materialExpedienteCapital || 0) + parseFloat(inputs.materialLimpezaCapital || 0) + parseFloat(inputs.projetosEducacionaisCapital || 0) + parseFloat(inputs.materialEsportivoCapital || 0) + parseFloat(inputs.brinquedosCapital || 0) + parseFloat(inputs.materialUsoEstProfessoresEdInfantilCapital || 0) + parseFloat(inputs.materialUsoEstProfessoresEdInfantilEdEspecialCapital || 0) + parseFloat(inputs.materialUsoEstProfessoresEnsinoFundamentalCapital || 0) + parseFloat(inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCapital || 0) + parseFloat(inputs.bensPermanentesEdInfantil || 0) + parseFloat(inputs.bensPermanentesEnsinoFundamental || 0) + parseFloat(inputs.bensPermanentesEdEspecial || 0));

  const despesasFndeTotal = String(parseFloat(despesasCusteioFnde || 0) + parseFloat(despesasCapitalFnde || 0));

  const despesasMunicipalTotal = String(parseFloat(inputs.despesasCusteioMunicipal || 0) + parseFloat(inputs.despesasCapitalMunicipal || 0) + parseFloat(inputs.devolucaoRecursosGea || 0));
  const despesasTotal = String(parseFloat(despesasFndeTotal || 0) + parseFloat(despesasMunicipalTotal || 0) + parseFloat(inputs.despesasTarifasBancarias || 0));
  const consolidadoSaldoAnteriorReceitas = String(parseFloat(saldosTotal || 0) + parseFloat(receitasTotal || 0));
  const consolidadoDespesas = String(parseFloat(despesasTotal || 0));
  const consolidadoSaldoFinalGeral = String(parseFloat(consolidadoSaldoAnteriorReceitas || 0) - parseFloat(consolidadoDespesas || 0));
  const saldoFinalTotal = String(parseFloat(inputs.saldoFinalEmCaixa || 0) + parseFloat(inputs.saldoContaMunicipal || 0) + parseFloat(inputs.saldoVerbasFnde || 0));

    setInputs({...inputs,

      'saldosContaMunicipalTotal': saldosContaMunicipalTotal,
      'saldosContaFederalFndeTotal': saldosContaFederalFndeTotal,
      'saldosTotal': saldosTotal,
      'receitasVerbasFndeCusteioTotal': receitasVerbasFndeCusteioTotal,
      'receitasVerbasFndeCapitalTotal': receitasVerbasFndeCapitalTotal,
      'receitasVerbasFndeTotal': receitasVerbasFndeTotal,
      'receitasVerbasMunicipaisCusteioTotal': receitasVerbasMunicipaisCusteioTotal,
      'receitasVerbasMunicipaisCapitalTotal': receitasVerbasMunicipaisCapitalTotal,
      'receitasVerbasMunicipaisTotal': receitasVerbasMunicipaisTotal,
      'receitasTotal': receitasTotal,

      'despesasCusteioFnde': despesasCusteioFnde,
      'despesasCapitalFnde': despesasCapitalFnde,
      'despesasFndeTotal': despesasFndeTotal,

      'despesasMunicipalTotal': despesasMunicipalTotal,
      'despesasTotal': despesasTotal,
      'consolidadoSaldoAnteriorReceitas': consolidadoSaldoAnteriorReceitas,
      'consolidadoDespesas': consolidadoDespesas,
      'consolidadoSaldoFinalGeral': consolidadoSaldoFinalGeral,
      'saldoFinalTotal': saldoFinalTotal
    });

 

  }

  const saveForm = (newFinalizado) => {

    console.log("finalizado: ", newFinalizado);

    const payload = {
      id: inputs.demonstrativosGerenciaisAnuaisId,

      anosId: inputs.anosId,

      finalizado: newFinalizado,

      saldosCaixa: parseFloat(inputs.saldosCaixa),
      saldosContaMunicipalCusteio: parseFloat(inputs.saldosContaMunicipalCusteio),
      saldosContaMunicipalCapital: parseFloat(inputs.saldosContaMunicipalCapital),
      saldosContaFederalFndeCusteio: parseFloat(inputs.saldosContaFederalFndeCusteio),
      saldosContaFederalFndeCapital: parseFloat(inputs.saldosContaFederalFndeCapital),
      rendimentosFederalCusteio: parseFloat(inputs.rendimentosFederalCusteio),
      rendimentosFederalCapital: parseFloat(inputs.rendimentosFederalCapital),
      receitasVerbasMunicipaisCusteio: parseFloat(inputs.receitasVerbasMunicipaisCusteio),
      rendimentosVerbasMunicipaisCusteio: parseFloat(inputs.rendimentosVerbasMunicipaisCusteio),
      
      receitasVerbasMunicipaisCapital: parseFloat(inputs.receitasVerbasMunicipaisCapital),
      rendimentosVerbasMunicipaisCapital: parseFloat(inputs.rendimentosVerbasMunicipaisCapital),

      receitasRecursosProprios: parseFloat(inputs.receitasRecursosProprios),
      receitasDoacoes: parseFloat(inputs.receitasDoacoes),
      receitasPromocoesSociais: parseFloat(inputs.receitasPromocoesSociais),
      receitasOutras: parseFloat(inputs.receitasOutras),

      datasDepositosRecursosFederais: inputs.datasDepositosRecursosFederais,
      // saldosCaixaFinal: parseFloat(inputs.saldosCaixaFinal),

      despesasCusteioMunicipal: parseFloat(inputs.despesasCusteioMunicipal),
      despesasCapitalMunicipal: parseFloat(inputs.despesasCapitalMunicipal),

      despesasTarifasBancarias: parseFloat(inputs.despesasTarifasBancarias),
      devolucaoRecursosGea: parseFloat(inputs.devolucaoRecursosGea),

      saldoFinalEmCaixa: parseFloat(parseFloat(inputs.saldoFinalEmCaixa)),
      saldoContaMunicipal: parseFloat(inputs.saldoContaMunicipal),
      saldoVerbasFnde: parseFloat(inputs.saldoVerbasFnde),

      formacaoProfissionaisEdInfantilCusteio: parseFloat(inputs.formacaoProfissionaisEdInfantilCusteio),
      formacaoProfissionaisEdInfantilCapital: parseFloat(inputs.formacaoProfissionaisEdInfantilCapital),
      formacaoProfissionaisEnsinoFundamentalCusteio: parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalCusteio),
      formacaoProfissionaisEnsinoFundamentalCapital: parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalCapital),
      formacaoProfissionaisEdInfantilEdEspecialCusteio: parseFloat(inputs.formacaoProfissionaisEdInfantilEdEspecialCusteio),
      formacaoProfissionaisEdInfantilEdEspecialCapital: parseFloat(inputs.formacaoProfissionaisEdInfantilEdEspecialCapital),
      formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio: parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio),
      formacaoProfissionaisEnsinoFundamentalEdEspecialCapital: parseFloat(inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCapital),
      conservacaoInstalacoesFisicasCusteio: parseFloat(inputs.conservacaoInstalacoesFisicasCusteio),
      conservacaoInstalacoesFisicasCapital: parseFloat(inputs.conservacaoInstalacoesFisicasCapital),
      manutencaoEquipamentosCusteio: parseFloat(inputs.manutencaoEquipamentosCusteio),
      manutencaoEquipamentosCapital: parseFloat(inputs.manutencaoEquipamentosCapital),
      materialExpedienteCusteio: parseFloat(inputs.materialExpedienteCusteio),
      materialExpedienteCapital: parseFloat(inputs.materialExpedienteCapital),
      materialLimpezaCusteio: parseFloat(inputs.materialLimpezaCusteio),
      materialLimpezaCapital: parseFloat(inputs.materialLimpezaCapital),
      projetosEducacionaisCusteio: parseFloat(inputs.projetosEducacionaisCusteio),
      projetosEducacionaisCapital: parseFloat(inputs.projetosEducacionaisCapital),
      materialEsportivoCusteio: parseFloat(inputs.materialEsportivoCusteio),
      materialEsportivoCapital: parseFloat(inputs.materialEsportivoCapital),
      brinquedosCusteio: parseFloat(inputs.brinquedosCusteio),
      brinquedosCapital: parseFloat(inputs.brinquedosCapital),
      materialUsoEstProfessoresEdInfantilCusteio: parseFloat(inputs.materialUsoEstProfessoresEdInfantilCusteio),
      materialUsoEstProfessoresEdInfantilCapital: parseFloat(inputs.materialUsoEstProfessoresEdInfantilCapital),
      materialUsoEstProfessoresEdInfantilEdEspecialCusteio: parseFloat(inputs.materialUsoEstProfessoresEdInfantilEdEspecialCusteio),
      materialUsoEstProfessoresEdInfantilEdEspecialCapital: parseFloat(inputs.materialUsoEstProfessoresEdInfantilEdEspecialCapital),
      materialUsoEstProfessoresEnsinoFundamentalCusteio: parseFloat(inputs.materialUsoEstProfessoresEnsinoFundamentalCusteio),
      materialUsoEstProfessoresEnsinoFundamentalCapital: parseFloat(inputs.materialUsoEstProfessoresEnsinoFundamentalCapital),
      materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio: parseFloat(inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio),
      materialUsoEstProfessoresEnsFundamentalEdEspecialCapital: parseFloat(inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCapital),
      bensPermanentesEdInfantil: parseFloat(inputs.bensPermanentesEdInfantil),
      bensPermanentesEnsinoFundamental: parseFloat(inputs.bensPermanentesEnsinoFundamental),
      bensPermanentesEdEspecial: parseFloat(inputs.bensPermanentesEdEspecial),
      registrosDeCartorio: parseFloat(inputs.registrosDeCartorio)
    }

    // const payload = inputs;
    setBackdrop(true);
    setMessage('Dados gravados com sucesso!');
    setSeverity('success');

    api.put(`demonstrativos-gerenciais-anuais/${inputs.demonstrativosGerenciaisAnuaisId}`, payload)
    .then((response) => {
      // console.log('response: ', response);

      getData();

      // console.log('inputs.saldoFinalTotal: ', inputs.saldoFinalTotal);
      // console.log('inputs.consolidadoSaldoFinalGeral: ', inputs.consolidadoSaldoFinalGeral);

      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  };

  const checkErrors = () => {
    saveForm(0);
  }

  const saveFormEnd = () => {
    // alert('aqui');
    setFinalizado(1);
    saveForm(1);
  }

  useEffect(() => {
    updateValues();
  }, [
    inputs.saldosCaixa,

    inputs.saldosContaMunicipalCusteio, 
    inputs.saldosContaMunicipalCapital,
    // inputs.saldosContaMunicipalTotal,

    inputs.saldosContaFederalFndeCusteio,
    inputs.saldosContaFederalFndeCapital,
    // inputs.saldosContaFederalFndeTotal,

    // inputs.saldosTotal,

    inputs.receitasVerbasFndeCusteio,
    inputs.rendimentosFederalCusteio,
    // inputs.receitasVerbasFndeCusteioTotal,

    inputs.receitasVerbasFndeCapital,
    inputs.rendimentosFederalCapital,
    // inputs.receitasVerbasFndeCapitalTotal,

    // inputs.receitasVerbasFndeTotal,

    inputs.receitasVerbasMunicipaisCusteio,
    inputs.rendimentosVerbasMunicipaisCusteio,
    // inputs.receitasVerbasMunicipaisCusteioTotal,

    inputs.receitasVerbasMunicipaisCapital,
    inputs.rendimentosVerbasMunicipaisCapital,
    // inputs.receitasVerbasMunicipaisCapitalTotal,

    // inputs.receitasVerbasMunicipaisTotal,

    inputs.receitasRecursosProprios,
    inputs.receitasDoacoes,
    inputs.receitasPromocoesSociais,
    inputs.receitasOutras,

    // inputs.receitasTotal,

    inputs.despesasCusteioFnde,
    inputs.despesasCapitalFnde,
    // inputs.despesasFndeTotal,

    inputs.despesasCusteioMunicipal,
    inputs.despesasCapitalMunicipal,
    // inputs.despesasMunicipalTotal,

    inputs.despesasTarifasBancarias,
    inputs.devolucaoRecursosGea,

    // inputs.despesasTotal,

//     inputs.consolidadoSaldoAnteriorReceitas,
//     inputs.consolidadoDespesas,
//     inputs.consolidadoSaldoFinalGeral,

    inputs.saldoFinalEmCaixa,
    inputs.saldoContaMunicipal,
    inputs.saldoVerbasFnde,
//     inputs.saldoFinalTotal,

inputs.formacaoProfissionaisEdInfantilCusteio,
inputs.formacaoProfissionaisEdInfantilCapital,
inputs.formacaoProfissionaisEnsinoFundamentalCusteio,
inputs.formacaoProfissionaisEnsinoFundamentalCapital,
inputs.formacaoProfissionaisEdInfantilEdEspecialCusteio,
inputs.formacaoProfissionaisEdInfantilEdEspecialCapital,
inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio,
inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCapital,
inputs.conservacaoInstalacoesFisicasCusteio,
inputs.conservacaoInstalacoesFisicasCapital,
inputs.manutencaoEquipamentosCusteio,
inputs.manutencaoEquipamentosCapital,
inputs.materialExpedienteCusteio,
inputs.materialExpedienteCapital,
inputs.materialLimpezaCusteio,
inputs.materialLimpezaCapital,
inputs.projetosEducacionaisCusteio,
inputs.projetosEducacionaisCapital,
inputs.materialEsportivoCusteio,
inputs.materialEsportivoCapital,
inputs.brinquedosCusteio,
inputs.brinquedosCapital,
inputs.materialUsoEstProfessoresEdInfantilCusteio,
inputs.materialUsoEstProfessoresEdInfantilCapital,
inputs.materialUsoEstProfessoresEdInfantilEdEspecialCusteio,
inputs.materialUsoEstProfessoresEdInfantilEdEspecialCapital,
inputs.materialUsoEstProfessoresEnsinoFundamentalCusteio,
inputs.materialUsoEstProfessoresEnsinoFundamentalCapital,
inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio,
inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCapital,
inputs.bensPermanentesEdInfantil,
inputs.bensPermanentesEnsinoFundamental,
inputs.bensPermanentesEdEspecial,
inputs.registrosDeCartorio,

]);


const getData = () => {
  //@ts-ignore
  const values = api.get(`demonstrativos-gerenciais-anuais/${query.id}`)
   values.then((response) => {
    //  console.log(response.data)

     setCaixaEscolar({
       ...caixaEscolar,
       'nome': response.data[0].caixaEscolarNome,
       'cnpj': response.data[0].caixaEscolarCnpj,
       'unidadeEscolarNome': response.data[0].unidadeEscolarNome,
       'unidadeEscolarTipoAbreviacao': response.data[0].unidadeEscolarTipoAbreviacao,
      })
      setInputs({
        ...inputs,

        // 'receitasVerbasFndeCusteio': response.data[0].demonstrativosGerenciaisAnuaisHasRecursosFederais.valorVerbaCusteio,
        // 'rendimentosFederalCusteio': response.data[0].
        // 'receitasVerbasFndeCusteioTotal': "0.0",
    
        // 'receitasVerbasFndeCapital': response.data[0].demonstrativosGerenciaisAnuaisHasRecursosFederais.valorVerbaPermanente,
        // 'rendimentosFederalCapital': response.data[0].
        // 'receitasVerbasFndeCapitalTotal': "0.0",
    
        // 'receitasVerbasFndeTotal': "0.0",


        // 'saldosCaixa': "0.0",

        // 'saldosContaMunicipalCusteio': "0.0", 
        // 'saldosContaMunicipalCapital': "0.0",
        // // 'saldosContaMunicipalTotal': "0.0",
    
        // 'saldosContaFederalFndeCusteio': "0.0",
        // 'saldosContaFederalFndeCapital': "0.0",
        // // 'saldosContaFederalFndeTotal': "0.0",
    
        // // 'saldosTotal': "0.0",
    
        // 'receitasVerbasFndeCusteio': response.data[0].demonstrativosGerenciaisAnuaisHasRecursosFederais.valorVerbaCusteio,
        // 'rendimentosFederalCusteio': "0.0",
        // // 'receitasVerbasFndeCusteioTotal': "0.0",
    
        // 'receitasVerbasFndeCapital': response.data[0].demonstrativosGerenciaisAnuaisHasRecursosFederais.valorVerbaPermanente,
        // 'rendimentosFederalCapital': "0.0",
        // // 'receitasVerbasFndeCapitalTotal': "0.0",
    
        // // 'receitasVerbasFndeTotal': "0.0",
    
        // 'receitasVerbasMunicipaisCusteio': "0.0",
        // 'rendimentosVerbasMunicipaisCusteio': "0.0",
        // // 'receitasVerbasMunicipaisCusteioTotal': "0.0",
    
        // 'receitasVerbasMunicipaisCapital': "0.0",
        // 'rendimentosVerbasMunicipaisCapital': "0.0",
        // // 'receitasVerbasMunicipaisCapitalTotal': "0.0",
    
        // // 'receitasVerbasMunicipaisTotal': "0.0",
    
        // 'receitasRecursosProprios': "0.0",
        // 'receitasDoacoes': "0.0",
        // 'receitasPromocoesSociais': "0.0",
        // 'receitasOutras': "0.0",
    
        // 'receitasTotal': "0.0",

        'demonstrativosGerenciaisAnuaisId': response.data[0].id,

        "anosId": response.data[0].anosId,

        'receitasVerbasFndeCusteio': response.data[0].demonstrativosGerenciaisAnuaisHasRecursosFederais.valorVerbaCusteio,
        'receitasVerbasFndeCapital': response.data[0].demonstrativosGerenciaisAnuaisHasRecursosFederais.valorVerbaPermanente,

        "saldosCaixa": response.data[0].saldosCaixa,

        "saldosContaMunicipalCusteio": response.data[0].saldosContaMunicipalCusteio,
        "saldosContaMunicipalCapital": response.data[0].saldosContaMunicipalCapital,

        "saldosContaFederalFndeCusteio": response.data[0].saldosContaFederalFndeCusteio,
        "saldosContaFederalFndeCapital": response.data[0].saldosContaFederalFndeCapital,

        "rendimentosFederalCusteio": response.data[0].rendimentosFederalCusteio,
        "rendimentosFederalCapital": response.data[0].rendimentosFederalCapital,

        "receitasVerbasMunicipaisCusteio": response.data[0].receitasVerbasMunicipaisCusteio,
        "rendimentosVerbasMunicipaisCusteio": response.data[0].rendimentosVerbasMunicipaisCusteio,

        "receitasVerbasMunicipaisCapital": response.data[0].receitasVerbasMunicipaisCapital,
        "rendimentosVerbasMunicipaisCapital": response.data[0].rendimentosVerbasMunicipaisCapital,

        "receitasRecursosProprios": response.data[0].receitasRecursosProprios,
        "receitasDoacoes": response.data[0].receitasDoacoes,
        "receitasPromocoesSociais": response.data[0].receitasPromocoesSociais,
        "receitasOutras": response.data[0].receitasOutras,

        "datasDepositosRecursosFederais": response.data[0].datasDepositosRecursosFederais,
        // "saldosCaixaFinal": response.data[0].saldosCaixaFinal,

        'despesasCusteioMunicipal': response.data[0].despesasCusteioMunicipal,
        'despesasCapitalMunicipal': response.data[0].despesasCapitalMunicipal,

        'despesasTarifasBancarias': response.data[0].despesasTarifasBancarias,
        'devolucaoRecursosGea': response.data[0].devolucaoRecursosGea,


        'saldoFinalEmCaixa': response.data[0].saldoFinalEmCaixa,
        'saldoContaMunicipal': response.data[0].saldoContaMunicipal,
        'saldoVerbasFnde': response.data[0].saldoVerbasFnde,


        'formacaoProfissionaisEdInfantilCusteio': response.data[0].formacaoProfissionaisEdInfantilCusteio,
        'formacaoProfissionaisEdInfantilCapital': response.data[0].formacaoProfissionaisEdInfantilCapital,
        'formacaoProfissionaisEnsinoFundamentalCusteio': response.data[0].formacaoProfissionaisEnsinoFundamentalCusteio,
        'formacaoProfissionaisEnsinoFundamentalCapital': response.data[0].formacaoProfissionaisEnsinoFundamentalCapital,
        'formacaoProfissionaisEdInfantilEdEspecialCusteio': response.data[0].formacaoProfissionaisEdInfantilEdEspecialCusteio,
        'formacaoProfissionaisEdInfantilEdEspecialCapital': response.data[0].formacaoProfissionaisEdInfantilEdEspecialCapital,
        'formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio': response.data[0].formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio,
        'formacaoProfissionaisEnsinoFundamentalEdEspecialCapital': response.data[0].formacaoProfissionaisEnsinoFundamentalEdEspecialCapital,
        'conservacaoInstalacoesFisicasCusteio': response.data[0].conservacaoInstalacoesFisicasCusteio,
        'conservacaoInstalacoesFisicasCapital': response.data[0].conservacaoInstalacoesFisicasCapital,
        'manutencaoEquipamentosCusteio': response.data[0].manutencaoEquipamentosCusteio,
        'manutencaoEquipamentosCapital': response.data[0].manutencaoEquipamentosCapital,
        'materialExpedienteCusteio': response.data[0].materialExpedienteCusteio,
        'materialExpedienteCapital': response.data[0].materialExpedienteCapital,
        'materialLimpezaCusteio': response.data[0].materialLimpezaCusteio,
        'materialLimpezaCapital': response.data[0].materialLimpezaCapital,
        'projetosEducacionaisCusteio': response.data[0].projetosEducacionaisCusteio,
        'projetosEducacionaisCapital': response.data[0].projetosEducacionaisCapital,
        'materialEsportivoCusteio': response.data[0].materialEsportivoCusteio,
        'materialEsportivoCapital': response.data[0].materialEsportivoCapital,
        'brinquedosCusteio': response.data[0].brinquedosCusteio,
        'brinquedosCapital': response.data[0].brinquedosCapital,
        'materialUsoEstProfessoresEdInfantilCusteio': response.data[0].materialUsoEstProfessoresEdInfantilCusteio,
        'materialUsoEstProfessoresEdInfantilCapital': response.data[0].materialUsoEstProfessoresEdInfantilCapital,
        'materialUsoEstProfessoresEdInfantilEdEspecialCusteio': response.data[0].materialUsoEstProfessoresEdInfantilEdEspecialCusteio,
        'materialUsoEstProfessoresEdInfantilEdEspecialCapital': response.data[0].materialUsoEstProfessoresEdInfantilEdEspecialCapital,
        'materialUsoEstProfessoresEnsinoFundamentalCusteio': response.data[0].materialUsoEstProfessoresEnsinoFundamentalCusteio,
        'materialUsoEstProfessoresEnsinoFundamentalCapital': response.data[0].materialUsoEstProfessoresEnsinoFundamentalCapital,
        'materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio': response.data[0].materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio,
        'materialUsoEstProfessoresEnsFundamentalEdEspecialCapital': response.data[0].materialUsoEstProfessoresEnsFundamentalEdEspecialCapital,
        'bensPermanentesEdInfantil': response.data[0].bensPermanentesEdInfantil,
        'bensPermanentesEnsinoFundamental': response.data[0].bensPermanentesEnsinoFundamental,
        'bensPermanentesEdEspecial': response.data[0].bensPermanentesEdEspecial,
        'registrosDeCartorio': response.data[0].registrosDeCartorio,
      })


      // setFinalizado(response.data[0].finalizado);

      setMenuOptions(['menuGravar']);

      const saldoFinalTotal = parseFloat(inputs.saldoFinalEmCaixa || 0) + parseFloat(inputs.saldoContaMunicipal || 0) + parseFloat(inputs.saldoVerbasFnde || 0);
      if (saldoFinalTotal === parseFloat(inputs.consolidadoSaldoFinalGeral)) {
        setMenuOptions(['menuGravar', 'menuGravarFinalizar']);
      }

      if (Number(response.data[0].finalizado) === 1) {
        setMenuOptions(['menuImprimir']);
      }


   })
   .catch((err) => {
     console.error("ops! ocorreu um erro" + err);
   })
 };

 React.useEffect(() => {
  getData();
 }, []);

  // const [initialValues, setInitialValues] = React.useState({});

  // const [rows, setRows] = React.useState([]);

  // const [fornecedoresCredoresId, setFornecedoresCredoresId] = useState('');
  // const [comprovantesDeDespesasProvisoriasId, setComprovantesDeDespesasProvisoriasId] = useState('');

  // const [bairroDistrito, setBairroDistrito] = useState('');
  // const [cep, setCep] = React.useState("");
  // const [cnpj, setCnpj] = React.useState(null);
  // const [cidadesId, setCidadesId] = React.useState(null);
  // const [cidadesList, setCidadesList] = React.useState([]);
  // const [endereco, setEndereco] = React.useState("");
  // const [estadosId, setEstadosId] = React.useState(null);
  // const [estadosList, setEstadosList] = React.useState([]);
  // const [fornecedoresCredoresSituacaoId, setFornecedoresCredoresSituacaoId] = React.useState(null);
  // const [fornecedoresCredoresSituacaoList, setFornecedoresCredoresSituacaoList] = React.useState([]);
  // const [numero, setNumero] = React.useState("");
  // const [complemento, setComplemento] = React.useState("");
  // const [nomeFantasia, setNomeFantasia] = React.useState("");
  // const [nomeRazaoSocial, setNomeRazaoSocial] = React.useState("");
  // const [nomeRazaoSocialNomeFantasia, setNomeRazaoSocialNomeFantasia] = React.useState("");
  // const [situacao, setSituacao] = React.useState("");
  // const [inscricaoMunicipal, setInscricaoMunicipal] = React.useState("");
  // const [inscricaoEstadual, setInscricaoEstadual] = React.useState("");
  // const [email, setEmail] = React.useState("");
  // const [site, setSite] = React.useState("");
  // const [socios, setSocios] = React.useState([]);
  // const [newSocios, setNewSocios] = React.useState([]);
  // const [sociosGroup, setSociosGroup] = React.useState("");
  // const [sociosTipos, setSociosTipos] = React.useState([]);
  // const [telefones, setTelefones] = React.useState(null);

  // const [tipoNotaFiscal, setTipoNotaFiscal] = React.useState([]);
  // const [tiposComprovantesDeDespesasList, setTiposComprovantesDeDespesasList] = React.useState([]);
  // const [chaveDeAcesso, setChaveDeAcesso] = React.useState(null);
  // const [dataEmissao, setDataEmissao] = React.useState(null);
  // const [dataPagamento, setDataPagamento] = React.useState(null);
  // const [dataCompensacaoChequeTransferencia, setDataCompensacaoChequeTransferencia] = React.useState(null);
  // const [numeroNotaRecibo, setNumeroNotaRecibo] = React.useState(null);  
  // const [numeroChequeOrdemPagamento, setNumeroChequeOrdemPagamento] = React.useState(null);
  // const [tiposComprovantesDeDespesasId, setTiposComprovantesDeDespesasId] = React.useState(null);
  // const [tiposComprovantesDeDespesas, setTiposComprovantesDeDespesas] = React.useState(null);

  // const [especiesId, setEspeciesId] = React.useState(null);
  // const [meiosDePagamentosList, setMeiosDePagamentosList] = React.useState([]);
  // const [meiosDePagamentosId, setMeiosDePagamentosId] = React.useState(null);
  // const [valorTotal, setValorTotal] = React.useState(null);

  // const [atividadesGroup, setAtividadesGroup] = React.useState('');


  // const [infoCardOrcamentoData, setInfoCardOrcamentoData] = React.useState({
  //   'caixas': '1',
  //   'totalItens': '2',
  //   'termos': '3',
  //   'total': '4',
  //   'despesas': '5',
  //   'saldos': '6'
  // });



  // const formatNewSocios = () => {

  //   let newSocios = [];

  //   if (socios && socios.length > 0 && sociosTipos && sociosTipos.length > 0) {
  //     let codigo = '';
  //     let object = {};

  //     socios.forEach(element => {
  //       // console.log('element: ', element.qual);
  //       codigo = element.qual.split('-');
  //       // console.log('newSociosTipos: ', sociosTipos.find(item => item.codigo === codigo[0]));
  //       // console.log('código sócio: ', codigo[0]);

  //       object = {
  //         nomeEmpresarial: element.nome,
  //         dataCadastro: formatDateEnUs2(new Date()),
  //         sociosTiposId: sociosTipos.find(item => item.codigo === codigo[0]).id
  //       }
  //       newSocios.push(object);
  //     });
  //   }

  //   setNewSocios(newSocios);
  // }
  // useEffect(() => {
  //   formatNewSocios();
  // }, [sociosTipos, initialValues]);

  // useEffect(() => {
  //   if (!especiesId) {
  //     setTiposComprovantesDeDespesas('');
  //     setTiposComprovantesDeDespesasId(null);
  //   }
  // }, [especiesId]);
  
  // useEffect(() => {
  //   // @ts-ignore
  //   if (query.id) {
  //     // @ts-ignore
  //     const values = api.get(`fornecedores-credores/${query.id}`)
  //     values.then((response) => {
  //       const nomeRazaoSocialNomeFantasia = setNomeRazaoSocialNomeFantasia(response.data.nomeRazaoSocial);
  //       if (response.data.nomeFantasia) {
  //         nomeRazaoSocialNomeFantasia = setNomeRazaoSocialNomeFantasia(response.data.nomeRazaoSocial + "/" + response.data.nomeFantasia);
  //       }
  //       setInitialValues(response.data);
  //       setCep(response.data.cep);
  //       setCnpj(response.data.cnpj);
  //       setBairroDistrito(response.data.bairroDistrito);
  //       setEndereco(response.data.endereco);
  //       // setNumero(response.data.numero);
  //       setComplemento(response.data.complemento);
  //       setNomeRazaoSocial(response.data.nomeRazaoSocial);
  //       setNomeFantasia(response.data.nomeFantasia);
  //       setNomeRazaoSocialNomeFantasia(nomeRazaoSocialNomeFantasia);
  //       setInscricaoMunicipal(response.data.inscricaoMunicipal);
  //       setInscricaoEstadual(response.data.inscricaoEstadual);
  //       setEmail(response.data.email);
  //       setSite(response.data.site);
  //       setSocios(response.data.socios);
  //       setTelefones(response.data.telefones);
  //     })
  //     .catch((err) => {
  //       console.error("ops! ocorreu um erro" + err);
  //     })
  //   } else {
  //     setNewRegister();
  //   }
  // }, []);

//   useEffect(() => {
//       // @ts-ignore
//       const values = api.get(`estados`)
//       values.then((response) => {
//         setEstadosList(response.data)
//       })
//       .catch((err) => {
//         console.error("ops! ocorreu um erro" + err);
//       })
//   }, []);

//   useEffect(() => {
//     // @ts-ignore
//     const values = api.get(`socios-tipos`)
//     values.then((response) => {
//       setSociosTipos(response.data);
//     })
//     .catch((err) => {
//       console.error("ops! ocorreu um erro" + err);
//     })
// }, []);

//   useEffect(() => {
//     setErrors([]);
//     if (cnpj && cnpj.replace(/\D/g, '').length === 14 && !validarCNPJ(cnpj)) {
//       setErrors(['cnpj']);
//     } else if (cnpj && cnpj.replace(/\D/g, '').length === 14 && validarCNPJ(cnpj)) {
//       getCNPJData(cnpj.replace(/\D/g, ''));
//     } 
    
//     if (!cnpj || (cnpj.replace(/\D/g, '').length < 14)) {
//       clearData();
//     }
// }, [cnpj]);

// const getCidadesPorEstado = () => {
//   setCidadesList([]);
//   if (estadosId && estadosId.id) {
//     const values = api.get(`cidades/por-estado/${estadosId.id}`)
//     values.then((response) => {
//       setCidadesList(response.data)
//     })
//     .catch((err) => {
//       console.error("ops! ocorreu um erro" + err);
//     })
//   }
// }
//   useEffect(() => {
//     getCidadesPorEstado();
//   }, [estadosId]);

  // useEffect(() => {
  //   setNewRegister();
  //   if (query.id) {
  //     // @ts-ignore
  //     const values = api.get(`fornecedores-credores/${query.id}`)
  //     values.then((response) => {
  //       setInitialValues(response.data);
  //     })
  //     .catch((err) => {
  //       console.error("ops! ocorreu um erro" + err);
  //     })
  //   }
  // }, []);

  // React.useEffect(() => {
  //   const values = api.get("itens")
  //    values.then((response) => {
  //      setRows(response.data)
  //    })
  //    .catch((err) => {
  //      console.error("ops! ocorreu um erro" + err);
  //    })
  //  }, []);
 
  // useEffect(() => {
  //   // @ts-ignore
  //   const values = api.get(`fornecedores-credores-situacao`)
  //   values.then((response) => {
  //     setFornecedoresCredoresSituacaoList(response.data);
  //   })
  //   .catch((err) => {
  //     console.error("ops! ocorreu um erro" + err);
  //   })
  // }, []);

  // useEffect(() => {
  //   // @ts-ignore
  //   const values = api.get(`tipos-comprovantes-de-despesas`)
  //   values.then((response) => {
  //     setTiposComprovantesDeDespesasList(response.data);
  //   })
  //   .catch((err) => {
  //     console.error("ops! ocorreu um erro" + err);
  //   })
  // }, []);

  // useEffect(() => {
  //   setCidadesId(cidadesList.find(item => item.value === initialValues.cidadesId));
  // }, [cidadesList]);

  // useEffect(() => {
  //   setEstadosId(estadosList.find(item => item.value === initialValues.estadosId));
  // }, [estadosList]);

  // useEffect(() => {
  //   setFornecedoresCredoresSituacaoId(fornecedoresCredoresSituacaoList.find(item => item.value === initialValues.fornecedoresCredoresSituacaoId));
  // }, [fornecedoresCredoresSituacaoList]);

  const [errors, setErrors] = React.useState([]);

  const handleClickAlert = () => {
    setOpen(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  //  const createNewRegister = () => {
  //   history.push(`/comprovantes-de-despesas-provisorios/new/${query.parcelasId}/${query.prestacaoDeContasId}`);
  //   setNewRegister();
  // }

  // const clearData = () => {
  //   setBairroDistrito('');
  //   setEndereco('');
  //   setNumero('');
  //   setComplemento('');
  //   setNomeRazaoSocial('data.nome');
  //   setNomeFantasia('');
  //   setNomeRazaoSocialNomeFantasia('');

  //   setSituacao([]);

  //   getFornecedoresCredoresSituacaoId([]);
  //   // setInscricaoMunicipal(response.data.inscricaoMunicipal);
  //   // setInscricaoEstadual(response.data.inscricaoEstadual);
  //   setEmail('');
  //   setSocios([]);
  //   setSociosGroup('');

  //   getNumerosTelefones('');

  //   setCep('');
  //   setInitialValues({ ...initialValues,
  //     'bairroDistrito': '',
  //     'endereco': '',
  //     'numero': '',
  //     'complemento': '',
  //     'nomeRazaoSocial': '',
  //     'nomeFantasia': '',
  //     'email': ''
  //   })
  // }

  // const getEndereco = (cep) => {
  //   fetch(`https:/viacep.com.br/ws/${cep}/json/`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // setEndereco(data.logradouro);
  //       // setBairroDistrito(data.bairro);
  //       // console.log('data: ', data);
  //       // console.log('data.ibge: ', data.ibge);
  //       if (data.ibge) {
  //         const values = api.get(`cidades/por-cidade-codigo/${data.ibge}`)
  //         .then((response) => {
  //           // setEstadosId(estadosList.find(item => item.value === response.data[0].estadosId));
  //           setInitialValues({ ...initialValues, 'cidadesId': response.data[0].id , 'estadosId': response.data[0].estadosId });

  //           setEstadosId(response.data[0].estadosId);
  //           setCidadesId(response.data[0].id);

  //           // console.log('response: ', response.data[0]);

  //           getCidadesPorEstado();
  //           setTimeout(function () {
  //             // let cidadeId = response;
  //             // cidadeId = cidadesList.find(item => item.value === response.data[0].id);
  //             // console.log('cidadeId: ', response);
  //             // setCidadesId(cidadesList.find(item => item.value === response.data[0].id));
  //             // setInitialValues({ ...initialValues, 'estadosId': response.data[0].estadosId })
  //             // setInitialValues({ ...initialValues, 'cidadesId': response.data[0].id })
  //           }, 1000);
  //         })
  //         .catch((err) => {
  //           console.error("ops! ocorreu um erro" + err);
  //         })
  //       }
  //     })
  // }

  // const getFornecedoresCredoresSituacaoId = (fornecedoresCredoresSituacaoId) => {
  //   setFornecedoresCredoresSituacaoId(fornecedoresCredoresSituacaoList.find(item => item.label === fornecedoresCredoresSituacaoId));
  // }

  // const getAtividadesGroup = (atividadePrincipal, atividadesSecundarias) => {
  //   // console.log('atividadePrincipal: ', atividadePrincipal);
  //   let atividadesGroup = '';
  //   atividadesGroup += atividadePrincipal.map(n => {
  //     if (n.code.replace(/[^\d]+/g,'') !== '0000000') {
  //       return `${n.code.replace(/[^\d]+/g,'')} - ${n.text} `
  //     }
  //   });
  //   atividadesGroup += atividadesSecundarias.map(n => {
  //     if (n.code.replace(/[^\d]+/g,'') !== '0000000') {
  //       return ` ${n.code.replace(/[^\d]+/g,'')} - ${n.text}`
  //     }
  //   });
  //   setAtividadesGroup(atividadesGroup);
  // }

  // const getSocios = (socios) => {
  //   if (socios) {
  //     socios.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
  //   }
  //   setSocios(socios);
  //   // console.log("socios: ", socios);
  // }

  // const getSociosGroup = (socios) => {
  //   if (socios) {
  //     socios.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
  //   }
  //   let sociosGroup = ""
  //   sociosGroup = socios.map(n => {
  //     return n.nome
  //   });
  //   setSociosGroup(sociosGroup);
  // }

  // const getNumerosTelefones = (telefones) => {
  //   let retorno = replaceAll(telefones, ' ', '')
  //   retorno = replaceAll(retorno, '-', '')
  //   retorno = retorno.split('/');
  //   const array = [];
  //   retorno.map(item => (
  //     array.push({
  //       'ddd': item.substr(1,2),
  //       'numero': item.substr(4),
  //     })
  //   ))
  //   setTelefones(array);
  // }

  // const checkIfRegistered = (cnpj) => {
  //   // setMessage('Preencha todos os campos! Verifique!');
  //   // setBackdrop(false);
  //   // setSeverity('error');
  //   // handleClickAlert();

  //   const values = api.get(`fornecedores-credores/pesquisar-cnpj/${cnpj}`)
  //   .then((response) => {
  //     setFornecedoresCredoresId(response.data);
  //   })
  //   .catch((err) => {
  //     console.error("ops! ocorreu um erro" + err);
  //   })
  // }

  // const getCNPJData = (cnpj) => {
  //   // fetch(`http://www.receitaws.com.br/v1/cnpj/${cnpj}`)
  //   fetch(`https://api.allorigins.win/raw?url=http://www.receitaws.com.br/v1/cnpj/${cnpj}`)
  //     .then((res) => res.json(
  //     ))
  //     .then((data) => {
  //       checkIfRegistered(cnpj);
  //       // setInitialValues(response.data);
  //       // setCnpj(response.data.cnpj);
  //       setBairroDistrito(data.bairro);
  //       setEndereco(data.logradouro);
  //       setNumero(data.numero);
  //       setComplemento(data.complemento);
  //       setNomeRazaoSocial(data.nome);
  //       setNomeFantasia(data.nomeFantasia);
  //       setNomeRazaoSocialNomeFantasia(data.nome);

  //         if (data.nomeFantasia) {
  //           setNomeRazaoSocialNomeFantasia(data.nome + "/" + data.nome);
  //         }

  //       setSituacao(data.situacao);

  //       getFornecedoresCredoresSituacaoId(data.situacao)
  //       // setInscricaoMunicipal(response.data.inscricaoMunicipal);
  //       // setInscricaoEstadual(response.data.inscricaoEstadual);
  //       setEmail(data.email);
  //       getSocios(data.qsa);
  //       getSociosGroup(data.qsa);
  //       getAtividadesGroup(data.atividade_principal, data.atividades_secundarias)

  //       getNumerosTelefones(data.telefone);

  //       setCep(data.cep);
  //       if (data.cep && data.cep.replace(/\D/g, '').length === 8) {
  //         getEndereco(data.cep.replace(/\D/g, ''));
  //       }
  //       setInitialValues({ ...initialValues,
  //         'bairroDistrito': data.bairro,
  //         'endereco': data.logradouro,
  //         'numero': data.numero,
  //         'complemento': data.complemento,
  //         'nomeRazaoSocial': data.nome,
  //         'nomeFantasia': data.nomeFantasia,
  //         'email': data.email })
  //     })
  // }

  // const testCNPJ = (cnpj) => {
  //   return (cnpj && cnpj.replace(/\D/g, '').length === 14 && validarCNPJ(cnpj));
  // }

  // const checkErrors = () => {
  //   const dado = [];
  //   if (!cnpj || !validarCNPJ(cnpj)) {
  //     dado.push('cnpj')
  //   }
  //   if (!nomeRazaoSocial || nomeRazaoSocial.length <= 9) {
  //     dado.push('nomeRazaoSocial');
  //   }
  //   if (!fornecedoresCredoresSituacaoId || !fornecedoresCredoresSituacaoId.id) {
  //     dado.push('fornecedoresCredoresSituacaoId');
  //   }
  //   if (!especiesId || !especiesId.id) {
  //     dado.push('especiesId');
  //   }
  //   if (!tiposComprovantesDeDespesasId || !tiposComprovantesDeDespesasId.id) {
  //     dado.push('tiposComprovantesDeDespesasId');
  //   }
  //   if (!meiosDePagamentosId || !meiosDePagamentosId.id) {
  //     dado.push('meiosDePagamentosId');
  //   }
  //   if (!numeroNotaRecibo || numeroNotaRecibo.length === 0) {
  //     dado.push('numeroNotaRecibo');
  //   }
  //   if (!dataPagamento || (formatDateEnUs2(dataPagamento)).length !== 10) {
  //     dado.push('dataPagamento');
  //   }
  //   if (!dataEmissao || (formatDateEnUs2(dataEmissao)).length !== 10) {
  //     dado.push('dataEmissao');
  //   }
  //   if (!numeroChequeOrdemPagamento || numeroChequeOrdemPagamento.length === 0) {
  //     dado.push('numeroChequeOrdemPagamento');
  //   }
  //   if (!dataCompensacaoChequeTransferencia || (formatDateEnUs2(dataCompensacaoChequeTransferencia)).length !== 10) {
  //     dado.push('dataCompensacaoChequeTransferencia');
  //   }
  //   // console.log('dataCompensacaoChequeTransferencia: ', dataCompensacaoChequeTransferencia);
  //   if (!valorTotal || Number(valorTotal) === 0) {
  //     dado.push('valorTotal');
  //   }

  //   setErrors(dado);

  //   setMessage('Dados gravados com sucesso!');
  //   setSeverity('success');

  //   if (dado.length === 0) {
  //     saveUpdateData();
  //   } else {
  //     setMessage('Preencha todos os campos! Verifique!');
  //     setBackdrop(false);
  //     setSeverity('error');
  //     handleClickAlert();
  //   }
  // }

  // const comprovantesDeDespesas = [{
  //   numero: numeroNotaRecibo ? numeroNotaRecibo.toUpperCase() : '',
  //   chave_de_acesso: '',
  //   modelo: '',
  //   serie: '',
  //   dataEmissao: formatDateEnUs2(dataEmissao),
  //   valorTotal: valorTotal,
  //   dataPagamento: formatDateEnUs2(dataPagamento),
  //   numeroChequeOrdemPagamento: numeroChequeOrdemPagamento ? numeroChequeOrdemPagamento.toUpperCase() : '',
  //   desconto: null,
  //   dataCompensacaoChequeTransferencia: formatDateEnUs2(dataCompensacaoChequeTransferencia),
  //   tiposComprovantesDeDespesasId: tiposComprovantesDeDespesasId && tiposComprovantesDeDespesasId.id ? tiposComprovantesDeDespesasId.id : null,
  //   meiosDePagamentosId: meiosDePagamentosId && meiosDePagamentosId.id ? meiosDePagamentosId.id : null,
  //   fornecedoresCredoresId: fornecedoresCredoresId,    
  //   especiesId: especiesId && especiesId.id ? especiesId.id : null,
  //   prestacoesDeContasId: query.prestacaoDeContasId
  // }]

  // const payload = {
  //   'bairroDistrito': bairroDistrito.toUpperCase(),
  //   'cep': cep.replace(/\D/g, ''),
  //   'cidadesId': initialValues.cidadesId ? initialValues.cidadesId : null,
  //   'cnpj': cnpj ? cnpj.replace(/\D/g, '') : '',
  //   'complemento': complemento.toUpperCase(),
  //   'email': email.toLowerCase(),
  //   'endereco': endereco.toUpperCase(),
  //   'estadosId': initialValues.estadosId ? initialValues.estadosId : null,
  //   'id': fornecedoresCredoresId,
  //   'inscricaoEstadual': inscricaoEstadual,
  //   'inscricaoMunicipal': inscricaoMunicipal,
  //   'nomeFantasia': nomeFantasia ? nomeFantasia.toUpperCase() : '',
  //   'nomeRazaoSocial': nomeRazaoSocial.toUpperCase(),
  //   'numero': numero.toUpperCase(),
  //   'site': site,
  //   'fornecedoresCredoresSituacaoId': fornecedoresCredoresSituacaoId && fornecedoresCredoresSituacaoId.id ? fornecedoresCredoresSituacaoId.id : null,
  //   'socios': newSocios,
  //   'comprovantesDeDespesasProvisorias': comprovantesDeDespesas
  // }

  // const saveUpdateData = () => {

  //   setMessage('Dados gravados com sucesso!');
  //   setSeverity('success');

  //   if (!fornecedoresCredoresId || fornecedoresCredoresId.length === 0) {
  //     delete payload.id;
  //     delete payload.comprovantesDeDespesasProvisorias.fornecedoresCredoresId
  //   } 

  //   let values = "";

  //   if (fornecedoresCredoresId) {
  //     if (!comprovantesDeDespesasProvisoriasId || comprovantesDeDespesasProvisoriasId.length === 0) {
  //       values = api.post(`comprovantes-de-despesas-provisorias`, comprovantesDeDespesas[0]);
  //     } else {
  //       values = api.put(`comprovantes-de-despesas-provisorias/${comprovantesDeDespesasProvisoriasId}`, comprovantesDeDespesas[0]);
  //     }
  //   } else {
  //     values = api.post(`fornecedores-credores/${fornecedoresCredoresId}`, payload);
  //   }

  //   // @ts-ignore
  //   // const values = fornecedoresCredoresId ? api.post(`comprovantes-de-despesas-provisorias`, comprovantesDeDespesas[0]) : api.put(`fornecedores-credores/${fornecedoresCredoresId}`, payload)
  //   values.then((response) => {
  //     setBackdrop(false);
  //     handleClickAlert();
  //     if (!fornecedoresCredoresId || fornecedoresCredoresId.length === 0) {
  //       setFornecedoresCredoresId(response.data.id);
  //       setInitialValues({...initialValues, fornecedoresCredoresId: response.data.id});
  //     } else {
  //       setComprovantesDeDespesasProvisoriasId(response.data.id);
  //       setInitialValues({...initialValues, comprovantesDeDespesasProvisoriasId: response.data.id});
  //     }
  //   })
  //   .catch((err) => {
  //     setMessage('Erro ao gravar os dados! Verifique!');
  //     setBackdrop(false);
  //     setSeverity('error');
  //     handleClickAlert();
  //     console.error("ops! ocorreu um erro" + err);
  //   })
  // }

  // const setNewRegister = () => {
  //   const newValues = {
  //     "bairroDistrito": "",
  //     "cep": "",
  //     "cidadesCodigo": "",
  //     "cidadesId": null,
  //     "cidadesNome": "",
  //     "cnpj": "",
  //     "complemento": "",
  //     "email": "",
  //     "endereco": "",
  //     "enderecoCompleto": "",
  //     "estadosAbreviacao": "",
  //     "estadosCodigo": "",
  //     "estadosId": "",
  //     "estadosNome": "",
  //     "id": "",
  //     "inscricaoEstadual": "",
  //     "inscricaoMunicipal": "",
  //     "nomeFantasia": "",
  //     "nomeRazaoSocial": "",
  //     "numero": "",
  //     "pais": "",
  //     "site": "",
  //     "socios": [],
  //     "telefonesNumeros": [],
  //     "vendedores": [],
  //     "vendedoresNomes": "",
  //     "fornecedoresCredoresSituacaoId": null
  //   }
  //   setBairroDistrito("");
  //   setCep("");
  //   setCidadesId(null);
  //   setCnpj("");
  //   setComplemento("");
  //   setEmail("");
  //   setEndereco("");
  //   setEstadosId(null);
  //   // 'id': data.id,
  //   setInscricaoEstadual("");
  //   setInscricaoMunicipal("");
  //   setNomeFantasia("");
  //   setNomeRazaoSocial("");
  //   setNumero("");
  //   setSite("");
  //   setFornecedoresCredoresSituacaoId(null);
  //   // setSocios([]);
  //   setInitialValues(newValues);
  // }

  // const handleChange = (event) => {
  //   setTipoNotaFiscal(event.target.value);
  // }

  // const handleTiposComprovantesDeDespesas = (value) => {
  //   setTiposComprovantesDeDespesas(value && value.label ? value.label : '');
  //   setTiposComprovantesDeDespesasId(value);
  //   // alert(JSON.stringify(value, null, 2));
  //   setInitialValues({...initialValues, 'tiposComprovantesDeDespesasId': value});
  // }

  // const handleEspecie = (value) => {
  //   // console.log("value2: ", value);

  //   setNumeroNotaRecibo(null);
  //   setDataEmissao(null);
  //   setEspeciesId(value);
  //   setInitialValues({...initialValues, 'especie': value});

  //   let tiposComprovantesDeDespesasId = {
  //     "abreviacao": "NF",
  //     "id": "62df2c67-3670-11ec-bc9c-00155d5484f1",
  //     "label": "NOTA FISCAL",
  //     "value": "62df2c67-3670-11ec-bc9c-00155d5484f1"
  //   };

  //   setTiposComprovantesDeDespesas(tiposComprovantesDeDespesasId.label);
  //   setTiposComprovantesDeDespesasId(tiposComprovantesDeDespesasId);
  //   setInitialValues({...initialValues, 'tiposComprovantesDeDespesasId': tiposComprovantesDeDespesasId});

  //   if (value && value.id === "a35fce1c-11e9-11ec-833c-dc41a970c8d9") {
  //     setTiposComprovantesDeDespesasId(null);
  //     setInitialValues({...initialValues, 'tiposComprovantesDeDespesasId': null});
  //   }
  // }

  // const handleMeiosDePagamentos = (value) => {
  //   setMeiosDePagamentosId(value);
  //   setInitialValues({...initialValues, 'meiosDePagamentosId': value})
  // }

  // const checkChaveDeAcesso = (chaveDeAcesso) => {
  //   if (chaveDeAcesso && chaveDeAcesso.replace(/[^\d]+/g,'').length === 44 && !validaChaveDeAcessoNotaFiscal(chaveDeAcesso)) {
  //     return true;
  //   }
  //   return false;
  // }

  // const itens = [{
  //   "id": null,
  //   "quantidadeComercial": null,
  //   "descricao": null,
  //   "valorUnitario": null,
  //   "valorTotal": null,
  //   // "desconto": null,
  //   // "origemDosRecursosId": null,
  //   "comprovantesDeDespesasId": null,
  //   "tiposRecursosId": null,
  //   "unidadesDeMedidasId": null,
  //   "new": null
  // }];

  // const handleInsertItem = (itemNotasFiscaisRecibos) => {
  //   // console.log("itemNotasFiscaisRecibos5555: ", itemNotasFiscaisRecibos);
  //   const newRows = [];
  //   const newItem = {
  //     "ordem": itemNotasFiscaisRecibos.ordem,
  //     "id": uuidv4(),
  //     "quantidadeComercial": itemNotasFiscaisRecibos.quantidadeComercial,
  //     "descricao": itemNotasFiscaisRecibos.descricao.toUpperCase(),
  //     "valorUnitario": parseFloat(itemNotasFiscaisRecibos.valorUnitario).toLocaleString('pt-BR',{ minimumFractionDigits: 2 }),
  //     "valorTotal": itemNotasFiscaisRecibos.valorTotal,
  //     "desconto": itemNotasFiscaisRecibos.desconto,
  //     "unidadesDeMedidasId": itemNotasFiscaisRecibos.unidadesDeMedidasId.id,
  //     "unidadesDeMedidasDescricao": itemNotasFiscaisRecibos.unidadesDeMedidasId.descricao,
  //     "unidadesDeMedidasAbreviacao": itemNotasFiscaisRecibos.unidadesDeMedidasId.abreviacao,
  //     // "origemDosRecursosId": itemNotasFiscaisRecibos.origemDosRecursosId,
  //     // "origemDosRecursosDescricao": itemNotasFiscaisRecibos.origemDosRecursosId.descricao,
  //     // "origemDosRecursosAbreviacao": itemNotasFiscaisRecibos.origemDosRecursosId.abreviacao,
  //     "tiposRecursosId": itemNotasFiscaisRecibos.tiposRecursosId.id,
  //     "tiposRecursosDescricao": itemNotasFiscaisRecibos.tiposRecursosId.label,
  //     "tiposRecursosAbreviacao": itemNotasFiscaisRecibos.tiposRecursosId.abreviacao,
  //     "comprovantesDeDespesasId": itemNotasFiscaisRecibos.comprovantesDeDespesasId,
  //     "new": null
  //   };

  //   let newOrder = 1;
  //   rows.forEach(item => {
  //     item.ordem = newOrder;
  //     item.new = false;
  //     newRows.push(item);
  //     newOrder++;
  //   })
  //   newItem.ordem = newOrder;
  //   newItem.new = true;
  //   newRows.push(newItem);
    
  //   setRows(newRows);
  //   // console.log('rows aqui: ', newRows)
  //   // setRows(newItem);
  // }

  // const handleDeleteItem = (selecteds) => {
  //   // alert('Delete Item Aqui!!!');

  //   // console.log('selecteds: ', selecteds);
  //   // console.log('rows: ', rows);

  //   const newRows = rows.filter(
  //     item => !selecteds.includes(item.id)
  //   );

  //   setRows(newRows);
  // }

  // const handleChangeOrdem = (data, value) => {
  //   const newRows = [];

  //   rows.forEach(item => {
  //     if (item.id === data.id) {
  //       // selecteds.push(item.id);
  //       item.ordem = value;
  //     }
  //     // item.new = false;
  //     // item.quantidadeComercial = 99;
  //     newRows.push(item);
  //   })
    
  //   setRows(newRows);
  // }

  // const handleOrder = () => {
  //   const newRows = [];
  //   // @ts-ignore
  //   const newR = rows.sort((a,b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
  //   let i = 1;
  //   newR.forEach(item => {
  //     item.ordem = i;
  //     newRows.push(item);
  //     i++;
  //   })
    
  //   setRows(newRows);
  // }

  // const goList = () => {
  //   history.push(`/caixas-escolares-list`);
  // }

  return (
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
          <SpinnerCarregando open={backdrop} />
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
              <>
                <Grid container spacing={2}  sx={{ mt: 3 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ ml: 2 }}
                  >
                    <Box>
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>DEMONSTRATIVO GERENCIAL ANUAL - ANO: 2021 - EDIÇÃO</Box>
                      </Typography> 
                    </Box>

                    <Box
                      component="div"
                      className={`${classes.bottomRightBox} ${classes.box}`}>

                        <div style={{ marginRight: 20}}>
                          <FormControl variant="standard" sx={{ m: 0, width: 150 }}>
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              size="small"
                              fullWidth
                              displayEmpty
                              multiple
                              value={selectedOptions}
                              onChange={handleChangeShow}

                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <em>Opções</em>;
                                }
                                return selected.join(', ');
                              }}

                              MenuProps={MenuProps}
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <MenuItem disabled value="">
                                <em>Opções</em>
                              </MenuItem>
                              {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                  <Checkbox checked={selectedOptions.indexOf(option) > -1} />
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        {/* <div>
                          <Button onClick={goList} color="primary" size="small" style={{ height: 35, marginRight: 20}}>
                            Cancelar
                          </Button>
                        </div> */}

                        <div>
                          <ButtomMenuDemonstrativo
                            menuOptions={menuOptions}
                            ano={'809ca722-7541-4899-921d-7d183a0fbe97'}
                            ids={query.id}
                            checkErrors={checkErrors}
                            saveFormEnd={saveFormEnd}
                            />
                        </div>
                    </Box>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ ml: 2, mt: 3 }}
                  >
                    <Box>
                      <Typography component="div">
                        <Box>
                          {/* caixaEscolar.nome + ' ' + caixaEscolar.unidadeEscolarNome */}

                      <div style={{ wordWrap: 'break-word', lineHeight: '1.2' }}>
                        <span align="left" style={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>
                          {caixaEscolar.nome + ' - ' + formatCnpj(caixaEscolar.cnpj)}
                        </span>
                        <br />
                        <span align="left" style={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                          {caixaEscolar.unidadeEscolarTipoAbreviacao + ' ' + caixaEscolar.unidadeEscolarNome }&nbsp;
                        </span>
                      </div>

                    </Box>
                         {/* <pre>{JSON.stringify(finalizado, null, 2)}</pre> */}
                      </Typography>
                    </Box>

                  </Grid>

                  <Grid container spacing={2} sx={{ pb: 3, ml: 0, mt: 2 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box>
                          <Typography component="div">
                            <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                              {!showSaldosData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowSaldosData} /> : <></>}
                              {showSaldosData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowSaldosData} /> : <></>}
                              SALDOS (EXTRATOS) - 01/01/2021
                            </Box>
                          </Typography>
                        </Box>
                      </Grid>

                      {showSaldosData ?
                        <Grid container spacing={2} sx={{ ml: 4, mt: 1 }}>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>CAIXA INTERNO</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          { selectedOptions.includes('Preencher') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Caixa"
                                value={inputs.saldosCaixa || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosCaixa"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>VERBA MUNICIPAL</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          { selectedOptions.includes('Preencher') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Conta Municipal - Custeio"
                                value={inputs.saldosContaMunicipalCusteio || "0.0"}
                                color="primary"
                                size="small"
                                focused
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosContaMunicipalCusteio"
                                InputProps={{
                                  inputComponent: NumberFormatCustom
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></> }

                          { selectedOptions.includes('Preencher') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Conta Municipal - Capital"
                                value={inputs.saldosContaMunicipalCapital || "0.0"}
                                color="primary"
                                size="small"
                                focused
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosContaMunicipalCapital"
                                InputProps={{
                                  inputComponent: NumberFormatCustom
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }

                          { selectedOptions.includes('Totais Parciais') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Conta Municipal - Total"
                                value={inputs.saldosContaMunicipalTotal || "0.0"}
                                color="primary"
                                size="small"
                                focused
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosContaMunicipalTotal"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>VERBA FEDERAL</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          { selectedOptions.includes('Preencher') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Conta Federal (FNDE) - Custeio"
                                value={inputs.saldosContaFederalFndeCusteio || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosContaFederalFndeCusteio"
                                InputProps={{
                                  inputComponent: NumberFormatCustom
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }

                          { selectedOptions.includes('Preencher') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Conta Federal (FNDE) - Capital"
                                value={inputs.saldosContaFederalFndeCapital || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosContaFederalFndeCapital"
                                InputProps={{
                                  inputComponent: NumberFormatCustom
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }

                          { selectedOptions.includes('Totais Parciais') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Conta Federal (FNDE) - Total"
                                value={inputs.saldosContaFederalFndeTotal || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosContaFederalFndeTotal"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>TOTAL GERAL</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          { selectedOptions.includes('Totais Gerais') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Saldos Totais"
                                value={inputs.saldosTotal || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="saldosTotal"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }
                        </Grid>
                      : <></>}
                  </Grid>

                  <Grid container spacing={2} sx={{ pb: 3, ml: 0, mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showReceitasData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowReceitasData} /> : <></>}
                            {showReceitasData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowReceitasData} /> : <></>}
                            RECEITAS DO EXERCÍCIO
                          </Box>
                        </Typography> 
                      </Box>
                    </Grid>
                    {showReceitasData ?
                      <Grid container spacing={2} sx={{ ml: 4, mt: 1 }}>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box>
                            <Typography component="div">
                              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>VERBA FEDERAL</Box>
                            </Typography> 
                          </Box>
                        </Grid>

                        { selectedOptions.includes('Não Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Federais (FNDE) - Custeio"
                              value={inputs.receitasVerbasFndeCusteio || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasFndeCusteio"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Rend. Ap. Financeira Federal - Custeio"
                              value={inputs.rendimentosFederalCusteio || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="rendimentosFederalCusteio"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Federais (FNDE) - Custeio - Total"
                              value={inputs.receitasVerbasFndeCusteioTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasFndeCusteioTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>

                        { selectedOptions.includes('Não Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Federais (FNDE) - Capital"
                              value={inputs.receitasVerbasFndeCapital || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasFndeCapital"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Rend. Ap. Financeira Federal - Capital"
                              value={inputs.rendimentosFederalCapital || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="rendimentosFederalCapital"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Federais (FNDE) - Capital - Total"
                              value={inputs.receitasVerbasFndeCapitalTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasFndeCapitalTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{m: 0, p: 0}}></Grid>

                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Federais (FNDE) - Total"
                              value={inputs.receitasVerbasFndeTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasFndeTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box>
                            <Typography component="div">
                              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>VERBA MUNICIPAL</Box>
                            </Typography> 
                          </Box>
                        </Grid>


                        { selectedOptions.includes('Não Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Municipais - Custeio"
                              value={inputs.receitasVerbasMunicipaisCusteio || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasMunicipaisCusteio"
                              InputProps={{
                                inputComponent: NumberFormatCustom
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Rend. Ap. Financeira Municipal - Custeio"
                              value={inputs.rendimentosVerbasMunicipaisCusteio || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="rendimentosVerbasMunicipaisCusteio"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Municipais - Custeio - Total"
                              value={inputs.receitasVerbasMunicipaisCusteioTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasMunicipaisCusteioTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{m: 0, p: 0}}></Grid>

                        { selectedOptions.includes('Não Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Municipais - Capital"
                              value={inputs.receitasVerbasMunicipaisCapital || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasMunicipaisCapital"
                              InputProps={{
                                inputComponent: NumberFormatCustom
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Rend. Ap. Financeira Municipal - Capital"
                              value={inputs.rendimentosVerbasMunicipaisCapital || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="rendimentosVerbasMunicipaisCapital"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Municipais - Capital - Total"
                              value={inputs.receitasVerbasMunicipaisCapitalTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasMunicipaisCapitalTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{m: 0, p: 0}}></Grid>

                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Verbas Municipais - Total"
                              value={inputs.receitasVerbasMunicipaisTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasVerbasMunicipaisTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box>
                            <Typography component="div">
                              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>OUTRAS</Box>
                            </Typography> 
                          </Box>
                        </Grid>

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Recursos Próprios"
                              value={inputs.receitasRecursosProprios || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasRecursosProprios"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Doações"
                              value={inputs.receitasDoacoes || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasDoacoes"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Promoções Sociais"
                              value={inputs.receitasPromocoesSociais || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasPromocoesSociais"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { /*selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Outras"
                              value={inputs.receitasOutras || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasOutras"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                            : <></> */}


                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box>
                            <Typography component="div">
                              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>TOTAL GERAL</Box>
                            </Typography> 
                          </Box>
                        </Grid>

                        { selectedOptions.includes('Totais Gerais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Total das Receitas"
                              value={inputs.receitasTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="receitasTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }
                      </Grid>
                    :<></> }
                  </Grid>

                  <Grid container spacing={2} sx={{ pb: 1, ml: 0, mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
  
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showDespesasData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowDespesasData} /> : <></>}
                            {showDespesasData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowDespesasData} /> : <></>}
                            DESPESAS DO EXERCÍCIO
                          </Box>
                        </Typography> 
                      </Box>

                    </Grid>

                    { showDespesasData ? 
                      <Grid container spacing={2} sx={{ ml: 4, mt: 0 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
                          <Box>
                          <Typography component="div">
                            <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                              {!showFndeDebitos ? <Plus style={{ cursor: 'pointer' }} onClick={getShowFndeDebitos} /> : <></>}
                              {showFndeDebitos ? <Minus style={{ cursor: 'pointer' }} onClick={getShowFndeDebitos} /> : <></>}
                              FNDE/PDDE
                            </Box>
                          </Typography>
                        </Box>
                      </Grid>


                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>FORMAÇÃO DOS PROFISSIONAIS DA ESCOLA - EDUCAÇÃO INFANTIL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.formacaoProfissionaisEdInfantilCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEdInfantilCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.formacaoProfissionaisEdInfantilCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEdInfantilCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>FORMAÇÃO DOS PROFISSIONAIS DA ESCOLA - ENSINO FUNDAMENTAL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.formacaoProfissionaisEnsinoFundamentalCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEnsinoFundamentalCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.formacaoProfissionaisEnsinoFundamentalCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEnsinoFundamentalCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>FORMAÇÃO DOS PROFISSIONAIS DA ESCOLA - EDUCAÇÃO ESPECIAL - INFANTIL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.formacaoProfissionaisEdInfantilEdEspecialCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEdInfantilEdEspecialCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.formacaoProfissionaisEdInfantilEdEspecialCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEdInfantilEdEspecialCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>FORMAÇÃO DOS PROFISSIONAIS DA ESCOLA - EDUCAÇÃO ESPECIAL - FUNDAMENTAL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEnsinoFundamentalEdEspecialCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.formacaoProfissionaisEnsinoFundamentalEdEspecialCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="formacaoProfissionaisEnsinoFundamentalEdEspecialCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>CONSERVAÇÃO DAS INSTALAÇÕES FÍSICAS (CONSERTOS OU REPAROS)</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.conservacaoInstalacoesFisicasCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="conservacaoInstalacoesFisicasCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.conservacaoInstalacoesFisicasCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="conservacaoInstalacoesFisicasCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MANUTENÇÃO DE EQUIPAMENTOS (CONSERTOS OU REPAROS)</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.manutencaoEquipamentosCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="manutencaoEquipamentosCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.manutencaoEquipamentosCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="manutencaoEquipamentosCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MATERIAL DE EXPEDIENTE (MATERIAL DE USO NA SECRETARIA E ADMINISTRATIVO)</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.materialExpedienteCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialExpedienteCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.materialExpedienteCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialExpedienteCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MATERIAL DE LIMPEZA</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.materialLimpezaCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialLimpezaCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.materialLimpezaCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialLimpezaCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>PROJETOS EDUCACIONAIS</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.projetosEducacionaisCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="projetosEducacionaisCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.projetosEducacionaisCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="projetosEducacionaisCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MATERIAL ESPORTIVO</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.materialEsportivoCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialEsportivoCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.materialEsportivoCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialEsportivoCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>BRINQUEDOS</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.brinquedosCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="brinquedosCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.brinquedosCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="brinquedosCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MATERIAL PARA USO DOS(AS) ESTUDANTES E PROFESSORES(AS) DA EDUCAÇÃO INFANTIL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.materialUsoEstProfessoresEdInfantilCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEdInfantilCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.materialUsoEstProfessoresEdInfantilCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEdInfantilCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MATERIAL PARA USO DOS(AS) ESTUDANTES E PROFESSORES(AS) DA EDUCAÇÃO ESPECIAL - INFANTIL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.materialUsoEstProfessoresEdInfantilEdEspecialCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEdInfantilEdEspecialCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.materialUsoEstProfessoresEdInfantilEdEspecialCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEdInfantilEdEspecialCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MATERIAL PARA USO DOS(AS) ESTUDANTES E PROFESSORES(AS) DO ENSINO FUNDAMENTAL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.materialUsoEstProfessoresEnsinoFundamentalCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEnsinoFundamentalCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.materialUsoEstProfessoresEnsinoFundamentalCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEnsinoFundamentalCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>MATERIAL PARA USO DOS(AS) ESTUDANTES E PROFESSORES(AS) DA EDUCAÇÃO ESPECIAL - FUNDAMENTAL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEnsFundamentalEdEspecialCusteio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.materialUsoEstProfessoresEnsFundamentalEdEspecialCapital || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="materialUsoEstProfessoresEnsFundamentalEdEspecialCapital"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>BENS PERMANENTES - EDUCAÇÃO INFANTIL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={"0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="bensPermanentesEdInfantil"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: "#e3f2fd" }, readOnly: true
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.bensPermanentesEdInfantil || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="bensPermanentesEdInfantil"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>BENS PERMANENTES - ENSINO FUNDAMENTAL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={"0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="bensPermanentesEnsinoFundamental"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: "#e3f2fd" }, readOnly: true
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.bensPermanentesEnsinoFundamental || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="bensPermanentesEnsinoFundamental"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>BENS PERMANENTES - EDUCAÇÃO ESPECIAL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={"0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="bensPermanentesEdEspecial"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: "#e3f2fd" }, readOnly: true
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={inputs.bensPermanentesEdEspecial || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="bensPermanentesEdEspecial"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }

                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>REGISTROS DE CARTÓRIO</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Custeio"
                                  value={inputs.registrosDeCartorio || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="registrosDeCartorio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextField
                                  id="outlined-name"
                                  label="Capital/Permanente"
                                  value={"0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="registrosDeCartorio"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: "#e3f2fd" }, readOnly: true
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                            : <></> }
                          </Grid>
                        </Grid>
                      :<></> }


                      {showFndeDebitos ?
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb:1, ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            { selectedOptions.includes('Preencher') ?
                              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Box>
                                  <Typography component="div">
                                    <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>TOTAL</Box>
                                  </Typography> 
                                </Box>
                              </Grid>
                            : <></> }
                            { selectedOptions.includes('Totais Parciais') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Total - FNDE"
                                value={inputs.despesasFndeTotal || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="despesasFndeTotal"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: "#e3f2fd" }, readOnly: true
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }
                            { selectedOptions.includes('Preencher') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Custeio - FNDE"
                                value={inputs.despesasCusteioFnde || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="despesasCusteioFnde"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: "#e3f2fd" }, readOnly: true
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }
                          { selectedOptions.includes('Preencher') ?
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                              <TextField
                                id="outlined-name"
                                label="Capital - FNDE"
                                value={inputs.despesasCapitalFnde || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="despesasCapitalFnde"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: "#e3f2fd" }, readOnly: true
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                          : <></> }
                          </Grid>
                        </Grid>
                      :<></> }







                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box>
                            <Typography component="div">
                              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>MUNICIPAIS</Box>
                            </Typography> 
                          </Box>
                        </Grid>

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Custeio - Municipal"
                              value={inputs.despesasCusteioMunicipal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="despesasCusteioMunicipal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }
                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Capital - Municipal"
                              value={inputs.despesasCapitalMunicipal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="despesasCapitalMunicipal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Devolução de Recursos Municipais (GEA)"
                              value={inputs.devolucaoRecursosGea || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="devolucaoRecursosGea"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Total - Municipal"
                              value={inputs.despesasMunicipalTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="despesasMunicipalTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box>
                            <Typography component="div">
                              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>OUTRAS</Box>
                            </Typography> 
                          </Box>
                        </Grid>

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Tarifas Bancárias"
                              value={inputs.despesasTarifasBancarias || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="despesasTarifasBancarias"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box>
                            <Typography component="div">
                              <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>TOTAL GERAL</Box>
                            </Typography> 
                          </Box>
                        </Grid>

                        { selectedOptions.includes('Totais Gerais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Total das Despesas"
                              value={inputs.despesasTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="despesasTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }
                      </Grid>
                    : <></> }

                  </Grid>

                  <Grid container spacing={2} sx={{ pb: 3, ml: 0, mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showConsolidadoData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowConsolidadoData} /> : <></>}
                            {showConsolidadoData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowConsolidadoData} /> : <></>}
                            CONSOLIDADO
                          </Box>
                        </Typography> 
                      </Box>

                    </Grid>

                    {showConsolidadoData ?
                      <Grid container spacing={2} sx={{ ml: 4, mt: 1 }}>
                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Saldo (Saldo Anterior + Receitas)"
                              value={inputs.consolidadoSaldoAnteriorReceitas || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="consolidadoSaldoAnteriorReceitas"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }
                        { selectedOptions.includes('Totais Parciais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Despesas"
                              value={inputs.consolidadoDespesas || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="consolidadoDespesas"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }
                        { selectedOptions.includes('Totais Gerais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Saldo Final Geral"
                              value={inputs.consolidadoSaldoFinalGeral || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="consolidadoSaldoFinalGeral"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: parseFloat(inputs.consolidadoSaldoFinalGeral) >= 0 ? "#e3f2fd" : '#ffe0e6' }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }
                      </Grid>
                    : <></> }
                  </Grid>

                  <Grid container spacing={2} sx={{ pb: 3, ml: 0, mt: 2 }}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showSaldoFinalData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowSaldoFinalData} /> : <></>}
                            {showSaldoFinalData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowSaldoFinalData} /> : <></>}
                            SALDO FINAL (EXTRATOS) - 31/12/2021
                          </Box>
                        </Typography> 
                      </Box>

                    </Grid>

                    {showSaldoFinalData ?
                      <Grid container spacing={2} sx={{ ml: 4, mt: 1 }}>

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Saldo em Caixa"
                              value={inputs.saldoFinalEmCaixa || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="saldoFinalEmCaixa"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Saldo em Conta Municipal"
                              value={inputs.saldoContaMunicipal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="saldoContaMunicipal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Preencher') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Saldo de verbas FNDE"
                              value={inputs.saldoVerbasFnde || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="saldoVerbasFnde"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }

                        { selectedOptions.includes('Totais Gerais') ?
                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <TextField
                              id="outlined-name"
                              label="Saldo Final"
                              value={inputs.saldoFinalTotal || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="saldoFinalTotal"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: parseFloat(inputs.saldoFinalTotal) === parseFloat(inputs.consolidadoSaldoFinalGeral) ? "#e3f2fd" : '#ffe0e6' }, readOnly: true
                                
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                        : <></> }
                      </Grid>
                    : <></>}

                  </Grid>


              </Grid>
              </>
          {/* <FornecedoresCredoresSocios socios={socios ? socios : []} /> */}
        </Paper>
      </Typography>
    </Container>
  );
}
