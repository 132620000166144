import React, { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Typography, Button, ListItem, TextField } from "@mui/material";

import { withStyles } from '@mui/styles';

import { useParams } from "react-router-dom";

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import UploadService from "../../../services/upload-files.service";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

export default function UploadFiles() {
    const query = useParams();
    const [selectedFiles, setSelectedFiles] = React.useState('');
    const [currentFile, setCurrentFile] = React.useState(undefined);
    const [progress, setProgress] = React.useState(0);
    const [isError, setIsError] = React.useState(false);
    const [fileInfos, setFileInfos] = React.useState([]);
    const [checkFileSize, setCheckFileSize] = React.useState(false);
    const [checkFileType, setCheckFileType] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('success');
    const [message, setMessage] = React.useState('Dados gravados com sucesso!');

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClickAlert = () => {
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const selectFile = (event) => {

        setSelectedFiles(event.target.files);
        setCheckFileSize(false);
        console.log('event: ', event);

        if (event.target.value.length !== 0) {
          if (event !== 'undefined' && event && event.target && event.target.files && event.target.files[0].size <= 6e5) {
            // console.log(event.target.files[0]);
            setCheckFileSize(true);
          } else {
            setSeverity('error');
            setMessage('Tamanho do arquivo inválido!');
            handleClickAlert();
          }
          setCheckFileType(true);
          if (event !== 'undefined' && event && event.target && event.target.files && event.target.files[0].type !== "application/pdf") {
            setCheckFileType(false)
            setSeverity('error');
            setMessage('O arquivo deve ser do tipo PDF!');
            handleClickAlert();
          }
        }
    }

    const upload = () => {
      const currentFile = selectedFiles[0];
      setProgress(0);
      setCurrentFile(currentFile);

      UploadService.upload(currentFile, query.id, (event) => {
        // console.log('event.loaded: ', event.loaded);
        // console.log('event.total: ', event.total);
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
          .then((response) => {
            setMessage(response.data.message);
            setIsError(false);
          })
          .then((files) => {
            setFileInfos(files.data);
          })
          .catch(() => {
            setProgress(0);
            setMessage("Could not upload the file!");
            setCurrentFile(undefined);
            setIsError(true);
          })
          setSelectedFiles(undefined);
    }

    return (
      <div className="mg20">
      {/*<pre>{JSON.stringify(checkFileSize, null, 2)}</pre>*/}
        {currentFile && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
            </Box>
          </Box>)
        }

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>

       {/* <label htmlFor="btn-upload">
          <div className="file-name">
            {selectedFiles && selectedFiles.length > 0 && checkFileSize && checkFileType ? selectedFiles[0].name : 'Selecione o arquivo'}
          </div>
          <input
            id="btn-upload"
            name="btn-upload"
            accept="application/pdf"
            style={{ display: 'none' }}
            type="file"
            onChange={selectFile} />
          <Button
            className="btn-choose"
            variant="outlined"
            component="span" >
             Selecione o arquivo
      </Button>
      </label>*/}

        {/*<div>
          <TextField
            id="outlined-basic"
            placeholder="Selecione o arquivo..."
            size="small"
            variant="outlined"
            inputProps={{ style: { cursor: "pointer" }, readOnly: true }}
            onClick={selectFile}
            />
        </div>

        <Button
          className="btn-upload"
          color="primary"
          variant="contained"
          component="span"
          disabled={!selectedFiles || !checkFileSize}
          onClick={upload}>
          Enviar
        </Button>/*}

        {/*<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
          {message}
      </Typography>*/}
      </div >
    )
}
