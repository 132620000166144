import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select, TextFieldProps } from "@mui/material";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';

import schema from '../schema';
import { useFormik } from 'formik';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando'
import Backdrop from '@mui/material/Backdrop';

import { Formik, Form, } from "formik";

import { MyTextField } from '../../../components/Controls/MyTextField';
import { MyTextFieldReadOnly } from '../../../components/Controls/MyTextFieldReadOnly';

import { validarCNPJ, replaceAll, formatDateEnUs, zeroPad, formatCNPJ } from "../../../utils/functions";

//@ts-ignore
import InputMask from 'react-input-mask';

import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function App() {
  const query = useParams();
  const history = useHistory();
  const classes = useStyles();
  
  const [initialValues, setInitialValues] = React.useState({});
  const [initialBancos, setInitialBancos] = React.useState([]);

  // const [initialDataEncerramento, setInitialDataEncerramento] = React.useState();
  const [caixasEscolaresList, setCaixasEscolaresList] = React.useState([]);

  const[id, setId] = React.useState(null);


  const[caixasEscolaresId, setCaixasEscolaresId] = React.useState(null);
  const [contasBancariasTiposVerbasId, setContasBancariasTiposVerbasId] = React.useState({});
  const [caixasEscolaresNome, setCaixasEscolaresNome] = React.useState(null);
  const [caixasEscolaresCnpj, setCaixasEscolaresCnpj] = React.useState(null);
  const [initialTiposVerbas, setInitialTiposVerbas] = React.useState([]);
  const [bancoId, setBancoId] = React.useState(null);
  const [bancoCodigo, setBancoCodigo] = React.useState(null);
  const [statusContasBancariasId, setStatusContaBancariaId] = React.useState([]);
  const [initialStatusContasBancarias, setInitialStatusContasBancarias] = React.useState([]);
  const [nomeAgencia, setNomeAgencia] = React.useState(null);
  const [numeroAgencia, setNumeroAgencia] = React.useState(null);
  const [numeroContaCorrente, setNumeroContaCorrente] = React.useState(null);
  const [dataEncerramento, setDataEncerramento] = React.useState(null);

  const [errors, setErrors] = React.useState([]);

  // const [errors, setErrors] = React.useState(
  //   {
  //     "initialTiposVerbas": false, "initialTiposVerbasMessage": 'Campo obrigatório!',
  //     "bancoCodigo": false, "bancoCodigoMessage": 'Campo obrigatório!',
  //     "statusContasBancariasId": false, "statusContasBancariasIdMessage": 'Campo obrigatório!',
  //     "dataEncerramento": false, "dataEncerramentoMessage": 'Campo obrigatório!',
  //     "caixasEscolaresId": false, "caixasEscolaresIdMessage": 'Campo obrigatório!',
  //   }
  // );

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const saveUpdateData = () => {
    let payload = {
      // @ts-ignore
      'id': id,
      // @ts-ignore
      'numeroAgencia': numeroAgencia ? numeroAgencia.toUpperCase() : '',
      // @ts-ignore
      'nomeAgencia': nomeAgencia ? nomeAgencia.toUpperCase() : '',
      // @ts-ignore
      'numeroContaCorrente': numeroContaCorrente ? numeroContaCorrente.toUpperCase() : '',
      // @ts-ignore
      'dataEncerramento': formatDateEnUs(dataEncerramento),
      // @ts-ignore
      'statusContasBancariasId': statusContasBancariasId.id,
      // @ts-ignore
      'bancosId': bancoId,               
      // @ts-ignore
      'contasBancariasTiposVerbasId': contasBancariasTiposVerbasId.id,    
      // @ts-ignore
      'caixasEscolaresId': caixasEscolaresId.id, 
    }

    if (!id) {
      //@ts-ignore
      delete payload.id;
    }
    
    // @ts-ignore
    const values = query.id ? api.put(`dados-bancarios/${id}`, payload) : api.post(`dados-bancarios`, payload)
    values.then((response) => {
      // setSubmitting(false);

      setId(response.data.id);
      setBackdrop(false);
      handleClickAlert();
      // console.log("submit: ", data);
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      // setSubmitting(false);
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const handleClickAlert = () => {
    setOpen(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   // @ts-ignore
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  const setGoBack = () => {
    history.push('/dados-bancarios-list');
  }

  const setNewRegister = () => {
    const newValues = {
      "numeroAgencia":'',
      "nomeAgencia": '',
      "numeroContaCorrente": '',
      "dataEncerramento": '',
      "bancoId": {},
      "bancoCodigo": '',
      "statusContasBancariasId": {},
      "contasBancariasTiposVerbasId": {},
      "caixasEscolaresId": null,
      "caixasEscolaresCnpj": '',
      "statusCaixasEscolaresId": null,
    }
    setInitialValues(newValues);
    setId(null);
    setCaixasEscolaresId(null);
    setContasBancariasTiposVerbasId({});
    //@ts-ignore
    setCaixasEscolaresNome('');
    //@ts-ignore
    setCaixasEscolaresCnpj('');
    // setInitialTiposVerbas([]);
    setBancoId(null);
    //@ts-ignore
    setBancoCodigo('');
    // setStatusContaBancariaId([]);
    // setInitialStatusContasBancarias([]);
    //@ts-ignore
    setNomeAgencia('');
    //@ts-ignore
    setNumeroAgencia('');
    //@ts-ignore
    setNumeroContaCorrente('');
    setDataEncerramento(null);

    history.push('/dados-bancarios/new');    
  }

  // @ts-ignore
  const testCNPJ = (cnpj) => {
    return (cnpj && cnpj.replace(/\D/g, '').length === 14 && validarCNPJ(cnpj));
  }

  const checkErrors = () => {
    const dado = [];
    if (!contasBancariasTiposVerbasId) {
      dado.push('contasBancariasTiposVerbasId');
    } 
    //@ts-ignore
    if (!bancoId || !bancoId.id) {
      dado.push('bancoId');
      dado.push('bancoCodigo');
    }
    //@ts-ignore
    if (!statusContasBancariasId || !statusContasBancariasId.id) {
      dado.push('statusContasBancariasId');
    }
    //@ts-ignore
    if (!nomeAgencia || nomeAgencia.length <= 1) {
      dado.push('nomeAgencia');
    }
    //@ts-ignore
    if (!numeroAgencia || numeroAgencia.length <= 1) {
      dado.push('numeroAgencia');
    }
    //@ts-ignore
    if (!numeroContaCorrente || numeroContaCorrente.length <= 1) {
      dado.push('numeroContaCorrente');
    }
    //@ts-ignore
    if ((statusContasBancariasId.id === 'ede6cf3e-242f-11ec-b590-00155dcabde0') && (!dataEncerramento || dataEncerramento.length <= 1)) {
      dado.push('dataEncerramento');
    }
    //@ts-ignore
    setErrors(dado);
    if (dado.length === 0) {
      saveUpdateData();
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (query.id) {
      // @ts-ignore
      const values = api.get(`dados-bancarios/${query.id}`)
      values.then((response) => {
        setInitialValues(response.data)

        setId(response.data.id);
        setCaixasEscolaresId(response.data.caixasEscolaresId);
        setCaixasEscolaresCnpj(response.data.caixasEscolaresCnpj);
        setBancoCodigo(response.data.bancoCodigo);
        setCaixasEscolaresNome(response.data.caixasEscolaresNome);
        setNomeAgencia(response.data.nomeAgencia);
        setNumeroAgencia(response.data.numeroAgencia);
        setNumeroContaCorrente(response.data.numeroContaCorrente);

        // let formatDate = new Date(response.data.dataEncerramento);
        // // @ts-ignore
        // formatDate = formatDate.setDate(formatDate.getDate() + 1);
        // formatDate = new Date(formatDate);
        // const newFormatDate = formatDate.getFullYear() + "-" + ((formatDate.getMonth() + 1)) + "-" + (formatDate.getDate());

        const newFormatDate = formatDateEnUs(response.data.dataEncerrament);
        // @ts-ignore
        setDataEncerramento(newFormatDate);
        // @ts-ignore
        setInitialDataEncerramento(newFormatDate);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    // @ts-ignore
    const values = api.get(`caixas-escolares/list`)
    values.then((response) => {
      setCaixasEscolaresList(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
   const values = api.get("bancos")
    values.then((response) => {
      setInitialBancos(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    const values = api.get("status-contas-bancarias")
     values.then((response) => {
      setInitialStatusContasBancarias(response.data)
     })
     .catch((err) => {
       console.error("ops! ocorreu um erro" + err);
     })
  }, []);
   
  useEffect(() => {
    const values = api.get("contas-bancarias-tipos-verbas")
     values.then((response) => {
      setInitialTiposVerbas(response.data)
     })
     .catch((err) => {
       console.error("ops! ocorreu um erro" + err);
     })
  }, []);

  useEffect(() => {
    // @ts-ignore
    setBancoId(initialBancos.find(item => item.value === initialValues.bancoId));
    // @ts-ignore
    setContasBancariasTiposVerbasId(initialTiposVerbas.find(item => item.value === initialValues.contasBancariasTiposVerbasId))
    // @ts-ignore
    setStatusContaBancariaId(initialStatusContasBancarias.find(item => item.value === initialValues.statusContasBancariasId));
  }, [initialValues, initialTiposVerbas, initialBancos, initialStatusContasBancarias]);

  //  return (initialValues ? 
   return (
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
          <SpinnerCarregando open={backdrop} />
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert
              // onClose={() => {handleClose}}
              //@ts-ignore
              severity={severity}
              sx={{ width: '100%' }}>
                {message}
              </Alert>
          </Snackbar>

          <Grid container spacing={2 }  sx={{ mt: 3 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ ml: 2 }}
            >
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Cadastro de Dados Bancários</Box>
                </Typography> 
              </Box>
              <Box
                component="div"
                className={`${classes.bottomRightBox} ${classes.box}`}>
                  <div>
                    <Button onClick={setGoBack} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20}}>
                      Cancelar
                    </Button>
                  </div>

                  <div>
                    <Button onClick={setNewRegister} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20}}>
                      Novo
                    </Button>
                  </div>

                  <div>
                    <Button type="submit" onClick={checkErrors} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                      Gravar
                    </Button>
                  </div>
              </Box>
            </Grid>
            
            {
              // @ts-ignore
            query.id ?
            <>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                {/* <MyTextFieldReadOnly  placeholder="Caixa Escolar" name="caixasEscolaresNome"/> */}
                <TextField
                  size="small"
                  value={caixasEscolaresNome}
                  disabled={!testCNPJ(caixasEscolaresCnpj)}
                  required
                  type="tel"
                  focused
                  fullWidth
                  label={("Caixa Escolar")}
                  inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                  onChange={e => {
                    //@ts-ignore
                    setCaixasEscolaresNome(e.target.value)
                      // setInitialValues({...initialValues, 'caixasEscolaresNome': e.target.value})
                    }
                  }
                  //@ts-ignore
                  error={errors.includes('caixasEscolaresNome')}
                  //@ts-ignore
                  helperText={errors.includes('caixasEscolaresNome') ? 'Campo obrigatório!' : ''}
                />
              </Grid>

              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>



              <TextField
                value={caixasEscolaresCnpj}
                type="tel"
                required
                focused
                fullWidth
                inputProps={{ readOnly: true }}
                size="small"
                label={("CNPJ")}
                onChange={e => {
                  //@ts-ignore
                  setCaixasEscolaresCnpj(e.target.value)
                    // setInitialValues({...initialValues, 'cnpj': e.target.value})
                  }
                }
                //@ts-ignore
                error={errors.includes('caixasEscolaresCnpj')}
                //@ts-ignore
                helperText={errors.includes('caixasEscolaresCnpj') ? 'Campo incorreto!' : ''} />









                {/* <MyTextFieldReadOnly placeholder="CNPJ" name="caixasEscolaresCnpj"/> */}
               {/* <InputMask
                  mask="99.999.999/9999-99"
                  value={caixasEscolaresCnpj}
                  //@ts-ignore
                  onChange={e => {
                    setCaixasEscolaresCnpj(e.target.value)
                      // setInitialValues({...initialValues, 'cnpj': e.target.value})
                    }
                  }
                  onBlur={caixasEscolaresCnpj}
                >
                  {//@ts-ignore
                    (inputProps: Props): ReactElement => {
                      return (
                        <TextField
                          {...inputProps}
                          type="tel"
                          required
                          focused
                          fullWidth
                          inputProps={{ readOnly: true }}
                          size="small"
                          label={("CNPJ")}
                          //@ts-ignore
                          error={errors.includes('caixasEscolaresCnpj')}
                          //@ts-ignore
                          helperText={errors.includes('caixasEscolaresCnpj') ? 'Campo incorreto!' : ''} />
                      );
                    }}
              </InputMask>
                  */}
              </Grid>
            </>
            : <></>}

            {
              // @ts-ignore
            !query.id ?
            <>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <Autocomplete
                  style={{ paddingTop: 10 }}
                  id="controlled-caixa-escolar"
                  value={caixasEscolaresId}
                  options={caixasEscolaresList}
                  onChange={(event, newValue) => {
                    // @ts-ignore
                    // initialValues.caixasEscolaresId = null;
                    // @ts-ignore
                    // initialValues.caixasEscolaresId = null;
                    // console.log('initialValues: ', initialValues);
                    // @ts-ignore
                    setCaixasEscolaresId(null);
                    //@ts-ignore
                    setCaixasEscolaresCnpj("");
                    // errors.caixasEscolaresId = true;
                    if (newValue) {
                      // @ts-ignore
                      // console.log('newValue: ', newValue.cnpj);
                      setCaixasEscolaresCnpj(newValue.cnpj);
                      // @ ts-ignore
                      // initialValues.caixasEscolaresId = newValue.id;
                      // @ts-ignore
                      initialValues.caixasEscolaresId = newValue.caixasEscolaresId;
                      setCaixasEscolaresId(newValue);
                      // errors.caixasEscolaresId = false;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Caixa Escolar"
                      variant="outlined"
                      size="small"
                      fullWidth
                      focused
                      required
                      //@ts-ignore
                      error={errors.includes('caixasEscolaresId')}
                      //@ts-ignore
                      helperText={errors.includes('caixasEscolaresId') ? 'Campo incorreto!' : ''}
                    />
                  )}
                />
              </Grid>
            
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ marginTop: 10 }}>
                {/* <MyTextFieldReadOnly placeholder="CNPJ" name="caixasEscolaresCnpj"/> */}
                <TextField
                  size="small"
                  value={caixasEscolaresCnpj}
                  // disabled={!testCNPJ(caixasEscolaresCnpj)}
                  required
                  type="tel"
                  focused
                  fullWidth
                  label={("Caixa Escolar")}
                  inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                  //@ts-ignore
                  error={errors.includes('caixasEscolaresCnpj')}
                  //@ts-ignore
                  helperText={errors.includes('caixasEscolaresCnpj') ? 'Campo obrigatório!' : ''}
                />
              </Grid>
            </>
            : <></>}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                style={{ paddingTop: 10 }}
                id="controlled-verba-tipo"
                value={contasBancariasTiposVerbasId}
                options={initialTiposVerbas}
                onChange={(event, newValue) => {

                  // @ts-ignore
                  setContasBancariasTiposVerbasId(null);
                  // errors.initialTiposVerbas = true;
                  if (newValue) {
                    // @ts-ignore
                    // initialValues.contasBancariasTiposVerbasId = newValue.id;
                    setContasBancariasTiposVerbasId(newValue);
                    // errors.initialTiposVerbas = false;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Verba (Tipo)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    //@ts-ignore
                    error={errors.includes('contasBancariasTiposVerbasId')}
                    //@ts-ignore
                    helperText={errors.includes('contasBancariasTiposVerbasId') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                style={{ paddingTop: 10, paddingBottom: 10 }}
                id="controlled-banco-numero"
                value={bancoId}
                options={initialBancos}
                size="small"
                onChange={(event, newValue) => {
                  // errors.bancoCodigo = true;
                  setBancoId(null);
                  //@ts-ignore
                  setBancoCodigo('');
                  // @ts-ignore
                  // initialValues.bancoCodigo = '';
                  if (newValue) {
                    // @ts-ignore
                    // initialValues.bancoCodigo = newValue.codigo;
                    // @ts-ignore
                    // initialValues.bancoId = newValue.id
                    setBancoId(newValue);
                    //@ts-ignore
                    setBancoCodigo(zeroPad(newValue.codigo, 3));
                    // errors.bancoCodigo = false;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Banco (Nome)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    //@ts-ignore
                    error={errors.includes('bancoId')}
                    //@ts-ignore
                    helperText={errors.includes('bancoId') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{ paddingTop: 25, paddingBottom: 15 }}>
              {/* <MyTextFieldReadOnly placeholder="Banco (Código)" name="bancoCodigo"/> */}
              <TextField
                size="small"
                value={bancoCodigo}
                // disabled={!testCNPJ(caixasEscolaresCnpj)}
                required
                type="tel"
                focused
                fullWidth
                label={("Banco (Código)")}
                inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                //@ts-ignore
                error={errors.includes('bancoCodigo')}
                //@ts-ignore
                helperText={errors.includes('bancoCodigo') ? 'Campo obrigatório!' : ''}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              {/* <pre>{JSON.stringify(statusContasBancariasId, null, 2)}</pre> */}
              <Autocomplete
                style={{ paddingTop: 10, paddingBottom: 10 }}
                id="controlled-conta-bancaria-id"
                value={statusContasBancariasId}
                options={initialStatusContasBancarias}
                onChange={(event, newValue) => {
                  // @ts-ignore
                  setStatusContaBancariaId([]);
                  // @ts-ignore
                  setDataEncerramento(null);
                  // @ts-ignore
                  // initialValues.statusContasBancariasId = null;
                  // @ts-ignore
                  // initialValues.dataEncerramento = null;
                  // errors.statusContasBancariasId = true;
                  if (newValue) {
                  // @ts-ignore
                  setStatusContaBancariaId(newValue);
                  // @ts-ignore
                  // initialValues.statusContasBancariasId = newValue.id;
                  // errors.statusContasBancariasId = false;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    //@ts-ignore
                    error={errors.includes('statusContasBancariasId')}
                    //@ts-ignore
                    helperText={errors.includes('statusContasBancariasId') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4} sm={6} md={4} lg={4} xl={4} style={{ paddingBottom: 15 }}>
              {/* <MyTextField placeholder="Agência (Nome)" name="nomeAgencia" required/> */}
              <TextField
                size="small"
                value={nomeAgencia}
                // disabled={!testCNPJ(caixasEscolaresCnpj)}
                required
                type="tel"
                focused
                fullWidth
                label={("Agência (Nome)")}
                inputProps={{ style: { textTransform: "uppercase" }}}
                onChange={e => {
                  //@ts-ignore
                  setNomeAgencia(e.target.value)
                }}
                //@ts-ignore
                error={errors.includes('nomeAgencia')}
                //@ts-ignore
                helperText={errors.includes('nomeAgencia') ? 'Campo obrigatório!' : ''}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: 15 }}>
              {/* <MyTextField placeholder="Agência (Número)" name="numeroAgencia" required/> */}
              <TextField
                size="small"
                value={numeroAgencia}
                // disabled={!testCNPJ(caixasEscolaresCnpj)}
                required
                type="tel"
                focused
                fullWidth
                label={("Agência (Número)")}
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={e => {
                  //@ts-ignore
                  setNumeroAgencia(e.target.value)
                }}
                //@ts-ignore
                error={errors.includes('numeroAgencia')}
                //@ts-ignore
                helperText={errors.includes('numeroAgencia') ? 'Campo obrigatório!' : ''}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingBottom: 15 }}>
              {/* <MyTextField placeholder="Conta-corrente (Número)" name="numeroContaCorrente" required/> */}
              <TextField
                size="small"
                value={numeroContaCorrente}
                // disabled={!testCNPJ(caixasEscolaresCnpj)}
                required
                type="tel"
                focused
                fullWidth
                label={("Conta-corrente (Número)")}
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={e => {
                  //@ts-ignore
                  setNumeroContaCorrente(e.target.value)
                }}
                //@ts-ignore
                error={errors.includes('numeroContaCorrente')}
                //@ts-ignore
                helperText={errors.includes('numeroContaCorrente') ? 'Campo obrigatório!' : ''}
              />
            </Grid>

            {
              // @ts-ignore 
              statusContasBancariasId && statusContasBancariasId.id === 'ede6cf3e-242f-11ec-b590-00155dcabde0' ?
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                  <DatePicker
                    label="Data de encerramento"
                    value={dataEncerramento}
                    onChange={(newValue: React.SetStateAction<null>) => {
                      // @ts-ignore
                      setDataEncerramento(newValue);
                      // errors.dataEncerramento = true;
                      if (newValue) {
                        // @ts-ignore
                        // let formatDate = new Date(newValue);
                        // // @ts-ignore
                        // formatDate = formatDate.setDate(formatDate.getDate() + 1);
                        // formatDate = new Date(formatDate);
                        const newFormatDate = formatDateEnUs(newValue); //formatDate.getFullYear() + "-" + ((formatDate.getMonth() + 1)) + "-" + (formatDate.getDate());
                        // @ts-ignore
                        // initialValues.dataEncerramento = newFormatDate;
                        // console.log('initialValues: ' , initialValues);
                        // errors.dataEncerramento = false;
                      }
                    }}
                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) =>
                      <TextField
                      {...params}
                      size="small"
                      fullWidth
                      focused
                      required
                      //@ts-ignore
                      error={errors.includes('dataEncerramento')}
                      //@ts-ignore
                      helperText={errors.includes('dataEncerramento') ? 'Campo obrigatório!' : ''}
                    />}
                  />
                </LocalizationProvider>
              </Grid>
              :
              <></>
            }
          </Grid>

        </Paper>
      </Typography>
    </Container>
   );
    // :
    // <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando ...</span></Typography>
  // );
}
