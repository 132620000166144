import React, { useEffect }  from 'react';
import { useParams, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
//@ts-ignore
// import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import AutoCompleteSelectComponent from "../../../components/Controls/AutoCompleteSelectComponent";
import SelectComponent from "../../../components/Controls/SelectComponent";
import Autocomplete from '@mui/material/Autocomplete';

import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';

import Snackbar from '@mui/material/Snackbar';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';

import ConferenciaTable from '../../../components/ConferenciaTable';
import ComprovantesDeDespesasListProvisorios from '../../../components/ComprovantesDeDespesasListProvisorios';

//@ts-ignore
import InputMask from 'react-input-mask';

import { convertStrintToFloat, uuidv4 } from "../../../utils/functions";

import api from '../../../services/api';
import { QrCodeScannerOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

//@ts-ignore
export default function EnhancedTable(props) {
  const history = useHistory();
  const query = useParams();
  const classes = useStyles();

  const [searchField, setSearchField] = React.useState('');
  const [totalValues, setTotalValues] = React.useState([]);
  const [listValues, setListValues] = React.useState([]);
  const [listStatusValues, setListStatusValues] = React.useState([]);
  
  const [listNewValues, setListNewValues] = React.useState([]);
  const [stateValueStatus, setStateValueStatus] = React.useState([]);
  const [errors, setErrors] = React.useState([]);

  const [rows, setRows] = React.useState([]);

  const [dados, setDados] = React.useState({
    "caixasEscolaresCnpj": "",
    "caixasEscolaresCodigoCredor": "",
    "caixasEscolaresId": "",
    "caixasEscolaresNome": "",
    "caixasEscolaresStatusCaixasEscolaresId": "",
    "caixasEscolaresTermo": "",
    "caixasEscolaresUnidadesEscolaresBairrosId": "",
    "caixasEscolaresUnidadesEscolaresCidadesId": "",
    "caixasEscolaresUnidadesEscolaresComplementos": null,
    "caixasEscolaresUnidadesEscolaresEmail": "",
    "caixasEscolaresUnidadesEscolaresId": "",
    "caixasEscolaresUnidadesEscolaresLeiDeCriacao": "",
    "caixasEscolaresUnidadesEscolaresNumero": "",
    "caixasEscolaresUnidadesEscolaresTelefonesUnidadesEscolares": [],
    "caixasEscolaresUnidadesEscolaresTiposAbreviacao": "",
    "caixasEscolaresUnidadesEscolaresTiposDescricao": "",
    "caixasEscolaresUnidadesEscolaresTiposDescricaoAbreviada": "",
    "caixasEscolaresUnidadesEscolaresTiposId": "",
    "caixasEscolaresUnidadesEscolaresCep": "",
    "caixasEscolaresUnidadesEscolaresLogradouro": "",
    "caixasEscolaresUnidadesEscolaresNome": "",
    "classificacoesOrcamentarias": null,
    "classificacoesOrcamentariasId": null,
    "comprovantesDeDespesasProvisorias": [],
    "conferenciasPrestacoesDeContasId": null,
    "conferenciasPrestacoesDeContasDataEmail": null,
    "conferenciasPrestacoesDeContasPrestacoesDeContasId": null,
    "conferenciasPrestacoesDeContasStatusId": null,
    "conferenciasPrestacoesDeContasStatusDescricao": "",
    "conferenciasPrestacoesDeContasStatusAbreviacao": "",
    "conferenciasPrestacoesDeContasStatusColor": "",
    "conferenciasPrestacoesDeContasObservacoes": [],
    "dadosBancarios": null,
    "dadosBancariosId": null,
    "data": null,
    "dataAssinatura": null,
    "dataPagamento": "",
    "dataPrevistaFinal": null,
    "dataPrevistaInicial": null,
    "dataPublicacaoDoc": null,
    "descricoesObjetosServicos": null,
    "detalhamentosDasDespesasDescricao": "",
    "detalhamentosDasDespesasId": "",
    "id": "",
    "naps": null,
    "napsId": null,
    "numerosParcelasDescricao": "",
    "numerosParcelasId": "",
    "numerosParcelasOrdem": "",
    "observacoesPrestacoesDeContas": [],
    "origemDosRecursosId": "",
    "prazoDeEntrega": null,
    "prestacoesDeContasData": null,
    "prestacoesDeContasDataParecerConselhoDeFiscal": null,
    "prestacoesDeContasDescricoesObjetosServicosId": null,
    "prestacoesDeContasDespesasProvisorias": 0,
    "prestacoesDeContasDevolucao": 0,
    "prestacoesDeContasId": "",
    "prestacoesDeContasParcelasId": "",
    "prestacoesDeContasRendimentos": 0,
    "prestacoesDeContasSaldoAnterior": 0,
    "prestacoesDeContasSecretariasDeEducacaoBairro": "",
    "prestacoesDeContasSecretariasDeEducacaoCep": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesCodigo": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosAbreviacao": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosCodigo": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosId": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosNome": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesId": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesNome": "",
    "prestacoesDeContasSecretariasDeEducacaoComplementos": null,
    "prestacoesDeContasSecretariasDeEducacaoEmail": "",
    "prestacoesDeContasSecretariasDeEducacaoId": "",
    "prestacoesDeContasSecretariasDeEducacaoLogradouro": "",
    "prestacoesDeContasSecretariasDeEducacaoNome": "",
    "prestacoesDeContasSecretariasDeEducacaoNumero": "",
    "prestacoesDeContasSecretariosEducacaoId": "",
    "prestacoesDeContasSituacoesPrestacoesDeContasId": null,
    "prestacoesDeContasValorSaldoBancario": null,
    "prestacoesDeContasVerbaPropria": 0,
    "prestacoesDeContasVerbaTc": 0,
    "termosDeCooperacaoAnosAno": "",
    "termosDeCooperacaoAnosId": "",
    "termosDeCooperacaoDataAssinatura": null,
    "termosDeCooperacaoDataSituacao": null,
    "termosDeCooperacaoExerciciosFinanceiros": [],
    "termosDeCooperacaoHasPercaptasValores": [],
    "termosDeCooperacaoId": "",
    "termosDeCooperacaoNumero": null,
    "termosDeCooperacaoObservacao": "",
    "termosDeCooperacaoPrazosProrrogados": [],
    "termosDeCooperacaoQuantidadeParcelasCusteio": null,
    "termosDeCooperacaoQuantidadeParcelasCusteioId": null,
    "termosDeCooperacaoSituacaoId": null,
    "termosDeCooperacaoTermosTiposId": "",
    "termosTiposDescricao": "",
    "termosTiposId": "",
    "tiposTermosDescricao": "",
    "tiposTermosId": "",
    "valor": 0
      // 'prestacoesDeContasId': dados.prestacoesDeContas[0].id,
      // 'parcelasId': dados.prestacoesDeContas[0].parcelasId,
      // 'caixaEscolarNome': dados.termosDeCooperacao.caixasEscolares.nome,
      // 'unidadeEscolarNome': dados.termosDeCooperacao.caixasEscolares.unidadesEscolares[0].tipos.abreviacao + ' ' + dados.termosDeCooperacao.caixasEscolares.unidadesEscolares[0].nome,
      // 'termoTipo': dados.termosTipos.descricao,
      // 'numeroParcelaDescricao': dados.numerosParcelas.descricao,
      // 'tipoTermo': dados.tiposTermos.descricao,
      // "ano": dados.termosDeCooperacaoAnosAno,
      // "conferenciasPrestacoesDeContasId": dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].id,
      // "statusCaixasEscolares": formatStatus(dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].status),
      // "saldoAnterior": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].saldoAnterior ? dados.prestacoesDeContas[0].saldoAnterior : '0,00',
      // "verbaTc": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaTc ? dados.prestacoesDeContas[0].verbaTc : '0,00',
      // "verbaPropria": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaPropria ? dados.prestacoesDeContas[0].verbaPropria : '0,00',
      // "rendimentos": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].rendimentos ?  dados.prestacoesDeContas[0].rendimentos : '0,00',
      // "totalCreditos": totalCreditos,
      // "despesas": somaDespesas(dados),
      // "despesasProvisorias": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].despesasProvisorias ? dados.prestacoesDeContas[0].despesasProvisorias : '0,00',
      // "devolucao": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].devolucao ? dados.prestacoesDeContas[0].devolucao : '0,00',
      // "saldo": totalCreditos - totalDebitos,
      // "observacoesConferencia": dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].observacoesConferenciasPrestacoesDeContas,
  });

  const [initialValues, setInitialValues] = React.useState({
    "caixasEscolaresCnpj": "",
    "caixasEscolaresCodigoCredor": "",
    "caixasEscolaresId": "",
    "caixasEscolaresNome": "",
    "caixasEscolaresStatusCaixasEscolaresId": "",
    "caixasEscolaresTermo": "",
    "caixasEscolaresUnidadesEscolaresBairrosId": "",
    "caixasEscolaresUnidadesEscolaresCidadesId": "",
    "caixasEscolaresUnidadesEscolaresComplementos": null,
    "caixasEscolaresUnidadesEscolaresEmail": "",
    "caixasEscolaresUnidadesEscolaresId": "",
    "caixasEscolaresUnidadesEscolaresLeiDeCriacao": "",
    "caixasEscolaresUnidadesEscolaresNumero": "",
    "caixasEscolaresUnidadesEscolaresTelefonesUnidadesEscolares": [],
    "caixasEscolaresUnidadesEscolaresTiposAbreviacao": "",
    "caixasEscolaresUnidadesEscolaresTiposDescricao": "",
    "caixasEscolaresUnidadesEscolaresTiposDescricaoAbreviada": "",
    "caixasEscolaresUnidadesEscolaresTiposId": "",
    "caixasEscolaresUnidadesEscolaresCep": "",
    "caixasEscolaresUnidadesEscolaresLogradouro": "",
    "caixasEscolaresUnidadesEscolaresNome": "",
    "classificacoesOrcamentarias": "",
    "classificacoesOrcamentariasId": null,
    "comprovantesDeDespesasProvisorias": [],
    "conferenciasPrestacoesDeContasId": null,
    "conferenciasPrestacoesDeContasDataEmail": null,
    "conferenciasPrestacoesDeContasPrestacoesDeContasId": null,
    "conferenciasPrestacoesDeContasStatusId": null,
    "conferenciasPrestacoesDeContasStatusDescricao": "",
    "conferenciasPrestacoesDeContasStatusAbreviacao": "",
    "conferenciasPrestacoesDeContasStatusColor": "",
    "conferenciasPrestacoesDeContasObservacoes": [],
    "dadosBancarios": null,
    "dadosBancariosId": null,
    "data": null,
    "dataAssinatura": null,
    "dataPagamento": "",
    "dataPrevistaFinal": null,
    "dataPrevistaInicial": null,
    "dataPublicacaoDoc": null,
    "descricoesObjetosServicos": null,
    "detalhamentosDasDespesasDescricao": "",
    "detalhamentosDasDespesasId": "",
    "id": "",
    "naps": null,
    "napsId": null,
    "numerosParcelasDescricao": "",
    "numerosParcelasId": "",
    "numerosParcelasOrdem": "",
    "observacoesPrestacoesDeContas": [],
    "origemDosRecursosId": "",
    "prazoDeEntrega": null,
    "prestacoesDeContasData": null,
    "prestacoesDeContasDataParecerConselhoDeFiscal": null,
    "prestacoesDeContasDescricoesObjetosServicosId": null,
    "prestacoesDeContasDespesasProvisorias": 0,
    "prestacoesDeContasDevolucao": 0,
    "prestacoesDeContasId": "",
    "prestacoesDeContasParcelasId": "",
    "prestacoesDeContasRendimentos": 0,
    "prestacoesDeContasSaldoAnterior": 0,
    "prestacoesDeContasSecretariasDeEducacaoBairro": "",
    "prestacoesDeContasSecretariasDeEducacaoCep": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesCodigo": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosAbreviacao": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosCodigo": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosId": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesEstadosNome": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesId": "",
    "prestacoesDeContasSecretariasDeEducacaoCidadesNome": "",
    "prestacoesDeContasSecretariasDeEducacaoComplementos": null,
    "prestacoesDeContasSecretariasDeEducacaoEmail": "",
    "prestacoesDeContasSecretariasDeEducacaoId": "",
    "prestacoesDeContasSecretariasDeEducacaoLogradouro": "",
    "prestacoesDeContasSecretariasDeEducacaoNome": "",
    "prestacoesDeContasSecretariasDeEducacaoNumero": "",
    "prestacoesDeContasSecretariosEducacaoId": "",
    "prestacoesDeContasSituacoesPrestacoesDeContasId": null,
    "prestacoesDeContasValorSaldoBancario": null,
    "prestacoesDeContasVerbaPropria": 0,
    "prestacoesDeContasVerbaTc": 0,
    "termosDeCooperacaoAnosAno": "",
    "termosDeCooperacaoAnosId": "",
    "termosDeCooperacaoDataAssinatura": null,
    "termosDeCooperacaoDataSituacao": null,
    "termosDeCooperacaoExerciciosFinanceiros": [],
    "termosDeCooperacaoHasPercaptasValores": [],
    "termosDeCooperacaoId": "",
    "termosDeCooperacaoNumero": null,
    "termosDeCooperacaoObservacao": "",
    "termosDeCooperacaoPrazosProrrogados": [],
    "termosDeCooperacaoQuantidadeParcelasCusteio": null,
    "termosDeCooperacaoQuantidadeParcelasCusteioId": null,
    "termosDeCooperacaoSituacaoId": null,
    "termosDeCooperacaoTermosTiposId": "",
    "termosTiposDescricao": "",
    "termosTiposId": "",
    "tiposTermosDescricao": "",
    "tiposTermosId": "",
    "valor": 0,

    "totalCreditos": 0, 
    "totalDebitos": 0,
    "saldoFinal": 0
      // 'prestacoesDeContasId': dados.prestacoesDeContas[0].id,
      // 'parcelasId': dados.prestacoesDeContas[0].parcelasId,
      // 'caixaEscolarNome': dados.termosDeCooperacao.caixasEscolares.nome,
      // 'unidadeEscolarNome': dados.termosDeCooperacao.caixasEscolares.unidadesEscolares[0].tipos.abreviacao + ' ' + dados.termosDeCooperacao.caixasEscolares.unidadesEscolares[0].nome,
      // 'termoTipo': dados.termosTipos.descricao,
      // 'numeroParcelaDescricao': dados.numerosParcelas.descricao,
      // 'tipoTermo': dados.tiposTermos.descricao,
      // "ano": dados.termosDeCooperacaoAnosAno,
      // "conferenciasPrestacoesDeContasId": dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].id,
      // "statusCaixasEscolares": formatStatus(dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].status),
      // "saldoAnterior": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].saldoAnterior ? dados.prestacoesDeContas[0].saldoAnterior : '0,00',
      // "verbaTc": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaTc ? dados.prestacoesDeContas[0].verbaTc : '0,00',
      // "verbaPropria": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaPropria ? dados.prestacoesDeContas[0].verbaPropria : '0,00',
      // "rendimentos": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].rendimentos ?  dados.prestacoesDeContas[0].rendimentos : '0,00',
      // "totalCreditos": totalCreditos,
      // "despesas": somaDespesas(dados),
      // "despesasProvisorias": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].despesasProvisorias ? dados.prestacoesDeContas[0].despesasProvisorias : '0,00',
      // "devolucao": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].devolucao ? dados.prestacoesDeContas[0].devolucao : '0,00',
      // "saldo": totalCreditos - totalDebitos,
      // "observacoesConferencia": dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].observacoesConferenciasPrestacoesDeContas,
  });

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = async (event: any) => {
    await setSearchField(event.target.value);
  };

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   //@ts-ignore
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  const formatStatus = (status: any) => {
    const object = {
      "id": status.id,
      "label": status.descricao,
      "value": status.id,
      "abreviacao": status.abreviacao
    }
    return object;
  }

  const somaDespesas = (data: any) => {
    // console.log('data: ', data.prestacoesDeContas[0]);
    let total = 0;
    data.prestacoesDeContas[0].comprovantesDeDespesasProvisorias.map(
      (item: any) => {
        total += parseFloat(item.valorTotal);
      }
   )
   return total;
  }

  const checkPendencias = (newValue: any) => {
    const values = api.get(`prestacoes-de-contas/pendencias/${dados.conferenciasPrestacoesDeContasId}`)
      values.then((response) => {
        if (response.data.length > 0 && newValue.id === "872bf3f4-1635-11ec-8785-dc41a970c8d9") {
          setMessage('Erro ao gravar os dados! Existem pendências para serem resolvidas! Verifique!');
          setSeverity('error');
          handleClickAlert();
          return
        } else if (response.data.length === 0 && newValue.id === "7eed4075-1635-11ec-8785-dc41a970c8d9") {
          setMessage('Erro ao gravar os dados! Não existem pendências a serem resolvidas! Verifique!');
          setSeverity('error');
          handleClickAlert();
          return
        }
        setInitialValues({...initialValues, "caixasEscolaresStatusCaixasEscolaresId": newValue});
        setDados({...dados, "caixasEscolaresStatusCaixasEscolaresId": newValue});
        updateStatusCaixasEscolares(newValue);
      })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const updateStatusCaixasEscolares = (newStatus: any) => {
    const payload = {
      "statusId": newStatus.id
    }
    const values = api.put(`conferencias-prestacoes-de-contas/${dados.conferenciasPrestacoesDeContasId}`, payload)
      .then((response) => {
        // console.log("conferenciasPrestacoesDeContasId", response.data)
        setMessage('Dados atualizados com sucesso!');
        setSeverity('success');
        setBackdrop(false);
        handleClickAlert();
      })
    .catch((err) => {
      // setSubmitting(false);
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  
  // let verbaTC = dados.prestacoesDeContasVerbaTc;
  // if (typeof dados.prestacoesDeContasVerbaTc === 'string') {
  //   verbaTC = (String(dados.prestacoesDeContasVerbaTc).replace('.', '')).replace(',', '.');
  // }

  const updateTcData = () => {
    const payload = {
      "saldoAnterior": convertStrintToFloat(dados.prestacoesDeContasSaldoAnterior),
      "verbaTc": convertStrintToFloat(dados.prestacoesDeContasVerbaTc),
      "verbaPropria": convertStrintToFloat(dados.prestacoesDeContasVerbaPropria),
      "rendimentos": convertStrintToFloat(dados.prestacoesDeContasRendimentos),
      "despesasProvisorias": convertStrintToFloat(dados.prestacoesDeContasDespesasProvisorias),
      "devolucao": convertStrintToFloat(dados.prestacoesDeContasDevolucao)
    }
    const values = api.put(`prestacoes-de-contas/${dados.prestacoesDeContasId}`, payload)
    values.then((response) => {

      const totalCreditos = parseFloat(response.data.saldoAnterior) + parseFloat(response.data.verbaTc) + parseFloat(response.data.verbaPropria) + parseFloat(response.data.rendimentos);
      const totalDebitos = parseFloat(response.data.despesasProvisorias) + parseFloat(response.data.devolucao);
      const saldo = totalCreditos - totalDebitos;

      //@ts-ignore
      setInitialValues({...initialValues, "totalCreditos": totalCreditos, "saldo": saldo});
      //@ts-ignore
      setDados({...dados, "totalCreditos": totalCreditos, "saldo": saldo});

      setBackdrop(false);
      handleClickAlert();
      getData();
    })
    .catch((err) => {
      // setSubmitting(false);
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();

      console.error("ops! ocorreu um erro" + err);
    })
  }

  const getData = () => {
    //@ts-ignore
    const values = api.get(`/parcelas/selecionada/${query.id}`)
    values.then((response) => {
      const dados = response.data;
      // console.log('dados: ', dados);
      setDados(dados);
      const object = {
        "abreviacao": response.data.conferenciasPrestacoesDeContasStatusAbreviacao,
        "id": response.data.conferenciasPrestacoesDeContasId,
        "label": response.data.conferenciasPrestacoesDeContasStatusDescricao,
        "value": response.data.conferenciasPrestacoesDeContasId
      }

      const prestacoesDeContasSaldoAnterior = dados.prestacoesDeContasSaldoAnterior.toLocaleString('pt-BR',{ minimumFractionDigits: 2 });
      const prestacoesDeContasVerbaTc = dados.prestacoesDeContasVerbaTc.toLocaleString('pt-BR',{ minimumFractionDigits: 2 });
      const prestacoesDeContasVerbaPropria = dados.prestacoesDeContasVerbaPropria.toLocaleString('pt-BR',{ minimumFractionDigits: 2 });
      const prestacoesDeContasRendimentos = dados.prestacoesDeContasRendimentos.toLocaleString('pt-BR',{ minimumFractionDigits: 2 });
      const totalCreditos =  parseFloat(dados.prestacoesDeContasSaldoAnterior) + parseFloat(dados.prestacoesDeContasVerbaTc) + parseFloat(dados.prestacoesDeContasVerbaPropria) + parseFloat(dados.prestacoesDeContasRendimentos);
      const prestacoesDeContasDespesasProvisorias = dados.prestacoesDeContasDespesasProvisorias.toLocaleString('pt-BR',{ minimumFractionDigits: 2 });
      const prestacoesDeContasDevolucao = dados.prestacoesDeContasDevolucao.toLocaleString('pt-BR',{ minimumFractionDigits: 2 });
      const totalDebitos =  parseFloat(prestacoesDeContasDespesasProvisorias) + parseFloat(prestacoesDeContasDevolucao);
      const saldoFinal = parseFloat(dados.prestacoesDeContasSaldoAnterior) +
        parseFloat(dados.prestacoesDeContasVerbaTc) +
        parseFloat(dados.prestacoesDeContasVerbaPropria) +
        parseFloat(dados.prestacoesDeContasRendimentos) -
        (parseFloat(dados.prestacoesDeContasDespesasProvisorias) + parseFloat(dados.prestacoesDeContasDevolucao));
      
      setDados({
        ...dados, "caixasEscolaresStatusCaixasEscolaresId": object,
        "prestacoesDeContasSaldoAnterior": prestacoesDeContasSaldoAnterior,
        "prestacoesDeContasVerbaTc": prestacoesDeContasVerbaTc,
        "prestacoesDeContasVerbaPropria": prestacoesDeContasVerbaPropria,
        "prestacoesDeContasRendimentos": prestacoesDeContasRendimentos,
        "totalCreditos": totalCreditos,
        "prestacoesDeContasDespesasProvisorias": prestacoesDeContasDespesasProvisorias,
        "prestacoesDeContasDevolucao": prestacoesDeContasDevolucao,
        "totalDebitos": totalDebitos,
        "saldoFinal": saldoFinal
      });

      // console.log('termosDeCooperacaoAnosANO: ', dados.termosDeCooperacaoAnosAno)
      // console.log('dados.prestacoesDeContas[0].id', dados.prestacoesDeContas[0].id);
      somaDespesas(dados);
      // console.log("dados.prestacoesDeContas[0].id: ", dados.prestacoesDeContas[0].id);
      
      // const verbaTc = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaTc ? parseFloat(dados.prestacoesDeContas[0].verbaTc) : 0;
      // const verbaPropria = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaPropria ? parseFloat(dados.prestacoesDeContas[0].verbaPropria) : 0;
      // const rendimentos = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].rendimentos ? parseFloat(dados.prestacoesDeContas[0].rendimentos) : 0;

      // const despesasProvisorias = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].despesasProvisorias ? parseFloat(dados.prestacoesDeContas[0].despesasProvisorias): 0;
      // const devolucao = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].devolucao ? parseFloat(dados.prestacoesDeContas[0].devolucao): 0;

      // const totalCreditos =  saldoAnterior + verbaTc + verbaPropria + rendimentos;
      // const totalDebitos =  despesasProvisorias + devolucao;
      // console.log('dados2: ', dados);
      setInitialValues(response.data);



      
      // setListValues(response.data);
      // setNewListValues(response.data);
    })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
    })
  }

  useEffect(() => {
    getData();
  }, []);

  const updateData = () => {
    getData();
  }

  //@ts-ignore
  const handleInsertItem = (itemNotasFiscaisRecibos) => {
    // console.log("itemNotasFiscaisRecibos5555: ", itemNotasFiscaisRecibos);
    const newRows = [];
    const newItem = {
      "ordem": itemNotasFiscaisRecibos.ordem,
      "id": uuidv4(),
      "quantidadeComercial": itemNotasFiscaisRecibos.quantidadeComercial,
      "descricao": itemNotasFiscaisRecibos.descricao.toUpperCase(),
      "valorUnitario": parseFloat(itemNotasFiscaisRecibos.valorUnitario).toLocaleString('pt-BR',{ minimumFractionDigits: 2 }),
      "valorTotal": itemNotasFiscaisRecibos.valorTotal,
      "desconto": itemNotasFiscaisRecibos.desconto,
      "unidadesDeMedidasId": itemNotasFiscaisRecibos.unidadesDeMedidasId.id,
      "unidadesDeMedidasDescricao": itemNotasFiscaisRecibos.unidadesDeMedidasId.descricao,
      "unidadesDeMedidasAbreviacao": itemNotasFiscaisRecibos.unidadesDeMedidasId.abreviacao,
      // "origemDosRecursosId": itemNotasFiscaisRecibos.origemDosRecursosId,
      // "origemDosRecursosDescricao": itemNotasFiscaisRecibos.origemDosRecursosId.descricao,
      // "origemDosRecursosAbreviacao": itemNotasFiscaisRecibos.origemDosRecursosId.abreviacao,
      "tiposRecursosId": itemNotasFiscaisRecibos.tiposRecursosId.id,
      "tiposRecursosDescricao": itemNotasFiscaisRecibos.tiposRecursosId.label,
      "tiposRecursosAbreviacao": itemNotasFiscaisRecibos.tiposRecursosId.abreviacao,
      "comprovantesDeDespesasId": itemNotasFiscaisRecibos.comprovantesDeDespesasId,
      "new": null
    };

    let newOrder = 1;
    rows.forEach(item => {
      //@ts-ignore
      item.ordem = newOrder;
      //@ts-ignore
      item.new = false;
      newRows.push(item);
      newOrder++;
    })
    newItem.ordem = newOrder;
    //@ts-ignore
    newItem.new = true;
    newRows.push(newItem);
    
    //@ts-ignore
    setRows(newRows);
    // console.log('rows aqui: ', newRows)
    // setRows(newItem);
  }

  //@ts-ignore
  const handleDeleteItem = (selecteds) => {
    // alert('Delete Item Aqui!!!');

    // console.log('selecteds: ', selecteds);
    // console.log('rows: ', rows);

    const newRows = rows.filter(
      //@ts-ignore
      item => !selecteds.includes(item.id)
    );

    setRows(newRows);
  }

  //@ts-ignore
  const handleChangeOrdem = (data, value) => {
    //@ts-ignore
    const newRows = [];

    rows.forEach(item => {
      //@ts-ignore
      if (item.id === data.id) {
        // selecteds.push(item.id);
        //@ts-ignore
        item.ordem = value;
      }
      // item.new = false;
      // item.quantidadeComercial = 99;
      newRows.push(item);
    })
    //@ts-ignore
    setRows(newRows);
  }

  const handleOrder = () => {
    //@ts-ignore
    const newRows = [];
    // @ts-ignore
    const newR = rows.sort((a,b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
    let i = 1;
    newR.forEach(item => {
      //@ts-ignore
      item.ordem = i;
      newRows.push(item);
      i++;
    })
    //@ts-ignore
    setRows(newRows);
  }

  React.useEffect(() => {
    const values = api.get('status')
     values.then((response) => {
       setListStatusValues(response.data);
     })
     .catch((err) => {
       console.error("ops! ocorreu um erro" + err);
     })
   }, []);

  //  const getObservacoesConferenciaData = (prestacaoDeContasId) => {
  //   const values = api.get(`observacoes-conferencias-prestacoes-de-contas/por-prestacao-de-contas/${prestacaoDeContasId}`)
  //   values.then((response) => {
  //     return response.data;
  //   })
  //   .catch((err) => {
  //     console.error("ops! ocorreu um erro" + err);
  //   })
  //  }
  

  return (initialValues ?
    <Box sx={{ width: '98%', height: '100h', m: 2 }}>
      <SpinnerCarregando open={backdrop} />

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
        <Alert
          // onClose={() => {handleClose}}
          //@ts-ignore
          severity={severity}
          sx={{ width: '100%' }}>
            {message}
        </Alert>
      </Snackbar>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 4 }}>
         {/* <pre>{JSON.stringify(dados.prestacoesDeContasDevolucao, null, 2)}</pre> */}
        <Grid item xs={7}>
          <Box>
            <Typography component="div">
              <Box
                sx={{ fontWeight: 'light', fontSize: 17, color: '#A9A9A9', pt: 2 }}>
                  {dados.caixasEscolaresNome}
              </Box>
              <Box
                sx={{ fontWeight: 'light', fontSize: 14, color: '#A9A9A9'}}>
                  {dados.caixasEscolaresUnidadesEscolaresNome} - {dados.termosTiposDescricao} ({dados.numerosParcelasDescricao}) - {dados.tiposTermosDescricao} - ANO: {dados.termosDeCooperacaoAnosAno}
              </Box>
            </Typography> 
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            component="div"
            className={`${classes.bottomRightBox} ${classes.box}`}>

            {/* <div style={{ paddingRight: 20 }}>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: 400 }}
              >
                <IconButton sx={{ p: '6px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  size="small"
                  placeholder="Pesquisar ..."
                  inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                  onChange={handleChange}
                  value={searchField}
                />
                { searchField.length > 0 ? 
                  <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                    <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                  : null }
              </Paper>
            </div> */}
            {/* <div style={{ paddingRight: 20 }}>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: 100 }}
              >
                <TextField
                  // className={classes.input}
                  size="small"
                  id="total"
                  color="primary"
                  label="Total"
                  variant="outlined"
                  value={totalValues ? String(totalValues).padStart(2, '0') : '00'}
                  inputProps={{ style: { textAlign: "center" } }}
                />
              </Paper>
            </div> */}
            <div style={{ paddingRight: 20 }}>
              <Button  onClick={() => {history.push(`/termos-de-cooperacao-list`)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                Voltar
              </Button>
            </div>
            <div>
              <Button onClick={updateTcData} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                Gravar
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 4 }}
        >
          <Box sx={{ pb: 1 }}>
            <Autocomplete
              id="controlled-caixa-escolar"
              value={dados.caixasEscolaresStatusCaixasEscolaresId}
              options={listStatusValues}
              //@ts-ignore
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size="small"
              onChange={(event, newValue) => {
                // setInitialValues({...initialValues, "caixasEscolaresStatusCaixasEscolaresId": newValue});
                // setInitialValues({...dados, "caixasEscolaresStatusCaixasEscolaresId": newValue});
                // updateStatusCaixasEscolares(newValue);
                checkPendencias(newValue);
                // console.log('newValue: ', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Status"
                  required
                  fullWidth
                  //@ts-ignore
                  required
                  style={{ width: 300 }}
                />
              )}
            />
          </Box>

          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Saldo Anteriorxx"
              size="small"
              variant="outlined"
              value={dados.prestacoesDeContasSaldoAnterior}
              outputFormat="number"
              // minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={dados.prestacoesDeContasSaldoAnterior}
              //@ts-ignore
              onChange={(event, newValue) => {
                setDados({
                  ...dados, 'prestacoesDeContasSaldoAnterior': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Termo de Compromisso"
              size="small"
              variant="outlined"
              value={dados.prestacoesDeContasVerbaTc}
              outputFormat="number"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={dados.prestacoesDeContasVerbaTc}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'prestacoesDeContasVerbaTc': newValue})
                setDados({...dados, 'prestacoesDeContasVerbaTc': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Recursos Próprios"
              size="small"
              variant="outlined"
              value={dados.prestacoesDeContasVerbaPropria}
              outputFormat="number"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={dados.prestacoesDeContasVerbaPropria}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'prestacoesDeContasVerbaPropria': newValue})
                setDados({...dados, 'prestacoesDeContasVerbaPropria': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>

          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Rendimentos"
              size="small"
              variant="outlined"
              value={dados.prestacoesDeContasRendimentos}
              outputFormat="number"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={dados.prestacoesDeContasRendimentos}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'prestacoesDeContasRendimentos': newValue})
                setDados({...dados, 'prestacoesDeContasRendimentos': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Total"
              size="small"
              variant="outlined"
              //@ts-ignore
              value={dados.totalCreditos}
              outputFormat="number"
              // minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              //@ts-ignore
              defaultValue={dados.totalCreditos}
              readonly
              inputProps={{ readOnly: true }}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'totalCreditos': newValue})
                //@ts-ignore
                setDados({...dados, 'totalCreditos': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Despesas"
              size="small"
              variant="outlined"
              value={dados.prestacoesDeContasDespesasProvisorias}
              outputFormat="number"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={dados.prestacoesDeContasDespesasProvisorias}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'prestacoesDeContasDespesasProvisorias': newValue})
                setDados({...dados, 'prestacoesDeContasDespesasProvisorias': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Devolução"
              size="small"
              variant="outlined"
              value={dados.prestacoesDeContasDevolucao}
              outputFormat="number"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={dados.prestacoesDeContasDevolucao}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'prestacoesDeContasDevolucao': newValue})
                setDados({...dados, 'prestacoesDeContasDevolucao': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Saldo Final"
              size="small"
              variant="outlined"
              //@ts-ignore
              value={dados.saldoFinal}
              outputFormat="number"
              // minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              // defaultValue={dados.saldo}
              inputProps={{ readOnly: true }}
              //@ts-ignore
              onChange={(event, newValue) => {
                // setInitialValues({...initialValues, 'saldo': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <ComprovantesDeDespesasListProvisorios data={dados} getData={getData} />
        </Grid>
         <Grid item xs={12}>
          {/* <ConferenciaTable 
            conferenciasPrestacoesDeContasId={dados && dados.conferenciasPrestacoesDeContas && dados.conferenciasPrestacoesDeContas[0].id ? dados.conferenciasPrestacoesDeContas[0].id : null} 
            observacoesConferencia={dados.conferenciasPrestacoesDeContas[0].observacoesConferenciasPrestacoesDeContas} updateData={updateData} /> */}
          <ConferenciaTable 
            conferenciasPrestacoesDeContasId={dados.conferenciasPrestacoesDeContasId} 
            observacoesConferencia={dados.conferenciasPrestacoesDeContasObservacoes} 
            updateData={updateData} />
        </Grid>
      </Grid>
    </Box>
    : <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando ...</span></Typography>
  );
}
