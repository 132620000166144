import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select } from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import InputMask from 'react-input-mask';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando'
import Backdrop from '@mui/material/Backdrop';
import TelefonesTable from '../../../components/TelefonesTable';
import api from "../../../services/api";
import FornecedoresCredoresSocios from "../../../components/FornecedoresCredoresSocios";
import { validarCNPJ, replaceAll } from "../../../utils/functions";

import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function DataFunction () {
  const query = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [initialValues, setInitialValues] = React.useState({});
  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const [bairroDistrito, setBairroDistrito] = useState('');
  const [cep, setCep] = React.useState("");
  const [cnpj, setCnpj] = React.useState(null);
  const [cidadesId, setCidadesId] = React.useState(null);
  const [cidadesList, setCidadesList] = React.useState([]);
  const [endereco, setEndereco] = React.useState("");
  const [estadosId, setEstadosId] = React.useState(null);
  const [estadosList, setEstadosList] = React.useState([]);
  const [fornecedoresCredoresSituacaoId, setFornecedoresCredoresSituacaoId] = React.useState(null);
  const [fornecedoresCredoresSituacaoList, setFornecedoresCredoresSituacaoList] = React.useState([]);
  const [numero, setNumero] = React.useState("");
  const [complemento, setComplemento] = React.useState("");
  const [nomeFantasia, setNomeFantasia] = React.useState("");
  const [nomeRazaoSocial, setNomeRazaoSocial] = React.useState("");
  const [inscricaoMunicipal, setInscricaoMunicipal] = React.useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [site, setSite] = React.useState("");
  const [socios, setSocios] = React.useState([]);
  const [telefones, setTelefones] = React.useState([]);
  const [errors, setErrors] = React.useState([]);

  useEffect(() => {
    // @ts-ignore
    if (query.id) {
      // @ts-ignore
      const values = api.get(`fornecedores-credores/${query.id}`)
      values.then((response) => {
        setInitialValues(response.data);
        setCep(response.data.cep);
        setCnpj(response.data.cnpj);
        setBairroDistrito(response.data.bairroDistrito);
        setEndereco(response.data.endereco);
        setNumero(response.data.numero);
        setComplemento(response.data.complemento);
        setNomeRazaoSocial(response.data.nomeRazaoSocial);
        setNomeFantasia(response.data.nomeFantasia);
        setInscricaoMunicipal(response.data.inscricaoMunicipal);
        setInscricaoEstadual(response.data.inscricaoEstadual);
        setEmail(response.data.email);
        setSite(response.data.site);
        setSocios(response.data.socios);
        setTelefones(response.data.telefones);

        const situacao = {
          id: response.data.situacaoId,
          label: response.data.situacaoDescricao,
          value: response.data.situacaoId
        }
        setFornecedoresCredoresSituacaoId(situacao);
        setInitialValues({...initialValues, 'fornecedoresCredoresSituacaoId': response.data.situacaoId});

        const estados = {
          codigo: response.data.estadosCodigo,
          id: response.data.estadosId,
          label: response.data.estadosNome,
          value: response.data.estadosId,
        }
        setEstadosId(estados);
        setInitialValues({...initialValues, 'estadosId': response.data.estadosId});





      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    } else {
      setNewRegister();
    }
  }, []);

  useEffect(() => {
    getCidadesPorEstado();
  }, [estadosId]);

  useEffect(() => {
      // @ts-ignore
      const values = api.get(`estados`)
      values.then((response) => {
        setEstadosList(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
  }, []);

const getCidadesPorEstado = () => {
  setCidadesList([]);
  if (estadosId && estadosId.id) {
    const values = api.get(`cidades/por-estado/${estadosId.id}`)
    .then((response) => {
      setCidadesList(response.data);
      // console.log('response.data cidades list: ', response.data);
      // setCidadesId(cidadesList.find(item => item.value === response.data[0].ibge));
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }
}

  useEffect(() => {
    setNewRegister();
    if (query.id) {
      // @ts-ignore
      const values = api.get(`fornecedores-credores/${query.id}`)
      .then((response) => {

        let newResponse = response.data;
        setInitialValues(newResponse);

        const object = {
          codigo: newResponse.cidadesCodigo,
          estadosId: newResponse.estadosId,
          id: newResponse.cidadesId,
          label: newResponse.cidadesNome,
          value: newResponse.cidadesId
        }

        setInitialValues({...newResponse, 'cidadesId': object});
        setCidadesId(object);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  const getCidadeId = (ibge) => {
    const values = api.get(`cidades/por-cidade-codigo/${ibge}`)
    .then((response) => {
      getEstadoId(response.data[0]);
      // console.log('response: ', response.data[0]);
    })
  }

  const getEstadoId = (data) => {
    const values = api.get(`estados/estado-por-codigo/${data.estadosId}`)
    .then((response) => {
      // console.log('data: ', data);
      // console.log('responseaaaaaa: ', response.data[0]);
      // console.log('cidades: ', data[0]);
      setCidadesId(data);
      setEstadosId(response.data[0])
    })
  }
 
  useEffect(() => {
    // @ts-ignore
    const values = api.get(`fornecedores-credores-situacao`)
    .then((response) => {
      setFornecedoresCredoresSituacaoList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  // useEffect(() => {
  //   setCidadesId(cidadesList.find(item => item.value === initialValues.cidadesId));
  // }, [cidadesList]);

  useEffect(() => {
    setEstadosId(estadosList.find(item => item.value === initialValues.estadosId));
  }, [estadosList]);

  const handleClickAlert = () => {
    setOpen(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

   const createNewRegister = () => {
    history.push('/fornecedores-credores/new');
    setNewRegister();
  }

  const getEndereco = () => {
    if (cep && (cep.replace(/[^\d]+/g,'')).length === 8) {
      setBairroDistrito('');
    //   // setRegiaoId(null);
      setEndereco('');
      fetch(`https:/viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((data) => {
          // console.log('data: ', data)
          if (data && data.logradouro) {
            setEndereco(data.logradouro.toUpperCase());
            setBairroDistrito(data.bairro.toUpperCase());
          }
          if (data && data.ibge) {
            getCidadeId(data.ibge);
          }
        })
    }
  }

  const getSocios = (socios) => {
    if (socios) {
      socios.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
    }
    setSocios(socios);
  }

  const getNumerosTelefones = (telefones) => {
    let retorno = replaceAll(telefones, ' ', '')
    retorno = replaceAll(retorno, '-', '')
    retorno = retorno.split('/');
    const array = [];
    retorno.map(item => (
      array.push({
        'ddd': item.substr(1,2),
        'numero': item.substr(4),
      })
    ))
    setTelefones(array);
  }

  const testCNPJ = (cnpj) => {
    return (cnpj && cnpj.replace(/\D/g, '').length === 14 && validarCNPJ(cnpj));
  }

  const checkErrors = () => {
    const dado = [];
    if (!bairroDistrito || !bairroDistrito.length > 5) {
      dado.push('bairroDistrito');
    } 
    if (!cep || cep.replace(/\D/g, '').length !== 8) {
      dado.push('cep')
    }
    if (!cidadesId || !cidadesId.id) {
      dado.push('cidadesId')
    }
    if (!estadosId || !estadosId.id) {
      dado.push('estadosId')
    }
    if (!cnpj || !validarCNPJ(cnpj)) {
      dado.push('cnpj')
    }
    if (!endereco || endereco.length <= 4) {
      dado.push('endereco');
    } 
    if (!numero || numero.length < 1) {
      dado.push('numero');
    }
    if (!nomeRazaoSocial || nomeRazaoSocial.length <= 9) {
      dado.push('nomeRazaoSocial');
    }
    if (!fornecedoresCredoresSituacaoId || !fornecedoresCredoresSituacaoId.id) {
      dado.push('fornecedoresCredoresSituacaoId');
    }
    // if (!nomeFantasia || nomeFantasia.length <= 9) {
    //   dado.push('nomeFantasia');
    // }
    // if (!inscricaoMunicipal || inscricaoMunicipal.length <= 4) {
    //   dado.push('inscricaoMunicipal');
    // }
    // if (!inscricaoEstadual || inscricaoEstadual.length <= 4) {
    //   dado.push('inscricaoEstadual');
    // }
    const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!email || !reg.test(email)) {
      dado.push('email');
    } 
    // const regUrl = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    // if (!site || !regUrl.test(site)) {
    //   dado.push('site');
    // } 
    setErrors(dado);

    if (dado.length === 0) {
      saveUpdateData();
    }
  }

  const goList = () => {
    history.push('/fornecedores-credores-list');
  }

  const saveUpdateData = () => {
    const data = initialValues;
    const payload = {
      'bairroDistrito': bairroDistrito.toUpperCase(),
      'cep': cep.replace(/\D/g, ''),
      'cidadesId': cidadesId && cidadesId.id ? cidadesId.id : null,
      'cnpj': cnpj ? cnpj.replace(/\D/g, '') : '',
      'complemento': complemento.toUpperCase(),
      'email': email.toLowerCase(),
      'endereco': endereco.toUpperCase(),
      'estadosId': estadosId.id,
      'id': query.id ? query.id : null,
      'inscricaoEstadual': inscricaoEstadual,
      'inscricaoMunicipal': inscricaoMunicipal,
      'nomeFantasia': nomeFantasia ? nomeFantasia.toUpperCase() : '',
      'nomeRazaoSocial': nomeRazaoSocial.toUpperCase(),
      'numero': numero.toUpperCase(),
      'site': site,
      'fornecedoresCredoresSituacaoId': fornecedoresCredoresSituacaoId && fornecedoresCredoresSituacaoId.id ? fornecedoresCredoresSituacaoId.id : null,
      'socios': socios
    }

    if (!query.id) {
      delete payload.id;
    }

    // @ts-ignore
    const values = query.id ? api.put(`fornecedores-credores/${data.id}`, payload) : api.post(`fornecedores-credores`, payload)
    values.then((response) => {
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const setNewRegister = () => {
    const newValues = {
      "bairroDistrito": "",
      "cep": "",
      "cidadesCodigo": "",
      "cidadesId": null,
      "cidadesNome": "",
      "cnpj": "",
      "complemento": "",
      "email": "",
      "endereco": "",
      "enderecoCompleto": "",
      "estadosAbreviacao": "",
      "estadosCodigo": "",
      "estadosId": "",
      "estadosNome": "",
      "id": "",
      "inscricaoEstadual": "",
      "inscricaoMunicipal": "",
      "nomeFantasia": "",
      "nomeRazaoSocial": "",
      "numero": "",
      "pais": "",
      "site": "",
      "socios": [],
      "telefonesNumeros": [],
      "vendedores": [],
      "vendedoresNomes": "",
      "fornecedoresCredoresSituacaoId": null,
      "situacaoDescricao": ''
    }
    setBairroDistrito("");
    setCep("");
    setCidadesId(null);
    setCnpj("");
    setComplemento("");
    setEmail("");
    setEndereco("");
    setEstadosId(null);
    // 'id': data.id,
    setInscricaoEstadual("");
    setInscricaoMunicipal("");
    setNomeFantasia("");
    setNomeRazaoSocial("");
    setNumero("");
    setSite("");
    setFornecedoresCredoresSituacaoId(null);
    // setSocios([]);

    setInitialValues(newValues);
  }

  return (
    <Container maxWidth="xl">
      {/* <pre>{JSON.stringify(cidadesList, null, 2)}</pre> */}
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
          <SpinnerCarregando open={backdrop} />
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
              <>
                <Grid container spacing={2 }  sx={{ mt: 3 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ ml: 2 }}
                  >
                    <Box>
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Fornecedor/Credor</Box>
                      </Typography>
                    </Box>
                    <Box
                      component="div"
                      className={`${classes.bottomRightBox} ${classes.box}`}>
                        <div>
                          <Button onClick={goList} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20}}>
                            Cancelar
                          </Button>
                        </div>

                        <div>
                          <Button type="reset" onClick={createNewRegister} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20}} disabled={false}>
                            Novo
                          </Button>
                        </div>

                        <div>
                          <Button type="submit" onClick={checkErrors} variant="outlined" color="primary" size="small" style={{ height: 35 }} disabled={false}>
                            Gravar
                          </Button>
                        </div>
                    </Box>
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <InputMask
                      mask="99.999.999/9999-99"
                      value={cnpj}
                      onChange={e => {
                          setCnpj(e.target.value)
                          setInitialValues({...initialValues, 'cnpj': e.target.value})
                        }
                      }
                      onBlur={cnpj}
                    >
                      {(inputProps: Props): ReactElement => (
                        <TextField
                          {...inputProps}
                          type="tel"
                          required
                          focused
                          fullWidth
                          size="small"
                          label={("CNPJ")}
                          error={errors.includes('cnpj')}
                          helperText={errors.includes('cnpj') ? 'Campo incorreto!' : ''}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextField
                    size="small"
                    value={initialValues.situacaoDescricao}
                    disabled={!testCNPJ(cnpj)}
                    required
                    type="tel"
                    focused
                    fullWidth
                    label={("Situação")}
                    inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                    error={errors.includes('situacao')}
                    helperText={errors.includes('situacao') ? 'Campo obrigatório!' : ''}
                  />
                </Grid>

                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <TextField
                      size="small"
                      value={nomeRazaoSocial}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      label={("Razão Social")}
                      inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                      onChange={e => {
                          setNomeRazaoSocial(e.target.value)
                          setInitialValues({...initialValues, 'nomeRazaoSocial': e.target.value})
                        }
                      }
                      error={errors.includes('nomeRazaoSocial')}
                      helperText={errors.includes('nomeRazaoSocial') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      size="small"
                      value={nomeFantasia}
                      disabled={!testCNPJ(cnpj)}
                      type="tel"
                      focused
                      fullWidth
                      label={("Nome Fantasia")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={e => {
                          setNomeFantasia(e.target.value)
                          setInitialValues({...initialValues, 'nomeFantasia': e.target.value})
                        }
                      }
                      error={errors.includes('nomeFantasia')}
                      helperText={errors.includes('nomeFantasia') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      size="small"
                      value={inscricaoMunicipal}
                      disabled={!testCNPJ(cnpj)}
                      type="tel"
                      focused
                      fullWidth
                      label={("Inscrição Municipal")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={e => {
                          setInscricaoMunicipal(e.target.value)
                          setInitialValues({...initialValues, 'inscricaoMunicipal': e.target.value})
                        }
                      }
                      error={errors.includes('inscricaoMunicipal')}
                      helperText={errors.includes('inscricaoMunicipal') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      size="small"
                      value={inscricaoEstadual}
                      disabled={!testCNPJ(cnpj)}
                      type="tel"
                      focused
                      fullWidth
                      label={("Inscrição Estadual")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={e => {
                          setInscricaoEstadual(e.target.value)
                          setInitialValues({...initialValues, 'inscricaoEstadual': e.target.value})
                        }
                      }
                      error={errors.includes('inscricaoEstadual')}
                      helperText={errors.includes('inscricaoEstadual') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Stack direction="row" spacing={1}>
                      <InputMask
                      mask="99999-999"
                      value={cep}
                      disabled={!testCNPJ(cnpj)}
                      onChange={e => {
                          setCep (e.target.value)
                        }
                      }
                      onBlur={cep}
                    >
                      {(inputProps: Props): ReactElement => (
                        <TextField
                          {...inputProps}
                          type="tel"
                          focused
                          required
                          fullWidth
                          size="small"
                          label={("CEP")}
                          error={errors.includes('cep')}
                          helperText={errors.includes('cep') ? 'Campo incorreto!' : ''}
                        />
                      )}
                      </InputMask>
                        <div style={{ paddingTop: 8, cursor: 'pointer' }}>
                          <SearchIcon onClick={getEndereco} />
                        </div>
                    </Stack>
                  </Grid>

                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                    <TextField
                      size="small"
                      value={endereco}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      label={("Endereco")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={e => {
                          setEndereco(e.target.value)
                          setInitialValues({...initialValues, 'endereco': e.target.value})
                        }
                      }
                      error={errors.includes('endereco')}
                      helperText={errors.includes('endereco') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <TextField
                      size="small"
                      value={numero}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      label={("Número")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={e => {
                          setNumero(e.target.value)
                          setInitialValues({...initialValues, 'numero': e.target.value})
                        }
                      }
                      error={errors.includes('numero')}
                      helperText={errors.includes('numero') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                    <TextField
                      size="small"
                      value={complemento}
                      type="tel"
                      disabled={!testCNPJ(cnpj)}
                      focused
                      fullWidth
                      label={("Complemento")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={e => {
                          setComplemento(e.target.value)
                          setInitialValues({...initialValues, 'complemento': e.target.value})
                        }
                      }
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <TextField
                      size="small"
                      value={bairroDistrito}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      label={("Bairro")}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      onChange={e => {
                        setBairroDistrito(e.target.value)
                        setInitialValues({...initialValues, 'bairroDistrito': e.target.value})
                      }}
                      error={errors.includes('bairroDistrito')}
                      helperText={errors.includes('bairroDistrito') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Autocomplete
                      id="controlled-caixa-escolar"
                      value={estadosId}
                      options={estadosList}
                      disabled={!testCNPJ(cnpj)}
                      onChange={(event, newValue) => {
                        // @ts-ignore
                        initialValues.estadosId = null;
                        initialValues.cidadesId = null;
                        // @ts-ignore
                        setCidadesId(null);
                        setEstadosId(null);
                        setInitialValues({...initialValues, 'estadosId': null})
                        if (newValue) {
                          // @ts-ignore
                          setEstadosId(newValue)
                          // @ ts-ignore
                          initialValues.estadosId = newValue.id;
                          setInitialValues({...initialValues, 'estadosId': newValue.id})
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estado"
                          variant="outlined"
                          size="small"
                          fullWidth
                          focused
                          disabled={!testCNPJ(cnpj)}
                          required
                          error={errors.includes('estadosId')}
                          helperText={errors.includes('estadosId') ? 'Campo obrigatório!' : ''}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Autocomplete
                      id="controlled-caixa-escolar"
                      value={cidadesId}
                      options={cidadesList}
                      disabled={!estadosId}
                      onChange={(event, newValue) => {
                        // @ts-ignore
                        initialValues.cidadesId = null;
                        setCidadesId(null);
                        if (newValue) {
                          setCidadesId(newValue)
                          // @ ts-ignore
                          setInitialValues({...initialValues, 'cidadesId': newValue.id})
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cidade"
                          value={cidadesId}
                          variant="outlined"
                          size="small"
                          fullWidth
                          focused
                          disabled={!testCNPJ(cnpj)}
                          required
                          error={errors.includes('cidadesId')}
                          helperText={errors.includes('cidadesId') ? 'Campo obrigatório!' : ''}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      label="E-mail"
                      value={email}
                      variant="outlined"
                      size="small"
                      fullWidth
                      focused
                      disabled={!testCNPJ(cnpj)}
                      required
                      placeholder="email@empresa.com.br"
                      inputProps={{ style: { textTransform: "lowercase" } }}
                      onChange={e => {
                        setEmail(e.target.value)
                        setInitialValues({...initialValues, 'email': e.target.value})
                      }}
                      error={errors.includes('email')}
                      helperText={errors.includes('email') ? 'Campo obrigatório incorreto!' : ''}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <TextField
                      label="Site"
                      value={site}
                      variant="outlined"
                      size="small"
                      fullWidth
                      focused
                      disabled={!testCNPJ(cnpj)}
                      placeholder="http://www.exemplo.com.br"
                      inputProps={{ style: { textTransform: "lowercase" } }}
                      onChange={e => {
                        setSite(e.target.value)
                        setInitialValues({...initialValues, 'site': e.target.value})
                      }}
                      error={errors.includes('site')}
                      helperText={errors.includes('site') ? 'Campo incorreto!' : ''}
                    />
                  </Grid>
                </Grid>
              </>
          <FornecedoresCredoresSocios socios={socios ? socios : []} />
          <TelefonesTable  telefones={telefones ? telefones : []} />
          
        </Paper>
      </Typography>
    </Container>
  );
}
