import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { FormControl } from '@mui/material';

import api from '../../../services/api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
  const [listValues, setListValues] = React.useState([
    {id: "ef997f88-81f6-11ec-b847-00155d6335ac", label: "2022", value: "ef997f88-81f6-11ec-b847-00155d6335ac"},
    {id: "809ca722-7541-4899-921d-7d183a0fbe97", label: "2021", value: "809ca722-7541-4899-921d-7d183a0fbe97"},
    {id: "ce60709e-5cde-419f-9e88-494895cd92a1", label: "2020", value: "ce60709e-5cde-419f-9e88-494895cd92a1"},
    {id: "368ce0ba-1593-11ec-a907-00155dedf0a9", label: "2019", value: "368ce0ba-1593-11ec-a907-00155dedf0a9"}
  ]);
  const [newClearValues, setNewClearValues] = React.useState(null);

  React.useEffect(() => {
   const values = api.get(props.apiConst)
    values.then((response) => {
    //   setListValues(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  React.useEffect(() => {
    if (props.clearAll) {
      clearValues();
    }
  }, [props.clearAll]);

  const clearValues = () => {
    setNewClearValues(1);

    const clear = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
    if (clear) {
      clear.click();
    }
  }

  React.useEffect(() => {
    // Take the Reference of Close Button
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
      
    // Add a Click Event Listener to the button

    if (close) {
      close.addEventListener("click", () => {
        console.log("Add your Own Functionality Here...");
      });
    }
  });

  // const [newValue, setNewValue] = React.useState([]);
  // const handleChange = async (value) => {
  //   console.log('value: ', value)
  //   await setNewValue(value);
  //  };

  const handleDataChange = (event, value) => {
      const arrayValues = [];
      value.map(element => {
        const list = listValues.filter(item => item.label === element);
        arrayValues.push(list[0]);
      });
    props.onSelectData(arrayValues);
    setNewClearValues(null);
  };

  return (
    <FormControl variant={props.variant} sx={{ minWidth: '100%' }}>
    {/*<pre>{JSON.stringify(listValues, null, 2)}</pre>*/}
    <Autocomplete
      size="small"
      multiple
      limitTags={2}
      id="tags-filled"
      options={listValues.map((option) => option.label)}
      // defaultValue={[listValues[0].label]}
      freeSolo
      onChange={(event, value) => handleDataChange(event, value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" color="primary" size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant={props.variant}
          label="Anos"
        />
      )}
    />

    </FormControl>

    )
}


