import { Box } from "@mui/system";
import React from "react";

import Regional from "../Regional";
import StatusCaixasEscolares from "../StatusCaixasEscolares";
import TiposEnsino from "../TiposEnsino";
import SelectComponent from "../Controls/SelectComponent";
import AutoCompleteSelectComponent from "../Controls/AutoCompleteSelectComponent";

import api from '../../services/api';

export default function SearchBar(props) {
  const [stateValueRegioes, setStateValueRegioes] = React.useState([]);
  const [stateValueBairros, setStateValueBairros] = React.useState([]);
  const [stateValueTipos, setStateValueTipos] = React.useState([]);
  const [stateValueTiposEnsinos, setStateValueTiposEnsinos] = React.useState([]);
  const [stateValueSituacoesMandato, setStateValueSituacoesMandato ] = React.useState([]);
  const [stateSearchValues, setStateSearchValues] = React.useState('');

  const handleDataRegioes = (value) => {
    const regioes = [];
    value.map(
      (item) => {
        regioes.push(item.id)
      }
   )
   setStateValueRegioes(regioes)
  }

  const handleDataBairros = (value) => {
    const bairros = [];
    value.map(
      (item) => {
        bairros.push(item.id)
      }
   )
   setStateValueBairros(bairros)
  }

  const handleDataTipos = (value) => {
    console.log('value: ', value);
    const tipos = [];
    value.map(
      (item) => {
        tipos.push(item.id)
      }
   )
   setStateValueTipos(tipos);
  }

  const handleDataTiposEnsinos = (value) => {
    const tiposEnsinos = [];
    value.map(
      (item) => {
        tiposEnsinos.push(item.id)
      }
   )
   setStateValueTiposEnsinos(tiposEnsinos);
  }

  const handleSituacaoMandato = (value) => {
    const situacaoesMandato = [];
    value.map(
      (item) => {
        situacaoesMandato.push(item.id)
      }
   )
   setStateValueSituacoesMandato(situacaoesMandato);
  }

  React.useEffect(() => {
    const data = [{'regioes': stateValueRegioes}, {'bairros': stateValueBairros}, {'tiposEnsinos': stateValueTiposEnsinos}, {'situacaoesMandato': stateValueSituacoesMandato}, {'tipos': stateValueTipos}]
    props.onSelectData(data);
  }, [stateValueRegioes, stateValueBairros, stateValueTiposEnsinos, stateValueSituacoesMandato, stateValueTipos]);

  return (
      <div style={{ width: '100%' }}>
          {/* <Box
              sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap' ,
              alignItems: 'center',
              bgcolor: 'background.paper',
          }}> */}
              <Box
                  sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap' ,
                  alignItems: 'center',
                  // flexGrow: 1,
                  flex: '100%',

                  direction: "row",
                  justifyContent:"space-between",
                  alignItems: "center",

                  pt: 0,
                  pb: 0,
                  // p: 1,
                  // m: 1,
                  bgcolor: 'background.paper',
              }}>
                  <Box sx={{ pr: 1, width: "25%" }}>
                      <AutoCompleteSelectComponent nLabel="Regionais" newWidth="450" variant="outlined" apiConst="regionais" onSelectData={handleDataRegioes} />
                  </Box>
                  <Box sx={{ pr: 1, width: "25%" }}>
                      <AutoCompleteSelectComponent nLabel="Bairros" newWidth="450" variant="outlined" apiConst="bairros" onSelectData={handleDataBairros} />
                  </Box>
                  <Box sx={{ pr: 1, width: "25%" }}>
                      <AutoCompleteSelectComponent nLabel="Tipos" newWidth="450" variant="outlined" apiConst="tipos-unidades-escolares" onSelectData={handleDataTipos} />
                  </Box>
                  {/* <Box sx={{ pr: 1, width: "25%" }}>
                      <AutoCompleteSelectComponent nLabel="Tipos Ensinos" newWidth="450" variant="outlined" apiConst="tipos-ensinos" onSelectData={handleDataTiposEnsinos} />
                  </Box> */}
                  <Box sx={{ pr: 0, width: "25%" }}>
                  <AutoCompleteSelectComponent nLabel="Situação Mandato" newWidth="450" variant="outlined" apiConst="tipos-de-mandatos" onSelectData={handleSituacaoMandato} />
              </Box>
              </Box> 

          {/* </Box> */}
      </div>
  )
}