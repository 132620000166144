import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import DeleteDialog from '../../components/DeleteDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SpinnerCarregando from '../SpinnerCarregando'

import api from '../../services/api';

const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'documentosTiposDescricao',
    align: 'left',
    label: 'Documento',
  },
  {
    id: 'initialDate',
    align: 'center',
    label: 'Data Inicial do Mandatao',
  },
  {
    id: 'finalDate',
    align: 'center',
    label: 'Data Final do Mandato',
  },
  {
    id: 'dataRegistroCartorio',
    align: 'center',
    label: 'Data do Registro no Cartório',
  },
  {
    id: 'visualizar',
    align: 'center',
    label: 'Visualizar',
  },
  {
    id: 'excluir',
    align: 'center',
    label: 'Excluir',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [row, setRow] = React.useState([]);

  const [backdrop, setBackdrop] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados excluídos com sucesso!');

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }
  const deleteRegister = () => {
    handleCloseDeleteDialog();
    setBackdrop (true);
    // @ts-ignore

    const values = api.delete(`estatutos-atas/por-caixa-escolar/${row.caixasEscolaresId}/${row.id}`)
    values.then((response) => {
      // setFornecedoresCredoresSituacaoList(response.data);
      getData();
      setBackdrop (false);
      setOpen(true);
      // console.log("response: ", response)
    })
    .catch((err) => {
      setSeverity('error');
      setMessage('Erro ao excluir os dados! Verifique!');
      setOpen(true);
      console.error("ops! ocorreu um erro" + err);
    })

    // handleCloseDeleteDialog();
    // props.newDeleteRegister();
    // console.log('rowwww: ', rowSelected.id)
    // // @ts-ignore
    // api.delete(`telefones-unidades-escolares/${rowSelected.id}`)
    // .then((response) => {
    //   setMessage('Dados excluídos com sucesso!');
    //   // props.updateData();
    //   setSelected([]);
    //   setBackdrop(false);
    //   handleClickAlert();
    //   getData();
    //   handleCloseDeleteDialog();
    // })
    // .catch((err) => {
    //   setMessage('Erro ao excluir os dados! Verifique!');
    //   // props.updateData();
    //   setSelected([]);
    //   setBackdrop(false);
    //   setSeverity('error');
    //   handleClickAlert();
    //   console.error("ops! ocorreu um erro" + err);
    // })
  }
  const handleClickAlert = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    setSelectedRow(event);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const showItem = (row) => {

    
    // console.log('showItem: ', row);
    setRow(row);
    // alert('showItem');

    history.push(`/estatutos-atas-list/relatorio/${row.caixasEscolaresId}/${row.id}`)
  }

  const deleteItem = (row) => {
    // console.log('deleteItem: ', row);
    setRow(row);
    setOpenDeleteDialog(true);
    // alert('deleteItem');
  }

  const getData = () => {
    setBackdrop (true);
    // @ts-ignore
    const values = api.get(`estatutos-atas/por-caixa-escolar/${props.caixasEscolaresId}`)
    values.then((response) => {
      // setFornecedoresCredoresSituacaoList(response.data);
      setRows(response.data);
      setBackdrop (false);
      // console.log("response: ", response)
    })
    .catch((err) => {
      setBackdrop (false);
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  useEffect(() => {
    getData();
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <SpinnerCarregando open={backdrop} />
        {/*<pre>{JSON.stringify(selected, null, 2)}</pre>*/}

        <DeleteDialog open={openDeleteDialog} handleCloseDeleteDialog={handleCloseDeleteDialog} deleteRegister={deleteRegister} />

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>

        <Paper sx={{ ninWidth: '100%', mb: 2 }}>
        
          <TableContainer sx={{ width: '100%' }} >
            <Table
              sx={{ width: '100%' }}
              aria-labelledby="tableTitle"
              size='small'
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  /*.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        // selected={isItemSelected}
                        key={row.id}
                      >
                        <TableCell
                          align="left"
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.documentosTiposDescricao}
                        </TableCell>
                        <TableCell align="center">{new Date(row.initialDate).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</TableCell>
                        <TableCell align="center">{new Date(row.finalDate).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</TableCell>
                        <TableCell align="center">{new Date(row.dataRegistroCartorio).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</TableCell>
                        <TableCell align="center"><DescriptionOutlinedIcon onClick={() => {showItem(row)}} color="primary" sx={{cursor: 'pointer' }} /></TableCell>
                        <TableCell align="center"><HighlightOffIcon color='warning' onClick={() => {deleteItem(row)}} sx={{cursor: 'pointer' }} /></TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/*<TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
        </Paper>
    </Box>
  );
}
