import React from "react";
import { ErrorMessage, Field } from "formik";
import TextField from "@mui/material/TextField";

import "./FormikField.css";
import { FormControl } from "@mui/material";
import { AlignHorizontalCenter } from "@mui/icons-material";

interface FormikFieldProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
}

const style = {
};
// @ts-ignore
const FormikField: React.FC<FormikFieldProps> = ({ name, label, type = "text", required = false }) => {
  return (
    <div className="FormikField">
      <FormControl fullWidth size="small">
        <Field
          autocomplete="off"
          autoComplete="off"
          required={required}
          as={TextField}
          label={label}
          name={name}
          fullWidth
          type={type}
          focused
          size="small"
          error={<ErrorMessage name={name}/>}
          inputProps={{ style: { textTransform: "uppercase" }, autoComplete: "off" }}/>
      </FormControl>
    </div>
  );
};

export default FormikField;
