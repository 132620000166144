import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import CaixaEscolarEditForm from '../CaixaEscolarEditForm';
import PresidenteCaixaEscolar from '../PresidenteCaixaEscolar';
import EditValoresTermoPrincipal from '../EditValoresTermoPrincipal';

import api from "../../../services/api";

export default function TermosDeCooperacaoEdit() {

  const query = useParams();
  const history = useHistory();

  const [data, setData] = useState([]);

  useEffect(() => {
    if (query.id) {
      getData();
    }
  }, []);

  const getData = () => {
    //@ts-ignore
    const values = api.get(`termos-de-cooperacao/${query.id}`)
    values.then((response) => {
      setData(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }

  return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>

        {/* <pre>{JSON.stringify(query.id, null, 2)}</pre> */}

        <CaixaEscolarEditForm />

        <PresidenteCaixaEscolar />

        <EditValoresTermoPrincipal />

      </Typography>
    </Container>
    </React.Fragment>
  );
}
