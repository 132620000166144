import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, Box, Typography, TextField, Autocomplete, Button } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { styled } from '@mui/material/styles';
import DeleteDialog from '../DeleteDialog';

import SpinnerCarregando from '../SpinnerCarregando'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';


import Input from '@mui/material/Input';
import api from "../../services/api";

import { makeStyles } from '@mui/styles';
import set from "date-fns/set/index";

const ariaLabel = { 'aria-label': 'description' };

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function DenseTable(props) {
  const classes = useStyles();
  const [id, setId] = React.useState(null);
  const [numero, setNumero] = React.useState('');
  const [tipo, setTipo] = React.useState(null);
  const [tiposTelefones, setTiposTelefones] = React.useState([]);
  const [rowSelected, setRowSelected] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }
  const deleteRegister = () => {
    handleCloseDeleteDialog();
    // console.log('rowwww: ', rowSelected.id)
    // @ts-ignore
    api.delete(`${props.path}/${rowSelected.id}`)
    .then((response) => {
      setMessage('Dados excluídos com sucesso!');
      // props.updateData();
      setSelected([]);
      setBackdrop(false);
      handleClickAlert();
      getData();
      handleCloseDeleteDialog();
    })
    .catch((err) => {
      setMessage('Erro ao excluir os dados! Verifique!');
      // props.updateData();
      setSelected([]);
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }
  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const values = api.get("tipos-telefones")
      .then((response) => {
        // console.log('response: ', response.data)
        setTiposTelefones(response.data.filter(
            listValues => listValues.id !== '9788141f-11ed-11ec-833c-dc41a970c8d9'
          )
        )
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
  }, []);

  const getData = () => {
    if (props.id) {
    const values = api.get(`${props.path2}/${props.id}`)
      .then((response) => {
        setRows(response.data)
        // console.log('responseAqui: ', response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }

  useEffect(() => {
    getData();
  }, [props.id]);

  const newInput = () => {
    setId(null);
    setNumero('');
    setTipo(null);
  }

  const checkErrors = () => {
    const dado = [];
    if (!numero || numero.length < 9) {
      dado.push('numero');
    }

    if (!tipo || !tipo.id) {
      dado.push('tipoTelefone');
      // console.log('tipo: ', tipo)
    }

    setErrors(dado);

    if (dado.length === 0) {
      saveUpdateData();
    }
  }

  const saveUpdateData = () => {
    const payload = {
      'id': id,
      'ddd': '31',
      'numero': numero.replace(/[^\d]+/g,''),
      'tiposTelefonesId': tipo.id,
      [props.idName]: props.id
    }
    // console.log('payload: ', payload)

    // // @ts-ignore
    if (!id) {
      delete payload.id;
    } 
    const url = props.path;
    const values = id ? api.put(`${url}/${id}`, payload) : api.post(url, payload)
    values.then((response) => {
      setMessage('Dados gravados com sucesso!');
      setSelected([]);
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
      setNumero('');
      setTipo(null);
      getData();
    })
    .catch((err) => {
      setMessage('Erro ao atualizar os dados! Verifique!123');
      setSelected([]);
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const handleEdit = (event, row) => {
    // console.log('row: ', row)
    setId(row.id);
    setNumero(row.numero);
    console.log(tiposTelefones);
    // setTiposTelefones(row.tiposTelefonesId);
    const tipo = tiposTelefones.filter(
      listValues => listValues.id === row.tiposTelefonesId
      )
    setTipo(tipo[0]);
    // console.log('tipo: ', tipo);
  }

  const handleOpenDeleteDialog = (event, row) => {
    setRowSelected(row);
    setOpenDeleteDialog(true);
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const phoneFormat = (value) => {
    return value
      .replace(/\D+/g, '')
      // .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
    }

    const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        cursor: 'pointer'
      },
    }));

  return (
    <>
      <SpinnerCarregando open={backdrop} />
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx= {{ px: 2, pt: 1 }}
      >
        <Box>
          <Typography component="div">
            <Box
              sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}
              display={{ xs: "none", sm: "none", md: "block", lg:"block", xl:"block" }}>
              Telefone(s)
            </Box>
          </Typography> 
        </Box>

        <Box
            component="div"
            display={{ xs: "none", sm: "none", md: "block", lg:"block", xl:"block" }}>
            <Box
            component="div"
            className={`${classes.bottomRightBox} ${classes.box}`}>

            <div style={{ width: 250, paddingRight: 20 }}>
              <TextField
                value={numero}
                required
                type="tel"
                focused
                fullWidth
                size="small"
                label={("Número")}
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={(e) => setNumero(phoneFormat(e.target.value))}
                error={errors.includes('numero')}
                helperText={errors.includes('numero') ? 'Campo incorreto!' : ''}
              />
            </div>

            <div style={{ width: 250, paddingRight: 20 }}>
              <Autocomplete
                id="controlled-demo"
                value={tipo}
                options={tiposTelefones}
                onChange={(event, newValue) => {
                  // @ts-ignore
                  setTipo(newValue);
                  // console.log('newValue: ', newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo Telefone"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    error={errors.includes('tipoTelefone')}
                    helperText={errors.includes('tipoTelefone') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </div>

            <div>
              <Button size="small" onClick={newInput} variant="outlined" color="primary" size="small" style={{ height: 35 }} sx={{ mr: 2 }}>
                Novo
              </Button>
            </div>

            <div>
              <Button size="small" onClick={checkErrors} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                Gravar
              </Button>
            </div>
          </Box>
        </Box>

      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx= {{ px: 2, pt: 1 }}
        display={{ xs: "block", sm: "block", md: "none", lg:"none", xl:"none" }}
      >

        <Box
          sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}
         >

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingBottom: 2 }}>
            <Box>
              <Typography component="div">
                <Box
                  sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>
                  Telefone(s)4
                </Box>
              </Typography> 
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pb: 2, px: 0 }}>

            <Box
              sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', px: 0 }}>
              <div style={{ width: '100%' }}>
                <TextField
                  value={numero}
                  required
                  type="tel"
                  focused
                  fullWidth
                  size="small"
                  label={("Número")}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={(e) => setNumero(phoneFormat(e.target.value))}
                  error={errors.includes('numero')}
                  helperText={errors.includes('numero') ? 'Campo incorreto!' : ''}
                />
              </div>
            </Box>

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pb: 2, px: 0 }}>
            <Box
              sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>
              <div style={{ width: '100%' }}>
                <Autocomplete
                  id="controlled-demo"
                  value={tipo}
                  options={tiposTelefones}
                  onChange={(event, newValue) => {
                    // @ts-ignore
                    setTipo(newValue);
                    // console.log('newValue: ', newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo Telefone"
                      variant="outlined"
                      size="small"
                      fullWidth
                      focused
                      required
                      error={errors.includes('tipoTelefone')}
                      helperText={errors.includes('tipoTelefone') ? 'Campo obrigatório!' : ''}
                    />
                  )}
                />
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingBottom: 2 }}>
            <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}>
                <div>
                  <Button size="small" onClick={newInput} variant="outlined" color="primary" size="small" style={{ height: 35, width: 200 }} sx={{ mr: 2 }}>
                    Novo
                  </Button>
                </div>
      
                {/* APAGAR <div>
                  <Button size="small" onClick={checkErrors} variant="outlined" color="primary" size="small" style={{ height: 35, width: 200 }}>
                    Gravar
                  </Button>
                </div>
                */}
            </Box>
          </Grid>

        </Box>

      </Grid>

      <Table size="small" aria-label="a table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Número</TableCell>
            <TableCell align="left">Tipo</TableCell>
            <TableCell align="center">#</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ?
            rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{row.numero}</TableCell>
                <TableCell align="left">{row.tipoTelefoneDescricao}</TableCell>

                <TableCell size="small" align="center" sx={{ fontSize: 11 }}>

                  <LightTooltip  title="Excluir">
                    <IconButton size="small" onClick={(event) => handleOpenDeleteDialog(event, row)}>
                      <HighlightOffOutlinedIcon size="small" color="error" sx={{ cursor:"pointer" }} />
                    </IconButton>
                  </LightTooltip >

                  <LightTooltip  title="Editar">
                    <IconButton size="small" onClick={(event) => handleEdit(event, row)}>
                      <ModeEditOutlinedIcon size="small" color="primary" sx={{ cursor:"pointer" }}/>
                    </IconButton>
                  </LightTooltip >
                </TableCell>
              </TableRow>
            ))
          :
          <TableRow>
            <TableCell align="left" sx={{ fontSize: 11 }} colSpan={3}>Sem dados</TableCell>
          </TableRow>}
        </TableBody>
      </Table>
      <DeleteDialog open={openDeleteDialog} handleCloseDeleteDialog={handleCloseDeleteDialog} deleteRegister={deleteRegister} />
    </>
  );
}
