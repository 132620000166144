// import { forEachChild } from "typescript";

export function validarCNPJ (cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g,'');
    if (cnpj === '') return false;
    if (cnpj.length !== 14)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (Number(resultado) !== Number(digitos.charAt(0)))
        return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado !== digitos.charAt(1))
      return false;
    return true;
}

export function formatCNPJ (cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g,'');
  if (cnpj === '') return false;
  return cnpj
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function formatCPF (cpf) {
  cpf = cpf.replace(/[^\d]+/g,'');
  if (cpf === '') return false;
  return cpf
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function formatCEP (cep) {
  cep = cep.replace(/[^\d]+/g,'');
  if (cep === '') return false;
  return cep
    .replace(/\D+/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

function checkRoles (roles, userRoles) {
  let checked = false;
  if (userRoles) {
    userRoles.map((item) => {
      if (roles.includes(item)) {
        checked = true;
      }
    });
  }
    return checked;
}

export function userRoles (type, userRoles) {
    let newValue= false;

    switch (type) {
      case "Login": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }
      case "Demonstrativo": {
        const roles = ['ROLE_DEMONSTRATIVO'];
        newValue = checkRoles(roles, userRoles);
        break;
      }
      case "Caixas Escolares - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Unidades Escolares - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Conselho Fiscal e Escolar - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Conselho Fiscal e Escolar - Caixa": {
        const roles = ['ROLE_ADMIN', 'ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Demonstrativo Anual - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Planejamento Financeiro Anual - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Presidentes Caixas Escolares - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Termos de Compromisso - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Planejamentos de Despesas - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Termos de Compromisso - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Gráficos - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Dados Bancarios - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Dados Bancarios - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Quantitativo de Estudantes - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Quantitativo de Estudantes - Unidades - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Fornecedores Credores - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Fornecedores Credores - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Per Capitas - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Comprovantes de Despesas - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Comprovantes de Despesas - AdminEdit": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Correção de Prestações - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Além dos Muros - Admin": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "Além dos Muros - Caixa": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "ROLE_ADMIN, ROLE_SEDUC_VISUALIZAR, ROLE_SEDUC_EDITAR": {
        const roles = ['ROLE_ADMIN', 'ROLE_SEDUC_VISUALIZAR', 'ROLE_SEDUC_EDITAR'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

      case "ROLE_PRESIDENTE, ROLE_PRESTADOR_DE_CONTAS": {
        const roles = ['ROLE_PRESIDENTE', 'ROLE_PRESTADOR_DE_CONTAS'];
        newValue = checkRoles(roles, userRoles);
        break;
      }

    default:
      break;
  }
  return newValue;
}

export function formatCnpj(cnpj) {
  if (cnpj && cnpj.replace(/[^\d]+/g,'') !== 14) {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return '';
}

export function getUserData () {
  const token = localStorage.getItem("token");
  let data = {
    email: '',
    exp: '',
    iat: '',
    user: '',
    userId: '',
    userRoles: []
  }
  if (token) {
    data = JSON.parse(atob(token.split('.')[1]));
    data.user = decodeURIComponent(escape(data.user));
    // console.log('dataUser', data.user);
  }
  return data;
}

export function validaChaveDeAcessoNotaFiscal (chaveDeAcesso) {
  chaveDeAcesso = chaveDeAcesso.replace(/[^\d]+/g,'');
  if (chaveDeAcesso === '') return false;
  if (chaveDeAcesso.length !== 44)
    return false;
  if (chaveDeAcesso === "00000000000000000000000000000000000000000000" ||
      chaveDeAcesso === "11111111111111111111111111111111111111111111" ||
      chaveDeAcesso === "22222222222222222222222222222222222222222222" ||
      chaveDeAcesso === "33333333333333333333333333333333333333333333" ||
      chaveDeAcesso === "44444444444444444444444444444444444444444444" ||
      chaveDeAcesso === "55555555555555555555555555555555555555555555" ||
      chaveDeAcesso === "66666666666666666666666666666666666666666666" ||
      chaveDeAcesso === "77777777777777777777777777777777777777777777" ||
      chaveDeAcesso === "88888888888888888888888888888888888888888888" ||
      chaveDeAcesso === "99999999999999999999999999999999999999999999")
  return false;

  let pos = 4;
  let tamanho = chaveDeAcesso.length - 1;
  let numeros = chaveDeAcesso.substring(0,tamanho);
  let soma = 0;
  let digito = chaveDeAcesso.substring(tamanho);
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (Number(resultado) !== Number(digito)) {
    return false;
  }
  return true;
}

export function formatTelefones (telefones) {
  let object = ""
  if (telefones) {
    telefones.map((item) => {
      object += ` (${item.ddd}) ${item.numero} - ${item.tipoTelefone} `;
      if (object.length > 0) {
        object += ` / `;
      }
    })
  }
  if (object.length > 0) {
    object = object.slice(1,-2);
  }
  return object;
}

export function uuidv4 () {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
  // @ts-ignore
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function convertStrintToFloat (value) {
  if (typeof value === 'string') {
    value = (String(value).replace('.', '')).replace(',', '.');
  }
  return value;
}

export function zeroPad (num, places) {
  return String(num).padStart(places, '0');
}
export function formatDateEnUs (date) {
  if (date) {
    let formatDate = new Date(date.dataEncerramento);
    formatDate = formatDate.setDate(formatDate.getDate() + 1);
    formatDate = new Date(formatDate);
    return formatDate.getFullYear() + "-" + ((formatDate.getMonth() + 1)) + "-" + (formatDate.getDate());
  }
  return null;
}

export function formatDateEnUs2 (date) {
  if (date) {
    let formatDate = new Date(date);
    // formatDate = formatDate.setDate(formatDate.getDate() + 1);
    // formatDate = new Date(date);
    return formatDate.getFullYear() + "-" + zeroPad(((formatDate.getMonth() + 1)), 2) + "-" + zeroPad((formatDate.getDate()), 2);
  }
  return null;
}

export function formatDatePtBr (date) {
  if (date) {
    // 2021-01-01
    return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0,4);
  }
  return null;
}

export function removeItemArray (item, array) {
  return array.filter(i => i !== item);
}

export function validarCPF(strCPF) {
    strCPF = strCPF.replace(/[^\d]+/g,'');
    let Soma;
    let Resto;
    Soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false;

  Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

export function isDate (date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
  }

function escapeRegExp (string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll (str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function validaEmail (email) {
  // @ts-ignore
  const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (email && reg.test(email)) {
    return true;
  }
  return false;
}