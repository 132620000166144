import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select } from "@mui/material";
// import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SelectComponentFontSize14 from '../../../components/Controls/SelectComponentFontSize14';
import { LteMobiledata } from "@mui/icons-material";

// import ItensNotasFiscaisRecibosList from '../../../components/Controls/ItensNotasFiscaisRecibosList';

export default function ItensNotasFiscaisRecibos(props) {
  // const classes = useStyles();

  const item = {
    "ordem": null,
    "id": null,
    "quantidadeComercial": null,
    "unidadeDeMedida": null,
    "descricao": null,
    "valorUnitario": null,
    "valorTotal": null,
    // "desconto": null,
    "unidadesDeMedidasId": null,
    "origemDosRecursosId": null,
    "comprovantesDeDespesasId": null,
    "tiposRecursosId": null
  };

  const [itemNotasFiscaisRecibos, setItemNotasFiscaisRecibos] = React.useState(item);

  const [quantidadeComercial, setQuantidadeComercial] = React.useState(null);
  // const [unidadeComercial, setUnidadeComercial] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [valorUnitario, setValorUnitario] = React.useState(null);
  const [valorTotal, setValorTotal] = React.useState(null);
  const [unidadesDeMedidasId, setUnidadesDeMedidasId] = React.useState(null);
  const [tiposRecursosId, setTiposRecursosId] = React.useState(null);

  const [errors, setErrors] = React.useState([]);

  const handleTiposRecursosId = (value) => {
    setTiposRecursosId(value);
    // setItemNotasFiscaisRecibos({...itemNotasFiscaisRecibos, 'tiposRecursosId': value});
  }

  const handleUnidadesDeMedidasId = (value) => {
    setUnidadesDeMedidasId(value);
    // setItemNotasFiscaisRecibos({...itemNotasFiscaisRecibos, 'unidadesDeMedidasId': value});
  }

  const clearItens = () => {
    setQuantidadeComercial('');
    setDescricao('');
    setValorUnitario('');
    setValorTotal('');
    // setUnidadesDeMedidasId(null); 
    // setTiposRecursosId(null);
  }

  const clearItem = () => {
    clearItens();
    setErrors([]);
  }

  const insertItem = () => {
    // clearItens();
    // console.log('itemNotasFiscaisRecibos1: ', itemNotasFiscaisRecibos);

    setErrors([]);

    let newErrors = [];

    if (!descricao) {
      newErrors.push('descricao');
    }

    if (!quantidadeComercial) {
      newErrors.push('quantidadeComercial');
    }

    if (!tiposRecursosId) {
      newErrors.push('tiposRecursosId');
    }

    if (!unidadesDeMedidasId) {
      newErrors.push('unidadesDeMedidasId');
    }

    if (Number(valorUnitario) === 0) {
      newErrors.push('valorUnitario');
    }

    if (newErrors.length > 0) {
        setOpenAlert(true);
        setErrors(newErrors);
    } else {

      // console.log('quantidadeComercial: ', quantidadeComercial);

      const newItens = ({
        "ordem": null,
        "id": null,
        "quantidadeComercial": quantidadeComercial,
        "unidadeDeMedida": null,
        "descricao": descricao,
        "valorUnitario": valorUnitario,
        "valorTotal": valorTotal,
        // "desconto": null,
        "unidadesDeMedidasId": unidadesDeMedidasId,
        "origemDosRecursosId": null,
        "comprovantesDeDespesasId": null,
        "tiposRecursosId": tiposRecursosId
      });

      setItemNotasFiscaisRecibos(newItens);

      // console.log("itemNotasFiscaisRecibos44444:", itemNotasFiscaisRecibos)

      props.handleInsertItem(newItens);
      clearItens();
      newErrors=[];
      // setItemNotasFiscaisRecibos(item);
    }
  }

  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('error');
  const [message, setMessage] = React.useState('Preencha todos os dados!');

  const handleClickAlert = () => {
    // setOpenAlert(true);
  };

  // @ts-ignore
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      cursor: 'pointer'
    },
  }));

  useEffect(() => {
    setValorTotal('');
    const valorTotal = quantidadeComercial && valorUnitario ? quantidadeComercial * valorUnitario : '0,00';
    setValorTotal(valorTotal);
    // setItemNotasFiscaisRecibos({...itemNotasFiscaisRecibos, 'valorTotal': valorTotal});
  }, [quantidadeComercial, valorUnitario, valorTotal]);

  return (
    <>
      <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleCloseAlert} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
        <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        sx={{ mt: 2 }}>

        <Box sx={{ mr: 1, width: "8%" }}>
        {/*
          <CurrencyTextField
            label="Quantidade"
            size="small"
            variant="outlined"
            value={quantidadeComercial}
            outputFormat="string"
            minimumValue="1"
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            // defaultValue={valorTotal}
            required
            fullWidth
            focused
            inputProps={{ style: { textTransform: "uppercase", maxLength: 80, fontSize: 15 } }}
            // InputProps={{ style: { color: "#c15165", borderColor: "primary", borderWidth: 2 } }}
            // InputProps={{ style: { backgroundColor: "#E1EBEE" } }}
            onChange={(event, newValue) => {
              // setItemNotasFiscaisRecibos({...itemNotasFiscaisRecibos, 'quantidadeComercial': newValue})
              setQuantidadeComercial(newValue);
              // console.log('itemNotasFiscaisRecibosQuantidade: ', itemNotasFiscaisRecibos);
            }}
            error={errors.includes('quantidadeComercial')}
            helperText={errors.includes('quantidadeComercial') ? 'Campo obrigatório!' : ''}
          />
          */}
        </Box>

        <Box sx={{ mr: 1, width: "16%" }}>
          <SelectComponentFontSize14
            nLabel="Unidade de Medida"
            newWidth="100%"
            variant="outlined"
            apiConst="unidades-de-medidas"
            onSelectData={handleUnidadesDeMedidasId}
            nRequired="true"
            errors={errors}
            errorType="unidadesDeMedidasId" />
        </Box>

        <Box sx={{ mr: 1, width: "25%" }}>
          <TextField
            size="small"
            value={descricao}
            required
            type="tel"
            focused
            fullWidth
            label={("Descrição")}
            inputProps={{ style: { textTransform: "uppercase", fontSize: 15 }, maxLength: 80 }}
            onChange={e => {
              setDescricao(e.target.value);
              // setItemNotasFiscaisRecibos({...itemNotasFiscaisRecibos, 'descricao': e.target.value})
              }
            }
            error={errors.includes('descricao')}
            helperText={errors.includes('descricao') ? 'Campo obrigatório!' : ''}
          />
        </Box>

        <Box sx={{ mr: 1, width: "9%" }}>
        {/*
          <CurrencyTextField
            label="V.Unitário"
            size="small"
            variant="outlined"
            value={valorUnitario}
            outputFormat="string"
            // minimumValue="0"
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            // defaultValue={valorTotal}
            required
            fullWidth
            inputProps={{ style: { textTransform: "uppercase", maxLength: 80, fontSize: 15 } }}
            // focused
            // InputProps={{ style: { color: "#c15165", borderColor: "primary", borderWidth: 2 } }}
            // InputProps={{ style: { backgroundColor: "#E1EBEE" } }}
            onChange={(event, newValue) => {
              setValorUnitario(newValue);
              // setItemNotasFiscaisRecibos({...itemNotasFiscaisRecibos, 'valorUnitario': newValue})
            }}
            error={errors.includes('valorUnitario')}
            helperText={errors.includes('valorUnitario') ? 'Campo obrigatório!' : ''}
          />
          */}
        </Box>

        <Box sx={{ mr: 1, width: "9%" }}>
        {/*
          <CurrencyTextField
            label="V.Total"
            size="small"
            variant="outlined"
            value={valorTotal}
            outputFormat="string"
            // minimumValue="0"
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            // defaultValue={valorTotal}
            required
            fullWidth
            focused
            inputProps={{ style: { textTransform: "uppercase", maxLength: 80, fontSize: 15 }, readOnly: true }}
            // InputProps={{ style: { color: "#c15165", borderColor: "primary", borderWidth: 2 } }}
            // InputProps={{ style: { backgroundColor: "#E1EBEE" } }}
            onChange={(event, newValue) => {
              setValorTotal(newValue);
              // setItemNotasFiscaisRecibos({...itemNotasFiscaisRecibos, 'valorTotal': newValue})
            }}
            error={errors.includes('descricao')}
            helperText={errors.includes('descricao') ? 'Campo obrigatório!' : ''}
          />
          */}
        </Box>

        <Box sx={{ width: "22%" }}>
          <SelectComponentFontSize14
            nLabel="Tipo Recurso"
            newWidth="100%"
            variant="outlined"
            apiConst="centros-de-custos"
            onSelectData={handleTiposRecursosId}
            nRequired="true"
            errors={errors}
            errorType="tiposRecursosId" />
        </Box>

        <Box sx={{ width: "2%" }}>
          <LightTooltip  title="Excluir">
            <IconButton onClick={clearItem}>
              <CloseOutlinedIcon  color="warning"sx={{ cursor:"pointer" }} />
            </IconButton>
          </LightTooltip >
        </Box>

        <Box sx={{ width: "2%" }}>
          <LightTooltip  title="Insere">
            <IconButton onClick={insertItem}>
              <CheckOutlinedIcon  color="primary"sx={{ cursor:"pointer" }} />
            </IconButton>
          </LightTooltip >
        </Box>

      </Grid>
    </>
  );
}
