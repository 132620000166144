import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialogDeliberacao(props) {
  // const [openAlertDialog, setOpenAlertDialog] = React.useState(false);

  const handleClickConfirmDelete = () => {
    props.handleClickConfirmDelete(false);
  };

  const handleClose = () => {
    props.handleCloseAlertDialog(false);
  };

  return (
    <Dialog
      open={props.openDeleteAlertDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {"APAGAR REGISTRO"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>Confirma a exclusão do registro?</div>
          <div>Ação: <b>{props.selectedRow.acoesMdeAcao}</b></div>
          {/* <pre>{JSON.stringify(props.selectedRow, null, 2)}</pre> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}
