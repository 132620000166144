import React from 'react';
import MyToolbar from '../../components/MyToolbar'

export default function CaixasEscolares() {
  // function searchData (data) {
  //   console.log('aaaaaaaaaaaa: ', data);
  //   props.onNewSearchData(data); 
  // }
  return (
    <React.Fragment>
      <MyToolbar />
      {/* <MyToolbar onSelectData={searchData} /> */}
    </React.Fragment>
  );
}
