import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CardHeader from '@mui/material/CardHeader';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 12,
    minWidth: '25%',
    textAlign: 'center',
  },
  header: {
    textAlign: 'center',
    spacing: 10,
    color: '#A9A9A9',
    fontSize: 20
  },
  datas: {
    textAlign: 'center',
    spacing: 10,
    color: '#A9A9A9'
  },
  list: {
    padding: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
  action: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const PricingCardDemo = React.memo(function PricingCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title={props.title} className={classes.header} />
      <Divider variant="middle" />
      <CardContent>
        <Typography variant="h4" align="center" className={classes.header}>
          { props.dados }
        </Typography>
       </CardContent>
    </Card>
  );
});

export default PricingCardDemo