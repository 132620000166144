import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import api from "../../../services/api";

export default function TermosDeCooperacaoEdit() {

  const query = useParams();
  const history = useHistory();

  const [data, setData] = useState([]);

  useEffect(() => {
    // @ts-ignore
    if (query.id) {
      getData();
    }
  }, []);

  const getData = () => {
    //@ts-ignore
    const values = api.get(`termos-de-cooperacao/${query.id}`)
    values.then((response) => {
      setData(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }

  return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>

        <Paper elevation={3}sx={{ p: 1, m: 2, display: 'flex', flexDirection: 'column' }}>
          <div>
            <h3>Termos de Compromisso - New / Edit</h3>
          </div>
        </Paper>

        <pre>{JSON.stringify(data, null, 2)}</pre>

      </Typography>
    </Container>
    </React.Fragment>
  );
}
