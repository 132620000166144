import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import SelectMembroCaixaEscolar from '../SelectMembroCaixaEscolar';
import { Newspaper } from "@mui/icons-material";

// const initialValues = {
//   acoesMdeAcao: "",
//   acoesMdeDias: "",
//   acoesMdeValor: "0,00"
// };

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      // prefix="R$ "
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function EditDialog(props) {
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [newValues, setNewValues] = React.useState({});
  const [title, setTitle] = React.useState("ALTERAR MEMBRO CAIXA ESCOLAR");
  const [showGravarButton, setShowGravaButton] = React.useState(true);

  const handleClickConfirm = () => {
    const data = {
      id: values.id,
      caixasEscolaresHasCargosFuncoesId: newValues.id ? newValues.id : values.caixasEscolaresHasCargosFuncoesId
    }
    props.handleClickConfirm(data);
  };

  const handleClose = () => {
    props.handleCloseEditDialog();
  };

  const handleInputChange = (e) => {
    //const name = e.target.name
    //const value = e.target.value
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeData = (value) => {
    setNewValues(value);
    // alert(JSON.stringify(value, null, 2));

  }

  const handleShowGravarButton = (value) => {
    setShowGravaButton(value);
  }

  useEffect(() => {
    setValues(props.selectedRow);
    setNewValues({});
  }, [props.selectedRow]);

  return (
    <Dialog
      open={props.openEditDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {/* <pre>{JSON.stringify(props.selectedRow, null, 2)}</pre> */}
          <Box sx={{ pt: 2 }}>
            <SelectMembroCaixaEscolar
              disabled={false}
              nLabel="Pessoa/Cargo"
              newWidth="100%"
              variant="outlined"
              nDisabled={false}
              onSelectData={handleChangeData}
              nRequired={true}
              rowSelected={values}
              error={false}
              handleShowGravarButton={handleShowGravarButton}
              // error={errors.includes('especiesId')}
              errorMessage='Campo obrigatório!'
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={handleClickConfirm}
          disabled={showGravarButton}>
          Gravar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
