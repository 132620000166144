import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Print from '@mui/icons-material/PrintOutlined';
import Edit from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';

import SpinnerCarregando from '../SpinnerCarregando'

import AlertDeleteDialog from '../AlertDeleteDialog';

import api from "../../services/api";

import { useHistory, withRouter } from "react-router-dom";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados excluídos com sucesso!');
  const [backdrop, setBackdrop] = React.useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  // @ts-ignore
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // setOpenAlert(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const showDeleteRegister = () => {
    deleteRegister();
  }
  const handleClick = (event) => {
    // console.log('id: ', props.id);
    setAnchorEl(event.currentTarget);
  };

  const handleEditClick = () => {
    history.push(`/projeto-alem-dos-muros-edit-form/${props.id}`);
  }

  const handleOnClick = (type) => {
    handleClose()

    // if (type === 'optional') {
    //   history.push(`${props.optionalPath}/${props.optionalId}`);
    // } else if (type !== 'delete') {
    //   history.push(`${props.path}/${type}/${props.id}`);
    // } else {
    //   setOpenDialog(true);
    // }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }; 

  const deleteRegister = () => {
    // @ts-ignore
    api.delete(`${props.path}/${props.id}`)
    .then((response) => {
      props.getNewData();
      setBackdrop(false);
      handleClickAlert();
      handleCloseDialog();
      props.getClicked('deleted');
    })
    .catch((err) => {
      setMessage('Erro ao excluir os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  return (
    <div>
      <SpinnerCarregando open={backdrop} />

      <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleCloseAlert} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>

        <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>

      </Snackbar>
      <AlertDeleteDialog open={openDialog} handleCloseDialog={handleCloseDialog} deleteRegister={showDeleteRegister} data1={props.data1} data2={props.data2} data3={props.data3}/>
      <IconButton
        size="small"
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <Paper sx={{ width: 150, maxWidth: '100%' }}>
          <MenuList>

            <MenuItem onClick={() => handleEditClick()}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Editar</ListItemText>
            </MenuItem>

            {/* APAGAR
            <MenuItem onClick={() => { handleOnClick('print') }}>
              <ListItemIcon>
                <Print fontSize="small" />
              </ListItemIcon>
              <ListItemText>Imprimir</ListItemText>
            </MenuItem>

            <Divider />
            <MenuItem onClick={() => handleOnClick('delete')}>
              <ListItemIcon>
                <DeleteOutlineOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Excluir</ListItemText>
            </MenuItem> */}

          </MenuList>
        </Paper>
      </StyledMenu>
    </div>
  );
}