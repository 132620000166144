import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack'

import { Autocomplete, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import SelectComponentFontSize14 from '../../../components/Controls/SelectComponentFontSize14';

import UploadFiles from '../UploadFiles';

export default function EstatutosAtasEditPostForm(props) {
  const [documentosTiposId, setTipoDocumento] = React.useState('');

  const [dataRegistroCartorio, setDataRegistroCartorio] = React.useState('');
  const [initialDate, setInitialDate] = React.useState('');
  const [finalDate, setFinalDate] = React.useState('');
  const [errors, setErrors] = React.useState([]);
  const [datasObrigatorias, setDatasObrigatorias] = React.useState([]);

  const [showObjects, setShowObjects] = React.useState({
    'documentosTiposIdWidth': 6,
    'initialDateWidth': 2,
    'finalDateWidth': 2,
    'dataRegistroCartorioWidth': 2,
  });

  // const handleChange = (event) => {
  //   setTipoDocumento(event.target.value);

  // };

  const handleTipoDocumento = (data) => {
    setTipoDocumento(data);
    setInitialDate(null);
    setFinalDate(null);
    setDataRegistroCartorio(null);
    setDatasObrigatorias([]);

    if (data && data.datasObrigatorias) {
      setDatasObrigatorias(data.datasObrigatorias);
      // Object.entries(data.datasObrigatorias))

    //   const entries = Object.data.datasObrigatorias(obj);
    // entries.forEach(entry => console.log(entry));


      // // data.datasObrigatorias.forEach(element => {
      // //   console.log('element: ', element);
      // // });
      // data.datasObrigatorias.map(([key, val]) => {
      //   console.log('val: ', val);
      // });
    }
    newCheckData(errors);
    // alert('handleTipoDocumento');
    // console.log('handleTipoDocumento:', data);
  };

  const Input = styled('input')({
    display: 'none',
  });

  useEffect(() => {
    const initialDate = datasObrigatorias.initialDate;
    const finalDate = datasObrigatorias.finalDate;
    const dataRegistroCartorio = datasObrigatorias.dataRegistroCartorio;

    if (initialDate && finalDate && dataRegistroCartorio) {
      setShowObjects({
        'documentosTiposIdWidth': 6,
        'initialDateWidth': 2,
        'finalDateWidth': 2,
        'dataRegistroCartorioWidth': 2,
      })
    } else if (initialDate && finalDate && !dataRegistroCartorio) {
      setShowObjects({
        'documentosTiposIdWidth': 8,
        'initialDateWidth': 2,
        'finalDateWidth': 2,
        // 'dataRegistroCartorioWidth': 2,
      })
    }  else if (!initialDate && !finalDate && !dataRegistroCartorio) {
      setShowObjects({
        'documentosTiposIdWidth': 12,
        // 'initialDateWidth': 2,
        // 'finalDateWidth': 2,
        // 'dataRegistroCartorioWidth': 2,
      })
    }
  }, [datasObrigatorias]);

  const newCheckData = (errors) => {
    
    props.checkData({
      documentosTiposId: documentosTiposId && documentosTiposId.id ? documentosTiposId.id : null,
      errors: errors,
      datas: {
        initialDate: initialDate,
        finalDate: finalDate,
        dataRegistroCartorio: dataRegistroCartorio
      }
    });
  }

  const checkAllErrors = () => {
    const initialDateData = datasObrigatorias.initialDate;
    const finalDateData  = datasObrigatorias.finalDate;
    const dataRegistroCartorioData  = datasObrigatorias.dataRegistroCartorio;
    // setErrors([]);
    const errors = [];
    // if (initialDate && finalDate && dataRegistroCartorio) {

      if (initialDateData && initialDate && finalDateData && finalDate) {
        if ((initialDate >= finalDate)) {
          if (!errors.includes('initialDate')) {
            errors.push('initialDate');
          }
        }
      }
    
      if (initialDateData && initialDate && dataRegistroCartorioData && dataRegistroCartorio) {
        if (initialDate >= dataRegistroCartorio) {
          if (!errors.includes('initialDate')) {
            errors.push('initialDate');
          }
        }
      }

      if (finalDateData && finalDate && initialDateData && initialDate) {
        if (finalDate <= initialDate) {
          if (!errors.includes('finalDate')) {
            errors.push('finalDate');
          }
        }
      }

      if (finalDateData && finalDate && dataRegistroCartorioData && dataRegistroCartorio) {
        if (finalDate <= dataRegistroCartorio) {
          if (!errors.includes('finalDate')) {
            errors.push('finalDate');
          }
        }
      }

      if (dataRegistroCartorioData && dataRegistroCartorio && initialDateData && initialDate) {
        if (dataRegistroCartorio <= initialDate) {
          if (!errors.includes('dataRegistroCartorio')) {
            errors.push('dataRegistroCartorio');
          }
        }
      }

      if (dataRegistroCartorioData && dataRegistroCartorio && finalDateData && finalDate) {
        if (dataRegistroCartorio >= finalDate) {
          if (!errors.includes('dataRegistroCartorio')) {
            errors.push('dataRegistroCartorio');
          }
        }
      }

      if (initialDateData) {
        if (!initialDate) {
            errors.push('initialDateData');
        }
      }
      
      if (finalDateData) {
          if (!finalDate) {
              errors.push('finalDateData');
          }
      }
      
      if (dataRegistroCartorioData) {
          if (!dataRegistroCartorio) {
              errors.push('dataRegistroCartorioData');
          }
      }

      setErrors(errors);      
    // }
    newCheckData(errors);
  }

  useEffect(() => {
    checkAllErrors();
  }, [initialDate, finalDate, dataRegistroCartorio]);

  return (
    <FormControl fullWidth>
      <Grid container spacing={2}  sx={{ mt: 0 }}>
      <Grid item xs={showObjects.documentosTiposIdWidth}>
        <Box sx={{ width: "100%" }}>
          <SelectComponentFontSize14
            nLabel="Tipo do Documento"
            newWidth="100%"
            variant="outlined"
            apiConst="documentos-tipos"
            onSelectData={handleTipoDocumento}
            nRequired="true"
            errors={errors}
            errorType="documentosTiposId"
            disabled={props.disabledItens} />
        </Box>
      </Grid>

      {datasObrigatorias.initialDate ?
        <Grid item xs={showObjects.initialDateWidth}>

        {
          !props.disabledItens ?
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
              <DatePicker
                label="Início do Mandato"
                value={initialDate}
                onChange={(newValue) => {
                  setInitialDate(newValue);
                  if (newValue) {
                    // let formatDate = new Date(newValue);
                    // const newFormatDate = (formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  size="small"
                  fullWidth
                  focused
                  required
                  error={errors.includes('initialDate')}
                  helperText={errors.includes('initialDate') ? 'Campo incorreto!' : ''}
                  disabled={props.disabledItens}
                />}
              />
            </LocalizationProvider>
          :
          <>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
            <TextField
              required
              size="small"
              fullWidth
              value={initialDate.toLocaleDateString("pt-BR").toString()}
              disabled              
              id="outlined-required"
              label="Início do Mandato"
              defaultValue="Hello World"
            />
            </LocalizationProvider>
          </>
        }
        </Grid>
      : <></>}

      {datasObrigatorias.finalDate ?
        <Grid item xs={showObjects.finalDateWidth}>
        {
          !props.disabledItens ?
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
              <DatePicker
                label="Final do Mandato"
                value={finalDate}
                onChange={(newValue) => {
                  setFinalDate(newValue);
                  if (newValue) {
                    // let formatDate = new Date(newValue);
                    // const newFormatDate = (formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  size="small"
                  fullWidth
                  focused
                  required
                  disabled={props.disabledItens}
                  error={errors.includes('finalDate')}
                  helperText={errors.includes('finalDate') ? 'Campo incorreto!' : ''}
                />}
              />
            </LocalizationProvider>
          :
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
              <TextField
                required
                size="small"
                fullWidth
                value={finalDate.toLocaleDateString("pt-BR").toString()}
                disabled                 
                id="outlined-required"
                label="Final do Mandato"
                defaultValue="Hello World"
              />
            </LocalizationProvider>
          </>
        }
        </Grid>
      : <></>}

      {
        datasObrigatorias.dataRegistroCartorio ?
          <Grid item xs={showObjects.dataRegistroCartorioWidth}>
            {
              !(props.disabledItens) ?
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                  <DatePicker
                    label="Registro no Cartório"
                    value={dataRegistroCartorio}
                    onChange={(newValue) => {
                      setDataRegistroCartorio(newValue);
                      if (newValue) {
                        // let formatDate = new Date(newValue);
                        // const newFormatDate = (formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                      }
                    }}
                    renderInput={(params) =>
                      <TextField
                      {...params}
                      size="small"
                      fullWidth
                      focused
                      required
                      error={errors.includes('dataRegistroCartorio')}
                      helperText={errors.includes('dataRegistroCartorio') ? 'Campo incorreto!' : ''}
                    />}
                  />
                </LocalizationProvider>
              :
              <>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                <TextField
                  required
                  size="small"
                  fullWidth
                  value={dataRegistroCartorio.toLocaleDateString("pt-BR").toString()}
                  disabled                
                  id="outlined-required"
                  label="Registro no Cartório"
                  defaultValue={dataRegistroCartorio.toLocaleDateString("pt-BR").toString()}
                />
                </LocalizationProvider>
              </>
            }
          </Grid>
        : <></>
      }

      <Grid item xs={3}>

        <UploadFiles />

      </Grid>
    </Grid>

    </FormControl>
  );
}
