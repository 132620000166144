import * as React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { StyledEngineProvider } from '@mui/material/styles';
import Layout from './pages/Layout';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

import history from './history';
import { AuthProvider } from './Context/AuthContext';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#4a89c8'
      },
      secondary: {
        main: '#662b7e'
      }
    }
  },
  ptBR,
);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <AuthProvider>
        <Router history={history}>
          <Layout />
        </Router>
        </AuthProvider>
    </StyledEngineProvider>
  </ThemeProvider>,
  document.querySelector("#root")
);
