import http from "../services/api";

class UploadFilesService {
  upload(file, id, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return http.post(`/estatutos-atas/upload/schools-documents/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      payload: {
        "teste": id
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/estatutos-atas/files");
  }
}

export default new UploadFilesService();
