import React, { useEffect } from 'react';
import { Box } from "@mui/system";
import Regional from "../Regional";
import StatusCaixasEscolares from "../StatusCaixasEscolares";
import TiposEnsino from "../TiposEnsino";
import SelectComponent from "../Controls/SelectComponent";
import AutoCompleteSelectComponent from "../Controls/AutoCompleteSelectComponent";
import AutoCompleteSelectComponentCaixasEscolas from "../Controls/AutoCompleteSelectComponentCaixasEscolas";
import AutoCompleteSelectComponentStatus from "../Controls/AutoCompleteSelectComponentStatus";
import { Button } from "@mui/material";

import api from '../../services/api';

export default function SearchBar(props) {
  const [anos, setAnos] = React.useState([]);
  const [caixasEscolares, setCaixasEscolares] = React.useState([]);
  const [unidadesEscolares, setUnidadesEscolares] = React.useState([]);
  const [numerosParcelas, setNumerosParcelas] = React.useState([]);
  const [tiposTermos, setTiposTermos] = React.useState([]);
  const [termosTipos, setTermosTipos] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [analistas, setAnalistas] = React.useState([]);

  const [checkStatus, setCheckStatus] = React.useState([])

  const [clearAll, setClearAll] = React.useState(null);

  // const [stateValueCaixasEscolares, setStateValueCaixasEscolares] = React.useState([]);
  // const [stateValueRegioes, setStateValueRegioes] = React.useState([]);
  // const [stateValueBairros, setStateValueBairros] = React.useState([]);
  // const [stateValueTiposEnsinos, setStateValueTiposEnsinos] = React.useState([]);
  // const [stateSearchValues, setStateSearchValues] = React.useState('');


  useEffect(() => {
    setClearAll(true);
  }, [props.showHideSearchBar]);

  const handleDataAnos = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setAnos(data)
  }

  const handleDataCaixasEscolares = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setCaixasEscolares(data)
  }

  const handleDataUnidadesEscolares = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setUnidadesEscolares(data)
  }

  const handleDataNumerosParcelas = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setNumerosParcelas(data)
  }

  const handleDataTermosTipos = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setTermosTipos(data)
  }

  const handleDataAnalistas = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setAnalistas(data)
  }

  const handleDataTiposTermos = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setTiposTermos(data)
  }

  const handleDataStatus = (value) => {
    setClearAll(null);
    const data = [];
    value.map(
      (item) => {
        data.push(item.id)
      }
   )
   setStatus(data)
  }

  const handleDataCancel = () => {
    setClearAll(1);
    props.onSearchData();
  }
  // const onSearchData = () => {
  //   alert('search');
  // }

  React.useEffect(() => {
    const data = [{'anos': anos}, {'caixasEscolares': caixasEscolares}, {'unidadesEscolares': unidadesEscolares}, {'tiposTermos': tiposTermos}, {'termosTipos': termosTipos}, {'status': status}, {'numerosParcelas': numerosParcelas}, {'analistas': analistas}]
    props.onSelectData(data);
  }, [anos, caixasEscolares, unidadesEscolares, tiposTermos, termosTipos, status, numerosParcelas, analistas]);

  // React.useEffect(() => {
  //   setCheckStatus(props.newData.status);
  // }), [props.newData.status]

  return (
      <div style={{ width: '100%' }}>
      {/* <pre>{JSON.stringify(checkStatus, null, 2)}</pre> */}
          {/* <Box
              sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap' ,
              alignItems: 'center',
              bgcolor: 'background.paper',
          }}> */}
              <Box
                  sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  // flexWrap: 'nowrap' ,
                  alignItems: 'center',
                  // flexGrow: 1,
                  flex: '100%',

                  direction: "row",
                  justifyContent:"space-between",
                  alignItems: "center",

                  pt: 1,
                  pb: 2,
                  // p: 1,
                  // m: 1,
                  bgcolor: 'background.paper',
              }}>
                {/*
                  <Box sx={{ pr: 1, width: "15%" }}>
                    <AutoCompleteSelectComponent nLabel="Anos" variant="outlined" apiConst="anos" onSelectData={handleDataAnos} clearAll={clearAll}/>
                  </Box>
                */}

                <Box sx={{ pr: 1, width: "70%" }}>
                  <AutoCompleteSelectComponentCaixasEscolas nLabel="Caixas Escolares" variant="outlined" apiConst="caixas-escolares/list" onSelectData={handleDataCaixasEscolares} clearAll={clearAll}/>
                </Box>

                <Box sx={{ pr: 0, width: "30%" }}>
                  <AutoCompleteSelectComponent nLabel="Analistas" variant="outlined" apiConst="analistas" onSelectData={handleDataAnalistas} clearAll={clearAll}/>
                </Box>

                {/*<Box sx={{ pr: 1, width: "44%" }}>
                  <AutoCompleteSelectComponent nLabel="Unidades Escolares" variant="outlined" apiConst="unidades-escolares/listagem" onSelectData={handleDataUnidadesEscolares} clearAll={clearAll}/>
                </Box>*/}
              </Box>

              <Box
                  sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap' ,
                  alignItems: 'center',
                  // flexGrow: 1,
                  flex: '100%',

                  direction: "row",
                  // justifyContent:"space-between",
                  alignItems: "center",

                  pt: 1,
                  pb: 2,
                  // p: 1,
                  // m: 1,
                  bgcolor: 'background.paper',
              }}>
                <Box sx={{ pr: 1, width: "14.5%" }}>
                  <AutoCompleteSelectComponent nLabel="Parcelas" newWidth="200" variant="outlined" apiConst="numeros-parcelas" onSelectData={handleDataNumerosParcelas} clearAll={clearAll}/>
                </Box>
                <Box sx={{ pr: 1, width: "15%" }}>
                    <AutoCompleteSelectComponent nLabel="Termo" newWidth="300" variant="outlined" apiConst="termos-tipos" onSelectData={handleDataTermosTipos} clearAll={clearAll}/>
                </Box>
                <Box sx={{ pr: 1, width: "35%" }}>
                  <AutoCompleteSelectComponent nLabel="Tipos" newWidth="500" variant="outlined" apiConst="tipos-termos" onSelectData={handleDataTiposTermos} clearAll={clearAll}/>
                </Box>
                <Box sx={{ pr: 0, width: "35.5%" }}>
                  <AutoCompleteSelectComponentStatus nLabel="Status" newWidth="390" variant="outlined" apiConst="status" onSelectData={handleDataStatus} status={checkStatus} clearAll={clearAll}/>
                </Box>
              </Box>

          {/* </Box> */}
      </div>
  )
}
