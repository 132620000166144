import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';
import api from "../../../services/api";
import { formatCnpj, formatDateEnUs2, getUserData, userRoles } from '../../../utils/functions';
import NumberFormat from 'react-number-format';
import ButtomMenuDemonstrativo from '../../../components/ButtomMenuDemonstrativo';
import { Plus, Minus } from 'mdi-material-ui/light';

import FormControlLabel from '@mui/material/FormControlLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { set, parseISO, format } from "date-fns";
import { convertToLocalTime } from 'date-fns-timezone';

import PlanejamentoFinanceiroOrientacoes from '../../../components/PlanejamentoFinanceiroOrientacoes';
import PlanejamentoFinanceiroAlert from '../../../components/PlanejamentoFinanceiroAlert';
import ButtomMenuPlanejamentoDespesas from '../../../components/ButtomMenuPlanejamentoDespesas';

import { formatDatePtBr, formatTelefones } from '../../../utils/functions';
import EditPrintMenuMembroConselho from '../../../components/EditPrintMenuMembroConselho';

import ProjetoAlemDosMurosAlertErrors from '../ProjetoAlemDosMurosAlertErrors';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  valorTotal: {
    backgroundColor : "#F5F5F5",
    borderColor: "primary"
  }
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix="R$ "
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function DataFunction () {
  const query = useParams();
  const history = useHistory();
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 230,
      },
    },
  };

  const options = [
    "Totais Gerais",
    "Totais Parciais",
    "Não Preencher",
    "Preencher"
  ];

  const [errors, setErrors] = React.useState([]);
  const [finalizado, setFinalizado] = React.useState(1);
  const [menuOptions, setMenuOptions] = React.useState(['menuGravar', 'menuGravarFinalizar', 'menuImprimir']);

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const [selectedOptions, setSelectedOptions] = React.useState(options);

  const [showServicosData, setShowServicosData] = React.useState(true);
  const [showMateriaisConsumoData, setShowMateriaisConsumoData] = React.useState(true);
  const [showPermanentesCapitalData, setShowPermanentesCapitalData] = React.useState(true);
  const [showOpcionaisData, setShowOpcionaisData] = React.useState(true);  
  const [showConsolidadoData, setShowConsolidadoData] = React.useState(true);
  const [showSaldoFinalData, setShowSaldoFinalData] = React.useState(true);
  const [showFndeDebitos, setShowFndeDebitos] = React.useState(true);

  const [showLiberado, setShowLiberado] = React.useState(false);
  const [editLiberado, setEditLiberado] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [adminEdit, setAdminEdit] = React.useState(false);

  const [showSituacao, setShowSituacao] = React.useState(false);
  const [showObservacaoSeduc, setShowObservacaoSeduc] = React.useState(false);
  const [desabilitaPreenchimento, setDesabilitaPreenchimento] = React.useState(false);
  const [savedData, setSavedData] = React.useState([]);
  const [saveType, setSaveType] = React.useState('saveForm');

  const [openOrientacoes, setOpenOrientacoes] = React.useState(false);
  const [openPlanejamentoFinanceiroAlert, setOpenPlanejamentoFinanceiroAlert] = React.useState(false);

  const [title, setTitle]=useState('PROJETOS ALÉM DOS MUROS - EDIÇÃO');
  const [subTitle, setSubTitle]=useState('');
  const [subSubTitle, setSubSubTitle]=useState('');
  const [nomeProjeto, setNomeProjeto]=useState('');

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  const [selected, setSelected] = React.useState([]);

  const [userData, setUserData] = React.useState({});

  const [openAlert, setOpenAlert] = React.useState(false);

  const closeOrientacoes = () => {
    setOpenOrientacoes(false);
  }

  const closeAlert = () => {
    setOpenAlert(false);
  }

  const openAlemDosMurosAlert = () => {
    setOpenAlert(true);
  }

  const closePlanejamentoFinanceiroAlert = () => {
    setOpenPlanejamentoFinanceiroAlert(false);
  }

  const openPlanejamentoAlert = () => {
    setOpenPlanejamentoFinanceiroAlert(true);
  }

  const [deferidoList, setDeferidoList] = React.useState([
    { value: 'DEFERIDO', label: 'DEFERIDO' },
    { value: 'INDEFERIDO', label: 'INDEFERIDO' },
    { value: 'ATUALIZADO', label: 'ATUALIZADO' }
  ]);

  const [tiposItensPlanejamentosFinanceirosList, setTiposItensPlanejamentosFinanceirosList] = React.useState([
    {
    value: '48a445f0-95dd-11ec-a4a0-00155de8a36d',
    label: 'SERVIÇOS DE TERCEIROS – PESSOA JURÍDICA E MEI'
    },
    {
      value:'69d653b0-95dd-11ec-a4a0-00155de8a36d',
      label:'MATERIAIS DE CONSUMO'
    },
    {
      value:'7d1b5b30-95dd-11ec-a4a0-00155de8a36d',
      label:'CAPITAL / INVESTIMENTOS / MATERIAIS PERMANENTES'
    }
  ]);

  const [inputs, setInputs] = React.useState({
    valorTotalProjetosServicosMateriais: "0.00",
    valorTotalProjetosLancheAlimentacao: "0.00",
    valorTotalProjetosGeral: "0.00",
    valorTotalPerCapita: "0.00",
    finalizado: 0,
    caixasEscolaresId: null,
    anosId: null
  });

  const [inputsValues, setInputsValues] = React.useState({
    id: null,
    onibusQuantidade: null,
    onibusValor: null,
    onibusNaoHaNecessidade: null,
    guiasQuantidade: null,
    guiasValor: null,
    guiasNaoHaNecessidade: null,
    lanchesQuantidade: null,
    lanchesValor: null,
    lanchesNaoHaNecessidade: null,
    ingressosQuantidade: null,
    ingressosValor: null,
    ingressosNaoHaNecessidade: null,
    materialidadeDescricao: null,
    materialidadeValor: null,
    materialidadeNaoHaNecessidade: null,
    oficinasQuantidade: null,
    oficinasValor: null,
    oficinasNaoHaNecessidade: null,
    companhiasTeatroQuantidade: null,
    companhiasTeatroValor: null,
    companhiaDeTeatroNaoHaNecessidade: null,
    oficineirosQuantidade: null,
    oficineirosValor: null,
    oficineirosNaoHaNecessidade: null,
    cancelado: null,
    valorTotalServicosMateriaisTotal: null,
    valorTotalLancheAlimentacao: null,
    valorTotalProjeto: null
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleInputsChangesShow = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

   async function handleBlockNaoHaNecessidade () {

    let values = inputsValues;

    if (Number(values.onibusNaoHaNecessidade) > 0) {
      setInputsValues(values => ({
        ...values,
        ['onibusQuantidade']: null,
        ['onibusValor']: null
      }))
    }

    if (Number(values.guiasNaoHaNecessidade) > 0) {
      setInputsValues(values => ({
        ...values,
        ['guiasQuantidade']: null,
        ['guiasValor']: null
      }))
    }

  }

  async function handleChangeValue(event) {
    const name = event.target.name;
    const value = event.target.checked ? event.target.checked : null;
    let values = inputsValues;
    const valorTotalServicosMateriaisTotal = values.valorTotalServicosMateriaisTotal;
    const valorTotalLancheAlimentacao = values.valorTotalLancheAlimentacao;
    const valorTotalProjeto = parseFloat(valorTotalServicosMateriaisTotal) + parseFloat(valorTotalLancheAlimentacao);

    if (value && name === 'onibusNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'onibusQuantidade': null,
        'onibusValor': null,
        'valorTotalServicosMateriaisTotal': parseFloat(valorTotalServicosMateriaisTotal) - parseFloat(values.onibusValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.onibusValor),
        [name]: value,
      }))
    } else if (value && name === 'guiasNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'guiasQuantidade': null,
        'guiasValor': null,
        'valorTotalServicosMateriaisTotal': parseFloat(valorTotalServicosMateriaisTotal) - parseFloat(values.guiasValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.guiasValor),
        [name]: value,
      }))
    } else if (value && name === 'lanchesNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'lanchesQuantidade': null,
        'lanchesValor': null,
        'valorTotalLancheAlimentacao': parseFloat(valorTotalLancheAlimentacao) - parseFloat(values.lanchesValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.lanchesValor),
        [name]: value,
      }))
    } else if (value && name === 'ingressosNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'ingressosQuantidade': null,
        'ingressosValor': null,
        'valorTotalServicosMateriaisTotal': parseFloat(valorTotalServicosMateriaisTotal) - parseFloat(values.ingressosValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.ingressosValor),
        [name]: value,
      }))
    } else if (value && name === 'materialidadeNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'materialidadeDescricao': null,
        'materialidadeValor': null,
        'valorTotalServicosMateriaisTotal': parseFloat(valorTotalServicosMateriaisTotal) - parseFloat(values.materialidadeValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.materialidadeValor),
        [name]: value,
      }))
    } else if (value && name === 'oficinasNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'oficinasQuantidade': null,
        'oficinasValor': null,
        'valorTotalServicosMateriaisTotal': parseFloat(valorTotalServicosMateriaisTotal) - parseFloat(values.oficinasValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.oficinasValor),
        [name]: value,
      }))
    } else if (value && name === 'companhiaDeTeatroNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'companhiasTeatroQuantidade': null,
        'companhiasTeatroValor': null,
        'valorTotalServicosMateriaisTotal': parseFloat(valorTotalServicosMateriaisTotal) - parseFloat(values.companhiasTeatroValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.companhiasTeatroValor),
        [name]: value,
      }))
    } else if (value && name === 'oficineirosNaoHaNecessidade') {
      setInputsValues(values => ({
        ...values,
        'oficineirosQuantidade': null,
        'oficineirosValor': null,
        'valorTotalServicosMateriaisTotal': parseFloat(valorTotalServicosMateriaisTotal) - parseFloat(values.oficineirosValor),
        'valorTotalProjeto': valorTotalProjeto - parseFloat(values.oficineirosValor),
        [name]: value,
      }))
    }
    else {
      setInputsValues(values => ({
        ...values,
        [name]: value,
      }))
    }
  };

  async function handleChangeNaoHaNecessidade(event) {
    await handleChangeValue(event);
  };

  const saveForm = () => {
    setBackdrop(true);
    api.put(`projetos-alem-dos-muros/${query.id}`, inputsValues)
    .then((response) => {
      getData();
      setMessage('Dados gravados com sucesso!');
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setSeverity('error');
      setBackdrop(false);
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })

  }

  const saveFinalizado = (newFinalizado) => {
  }

  const checkErrors = () => {
    const errorsTypes = [];
    const newErrors = [];

    if (inputsValues.cancelado) {
      saveForm();
    } else {
      if ((Number(inputsValues.onibusQuantidade) === 0 && Number(inputsValues.onibusValor) === 0 && Number(inputsValues.onibusNaoHaNecessidade) !== 1) || (Number(inputsValues.onibusQuantidade) > 0 && Number(inputsValues.onibusValor) === 0) || Number(inputsValues.onibusQuantidade) === 0 && Number(inputsValues.onibusValor) > 0) {
        errorsTypes.push('Erros ônibus!');
        newErrors.push('onibus');
      }

      if ((Number(inputsValues.guiasQuantidade) === 0 && Number(inputsValues.guiasValor) === 0 && Number(inputsValues.guiasNaoHaNecessidade) !== 1) || (Number(inputsValues.guiasQuantidade) > 0 && Number(inputsValues.guiasValor) === 0) || (Number(inputsValues.guiasQuantidade) === 0 && Number(inputsValues.guiasValor) > 0)) {
        errorsTypes.push('Erros guias!');
        newErrors.push('guias');
      }

      if ((Number(inputsValues.lanchesQuantidade) === 0 && Number(inputsValues.lanchesValor) === 0 && Number(inputsValues.lanchesNaoHaNecessidade) !== 1) || (Number(inputsValues.lanchesQuantidade) > 0 && Number(inputsValues.lanchesValor) === 0) || Number(inputsValues.lanchesQuantidade) === 0 && Number(inputsValues.lanchesValor) > 0) {
        errorsTypes.push('Erros lanches/alimentação!');
        newErrors.push('lanches');
      }

      if ((Number(inputsValues.ingressosQuantidade) === 0 && Number(inputsValues.ingressosValor) === 0 && Number(inputsValues.ingressosNaoHaNecessidade) !== 1) || (Number(inputsValues.ingressosQuantidade) > 0 && Number(inputsValues.ingressosValor) === 0) || (Number(inputsValues.ingressosQuantidade) === 0 && Number(inputsValues.ingressosValor) > 0)) {
        errorsTypes.push('Erros ingressos!');
        newErrors.push('ingressos');
      }

      const materiaisDescricao = inputsValues.materialidadeDescricao ? inputsValues.materialidadeDescricao.trim() : '';
      if (Number(inputsValues.materialidadeNaoHaNecessidade) !== 1 && (Number(inputsValues.materialidadeValor) === 0 || materiaisDescricao.length <= 5)) {
        errorsTypes.push('Erros materialidade!');
        newErrors.push('materialidade');
      }

      if ((Number(inputsValues.oficinasQuantidade) === 0 && Number(inputsValues.oficinasValor) === 0 && Number(inputsValues.oficinasNaoHaNecessidade) !== 1) || (Number(inputsValues.oficinasQuantidade) > 0 && Number(inputsValues.oficinasValor) === 0) || (Number(inputsValues.oficinasQuantidade) === 0 && Number(inputsValues.oficinasValor) > 0)) {
        errorsTypes.push('Erros oficinas!');
        newErrors.push('oficinas');
      }

      if ((Number(inputsValues.companhiasTeatroQuantidade) === 0 && Number(inputsValues.companhiasTeatroValor) === 0 && Number(inputsValues.companhiaDeTeatroNaoHaNecessidade) !== 1) || (Number(inputsValues.companhiasTeatroQuantidade) > 0 && Number(inputsValues.companhiasTeatroValor) === 0) || (Number(inputsValues.companhiasTeatroQuantidade) === 0 && Number(inputsValues.companhiasTeatroValor) > 0)) {
        errorsTypes.push('Erros companhias!');
        newErrors.push('companhiasTeatro');
      }

      if ((Number(inputsValues.oficineirosQuantidade) === 0 && Number(inputsValues.oficineirosValor) === 0 && Number(inputsValues.oficineirosNaoHaNecessidade) !== 1) || (Number(inputsValues.oficineirosQuantidade) > 0 && Number(inputsValues.oficineirosValor) === 0) || (Number(inputsValues.oficineirosQuantidade) === 0 && Number(inputsValues.oficineirosValor) > 0)) {
        errorsTypes.push('Erros oficineiros!');
        newErrors.push('oficineiros');
      }
      if (newErrors.length > 0) {
        // alert('Existem erros! Altere antes de gravar!')

        setOpenAlert(true);
      } else {
        saveForm();
      }
      setErrors(newErrors);
    }


    // if (type === 'save') {
    //   saveForm(0);
    // } else if (type === 'saveEnd') {
    //   saveFormEnd();
    // } else if (type === 'print') {
    //   printPlanejamento();
    // }
  }

  const goBack = () => {
    history.push(`/projeto-alem-dos-muros-list/${inputs.anosId}/${inputs.caixasEscolaresId}`)
  }

  const saveFormEnd = () => {
    openPlanejamentoAlert();
    // setFinalizado(1);
    // saveForm(1);
  }

  const handleClickAlert = () => {
    setOpen(true);
  };
  const columns = [
    { id: 'ordem', label: 'Ordem', minWidth: 170 },
    { id: 'projeto', label: 'Projeto', minWidth: 100 },
    { id: 'servicos', label: 'Serviços/Materialidade(R$)', minWidth: 170, align: 'right'},
    { id: 'alimentacao', label: 'Alimentação(R$)', minWidth: 170, align: 'right'},
    { id: 'total', label: 'Total (R$)', minWidth: 170, align: 'right'},
    { id: 'menu', label: '#', align: 'center'},
  ];
  const handleClick = (event, name) => {
    alert('x');
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1),
    //   );
    // }
    // setSelected(newSelected);
  };
  const getShowServicosData = () => {
    setShowServicosData(!showServicosData);
  }
  const getShowMateriaisConsumoData = () => {
    setShowMateriaisConsumoData(!showMateriaisConsumoData);
  }
  const getShowPermanentesCapitalData = () => {
    setShowPermanentesCapitalData(!showPermanentesCapitalData);
  }
  const getShowOpcionaisData = () => {
    setShowOpcionaisData(!showOpcionaisData);
  }
  const getShowConsolidadoData = () => {
    setShowConsolidadoData(!showConsolidadoData);
  }
  const getShowSaldoFinalData = () => {
    setShowSaldoFinalData(!showSaldoFinalData);
  }
  const handleInputsChanges = (event) => {
    const name = event.target.name;
    let value = event.target.value < 0 ? (event.target.value = 0) : event.target.value;
    if (typeof value === 'string') {
      value = value.toUpperCase();
      if (name === 'materialidadeDescricao') {
        value = value.substring(0, 199);
      }
    }
    setInputsValues(values => ({...values, [name]: value}))
  }
  const handleInputsChangesNumbers = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value) < 0 || !Number(event.target.value) > 0 ? (event.target.value = 0) : Number(event.target.value)
    setInputsValues(values => ({...values, [name]: value}))
  }
  const handleInputsCancelaProjeto = (event) => {
    const name = event.target.name;
    const value = !inputsValues.cancelado;
    if (value) {
      const object = {
        id: inputsValues.id,
        onibusQuantidade: null,
        onibusValor: null,
        onibusNaoHaNecessidade: null,
        guiasQuantidade: null,
        guiasValor: null,
        guiasNaoHaNecessidade: null,
        lanchesQuantidade: null,
        lanchesValor: null,
        lanchesNaoHaNecessidade: null,
        ingressosQuantidade: null,
        ingressosValor: null,
        ingressosNaoHaNecessidade: null,
        materialidadeDescricao: null,
        materialidadeValor: null,
        materialidadeNaoHaNecessidade: null,
        oficinasQuantidade: null,
        oficinasValor: null,
        oficinasNaoHaNecessidade: null,
        companhiasTeatroQuantidade: null,
        companhiasTeatroValor: null,
        companhiaDeTeatroNaoHaNecessidade: null,
        oficineirosQuantidade: null,
        oficineirosValor: null,
        oficineirosNaoHaNecessidade: null,
        cancelado: true,
        valorTotalServicosMateriaisTotal: null,
        valorTotalLancheAlimentacao: null,
        valorTotalProjeto: null
      }
      setInputsValues(object);
    } else {
      setInputsValues(values => ({...values, [name]: value}))
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // const updateValues = () => {
  //   const valorSolicitadoSubtotal01 = parseFloat(inputs.valorSolicitado01 || 0) + parseFloat(inputs.valorSolicitado02 || 0) + parseFloat(inputs.valorSolicitado03 || 0) + parseFloat(inputs.valorSolicitado04 || 0) + parseFloat(inputs.valorSolicitado05 || 0) + parseFloat(inputs.valorSolicitado06 || 0) + parseFloat(inputs.valorSolicitado07 || 0) + parseFloat(inputs.valorSolicitado08 || 0) + parseFloat(inputs.valorSolicitado09 || 0) + parseFloat(inputs.valorSolicitado10 || 0) +  parseFloat(inputs.valorSolicitado11 || 0);
  //   const valorLiberadoSubtotal01 = parseFloat(inputs.valorLiberado01 || 0) + parseFloat(inputs.valorLiberado02 || 0) + parseFloat(inputs.valorLiberado03 || 0) + parseFloat(inputs.valorLiberado04 || 0) + parseFloat(inputs.valorLiberado05 || 0) + parseFloat(inputs.valorLiberado06 || 0) + parseFloat(inputs.valorLiberado07 || 0) + parseFloat(inputs.valorLiberado08 || 0) + parseFloat(inputs.valorLiberado09 || 0) + parseFloat(inputs.valorLiberado10 || 0) + parseFloat(inputs.valorLiberado11 || 0);

  //   const valorSolicitadoSubtotal02 = parseFloat(inputs.valorSolicitado12 || 0) + parseFloat(inputs.valorSolicitado13 || 0) + parseFloat(inputs.valorSolicitado14 || 0) + parseFloat(inputs.valorSolicitado15 || 0) + parseFloat(inputs.valorSolicitado16 || 0) + parseFloat(inputs.valorSolicitado17 || 0);
  //   const valorLiberadoSubtotal02 = parseFloat(inputs.valorLiberado12 || 0) + parseFloat(inputs.valorLiberado13 || 0) + parseFloat(inputs.valorLiberado14 || 0) + parseFloat(inputs.valorLiberado15 || 0) + parseFloat(inputs.valorLiberado16 || 0) + parseFloat(inputs.valorLiberado17 || 0);

  //   const valorSolicitadoSubtotal03 = parseFloat(inputs.valorSolicitado18 || 0) + parseFloat(inputs.valorSolicitado19 || 0) + parseFloat(inputs.valorSolicitado20 || 0) ;
  //   const valorLiberadoSubtotal03  = parseFloat(inputs.valorLiberado18 || 0) + parseFloat(inputs.valorLiberado19 || 0) + parseFloat(inputs.valorLiberado20 || 0) ;

  //   const valorSolicitadoSubtotal04 = parseFloat(inputs.valorSolicitado21 || 0) + parseFloat(inputs.valorSolicitado22 || 0) +  parseFloat(inputs.valorSolicitado23 || 0);
  //   const valorLiberadoSubtotal04  = parseFloat(inputs.valorLiberado21 || 0) + parseFloat(inputs.valorLiberado22 || 0) +  parseFloat(inputs.valorLiberado23 || 0);

  //   const valorTotalGeralSolicitado = parseFloat(valorSolicitadoSubtotal01 || 0)  + parseFloat(valorSolicitadoSubtotal02 || 0) + parseFloat(valorSolicitadoSubtotal03 || 0) + + parseFloat(valorSolicitadoSubtotal04 || 0);

  //   const valorTotalGeralLiberado = parseFloat(valorLiberadoSubtotal01 || 0)  + parseFloat(valorLiberadoSubtotal02 || 0) + parseFloat(valorLiberadoSubtotal03 || 0) + + parseFloat(valorLiberadoSubtotal04 || 0);

  //   setInputs(values => ({
  //     ...values, 
  //     "valorTotalGeralSolicitado": String(valorTotalGeralSolicitado),
  //     "valorTotalGeralLiberado": String(valorTotalGeralLiberado),
  //     "valorSolicitadoSubtotal01": String(valorSolicitadoSubtotal01),
  //     "valorSolicitadoSubtotal02": String(valorSolicitadoSubtotal02),
  //     "valorSolicitadoSubtotal03": String(valorSolicitadoSubtotal03),
  //     "valorSolicitadoSubtotal04": String(valorSolicitadoSubtotal04),
  //     "valorLiberadoSubtotal01": String(valorLiberadoSubtotal01),
  //     "valorLiberadoSubtotal02": String(valorLiberadoSubtotal02),
  //     "valorLiberadoSubtotal03": String(valorLiberadoSubtotal03),
  //     "valorLiberadoSubtotal04": String(valorLiberadoSubtotal04),
  //   }))
  // };
  // React.useEffect(() => {
  //   updateValues();
  //  }, [
  //   inputs.valorSolicitado01,
  //   inputs.valorSolicitado02,
  //   inputs.valorSolicitado03,
  //   inputs.valorSolicitado04,
  //   inputs.valorSolicitado05,
  //   inputs.valorSolicitado06,
  //   inputs.valorSolicitado07,
  //   inputs.valorSolicitado08,
  //   inputs.valorSolicitado09,
  //   inputs.valorSolicitado10,
  //   inputs.valorSolicitado11,
  //   inputs.valorSolicitado12,
  //   inputs.valorSolicitado13,
  //   inputs.valorSolicitado14,
  //   inputs.valorSolicitado15,
  //   inputs.valorSolicitado16,
  //   inputs.valorSolicitado17,
  //   inputs.valorSolicitado18,
  //   inputs.valorSolicitado19,
  //   inputs.valorSolicitado20,
  //   inputs.valorSolicitado21,
  //   inputs.valorSolicitado22,
  //   inputs.valorSolicitado23,

  //   inputs.valorLiberado01,
  //   inputs.valorLiberado02,
  //   inputs.valorLiberado03,
  //   inputs.valorLiberado04,
  //   inputs.valorLiberado05,
  //   inputs.valorLiberado06,
  //   inputs.valorLiberado07,
  //   inputs.valorLiberado08,
  //   inputs.valorLiberado09,
  //   inputs.valorLiberado10,
  //   inputs.valorLiberado11,
  //   inputs.valorLiberado12,
  //   inputs.valorLiberado13,
  //   inputs.valorLiberado14,
  //   inputs.valorLiberado15,
  //   inputs.valorLiberado16,
  //   inputs.valorLiberado17,
  //   inputs.valorLiberado18,
  //   inputs.valorLiberado19,
  //   inputs.valorLiberado20,
  //   inputs.valorLiberado21,
  //   inputs.valorLiberado22,
  //   inputs.valorLiberado23
  // ]);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    getAdmin();
  }, [userData]);

  React.useEffect(() => {
    if (savedData.length >= 2) {
      getData();
    }
  }, [savedData]);

  const getAdmin = () => {
    if (userRoles('Comprovantes de Despesas - Admin', userData.userRoles)) {
      setAdmin(true);
      setShowLiberado(true);
      setShowObservacaoSeduc(true);
      setSaveType('finaliza');
    }
    if (userRoles('Comprovantes de Despesas - AdminEdit', userData.userRoles)) {
      setAdminEdit(true);
      setShowLiberado(true);
      setShowObservacaoSeduc(true);
      setSaveType('finaliza');
    }
  }

  const getData = () => {
  setBackdrop(true);

  api.get(`projetos-alem-dos-muros/por-projeto/${query.id}`)
    .then((response) => {
      const data = response.data;
      const title = `PROJETOS ALÉM DOS MUROS - EDIÇÃO - ANO: ${data.newData[0].anosAno}`;
      setTitle(title);
      const caixasEscolaresCnpj = formatCnpj(data.newData[0].caixasEscolaresCnpj);
      const caixaEscolarData = `${data.newData[0].caixasEscolaresNome} - CNPJ: ${caixasEscolaresCnpj}`
      const unidadeEscolarData = `${data.newData[0].tiposUnidadesEscolaresAbreviacao} ${data.newData[0].unidadesEscolaresNome}`
      setSubTitle(caixaEscolarData);
      setSubSubTitle(unidadeEscolarData);
      setNomeProjeto(data.newData[0].descricao)
      setUserData(getUserData());
      setListValues(data.newData);
      setInputsValues(data.newData[0])
      setInputs({
        valorTotalProjetosServicosMateriais: data.valorTotalProjetosServicosMateriais,
        valorTotalProjetosLancheAlimentacao: data.valorTotalProjetosLancheAlimentacao,
        valorTotalProjetosGeral: data.valorTotalProjetosGeral,
        valorTotalPerCapita: data.valorTotalPerCapita,
        finalizado: data.finalizado,
        caixasEscolaresId: data.newData[0].caixasEscolaresId,
        anosId: data.newData[0].anosId
      });

      if (data.finalizado) {
        setMessage('Alteração dos projetos finalizada!');
        setSeverity('error');
        handleClickAlert();
      }
      setBackdrop(false);

      // const unidadeEscolar = `${data.unidadesEscolaresTipoAbreviacao} ${data.unidadesEscolaresNome}`;
      // setSubSubTitle(unidadeEscolar);

      // const planejamentosFinanceiros = data.planejamentosFinanceiros;
      // const percapita = Number(data.percapita) >= 1 ? true : false;
      // if (Number(data.percapita) >= 1) {
      //   setDesabilitaPreenchimento(true);
      // }
      // setFinalizado(data.finalizado);
      // let  newDate = new Date();
      // newDate = formatDateEnUs2(newDate);

      // if (data.data && data.finalizado) {
      //   newDate = data.data;
      //   setDesabilitaPreenchimento(true);
      // }

      // setInputs(values => ({
      //   ...values, 
      //   "id": data.id,
      //   "ano": data.ano,
      //   "verbaRecebidaAnoAnterior": data.verbaRecebidaAnoAnterior,
      //   "observacoesCaixaEscolar": data.observacoesCaixaEscolar,
      //   "observacoesSeduc": data.observacoesSeduc,
      //   "percapita": Number(data.percapita) >= 1 ? true : false,
      //   "data": newDate,
      //   "finalizado": data.finalizado,
      // }))

      // planejamentosFinanceiros.map((item) => (
      //   setInputs(values => ({
      //     ...values, 
      //     ["planejamentosFinanceirosHasItensId" + item.itensPlanejamentosFinanceirosOrdem] : item.planejamentosFinanceirosHasItensId,
      //     ["valorSolicitado" + item.itensPlanejamentosFinanceirosOrdem]: item.valorSolicitado,
      //     ["valorLiberado" + item.itensPlanejamentosFinanceirosOrdem]: item.valorLiberado,
      //     ["itensPlanejamentosFinanceirosId" + item.itensPlanejamentosFinanceirosOrdem]: item.itensPlanejamentosFinanceirosId,
      //     ["itensPlanejamentosFinanceirosDescricao" + item.itensPlanejamentosFinanceirosOrdem]: item.itensPlanejamentosFinanceirosDescricao,
      //     ["itensPlanejamentosFinanceirosOrdem" + item.itensPlanejamentosFinanceirosOrdem]: item.itensPlanejamentosFinanceirosOrdem,
      //     ["tiposItensPlanejamentosFinanceirosId" + item.itensPlanejamentosFinanceirosOrdem]: item.tiposItensPlanejamentosFinanceirosId,
      //     ["tiposItensPlanejamentosFinanceirosDescricao" + item.itensPlanejamentosFinanceirosOrdem]: item.tiposItensPlanejamentosFinanceirosDescricao,
      //     ["tiposItensPlanejamentosFinanceirosOrdem" + item.itensPlanejamentosFinanceirosOrdem]: item.tiposItensPlanejamentosFinanceirosOrdem,
      //     ["situacoesItensSolicitadosId" + item.itensPlanejamentosFinanceirosOrdem]: item.situacoesItensSolicitadosId,
      //     ["situacoesItensSolicitadosDescricao" + item.itensPlanejamentosFinanceirosOrdem]: item.situacoesItensSolicitadosDescricao
      //   }))
      // ))
      // console.log('inputs: ', inputs);
    })
    .catch((err) => {
      setMessage('Erro ao obter os dados! Verifique!');
      setSeverity('error');
      setBackdrop(false);
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  return (
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>

      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
      {message}
      </Alert>
      </Snackbar>
      {
        backdrop ? <SpinnerCarregando open={backdrop} /> : 
          <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
            <ProjetoAlemDosMurosAlertErrors open={openAlert} closeAlert={closeAlert} />
            <PlanejamentoFinanceiroOrientacoes open={openOrientacoes} closeOrientacoes={closeOrientacoes} />
            <PlanejamentoFinanceiroAlert open={openPlanejamentoFinanceiroAlert} closePlanejamentoFinanceiroAlert={closePlanejamentoFinanceiroAlert} type={saveType} saveForm={checkErrors} />
            <Grid container spacing={2} sx={{ mt: 3 , justifyContent: "center" }}>
            {/* <pre>{JSON.stringify(inputs, null, 2)}</pre> */}

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box>
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2, justifyContent: "center" }}>{title}</Box>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pb: 0 }}>
              <Box
                component="div"
                className={`${classes.bottomRightBox} ${classes.box}`}>

                { !admin && !adminEdit ?
                  <div>
                    <Button
                      onClick={() => {checkErrors()}}
                      variant="outlined"
                      color="primary"
                      size="small"
                      disabled={Number(inputs.finalizado) === 1}
                      style={{ height: 35, marginRight: 20, width: 140 }}>
                      GRAVAR
                    </Button>
                  </div>
                  : <></>
                }

                { !admin && !adminEdit ?
                  <div>
                    <Button
                      onClick={() => {goBack()}}
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ height: 35, marginRight: 20, width: 140 }}>
                      VOLTAR
                    </Button>
                  </div>
                  : <></>
                }

              </Box>
              </Grid>

              <Grid item xs={12} sx={{ flex: '100%', pb: 0 }}>
                <Box>
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 0 }}>{subTitle}</Box>
                  </Typography>
                </Box>

                <Box>
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 0 }}>{subSubTitle}</Box>
                  </Typography>
                </Box>

                <Box>
                  <Typography component="div">
                    <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 4 }}>PROJETO: {nomeProjeto}</Box>
                  </Typography>
                </Box>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={`${classes.centerBox} ${classes.box}`}>
                    <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 14, color: '#A9A9A9' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={inputsValues.cancelado}
                            name="cancelado"
                            disabled={inputs.finalizado}
                            onChange={handleInputsCancelaProjeto}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="CANCELAR PROJETO" />
                    </Box>
                  </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ pb: 3, ml: 0, mt: 2 }}>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            ÔNIBUS/TRANSPORTE
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="onibusQuantidade"
                        label="Quantidade"
                        value={inputsValues.onibusQuantidade || "0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="onibusQuantidade"
                        error={errors.includes('onibus')}
                        helperText={errors.includes('onibus') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.onibusNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff",
                          },
                            disabled: inputsValues.onibusNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={
                          handleInputsChangesNumbers
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="onibusValor"
                        label="Valor Total"
                        value={inputsValues.onibusValor || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="onibusValor"
                        error={errors.includes('onibus')}
                        helperText={errors.includes('onibus') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: {
                            backgroundColor: inputsValues.onibusNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.onibusNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="onibusNaoHaNecessidade"
                            checked={inputsValues.onibusNaoHaNecessidade}
                            onChange={handleChangeNaoHaNecessidade}
                            inputProps={{ 
                              'aria-label': 'controlled',
                              style: {
                                backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                              },
                              disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                            }}
                          />
                        }
                        label="Não é necessário ou incluso em outro item"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            GUIAS TURÍSTICOS
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="guiasQuantidade"
                        label="Quantidade"
                        value={inputsValues.guiasQuantidade || "0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="guiasQuantidade"
                        error={errors.includes('guias')}
                        helperText={errors.includes('guias') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.guiasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.guiasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={
                          handleInputsChangesNumbers
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="guiasValor"
                        label="Valor Total"
                        value={inputsValues.guiasValor || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="guiasValor"
                        error={errors.includes('guias')}
                        helperText={errors.includes('guias') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: {
                            backgroundColor: inputsValues.guiasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.guiasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="guiasNaoHaNecessidade"
                            checked={inputsValues.guiasNaoHaNecessidade}
                            onChange={handleChangeNaoHaNecessidade}
                            inputProps={{
                              'aria-label': 'controlled',
                              style: {
                                backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                              },
                              disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                            }}
                          />
                        }
                        label="Não é necessário ou incluso em outro item"
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            ALIMENTAÇÃO
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="lanchesQuantidade"
                        label="Quantidade"
                        value={inputsValues.lanchesQuantidade || "0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="lanchesQuantidade"
                        error={errors.includes('lanches')}
                        helperText={errors.includes('lanches') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.lanchesNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.lanchesNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={
                          handleInputsChangesNumbers
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="lanchesValor"
                        label="Valor Total"
                        value={inputsValues.lanchesValor || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="lanchesValor"
                        error={errors.includes('lanches')}
                        helperText={errors.includes('lanches') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: {
                            backgroundColor: inputsValues.lanchesNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.lanchesNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="lanchesNaoHaNecessidade"
                            checked={inputsValues.lanchesNaoHaNecessidade}
                            onChange={handleChangeNaoHaNecessidade}
                            inputProps={{
                              'aria-label': 'controlled',
                              style: {
                                backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                              },
                              disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                            }}
                          />
                        }
                        label="Não é necessário ou incluso em outro item"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            INGRESSOS
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="ingressosQuantidade"
                        label="Quantidade"
                        value={inputsValues.ingressosQuantidade || "0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="ingressosQuantidade"
                        error={errors.includes('ingressos')}
                        helperText={errors.includes('ingressos') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.ingressosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.ingressosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={
                          handleInputsChangesNumbers
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="ingressosValor"
                        label="Valor Total"
                        value={inputsValues.ingressosValor || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="ingressosValor"
                        error={errors.includes('ingressos')}
                        helperText={errors.includes('ingressos') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: {
                            backgroundColor: inputsValues.ingressosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.ingressosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="ingressosNaoHaNecessidade"
                            checked={inputsValues.ingressosNaoHaNecessidade}
                            onChange={handleChangeNaoHaNecessidade}
                            inputProps={{
                              'aria-label': 'controlled',
                              style: {
                                backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                              },
                              disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                            }}
                          />
                        }
                        label="Não é necessário ou incluso em outro item"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            OFICINAS
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="oficinasQuantidade"
                        label="Quantidade"
                        value={inputsValues.oficinasQuantidade || "0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="oficinasQuantidade"
                        error={errors.includes('oficinas')}
                        helperText={errors.includes('oficinas') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.oficinasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.oficinasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={
                          handleInputsChangesNumbers
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="oficinasValor"
                        label="Valor Total"
                        value={inputsValues.oficinasValor || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="oficinasValor"
                        error={errors.includes('oficinas')}
                        helperText={errors.includes('oficinas') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: {
                            backgroundColor: inputsValues.oficinasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.oficinasNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="oficinasNaoHaNecessidade"
                            checked={inputsValues.oficinasNaoHaNecessidade}
                            onChange={handleChangeNaoHaNecessidade}
                            inputProps={{
                              'aria-label': 'controlled',
                              style: {
                                backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                              },
                              disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                            }}
                          />
                        }
                        label="Não é necessário ou incluso em outro item"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            COMPANHIAS DE TEATRO (APRESENTAÇÃO NA ESCOLA/CEMEI)
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="companhiasTeatroQuantidade"
                        label="Quantidade"
                        value={inputsValues.companhiasTeatroQuantidade || "0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="companhiasTeatroQuantidade"
                        error={errors.includes('companhiasTeatro')}
                        helperText={errors.includes('companhiasTeatro') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.companhiaDeTeatroNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.companhiaDeTeatroNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={
                          handleInputsChangesNumbers
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="companhiasTeatroValor"
                        label="Valor Total"
                        value={inputsValues.companhiasTeatroValor || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="companhiasTeatroValor"
                        error={errors.includes('companhiasTeatro')}
                        helperText={errors.includes('companhiasTeatro') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: {
                            backgroundColor: inputsValues.companhiaDeTeatroNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.companhiaDeTeatroNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="companhiaDeTeatroNaoHaNecessidade"
                            checked={inputsValues.companhiaDeTeatroNaoHaNecessidade}
                            onChange={handleChangeNaoHaNecessidade}
                            inputProps={{
                              'aria-label': 'controlled',
                              style: {
                                backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                              },
                              disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                            }}
                          />
                        }
                        label="Não é necessário ou incluso em outro item"
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            OFICINEIROS
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="oficineirosQuantidade"
                        label="Quantidade"
                        value={inputsValues.oficineirosQuantidade || "0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="oficineirosQuantidade"
                        error={errors.includes('oficineiros')}
                        helperText={errors.includes('oficineiros') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.oficineirosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.oficineirosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={
                          handleInputsChangesNumbers
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="oficineirosValor"
                        label="Valor Total"
                        value={inputsValues.oficineirosValor || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="oficineirosValor"
                        error={errors.includes('oficineiros')}
                        helperText={errors.includes('oficineiros') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: {
                            backgroundColor: inputsValues.oficineirosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.oficineirosNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="oficineirosNaoHaNecessidade"
                            checked={inputsValues.oficineirosNaoHaNecessidade}
                            onChange={handleChangeNaoHaNecessidade}
                            inputProps={{
                              'aria-label': 'controlled',
                              style: {
                                backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                              },
                              disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                            }}
                          />
                        }
                        label="Não é necessário ou incluso em outro item"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                    <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                      <Grid justifyContent="flex-end" item xs={12} sm={12} md={6} lg={6} xl={6} className={`${classes.centerBox} ${classes.box}`} >
                        <Box>
                          <Typography component="div">
                            <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                              MATERIALIDADE
                            </Box>
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        <TextField
                          id="materialidadeValor"
                          label="Valor Total"
                          value={inputsValues.materialidadeValor || "0.00"}
                          color="primary"
                          size="small"
                          focused 
                          fullWidth
                          autoComplete="off"
                          dir="rtl"
                          name="materialidadeValor"
                          error={errors.includes('materialidade')}
                          helperText={errors.includes('materialidade') ? "Valor incorreto ou ausente" : ""}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            style: {
                              backgroundColor: inputsValues.materialidadeNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                            },
                            disabled: inputsValues.materialidadeNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado  ? true : false
                          }}
                          onChange={handleInputsChanges}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="materialidadeNaoHaNecessidade"
                              checked={inputsValues.materialidadeNaoHaNecessidade}
                              onChange={handleChangeNaoHaNecessidade}
                              inputProps={{
                                'aria-label': 'controlled',
                                style: {
                                  backgroundColor: inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                                },
                                disabled: inputsValues.cancelado || inputs.finalizado ? true : false
                              }}
                            />
                          }
                          label="Não é necessário ou incluso em outro item"
                        />
                      </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:5, lg:5, xl:5 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            MATERIALIDADE DESCRIÇÃO RESUMIDA
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <TextField
                        id="materialidadeDescricao"
                        label=""
                        value={inputsValues.materialidadeDescricao || ""}
                        color="primary"
                        size="small"
                        focused
                        fullWidth
                        autoComplete="off"
                        name="materialidadeDescricao"
                        error={errors.includes('materialidade')}
                        helperText={errors.includes('materialidade') ? "Valor incorreto ou ausente" : ""}
                        InputProps={{
                          style: {
                            backgroundColor: inputsValues.materialidadeNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? "#e3f2fd" : "#ffffff"
                          },
                          disabled: inputsValues.materialidadeNaoHaNecessidade || inputsValues.cancelado || inputs.finalizado ? true : false
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={6} lg={6} xl={6} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            VALOR TOTAL SOLICITADO (SERVIÇOS/MATERIAIS)
                          </Box>
                        </Typography> 
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="valorTotalServicosMateriaisTotal"
                        label="Valor Total"
                        value={inputsValues.valorTotalServicosMateriaisTotal || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="valorTotalServicosMateriaisTotal"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: { backgroundColor: "#e3f2fd" }, readOnly: true
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={6} lg={6} xl={6} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            VALOR TOTAL SOLICITADO (ALIMENTAÇÃO)
                          </Box>
                        </Typography> 
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="valorTotalLancheAlimentacao"
                        label="Valor Total"
                        value={inputsValues.valorTotalLancheAlimentacao || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="valorTotalLancheAlimentacao"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: { backgroundColor: "#e3f2fd" }, readOnly: true
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                  <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                    <Grid justifyContent="flex-end" item xs={12} sm={12} md={6} lg={6} xl={6} className={`${classes.centerBox} ${classes.box}`} >
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>
                            VALOR TOTAL SOLICITADO (GERAL)
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <TextField
                        id="valorTotalProjeto"
                        label="Valor Total"
                        value={inputsValues.valorTotalProjeto || "0.0"}
                        color="primary"
                        size="small"
                        focused 
                        fullWidth
                        autoComplete="off"
                        dir="rtl"
                        name="valorTotalProjeto"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          style: { backgroundColor: "#e3f2fd" }, readOnly: true
                        }}
                        onChange={handleInputsChanges}
                      />
                    </Grid>

                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Paper>
      }

      </Typography>
    </Container>
  );
}
