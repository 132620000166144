import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { FormControl } from '@mui/material';

import api from '../../../services/api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
  const [listValues, setListValues] = React.useState([]);
  const [newClearValues, setNewClearValues] = React.useState(null);

  React.useEffect(() => {
   const values = api.get(props.apiConst)
    values.then((response) => {
      setListValues(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  React.useEffect(() => {
    if (props.clearAll) {
      clearValues();
    }
  }, [props.clearAll]);

  const clearValues = () => {
    setNewClearValues(1);
    // console.log('aqui2');

    const clear = document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0];
    if (clear) {
      clear.click();
    }
  }

  React.useEffect(() => {
    // Take the Reference of Close Button
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
      
    // Add a Click Event Listener to the button

    if (close) {
      close.addEventListener("click", () => {
        console.log("Add your Own Functionality Here...");
      });
    }
  });

  // const [newValue, setNewValue] = React.useState([]);
  // const handleChange = async (value) => {
  //   console.log('value: ', value)
  //   await setNewValue(value);
  //  };

  const handleDataChange = (data) => {
    props.onSelectData(data);
    // alert('novo');
    setNewClearValues(null);
  };

  return (
    <FormControl variant={props.variant} sx={{ minWidth: '100%' }}>
      <Autocomplete
        multiple
        limitTags={1}
        size="small"
        id="checkboxes-tags-demo"
        options={listValues}
        
        disableCloseOnSelect

        onChange={(event, value) => handleDataChange(value)}

        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField {...params} label={props.nLabel} placeholder="" variant={props.variant} />
        )}
      />
    </FormControl>
  );
}
