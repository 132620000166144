import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import { Button } from "@mui/material";

import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ClearOutlinedIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { formatDateEnUs } from "../../utils/functions";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SpinnerCarregando from '../../components/SpinnerCarregando';

import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

import DeleteDialog from '../../components/DeleteDialog';
import EditDialog from '../../components/EditDialog';
import InsertDialog from '../../components/InsertDialog';

import DeleteFormatOptions from '../../components/DeleteFormatOptions';

import { styled } from '@mui/material/styles';

import api from '../../services/api';

let rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

  const headCells = [
    {
      id: 'dataInsercao',
      numeric: false,
      disablePadding: true,
      label: 'Data Inserção',
      width: '15%',
      align: "left"
    },
    {
      id: 'observacao',
      numeric: false,
      disablePadding: true,
      label: 'Observação/Correção',
      width: '35%',
      align: "left"
    },
    {
      id: 'tiposObservacoesConferenciasPrestacoesDeContas.descricao',
      numeric: false,
      disablePadding: true,
      label: 'Tipo',
      width: '20%',
      align: "left"
    },
    {
      id: 'corrigido',
      numeric: false,
      disablePadding: true,
      label: 'Situação',
      width: '10%',
      align: "left"
    },
    {
      id: 'dataCorrecao',
      numeric: false,
      disablePadding: true,
      label: 'Data Correção',
      width: '10%',
      align: "left"
    },
    {
      id: 'usuarios.pessoas.nome',
      numeric: false,
      disablePadding: true,
      label: 'Responsável',
      width: '15%',
      align: "left"
    },
    {
      id: '',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '5%',
      align: "center"
    }
  ];
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" size="small" padding="checkbox">
          <Checkbox 
            size="small"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            padding="none"
            size="small"
            key={headCell.id}
            // align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         // ...(numSelected > 0 && {
//         //   bgcolor: (theme) =>
//         //     alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//         // }),
//       }}
//     >
//       {/* {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: '1 1 100%' }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : ( */}
//       <Box>
//         <Typography component="div">
//           <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Per Capitas - Listagem</Box>
//         </Typography> 
//       </Box>
//       {/* )} */}

//       {/* {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )} */}
//     </Toolbar>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  negativeItem: {
    color: "#c15165"
  },
  positiveItem: {
    color: "primary"
  }
}));

// const updateData = () => {
//   props.updateData();
// };

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  const [searchField, setSearchField] = React.useState('');

  const handleChange = async (event) => {
    await setSearchField(event.target.value);
  };

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const handleClickAlert = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [listObservacoesValues, setListObservacoesValues] = React.useState('Dados gravados com sucesso!');
  useEffect(() => {
    const values = api.get(`tipos-observacoes-conferencias-prestacoes-de-contas`)
    values.then((response) => {
      setListObservacoesValues(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const classes = useStyles();

  useEffect(() => {
    setNewListValues(props.observacoesConferencia);
    // const values = api.get(`observacoes-conferencias-prestacoes-de-contas/por-prestacao-de-contas/${props.prestacaoDeContasId}`)
    // values.then((response) => {
    //   // rows = response.data;
    //   setListValues(response.data);
    //   setNewListValues(response.data);
    // })
    // .catch((err) => {
    //   console.error("ops! ocorreu um erro" + err);
    // })
  }, [props.observacoesConferencia]);

  useEffect(() => {
    //   //Doing filteration on according to select state option
      let newListValue = props.observacoesConferencia;
      // newListValue = listValues.filter(
      //   listValues => listValues.percaptasDescricoesDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
      //   listValues.tiposEnsinosDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
      //   listValues.anos.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      // );
    //   if ((searchData) && (searchData[0].regioes.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[0].regioes.includes(listValues.regiao_id)
    //     );
    //   }
    //   if ((searchData) && (searchData[1].bairros.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[1].bairros.includes(listValues.bairro_id)
    //     );
    //   }
    //   if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
    //     newListValue = listValues.filter(
    //       listValues => searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
    //         searchData[2].tiposEnsinos
    //         .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
    //     );
    //   }

      //set state variable data after filteration
      setNewListValues(newListValue);
      }, [searchField])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newListValues.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      cursor: 'pointer'
    },
  }));

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const changeValor = (newValue, row) => {
    const newSelecteds = newListValues.map((n) => {
      if (n.id === row.id) {
          return { ...n, 'valor': newValue.replace(',', '.') }
      }
      return n;
    });
    setNewListValues(newSelecteds);
  }

  const handleSelect = (event, row) => {
    // console.log('event: ', event.target.value);
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected, row.id);
    }
    changeValor(event.target.value, row);
    setSelected([...new Set(newSelected)]);
  };

  const createNewData = () => {
    setOpenInsertDialog(true);
  }

  const newData = () => {
    // console.log('selected: ', selected);
    // console.log('newListValues: ', newListValues);

    // const data = newListValues.map((item) => {
    //   if (selected.includes(item.id)) {
    //       return {
    //         "id": item.id,
    //         "valor": item.valor
    //       }
    //   }
    // });

    const payload = [];

    newListValues.forEach(item => {
      if (selected.includes(item.id)) {
        payload.push({
          "id": item.id,
          "valor": item.valor
        });
      }
    });

    // console.log('payload: ', payload);


    // const values = api.patch("percaptas-valores")
    //   const values = api.post(`percaptas-valores`, payload)
    //   values.then((response) => {
    //     setSubmitting(false);
    //     console.log("submit: ", data);
    //   })
    //   .catch((err) => {
    //     setSubmitting(false);
    //     console.error("ops! ocorreu um erro" + err);
    //   }

    // console.log('values:' , 'values');

    //   const payload = {
    //     // @ts-ignore
    //     'id': data.caixasEscolaresId,
    //     // @ts-ignore
    //     'nome': data.caixa_escolar_nome,
    //     // @ts-ignore
    //     'termo': data.termo,
    //     // @ts-ignore
    //     'codigo_credor': data.codigo_credor
    //   }
    //   // @ts-ignore
    setBackdrop(true);
    const values = api.patch(`percaptas-valores`, payload)
    values.then((response) => {
      setBackdrop(false);
      handleClickAlert();
      setSelected([]);
    })
    .catch((err) => {
      // setSubmitting(false);
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();

      console.error("ops! ocorreu um erro" + err);
    })
  }

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openInsertDialog, setOpenInsertDialog] = React.useState(false);
   
  const [rowSelected, setRowSelected] = React.useState(null);

  const handleOpenDeleteDialog = (event, row) => {
    setRowSelected(row);
    setOpenDeleteDialog(true);
  }
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleOpenEditDialog = (event, row) => {
    setRowSelected(row);
    setOpenEditDialog(true);
  }
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  }

  const handleCloseInsertDialog = () => {
    setOpenInsertDialog(false); 
  }

  const deleteSelecteds = () => {
    editDeleteSelecteds('delete-selecteds');
  }

  const editCorrigidoSelecteds = () => {
    editDeleteSelecteds('edit-selecteds', 1);
  }

  const editPendenteSelecteds = () => {
    editDeleteSelecteds('edit-selecteds', null);
  }

  const editDeleteSelecteds = (path, situacao) => {

    const payload = [];

    newListValues.forEach(item => {
      if (selected.includes(item.id)) {
        const formatDate = item.dataCorrecao ? new Date(item.dataCorrecao) : null;
        if (path === 'edit-selecteds') {
          item.corrigido = Number(situacao) === 1 ? 1 : null;
        }
        payload.push({
          "id": item.id,
          // "observacao": item.observacao,
          // "dataCorrecao": item.dataCorrecao ? formatDate.getFullYear() + "-" + (formatDate.getMonth() + 1) + "-" + formatDate.getDate() : null,
          "corrigido": item.corrigido,
        });
      }
    });

    // // @ts-ignore
    api.patch(`observacoes-conferencias-prestacoes-de-contas/${path}`, payload)
    .then((response) => {
      setMessage('Dados gravados com sucesso!');
      props.updateData();
      setSelected([]);
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao atualizar os dados! Verifique!');
      props.updateData();
      setSelected([]);
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const insertRegister = (observacao, dataCorrecao, corrigido, tipo, dataInsercao) => {
    const formatDateCorrecao = dataCorrecao ? new Date(dataCorrecao) : null;
    const formatDateInsercao = dataInsercao ? new Date(dataInsercao) : null;
    let newDataCorrecao = null;
    let newDataInsercao = null;
    if (Number(corrigido) === 1) {
      newDataCorrecao = dataCorrecao ? formatDateCorrecao.getFullYear() + "-" + (formatDateCorrecao.getMonth() + 1) + "-" + formatDateCorrecao.getDate() : null;
    }
    newDataInsercao = dataInsercao ? formatDateInsercao.getFullYear() + "-" + (formatDateInsercao.getMonth() + 1) + "-" + formatDateInsercao.getDate() : null;
    const payload = {
      "conferenciasPrestacoesDeContasId": props.conferenciasPrestacoesDeContasId,
      "observacao": observacao,
      "dataCorrecao": newDataCorrecao,
      "corrigido": Number(corrigido) === 1 ? 1 : null,
      "tiposObservacoesConferenciasPrestacoesDeContasId": tipo,
      "dataInsercao": newDataInsercao
    }

    // // @ts-ignore
    api.post(`observacoes-conferencias-prestacoes-de-contas`, payload)
    .then((response) => {
      setMessage('Dados gravados com sucesso!');
      props.updateData();
      setSelected([]);
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao atualizar os dados! Verifique!');
      props.updateData();
      setSelected([]);
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const editRegister = (id, observacao, dataCorrecao, dataInsercao, corrigido) => {
    const formatDateCorrecao = dataCorrecao ? new Date(dataCorrecao) : null;
    const formatDateInsercao = dataInsercao ? new Date(dataInsercao) : null;
    console.log('formatDateInsercao: ', formatDateInsercao);
    let newDataCorrecao = null;
    let newDataInsercao = null;
    if (Number(corrigido) === 1) {
      newDataCorrecao = dataCorrecao ? formatDateCorrecao.getFullYear() + "-" + (formatDateCorrecao.getMonth() + 1) + "-" + formatDateCorrecao.getDate() : null;
    }
    newDataInsercao = dataInsercao ? formatDateInsercao.getFullYear() + "-" + (formatDateInsercao.getMonth() + 1) + "-" + formatDateInsercao.getDate() : null;
    
    const payload = {
      "id": id,
      "observacao": observacao,
      "dataCorrecao": newDataCorrecao,
      "dataInsercao": newDataInsercao,
      "corrigido": Number(corrigido) === 1 ? 1 : null,
    }

    // // @ts-ignore
    api.put(`observacoes-conferencias-prestacoes-de-contas/${id}`, payload)
    .then((response) => {
      props.updateData();
      setSelected([]);
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao atualizar os dados! Verifique!');
      props.updateData();
      setSelected([]);
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const deleteRegister = () => {
    handleCloseDeleteDialog();
    // @ts-ignore
    api.delete(`observacoes-conferencias-prestacoes-de-contas/${rowSelected.id}`)
    .then((response) => {
      setMessage('Dados excluídos com sucesso!');
      props.updateData();
      setSelected([]);
      setBackdrop(false);
      handleClickAlert();
      handleCloseDeleteDialog();
    })
    .catch((err) => {
      setMessage('Erro ao excluir os dados! Verifique!');
      props.updateData();
      setSelected([]);
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - newListValues.length) : 0;

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      {/* <Paper sx={{ m: 4 }}> */}
        
      <SpinnerCarregando open={backdrop} />

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <DeleteDialog open={openDeleteDialog} handleCloseDeleteDialog={handleCloseDeleteDialog} deleteRegister={deleteRegister} />

      <EditDialog open={openEditDialog} rowSelected={rowSelected} tiposDeObservacoes={listObservacoesValues} handleCloseEditDialog={handleCloseEditDialog} editRegister={editRegister} />
      <InsertDialog open={openInsertDialog} tiposDeObservacoes={listObservacoesValues} handleCloseInsertDialog={handleCloseInsertDialog} insertRegister={insertRegister} />

        {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%' }}>

          <Grid item xs={7}>
            <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Solicitações de Correções3</Box>
          </Grid>

          <Grid item direction="row" xs={5}>
          <Box
            component="div"
            className={`${classes.bottomRightBox} ${classes.box}`}>
              <div style={{ paddingRight: 10 }}>
                <DeleteFormatOptions
                  setDisabled={selected.length === 0}
                  editCorrigidoSelecteds={editCorrigidoSelecteds}
                  editPendenteSelecteds={editPendenteSelecteds}
                  deleteSelecteds={deleteSelecteds}/>
              </div>
              <div>
                <Button type="submit" variant="outlined" color="primary" size="small" style={{ height: 35 }} onClick={createNewData}>
                  Nova
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>

        <TableContainer sx={{ maxHeight: 200 }}>
          <Table
            stickyHeader 
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 newListValues.slice().sort(getComparator(order, orderBy)) */}
              {newListValues.length > 0 ? stableSort(newListValues, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {/* <TableCell size="small" padding="checkbox" key={row.id}>
                      <IconButton onClick={() => setIsExpandedData(row.id)}>
                        {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell> */}
                    {/* <TableCell size="small" component="th" id={labelId} scope="row" padding="normal" sx={{  fontSize: 13 }}>
                      {row.anos}
                    </TableCell> */}

                      <TableCell size="small" padding="checkbox">
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>

                    <TableCell
                      padding="none"
                      size="small"
                      align="left"
                      sx={{  fontSize: 11 }}>
                      {row.dataInsercao ? new Date(row.dataInsercao).toLocaleDateString('pt-BR', {timeZone: 'UTC'}) : ''}
                    </TableCell>

                    <TableCell
                      padding="none"
                      size="small"
                      align="left"
                      sx={{ fontSize: 11 }}>
                      {row.observacao}
                    </TableCell>
                    {/* <TableCell size="small" align="left" sx={{ fontSize: 13 }}>{row.corrigido ? "CORRIGIDO" : "PENDENTE"}</TableCell> */}

                    <TableCell padding="none" size="small" align="left" sx={{ fontSize: 11 }}>{row.tiposObservacoesConferenciasPrestacoesDeContas ? row.tiposObservacoesConferenciasPrestacoesDeContas.descricao : ''}</TableCell>


                    <TableCell padding="none" size="small" align="left" className={row.corrigido ? classes.positiveItem : classes.negativeItem} sx={{ minWidth: 50, fontSize: 11 }}>{row.corrigido ? "CORRIGIDO" : "PENDENTE"}</TableCell>

                    <TableCell padding="none" size="small" align="left" sx={{ fontSize: 11 }}>{row.dataCorrecao ? new Date(row.dataCorrecao).toLocaleDateString('pt-BR', {timeZone: 'UTC'}) : ''}</TableCell>
                    <TableCell padding="none" size="small" align="left" sx={{ fontSize: 11 }}>{row.usuarios ? row.usuarios.pessoas.nome : ''}</TableCell>
                    
                    <TableCell padding="none" size="small" align="left" sx={{ fontSize: 11 }}>
                      <LightTooltip  title="Excluir">
                        <IconButton size="small" onClick={(event) => handleOpenDeleteDialog(event, row)}>
                          <HighlightOffOutlinedIcon size="small" sx={{ color:'#c15165', cursor:"pointer" }} />
                        </IconButton>
                      </LightTooltip >

                      <LightTooltip  title="Editar">
                        <IconButton size="small" onClick={(event) => handleOpenEditDialog(event, row)}>
                          <ModeEditOutlinedIcon size="small" color="primary" sx={{ cursor:"pointer" }}/>
                        </IconButton>
                      </LightTooltip >
                    </TableCell>
                    {/* <TableCell size="small" align="left" sx={{  fontSize: 13 }}>{row.valor}</TableCell> */}

                    {/* <TableCell size="small" align="center" sx={{  fontSize: 13 }}><EditPrintMenu id={row.id} path="caixas-escolares-has-cargos-funcoes" /></TableCell> */}
                  </TableRow>
                  );
                }): <> <TableRow><TableCell colSpan={8} size="large" align="left" sx={{ fontSize: 11 }}><span>SEM DADOS</span></TableCell></TableRow></>}
              {emptyRows > 0 && (
                <TableRow
                  // style={{
                  //   height: (dense ? 33 : 53) * emptyRows,
                  // }}
                >
                  <TableCell padding="none" size="small" colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={newListValues.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      {/* </Paper> */}
    </Box>
  );
}
