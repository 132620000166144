import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import { visuallyHidden } from '@mui/utils';

import ClearOutlinedIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

import { Button } from "@mui/material";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import SearchBar from '../../../components/SearchBar';
import EditPrintMenuMembroConselho from '../../../components/EditPrintMenuMembroConselho';
import TotalShow from '../../../components/TotalShow';
import Collapse from '@mui/material/Collapse';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import SpinnerCarregando from '../../../components/SpinnerCarregando';

import { useParams, useHistory } from "react-router-dom";

import { formatDatePtBr } from '../../../utils/functions';


import api from '../../../services/api';

const rows = [];

function descendingComparator(a, b, orderBy) {

  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") < a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return -1;
  }
  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") > a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'caixasEscolaresNome',
    numeric: false,
    disablePadding: false,
    label: 'Função',
    width: '20%',
    align: "left"
  },
  {
    id: 'pessoaCargoFuncao',
    numeric: false,
    disablePadding: false,
    label: 'Função',
    width: '15%',
    align: "left"
  },
  {
    id: 'pessoaNome',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
    width: '30%',
    align: "left"
  },
  {
    id: 'pessoaPeriodoDeMandatosDataInicio',
    numeric: false,
    disablePadding: false,
    label: 'Início do Mandato',
    width: '15%',
    align: "left"
  },
  {
    id: 'pessoaPeriodoDeMandatosDataFinal',
    numeric: false,
    disablePadding: false,
    label: 'Fim do Mandato',
    width: '15%',
    align: "left"
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            size="small"
            width={headCell.width}
            align={headCell.align}
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.pessoaCargoFuncao ? order : false}
          >
            <TableSortLabel
              width={headCell.width}
              align={headCell.align}
              active={orderBy === headCell.pessoaCargoFuncao}
              direction={orderBy === headCell.pessoaCargoFuncao ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.pessoaCargoFuncao ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <span></span>
        </TableCell>
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable(props) {
  const query = useParams();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('pessoaCargoFuncao');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);
  const [showHideSearchBar, setShowHideSearchBar] = React.useState(false);

  const [backdrop, setBackdrop] = React.useState(false);

  const [caixasEscolaresNome, setCaixasEscolaresNome] = React.useState('');

  useEffect(() => {
    setBackdrop(true);
    //@ts-ignore
    const values = !query.id ? api.get("caixas-escolares-has-cargos-funcoes") : api.get(`caixas-escolares-has-cargos-funcoes/por-caixa-escolar/${query.id}`);
    values.then((response) => {
      setListValues(response.data);
      setNewListValues(response.data);
      setBackdrop(false);

      if (query.id) {
        setCaixasEscolaresNome(response.data[0].caixasEscolaresNome);
      }

    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
      setBackdrop(false);
    })
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const classes = useStyles();

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const setNewRegister = () => {
    if (query.id) {
      //@ts-ignore
      history.push(`/conselho-fiscal-escolar/new/${query.id}`)
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
     const [searchField, setSearchField] = React.useState('');
     const [searchData, setSearchData] = React.useState([
       {regioes: []}, {bairros: []}, {tiposEnsinos: []}
     ]);
     const handleChange = async (event) => {
      await setSearchField(event.target.value);
     };

     const handleDataSearch = async (data) => {
      setSearchData(data);
     };

     useEffect(() => {
      //Doing filteration on according to select state option
      let newListValue = listValues;
      newListValue = listValues.filter(
        listValues => listValues.caixasEscolaresNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.pessoaNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
          listValues.pessoaCargoFuncao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      );
      if ((searchData) && (searchData[0].regioes.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[0].regioes.includes(listValues.unidadeEscolarRegionalId)
        );
      }
      if ((searchData) && (searchData[1].bairros.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[1].bairros.includes(listValues.unidadeEscolarBairroId)
        );
      }
      if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
        );
      }

      //set state variable data after filteration
      setNewListValues(newListValue);
        // console.log(searchData)
      }, [searchField, searchData])

      const [isExpanded, setIsExpanded] = React.useState([]);
      const [totalValues, setTotalValues] = React.useState([]);

      const showSearchBar = () => {
        setShowHideSearchBar(!showHideSearchBar)
      }

      const setIsExpandedData = async (id) => {
        let filtered = isExpanded;

        if (isExpanded.includes(id)) {
          filtered = isExpanded.filter(function(value, index, arr){ 
            return value !== id;
          });
        } else {
          filtered.push(id)
        }
        setIsExpanded(filtered)
       };

          useEffect(() => {
         setTotalValues(newListValues.length)
        }, [newListValues]);

  return (
    <Box sx={{ width: '100%', mt: 2  }}>
      {/* <Paper sx={{ width: '100%', mb: 2 }}> */}

        <SpinnerCarregando open={backdrop} />

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 2 }}>
          <Grid item xs={7}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>Membros do Conselho Fiscal e Escolar- Por Caixa Escolar</Box>
              </Typography> 
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}>

              <div style={{ paddingRight: 20 }}>
              <TotalShow title={'Total: '} data={totalValues ? String(totalValues).padStart(2, '0') : '00'} />
              {/*
                <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 100 }}
                >
                  <TextField
                    // className={classes.input}
                    size="small"
                    id="total"
                    color="primary"
                    label="Total"
                    variant="outlined"
                    value={totalValues ? String(totalValues).padStart(2, '0') : '00'}
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </Paper>
              */}
            </div>

            <div style={{ paddingRight: 20 }}>
              <Button onClick={showSearchBar} variant="outlined" color="primary"  endIcon={<SearchOutlinedIcon />} size="small" style={{ height: 35 }}>
                Pesquisar
              </Button>
            </div>

              <div style={{ paddingRight: 20 }}>
                <Button onClick={() => {history.push(`/caixas-escolares-list`)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                  Voltar
                </Button>
              </div>
              {/*<div>
                <Button onClick={() => {setNewRegister()}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                  Novo
                </Button>
              </div>*/}
            </Box>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ flex: '100%', px: 4, pb: 2 }}>
          <Box>
            <Typography component="div">
              <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 1 }}>{caixasEscolaresNome}</Box>
            </Typography> 
          </Box>
        </Grid>

        <Collapse in={showHideSearchBar}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 2 }}>
            <Grid item sx={{ flex: '100%', pt: 2, pb: 3 }}>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <IconButton sx={{ p: '6px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  size="small"
                  placeholder="Pesquisar ..."
                  inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                  onChange={handleChange}
                  value={searchField}
                />
                { searchField.length > 0 ? 
                  <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                    <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                  : null }
              </Paper>
            </Grid>

            <Grid item sx={{ flex: '100%', pt: 0, pb: 2 }}>
              <div><SearchBar onSelectData={handleDataSearch} /></div>
            </Grid>
          </Grid>
        </Collapse>
        
        <TableContainer sx={{ maxHeight: 500, px: 4 }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
            />
            <TableBody>
              {stableSort(newListValues, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.pessoaCargoFuncao);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.pessoaCargoFuncao)}
                        key={row.id}
                      >
                        <TableCell padding="checkbox" key={row.id}>
                          <IconButton onClick={() => setIsExpandedData(row.id)}>
                            {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell align="left" sx={{  fontSize: 13 }}>{row.caixasEscolaresNome}</TableCell>
                        <TableCell align="left" sx={{  fontSize: 13 }}>{row.pessoaCargoFuncao}</TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                          sx={{  fontSize: 13 }}
                        >
                          {row.pessoaNome}
                        </TableCell>
                        <TableCell align="center" sx={{  fontSize: 13 }}>{formatDatePtBr(row.pessoaPeriodoDeMandatosDataInicio)}</TableCell>
                        <TableCell align="center" sx={{  fontSize: 13 }}>{formatDatePtBr(row.pessoaPeriodoDeMandatosDataFinal)}</TableCell>
                        <TableCell align="left" sx={{  fontSize: 13 }}><EditPrintMenuMembroConselho id={row.id} path="/conselho-fiscal-escolar" /></TableCell>
                      </TableRow>

                      {isExpanded.includes(row.id) && (
                        <TableRow>
                          <TableCell padding="checkbox" />
                          <TableCell>Teste</TableCell>
                        </TableRow>
                      )}

                    </>
                    
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}