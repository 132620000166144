import React, { useEffect }  from 'react';
import { useParams, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
//@ts-ignore
// import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import AutoCompleteSelectComponent from "../../../components/Controls/AutoCompleteSelectComponent";
import SelectComponent from "../../../components/Controls/SelectComponent";
import Autocomplete from '@mui/material/Autocomplete';

import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';

import Snackbar from '@mui/material/Snackbar';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';

import ConferenciaTable from '../../../components/ConferenciaTable';
import ComprovantesDeDespesasList from '../../../components/ComprovantesDeDespesasList';

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable(props: any) {
  const history = useHistory();
  const query = useParams();
  const classes = useStyles();

  const [searchField, setSearchField] = React.useState('');
  const [totalValues, setTotalValues] = React.useState([]);
  const [listValues, setListValues] = React.useState([]);
  const [listStatusValues, setListStatusValues] = React.useState([]);
  
  const [listNewValues, setListNewValues] = React.useState([]);
  const [stateValueStatus, setStateValueStatus] = React.useState([]);
  const [errors, setErrors] = React.useState([]);

  const [initialValues, setInitialValues] = React.useState({
    "prestacoesDeContasId": "",
    "caixaEscolarNome": "",
    "unidadeEscolarNome": "",   
    "termoTipo": "",
    "numeroParcelaDescricao": "",
    "tipoTermo": "",
    "ano": "",
    "conferenciasPrestacoesDeContasId": "",
    "statusCaixasEscolares": "",
    "saldoAnterior": 0,
    "verbaTc": 0,
    "verbaPropria": 0,
    "rendimentos": 0,
    "totalCreditos": 0,
    "despesas": 0,
    "despesasProvisorias": 0,
    "devolucao": 0,
    "saldo": 0,
    "observacoesConferencia": [{
      "id": "",
      "anos": "",
      "observacao": "",
      "corrigido": "",
      "dataCorrecao": "",
      "dataInsercao": "",
    }]
  });

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const handleClickAlert = () => {
    setOpen(true);
  };

  //@ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //@ts-ignore
  const handleChange = async (event) => {
    await setSearchField(event.target.value);
  };

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   //@ts-ignore
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  //@ts-ignore
  const formatStatus = (status) => {
    const object = {
      "id": status.id,
      "label": status.descricao,
      "value": status.id,
      "abreviacao": status.abreviacao
    }
    return object;
  }

  //@ts-ignore
  const somaDespesas = (dado) => {
    let total = 0;
    dado.prestacoesDeContas[0].comprovantesDeDespesas.map(
      //@ts-ignore
      (item) => {
        total += parseFloat(item.valorTotal);
      }
   )
   return total;
  }

  //@ts-ignore
  const checkPendencias = (newValue) => {
    const values = api.get(`prestacoes-de-contas/pendencias/${initialValues.conferenciasPrestacoesDeContasId}`)
      values.then((response) => {
        if (response.data.length > 0 && newValue.id === "872bf3f4-1635-11ec-8785-dc41a970c8d9") {
          setMessage('Erro ao gravar os dados! Existem pendências para serem resolvidas! Verifique!');
          setSeverity('error');
          handleClickAlert();
          return
        } else if (response.data.length === 0 && newValue.id === "7eed4075-1635-11ec-8785-dc41a970c8d9") {
          setMessage('Erro ao gravar os dados! Não existem pendências a serem resolvidas! Verifique!');
          setSeverity('error');
          handleClickAlert();
          return
        }
        setInitialValues({...initialValues, "statusCaixasEscolares": newValue});
        updateStatusCaixasEscolares(newValue);
      })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }

  //@ts-ignore
  const updateStatusCaixasEscolares = (newStatus) => {
    const payload = {
      "statusId": newStatus.id
    }
    const values = api.put(`conferencias-prestacoes-de-contas/${initialValues.conferenciasPrestacoesDeContasId}`, payload)
      values.then((response) => {
        // console.log("conferenciasPrestacoesDeContasId", response.data)
        setMessage('Dados atualizados com sucesso!');
        setSeverity('success');
        setBackdrop(false);
        handleClickAlert();
      })
    .catch((err) => {
      // setSubmitting(false);
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const updateTcData = () => {
    const payload = {
      //@ts-ignore
      "saldoAnterior": parseFloat(initialValues.saldoAnterior),
      //@ts-ignore
      "verbaTc": parseFloat(initialValues.verbaTc),
      //@ts-ignore
      "verbaPropria": parseFloat(initialValues.verbaPropria),
      //@ts-ignore
      "rendimentos": parseFloat(initialValues.rendimentos),
      //@ts-ignore
      "despesasProvisorias": parseFloat(initialValues.despesasProvisorias),
      //@ts-ignore
      "devolucao": parseFloat(initialValues.devolucao)
    }
    const values = api.put(`prestacoes-de-contas/${initialValues.prestacoesDeContasId}`, payload)
    values.then((response) => {

      const totalCreditos = parseFloat(response.data.saldoAnterior) + parseFloat(response.data.verbaTc) + parseFloat(response.data.verbaPropria) + parseFloat(response.data.rendimentos);
      const totalDebitos = parseFloat(response.data.despesasProvisorias) + parseFloat(response.data.devolucao);
      const saldo = totalCreditos - totalDebitos;

      setInitialValues({...initialValues, "totalCreditos": totalCreditos, "saldo": saldo});

      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      // setSubmitting(false);
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();

      console.error("ops! ocorreu um erro" + err);
    })
  }

  const getData = () => {
    //@ts-ignore
    const values = api.get(`/parcelas/selecionada/${query.id}`)
    values.then((response) => {
      const dados = response.data;
      // console.log('dados: ', dados);
      // console.log('dados.prestacoesDeContas[0].id', dados.prestacoesDeContas[0].id);
      somaDespesas(dados);
      // console.log("dados.prestacoesDeContas[0].id: ", dados.prestacoesDeContas[0].id);
      const saldoAnterior = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].saldoAnterior ? parseFloat(dados.prestacoesDeContas[0].saldoAnterior) : 0;
      const verbaTc = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaTc ? parseFloat(dados.prestacoesDeContas[0].verbaTc) : 0;
      const verbaPropria = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaPropria ? parseFloat(dados.prestacoesDeContas[0].verbaPropria) : 0;
      const rendimentos = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].rendimentos ? parseFloat(dados.prestacoesDeContas[0].rendimentos) : 0;

      const despesasProvisorias = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].despesasProvisorias ? parseFloat(dados.prestacoesDeContas[0].despesasProvisorias): 0;
      const devolucao = dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].devolucao ? parseFloat(dados.prestacoesDeContas[0].devolucao): 0;

      const totalCreditos =  saldoAnterior + verbaTc + verbaPropria + rendimentos;
      const totalDebitos =  despesasProvisorias + devolucao;
      // console.log('xxxx: ');
      // console.log('xxxx: ', dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].id);
      // console.log('aqui: ', dados.prestacoesDeContas[0].id);
      //@ts-ignore
      setInitialValues({
        'prestacoesDeContasId': dados.prestacoesDeContas[0].id,
        'caixaEscolarNome': dados.termosDeCooperacao.caixasEscolares.nome,
        'unidadeEscolarNome': dados.termosDeCooperacao.caixasEscolares.unidadesEscolares[0].tipos.abreviacao + ' ' + dados.termosDeCooperacao.caixasEscolares.unidadesEscolares[0].nome,
        'termoTipo': dados.termosTipos.descricao,
        'numeroParcelaDescricao': dados.numerosParcelas.descricao,
        'tipoTermo': dados.tiposTermos.descricao,
        "ano": dados.termosDeCooperacao.anos.ano,
        "conferenciasPrestacoesDeContasId": dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].id,
        //@ts-ignore
        "statusCaixasEscolares": formatStatus(dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].status),
        "saldoAnterior": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].saldoAnterior ? dados.prestacoesDeContas[0].saldoAnterior : '0,00',
        "verbaTc": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaTc ? dados.prestacoesDeContas[0].verbaTc : '0,00',
        "verbaPropria": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].verbaPropria ? dados.prestacoesDeContas[0].verbaPropria : '0,00',
        "rendimentos": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].rendimentos ?  dados.prestacoesDeContas[0].rendimentos : '0,00',
        "totalCreditos": totalCreditos,
        "despesas": somaDespesas(dados),
        "despesasProvisorias": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].despesasProvisorias ? dados.prestacoesDeContas[0].despesasProvisorias : '0,00',
        "devolucao": dados.prestacoesDeContas[0] && dados.prestacoesDeContas[0].devolucao ? dados.prestacoesDeContas[0].devolucao : '0,00',
        "saldo": totalCreditos - totalDebitos,
        "observacoesConferencia": dados.prestacoesDeContas[0].conferenciasPrestacoesDeContas[0].observacoesConferenciasPrestacoesDeContas,
      });
      // console.log('dados2: ', dados);
      // setInitialValues(response.data);
      // setListValues(response.data);
      // setNewListValues(response.data);
    })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
    })
  }

  useEffect(() => {
    getData();
  }, []);

  const updateData = () => {
    getData();
  }

  React.useEffect(() => {
    const values = api.get('status')
     values.then((response) => {
       setListStatusValues(response.data);
     })
     .catch((err) => {
       console.error("ops! ocorreu um erro" + err);
     })
   }, []);

  //  const getObservacoesConferenciaData = (prestacaoDeContasId) => {
  //   const values = api.get(`observacoes-conferencias-prestacoes-de-contas/por-prestacao-de-contas/${prestacaoDeContasId}`)
  //   values.then((response) => {
  //     return response.data;
  //   })
  //   .catch((err) => {
  //     console.error("ops! ocorreu um erro" + err);
  //   })
  //  }

  return (initialValues ?
    <Box sx={{ width: '98%', height: '100h', m: 2 }}>
      <SpinnerCarregando open={backdrop} />

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
        <Alert
          // onClose={() => {handleClose}}
          //@ts-ignore
          severity={severity}
          sx={{ width: '100%' }}>
            {message}
        </Alert>
      </Snackbar>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 4 }}>
        <Grid item xs={7}>
          <Box>
            <Typography component="div">
              <Box
                sx={{ fontWeight: 'light', fontSize: 17, color: '#A9A9A9', pt: 2 }}>
                  {initialValues.caixaEscolarNome}
              </Box>
              <Box
                sx={{ fontWeight: 'light', fontSize: 14, color: '#A9A9A9'}}>
                  {initialValues.unidadeEscolarNome} - {initialValues.termoTipo} ({initialValues.numeroParcelaDescricao}) - {initialValues.tipoTermo} - ANO: {initialValues.ano}
              </Box>
            </Typography> 
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            component="div"
            className={`${classes.bottomRightBox} ${classes.box}`}>

            {/* <div style={{ paddingRight: 20 }}>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: 400 }}
              >
                <IconButton sx={{ p: '6px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  size="small"
                  placeholder="Pesquisar ..."
                  inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                  onChange={handleChange}
                  value={searchField}
                />
                { searchField.length > 0 ? 
                  <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                    <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                  : null }
              </Paper>
            </div> */}
            {/* <div style={{ paddingRight: 20 }}>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: 100 }}
              >
                <TextField
                  // className={classes.input}
                  size="small"
                  id="total"
                  color="primary"
                  label="Total"
                  variant="outlined"
                  value={totalValues ? String(totalValues).padStart(2, '0') : '00'}
                  inputProps={{ style: { textAlign: "center" } }}
                />
              </Paper>
            </div> */}
            <div style={{ paddingRight: 20 }}>
              <Button  onClick={() => {history.push(`/termos-de-cooperacao-list`)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                Voltar
              </Button>
            </div>
            <div>
              <Button onClick={updateTcData} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                Gravar
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 4 }}
        >
          <Box sx={{ pb: 1 }}>
            <Autocomplete
              id="controlled-caixa-escolar"
              value={initialValues.statusCaixasEscolares}
              options={listStatusValues}
              //@ts-ignore
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size="small"
              onChange={(event, newValue) => {
                // setInitialValues({...initialValues, "statusCaixasEscolares": newValue});
                // updateStatusCaixasEscolares(newValue);
                checkPendencias(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  label="Status"
                  required
                  fullWidth
                  //@ts-ignore
                  required
                  style={{ width: 300 }}
                />
              )}
            />
          </Box>

          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Saldo Anterior"
              size="small"
              variant="outlined"
              value={initialValues.saldoAnterior}
              outputFormat="string"
              // minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={initialValues.saldoAnterior}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'saldoAnterior': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Termo de Compromisso"
              size="small"
              variant="outlined"
              value={initialValues.verbaTc}
              outputFormat="string"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={initialValues.verbaTc}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'verbaTc': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            *}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Recursos Próprios"
              size="small"
              variant="outlined"
              value={initialValues.verbaPropria}
              outputFormat="string"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={initialValues.verbaPropria}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'verbaPropria': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>

          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Rendimentos"
              size="small"
              variant="outlined"
              value={initialValues.rendimentos}
              outputFormat="string"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={initialValues.rendimentos}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'rendimentos': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Total"
              size="small"
              variant="outlined"
              value={initialValues.totalCreditos}
              outputFormat="string"
              // minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={initialValues.totalCreditos}
              readonly
              inputProps={{ readOnly: true }}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'totalCreditos': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Despesas"
              size="small"
              variant="outlined"
              value={initialValues.despesasProvisorias}
              outputFormat="string"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={initialValues.despesasProvisorias}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'despesasProvisorias': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Devolução"
              size="small"
              variant="outlined"
              value={initialValues.devolucao}
              outputFormat="string"
              minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              defaultValue={initialValues.devolucao}
              //@ts-ignore
              onChange={(event, newValue) => {
                setInitialValues({...initialValues, 'devolucao': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
          
          <Box sx={{ pb: 1 }}>
            {/*
            <CurrencyTextField
              label="Saldo Final"
              size="small"
              variant="outlined"
              value={initialValues.saldo}
              outputFormat="string"
              // minimumValue="0"
              currencySymbol=""
              decimalCharacter=","
              digitGroupSeparator="."
              // defaultValue={initialValues.saldo}
              inputProps={{ readOnly: true }}
              //@ts-ignore
              onChange={(event, newValue) => {
                // setInitialValues({...initialValues, 'saldo': newValue})
              }}
              style={{ width: 120, backgroundColor : '#F5F5F5' }}
            />
            */}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <ComprovantesDeDespesasList />
        </Grid>

        <Grid item xs={12}>
          <ConferenciaTable
            conferenciasPrestacoesDeContasId={initialValues.conferenciasPrestacoesDeContasId}
            observacoesConferencia={initialValues.observacoesConferencia}
            updateData={updateData}
          />
        </Grid>

      </Grid>
    </Box>
    : <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando ...</span></Typography>
  );
}
