import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select } from "@mui/material";

import InputMask from 'react-input-mask';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando'
import Backdrop from '@mui/material/Backdrop';
import TelefonesTable from '../../../components/TelefonesTable';

// import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import api from "../../../services/api";
import FornecedoresCredoresSocios from "../../../components/FornecedoresCredoresSocios";
import { validarCNPJ, replaceAll, validaChaveDeAcessoNotaFiscal, formatDateEnUs,formatDateEnUs2, uuidv4 } from "../../../utils/functions";

import MenuItem from '@mui/material/MenuItem';

// import { uuidv4 } from '../../../utils/functions'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';

import SelectComponent from '../../../components/Controls/SelectComponent';

// import { formatDateEnUs } from "../../../utils/functions";

// import ItensNotasFiscaisRecibos from '../../../components/Controls/ItensNotasFiscaisRecibos';

// import ItensNotasFiscaisRecibosList  from '../../../components/ItensNotasFiscaisRecibosList';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  valorTotal: {
    backgroundColor : "#F5F5F5",
    borderColor: "primary"
  }
}));

export default function DataFunction () {
  const query = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [rows, setRows] = React.useState([]);

  const [initialValues, setInitialValues] = React.useState({});

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const [fornecedoresCredoresId, setFornecedoresCredoresId] = useState('');
  const [comprovantesDeDespesasProvisoriasId, setComprovantesDeDespesasProvisoriasId] = useState('');

  const [bairroDistrito, setBairroDistrito] = useState('');
  const [cep, setCep] = React.useState("");
  const [cnpj, setCnpj] = React.useState(null);
  const [cidadesId, setCidadesId] = React.useState(null);
  const [cidadesList, setCidadesList] = React.useState([]);
  const [endereco, setEndereco] = React.useState("");
  const [estadosId, setEstadosId] = React.useState(null);
  const [estadosList, setEstadosList] = React.useState([]);
  const [fornecedoresCredoresSituacaoId, setFornecedoresCredoresSituacaoId] = React.useState(null);
  const [fornecedoresCredoresSituacaoList, setFornecedoresCredoresSituacaoList] = React.useState([]);
  const [numero, setNumero] = React.useState("");
  const [complemento, setComplemento] = React.useState("");
  const [nomeFantasia, setNomeFantasia] = React.useState("");
  const [nomeRazaoSocial, setNomeRazaoSocial] = React.useState("");
  const [nomeRazaoSocialNomeFantasia, setNomeRazaoSocialNomeFantasia] = React.useState("");
  const [situacao, setSituacao] = React.useState("");
  const [inscricaoMunicipal, setInscricaoMunicipal] = React.useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [site, setSite] = React.useState("");
  const [socios, setSocios] = React.useState([]);
  const [newSocios, setNewSocios] = React.useState([]);
  const [sociosGroup, setSociosGroup] = React.useState("");
  const [sociosTipos, setSociosTipos] = React.useState([]);
  const [telefones, setTelefones] = React.useState(null);

  const [tipoNotaFiscal, setTipoNotaFiscal] = React.useState([]);
  const [tiposComprovantesDeDespesasList, setTiposComprovantesDeDespesasList] = React.useState([]);
  const [chaveDeAcesso, setChaveDeAcesso] = React.useState(null);
  const [dataEmissao, setDataEmissao] = React.useState(null);
  const [dataPagamento, setDataPagamento] = React.useState(null);
  const [dataCompensacaoChequeTransferencia, setDataCompensacaoChequeTransferencia] = React.useState(null);
  const [numeroNotaRecibo, setNumeroNotaRecibo] = React.useState(null);  
  const [numeroChequeOrdemPagamento, setNumeroChequeOrdemPagamento] = React.useState(null);
  const [tiposComprovantesDeDespesasId, setTiposComprovantesDeDespesasId] = React.useState(null);
  const [tiposComprovantesDeDespesas, setTiposComprovantesDeDespesas] = React.useState(null);

  const [especiesId, setEspeciesId] = React.useState(null);
  const [meiosDePagamentosList, setMeiosDePagamentosList] = React.useState([]);
  const [meiosDePagamentosId, setMeiosDePagamentosId] = React.useState(null);
  const [valorTotal, setValorTotal] = React.useState(null);

  const formatNewSocios = () => {

    let newSocios = [];

    if (socios && socios.length > 0 && sociosTipos && sociosTipos.length > 0) {
      let codigo = '';
      let object = {};

      socios.forEach(element => {
        // console.log('element: ', element.qual);
        codigo = element.qual.split('-');
        // console.log('newSociosTipos: ', sociosTipos.find(item => item.codigo === codigo[0]));
        // console.log('código sócio: ', codigo[0]);

        object = {
          nomeEmpresarial: element.nome,
          dataCadastro: formatDateEnUs2(new Date()),
          sociosTiposId: sociosTipos.find(item => item.codigo === codigo[0]).id
        }
        newSocios.push(object);
      });
    }

    setNewSocios(newSocios);
  }
  useEffect(() => {
    formatNewSocios();
  }, [sociosTipos, initialValues]);

  useEffect(() => {
    if (!especiesId) {
      setTiposComprovantesDeDespesas('');
      setTiposComprovantesDeDespesasId(null);
    }
  }, [especiesId]);
  
  useEffect(() => {
    // @ts-ignore
    if (query.id) {
      // @ts-ignore
      const values = api.get(`fornecedores-credores/${query.id}`)
      values.then((response) => {
        const nomeRazaoSocialNomeFantasia = setNomeRazaoSocialNomeFantasia(response.data.nomeRazaoSocial);
        if (response.data.nomeFantasia) {
          nomeRazaoSocialNomeFantasia = setNomeRazaoSocialNomeFantasia(response.data.nomeRazaoSocial + "/" + response.data.nomeFantasia);
        }
        setInitialValues(response.data);
        setCep(response.data.cep);
        setCnpj(response.data.cnpj);
        setBairroDistrito(response.data.bairroDistrito);
        setEndereco(response.data.endereco);
        // setNumero(response.data.numero);
        setComplemento(response.data.complemento);
        setNomeRazaoSocial(response.data.nomeRazaoSocial);
        setNomeFantasia(response.data.nomeFantasia);
        setNomeRazaoSocialNomeFantasia(nomeRazaoSocialNomeFantasia);
        setInscricaoMunicipal(response.data.inscricaoMunicipal);
        setInscricaoEstadual(response.data.inscricaoEstadual);
        setEmail(response.data.email);
        setSite(response.data.site);
        setSocios(response.data.socios);
        setTelefones(response.data.telefones);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    } else {
      setNewRegister();
    }
  }, []);

  useEffect(() => {
      // @ts-ignore
      const values = api.get(`estados`)
      values.then((response) => {
        setEstadosList(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
  }, []);

  useEffect(() => {
    // @ts-ignore
    const values = api.get(`socios-tipos`)
    values.then((response) => {
      setSociosTipos(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
}, []);

  useEffect(() => {
    setErrors([]);
    if (cnpj && cnpj.replace(/\D/g, '').length === 14 && !validarCNPJ(cnpj)) {
      setErrors(['cnpj']);
    } else if (cnpj && cnpj.replace(/\D/g, '').length === 14 && validarCNPJ(cnpj)) {
      getCNPJData(cnpj.replace(/\D/g, ''));
    } 
    
    if (!cnpj || (cnpj.replace(/\D/g, '').length < 14)) {
      clearData();
    }
}, [cnpj]);

const getCidadesPorEstado = () => {
  setCidadesList([]);
  if (estadosId && estadosId.id) {
    const values = api.get(`cidades/por-estado/${estadosId.id}`)
    values.then((response) => {
      setCidadesList(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }
}
  useEffect(() => {
    getCidadesPorEstado();
  }, [estadosId]);

  useEffect(() => {
    setNewRegister();
    if (query.id) {
      // @ts-ignore
      const values = api.get(`fornecedores-credores/${query.id}`)
      values.then((response) => {
        setInitialValues(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  React.useEffect(() => {
    const values = api.get("itens")
     values.then((response) => {
       setRows(response.data)
     })
     .catch((err) => {
       console.error("ops! ocorreu um erro" + err);
     })
   }, []);
 
  useEffect(() => {
    // @ts-ignore
    const values = api.get(`fornecedores-credores-situacao`)
    values.then((response) => {
      setFornecedoresCredoresSituacaoList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    // @ts-ignore
    const values = api.get(`tipos-comprovantes-de-despesas`)
    values.then((response) => {
      setTiposComprovantesDeDespesasList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    setCidadesId(cidadesList.find(item => item.value === initialValues.cidadesId));
  }, [cidadesList]);

  useEffect(() => {
    setEstadosId(estadosList.find(item => item.value === initialValues.estadosId));
  }, [estadosList]);

  useEffect(() => {
    setFornecedoresCredoresSituacaoId(fornecedoresCredoresSituacaoList.find(item => item.value === initialValues.fornecedoresCredoresSituacaoId));
  }, [fornecedoresCredoresSituacaoList]);

  const [errors, setErrors] = React.useState([]);

  const handleClickAlert = () => {
    setOpen(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

   const createNewRegister = () => {
    history.push(`/comprovantes-de-despesas-provisorios/new/${query.parcelasId}/${query.prestacaoDeContasId}`);
    setNewRegister();
  }

  const clearData = () => {
    setBairroDistrito('');
    setEndereco('');
    setNumero('');
    setComplemento('');
    setNomeRazaoSocial('data.nome');
    setNomeFantasia('');
    setNomeRazaoSocialNomeFantasia('');

    setSituacao([]);

    getFornecedoresCredoresSituacaoId([])
    // setInscricaoMunicipal(response.data.inscricaoMunicipal);
    // setInscricaoEstadual(response.data.inscricaoEstadual);
    setEmail('');
    setSocios([]);
    setSociosGroup('');

    getNumerosTelefones('');

    setCep('');
    setInitialValues({ ...initialValues,
      'bairroDistrito': '',
      'endereco': '',
      'numero': '',
      'complemento': '',
      'nomeRazaoSocial': '',
      'nomeFantasia': '',
      'email': ''
    })
  }

  const getEndereco = (cep) => {
    fetch(`https:/viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        // setEndereco(data.logradouro);
        // setBairroDistrito(data.bairro);
        // console.log('data: ', data);
        // console.log('data.ibge: ', data.ibge);
        if (data.ibge) {
          const values = api.get(`cidades/por-cidade-codigo/${data.ibge}`)
          .then((response) => {
            // setEstadosId(estadosList.find(item => item.value === response.data[0].estadosId));
            setInitialValues({ ...initialValues, 'cidadesId': response.data[0].id , 'estadosId': response.data[0].estadosId });

            setEstadosId(response.data[0].estadosId);
            setCidadesId(response.data[0].id);

            // console.log('response: ', response.data[0]);

            getCidadesPorEstado();
            setTimeout(function () {
              // let cidadeId = response;
              // cidadeId = cidadesList.find(item => item.value === response.data[0].id);
              // console.log('cidadeId: ', response);
              // setCidadesId(cidadesList.find(item => item.value === response.data[0].id));
              // setInitialValues({ ...initialValues, 'estadosId': response.data[0].estadosId })
              // setInitialValues({ ...initialValues, 'cidadesId': response.data[0].id })
            }, 1000);
          })
          .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
          })
        }
      })
  }

  const getFornecedoresCredoresSituacaoId = (fornecedoresCredoresSituacaoId) => {
    setFornecedoresCredoresSituacaoId(fornecedoresCredoresSituacaoList.find(item => item.label === fornecedoresCredoresSituacaoId));
  }

  const getSocios = (socios) => {
    if (socios) {
      socios.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
    }
    setSocios(socios);
    // console.log("socios: ", socios);
  }

  const getSociosGroup = (socios) => {
    if (socios) {
      socios.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
    }
    let sociosGroup = ""
    sociosGroup = socios.map(n => {
      return n.nome
    });
    setSociosGroup(sociosGroup);
  }

  const getNumerosTelefones = (telefones) => {
    let retorno = replaceAll(telefones, ' ', '')
    retorno = replaceAll(retorno, '-', '')
    retorno = retorno.split('/');
    const array = [];
    retorno.map(item => (
      array.push({
        'ddd': item.substr(1,2),
        'numero': item.substr(4),
      })
    ))
    setTelefones(array);
  }

  const checkIfRegistered = (cnpj) => {
    // setMessage('Preencha todos os campos! Verifique!');
    // setBackdrop(false);
    // setSeverity('error');
    // handleClickAlert();

    const values = api.get(`fornecedores-credores/pesquisar-cnpj/${cnpj}`)
    .then((response) => {
      setFornecedoresCredoresId(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const getCNPJData = (cnpj) => {
    // fetch(`http://www.receitaws.com.br/v1/cnpj/${cnpj}`)
    fetch(`https://api.allorigins.win/raw?url=http://www.receitaws.com.br/v1/cnpj/${cnpj}`)
      .then((res) => res.json(
      ))
      .then((data) => {
        checkIfRegistered(cnpj);
        // setInitialValues(response.data);
        // setCnpj(response.data.cnpj);
        setBairroDistrito(data.bairro);
        setEndereco(data.logradouro);
        setNumero(data.numero);
        setComplemento(data.complemento);
        setNomeRazaoSocial(data.nome);
        setNomeFantasia(data.nomeFantasia);
        setNomeRazaoSocialNomeFantasia(data.nome);

          if (data.nomeFantasia) {
            setNomeRazaoSocialNomeFantasia(data.nome + "/" + data.nome);
          }

        setSituacao(data.situacao);

        getFornecedoresCredoresSituacaoId(data.situacao)
        // setInscricaoMunicipal(response.data.inscricaoMunicipal);
        // setInscricaoEstadual(response.data.inscricaoEstadual);
        setEmail(data.email);
        getSocios(data.qsa);
        getSociosGroup(data.qsa);

        getNumerosTelefones(data.telefone);

        setCep(data.cep);
        if (data.cep && data.cep.replace(/\D/g, '').length === 8) {
          getEndereco(data.cep.replace(/\D/g, ''));
        }
        setInitialValues({ ...initialValues,
          'bairroDistrito': data.bairro,
          'endereco': data.logradouro,
          'numero': data.numero,
          'complemento': data.complemento,
          'nomeRazaoSocial': data.nome,
          'nomeFantasia': data.nomeFantasia,
          'email': data.email })
      })
  }

  const testCNPJ = (cnpj) => {
    return (cnpj && cnpj.replace(/\D/g, '').length === 14 && validarCNPJ(cnpj));
  }

  const checkErrors = () => {
    const dado = [];
    if (!cnpj || !validarCNPJ(cnpj)) {
      dado.push('cnpj')
    }
    if (!nomeRazaoSocial || nomeRazaoSocial.length <= 9) {
      dado.push('nomeRazaoSocial');
    }
    if (!fornecedoresCredoresSituacaoId || !fornecedoresCredoresSituacaoId.id) {
      dado.push('fornecedoresCredoresSituacaoId');
    }
    if (!especiesId || !especiesId.id) {
      dado.push('especiesId');
    }
    if (!tiposComprovantesDeDespesasId || !tiposComprovantesDeDespesasId.id) {
      dado.push('tiposComprovantesDeDespesasId');
    }
    if (!meiosDePagamentosId || !meiosDePagamentosId.id) {
      dado.push('meiosDePagamentosId');
    }
    if (!numeroNotaRecibo || numeroNotaRecibo.length === 0) {
      dado.push('numeroNotaRecibo');
    }
    if (!dataPagamento || (formatDateEnUs2(dataPagamento)).length !== 10) {
      dado.push('dataPagamento');
    }
    if (!dataEmissao || (formatDateEnUs2(dataEmissao)).length !== 10) {
      dado.push('dataEmissao');
    }
    if (!numeroChequeOrdemPagamento || numeroChequeOrdemPagamento.length === 0) {
      dado.push('numeroChequeOrdemPagamento');
    }
    if (!dataCompensacaoChequeTransferencia || (formatDateEnUs2(dataCompensacaoChequeTransferencia)).length !== 10) {
      dado.push('dataCompensacaoChequeTransferencia');
    }
    // console.log('dataCompensacaoChequeTransferencia: ', dataCompensacaoChequeTransferencia);
    if (!valorTotal || Number(valorTotal) === 0) {
      dado.push('valorTotal');
    }

    setErrors(dado);

    setMessage('Dados gravados com sucesso!');
    setSeverity('success');

    if (dado.length === 0) {
      saveUpdateData();
    } else {
      setMessage('Preencha todos os campos! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
    }
  }

  const comprovantesDeDespesas = [{
    numero: numeroNotaRecibo ? numeroNotaRecibo.toUpperCase() : '',
    chave_de_acesso: '',
    modelo: '',
    serie: '',
    dataEmissao: formatDateEnUs2(dataEmissao),
    valorTotal: valorTotal,
    dataPagamento: formatDateEnUs2(dataPagamento),
    numeroChequeOrdemPagamento: numeroChequeOrdemPagamento ? numeroChequeOrdemPagamento.toUpperCase() : '',
    desconto: null,
    dataCompensacaoChequeTransferencia: formatDateEnUs2(dataCompensacaoChequeTransferencia),
    tiposComprovantesDeDespesasId: tiposComprovantesDeDespesasId && tiposComprovantesDeDespesasId.id ? tiposComprovantesDeDespesasId.id : null,
    meiosDePagamentosId: meiosDePagamentosId && meiosDePagamentosId.id ? meiosDePagamentosId.id : null,
    fornecedoresCredoresId: fornecedoresCredoresId,    
    especiesId: especiesId && especiesId.id ? especiesId.id : null,
    prestacoesDeContasId: query.prestacaoDeContasId
  }]

  const payload = {
    'bairroDistrito': bairroDistrito.toUpperCase(),
    'cep': cep.replace(/\D/g, ''),
    'cidadesId': initialValues.cidadesId ? initialValues.cidadesId : null,
    'cnpj': cnpj ? cnpj.replace(/\D/g, '') : '',
    'complemento': complemento.toUpperCase(),
    'email': email.toLowerCase(),
    'endereco': endereco.toUpperCase(),
    'estadosId': initialValues.estadosId ? initialValues.estadosId : null,
    'id': fornecedoresCredoresId,
    'inscricaoEstadual': inscricaoEstadual,
    'inscricaoMunicipal': inscricaoMunicipal,
    'nomeFantasia': nomeFantasia ? nomeFantasia.toUpperCase() : '',
    'nomeRazaoSocial': nomeRazaoSocial.toUpperCase(),
    'numero': numero.toUpperCase(),
    'site': site,
    'fornecedoresCredoresSituacaoId': fornecedoresCredoresSituacaoId && fornecedoresCredoresSituacaoId.id ? fornecedoresCredoresSituacaoId.id : null,
    'socios': newSocios,
    'comprovantesDeDespesasProvisorias': comprovantesDeDespesas
  }

  const saveUpdateData = () => {

    setMessage('Dados gravados com sucesso!');
    setSeverity('success');

    if (!fornecedoresCredoresId || fornecedoresCredoresId.length === 0) {
      delete payload.id;
      delete payload.comprovantesDeDespesasProvisorias.fornecedoresCredoresId
    } 

    let values = "";

    if (fornecedoresCredoresId) {
      if (!comprovantesDeDespesasProvisoriasId || comprovantesDeDespesasProvisoriasId.length === 0) {
        values = api.post(`comprovantes-de-despesas-provisorias`, comprovantesDeDespesas[0]);
      } else {
        values = api.put(`comprovantes-de-despesas-provisorias/${comprovantesDeDespesasProvisoriasId}`, comprovantesDeDespesas[0]);
      }      
    } else {
      values = api.post(`fornecedores-credores/${fornecedoresCredoresId}`, payload);
    }

    // @ts-ignore
    // const values = fornecedoresCredoresId ? api.post(`comprovantes-de-despesas-provisorias`, comprovantesDeDespesas[0]) : api.put(`fornecedores-credores/${fornecedoresCredoresId}`, payload)
    values.then((response) => {
      setBackdrop(false);
      handleClickAlert();
      if (!fornecedoresCredoresId || fornecedoresCredoresId.length === 0) {
        setFornecedoresCredoresId(response.data.id);
        setInitialValues({...initialValues, fornecedoresCredoresId: response.data.id});
      } else {
        setComprovantesDeDespesasProvisoriasId(response.data.id);
        setInitialValues({...initialValues, comprovantesDeDespesasProvisoriasId: response.data.id});
      }
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const setNewRegister = () => {
    const newValues = {
      "bairroDistrito": "",
      "cep": "",
      "cidadesCodigo": "",
      "cidadesId": null,
      "cidadesNome": "",
      "cnpj": "",
      "complemento": "",
      "email": "",
      "endereco": "",
      "enderecoCompleto": "",
      "estadosAbreviacao": "",
      "estadosCodigo": "",
      "estadosId": "",
      "estadosNome": "",
      "id": "",
      "inscricaoEstadual": "",
      "inscricaoMunicipal": "",
      "nomeFantasia": "",
      "nomeRazaoSocial": "",
      "numero": "",
      "pais": "",
      "site": "",
      "socios": [],
      "telefonesNumeros": [],
      "vendedores": [],
      "vendedoresNomes": "",
      "fornecedoresCredoresSituacaoId": null
    }
    setBairroDistrito("");
    setCep("");
    setCidadesId(null);
    setCnpj("");
    setComplemento("");
    setEmail("");
    setEndereco("");
    setEstadosId(null);
    // 'id': data.id,
    setInscricaoEstadual("");
    setInscricaoMunicipal("");
    setNomeFantasia("");
    setNomeRazaoSocial("");
    setNumero("");
    setSite("");
    setFornecedoresCredoresSituacaoId(null);
    // setSocios([]);
    setInitialValues(newValues);
  }

  const handleChange = (event) => {
    setTipoNotaFiscal(event.target.value);
  }

  const handleTiposComprovantesDeDespesas = (value) => {
    setTiposComprovantesDeDespesas(value && value.label ? value.label : '');
    setTiposComprovantesDeDespesasId(value);
    // alert(JSON.stringify(value, null, 2));
    setInitialValues({...initialValues, 'tiposComprovantesDeDespesasId': value});
  }

  const handleEspecie = (value) => {
    // console.log("value2: ", value);

    setNumeroNotaRecibo(null);
    setDataEmissao(null);
    setEspeciesId(value);
    setInitialValues({...initialValues, 'especie': value});

    let tiposComprovantesDeDespesasId = {
      "abreviacao": "NF",
      "id": "62df2c67-3670-11ec-bc9c-00155d5484f1",
      "label": "NOTA FISCAL",
      "value": "62df2c67-3670-11ec-bc9c-00155d5484f1"
    };

    setTiposComprovantesDeDespesas(tiposComprovantesDeDespesasId.label);
    setTiposComprovantesDeDespesasId(tiposComprovantesDeDespesasId);
    setInitialValues({...initialValues, 'tiposComprovantesDeDespesasId': tiposComprovantesDeDespesasId});

    if (value && value.id === "a35fce1c-11e9-11ec-833c-dc41a970c8d9") {
      setTiposComprovantesDeDespesasId(null);
      setInitialValues({...initialValues, 'tiposComprovantesDeDespesasId': null});
    }
  }

  const handleMeiosDePagamentos = (value) => {
    setMeiosDePagamentosId(value);
    setInitialValues({...initialValues, 'meiosDePagamentosId': value})
  }

  const checkChaveDeAcesso = (chaveDeAcesso) => {
    if (chaveDeAcesso && chaveDeAcesso.replace(/[^\d]+/g,'').length === 44 && !validaChaveDeAcessoNotaFiscal(chaveDeAcesso)) {
      return true;
    }
    return false;
  }

  const itens = [{
    "id": null,
    "quantidadeComercial": null,
    "descricao": null,
    "valorUnitario": null,
    "valorTotal": null,
    // "desconto": null,
    // "origemDosRecursosId": null,
    "comprovantesDeDespesasId": null,
    "tiposRecursosId": null,
    "unidadesDeMedidasId": null,
    "new": null
  }];
  
  // function NumberFormatCustom(props) {
  //   const { inputRef, onChange, ...other } = props;
  
  //   return (
  //     <NumberFormat
  //       {...other}
  //       getInputRef={inputRef}
  //       onValueChange={values => {
  //         onChange({
  //           target: {
  //             name: props.name,
  //             value: values.value
  //           }
  //         });
  //       }}
  //       thousandSeparator
  //       // isNumericString
  //     />
  //   );
  // }

  const handleInsertItem = (itemNotasFiscaisRecibos) => {
    // console.log("itemNotasFiscaisRecibos5555: ", itemNotasFiscaisRecibos);
    const newRows = [];
    const newItem = {
      "ordem": itemNotasFiscaisRecibos.ordem,
      "id": uuidv4(),
      "quantidadeComercial": itemNotasFiscaisRecibos.quantidadeComercial,
      "descricao": itemNotasFiscaisRecibos.descricao.toUpperCase(),
      "valorUnitario": parseFloat(itemNotasFiscaisRecibos.valorUnitario).toLocaleString('pt-BR',{ minimumFractionDigits: 2 }),
      "valorTotal": itemNotasFiscaisRecibos.valorTotal,
      "desconto": itemNotasFiscaisRecibos.desconto,
      "unidadesDeMedidasId": itemNotasFiscaisRecibos.unidadesDeMedidasId.id,
      "unidadesDeMedidasDescricao": itemNotasFiscaisRecibos.unidadesDeMedidasId.descricao,
      "unidadesDeMedidasAbreviacao": itemNotasFiscaisRecibos.unidadesDeMedidasId.abreviacao,
      // "origemDosRecursosId": itemNotasFiscaisRecibos.origemDosRecursosId,
      // "origemDosRecursosDescricao": itemNotasFiscaisRecibos.origemDosRecursosId.descricao,
      // "origemDosRecursosAbreviacao": itemNotasFiscaisRecibos.origemDosRecursosId.abreviacao,
      "tiposRecursosId": itemNotasFiscaisRecibos.tiposRecursosId.id,
      "tiposRecursosDescricao": itemNotasFiscaisRecibos.tiposRecursosId.label,
      "tiposRecursosAbreviacao": itemNotasFiscaisRecibos.tiposRecursosId.abreviacao,
      "comprovantesDeDespesasId": itemNotasFiscaisRecibos.comprovantesDeDespesasId,
      "new": null
    };

    let newOrder = 1;
    rows.forEach(item => {
      item.ordem = newOrder;
      item.new = false;
      newRows.push(item);
      newOrder++;
    })
    newItem.ordem = newOrder;
    newItem.new = true;
    newRows.push(newItem);
    
    setRows(newRows);
    // console.log('rows aqui: ', newRows)
    // setRows(newItem);
  }

  const handleDeleteItem = (selecteds) => {
    // alert('Delete Item Aqui!!!');

    // console.log('selecteds: ', selecteds);
    // console.log('rows: ', rows);

    const newRows = rows.filter(
      item => !selecteds.includes(item.id)
    );

    setRows(newRows);
  }

  const handleChangeOrdem = (data, value) => {
    const newRows = [];

    rows.forEach(item => {
      if (item.id === data.id) {
        // selecteds.push(item.id);
        item.ordem = value;
      }
      // item.new = false;
      // item.quantidadeComercial = 99;
      newRows.push(item);
    })
    
    setRows(newRows);
  }

  const handleOrder = () => {
    const newRows = [];
    // @ts-ignore
    const newR = rows.sort((a,b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0));
    let i = 1;
    newR.forEach(item => {
      item.ordem = i;
      newRows.push(item);
      i++;
    })
    
    setRows(newRows);
  }

  const goList = () => {
    history.push(`/parcelas/correcao-provisoria/${query.parcelasId}`);
  }

  return (
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
          <SpinnerCarregando open={backdrop} />
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
              <>
                <Grid container spacing={2}  sx={{ mt: 3 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ ml: 2 }}
                  >
                    <Box>
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Comprovantes de Despesas Provisórios - Edição </Box>
                      </Typography> 
                    </Box>

                    <Box
                      component="div"
                      className={`${classes.bottomRightBox} ${classes.box}`}>
                        <div>
                          <Button onClick={goList} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20}}>
                            Cancelar
                          </Button>
                        </div>

                        <div>
                          <Button type="reset" onClick={createNewRegister} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20}} disabled={false}>
                            Novo
                          </Button>
                        </div>

                        <div>
                          <Button type="submit" onClick={checkErrors} variant="outlined" color="primary" size="small" style={{ height: 35 }} disabled={false}>
                            Gravar
                          </Button>
                        </div>
                    </Box>
                  </Grid>

                  <Grid container spacing={2}  sx={{ ml: 2, mt: 1}}>
                    <Box>
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Fornecedor</Box>
                      </Typography> 
                    </Box>
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <InputMask
                      mask="99.999.999/9999-99"
                      value={cnpj}
                      onChange={e => {
                          setCnpj(e.target.value)
                          setInitialValues({...initialValues, 'cnpj': e.target.value})
                        }
                      }
                      onBlur={cnpj}
                    >
                      {(inputProps: Props): ReactElement => (
                        <TextField
                          {...inputProps}
                          type="tel"
                          required
                          focused
                          fullWidth
                          size="small"
                          label={("CNPJ")}
                          error={errors.includes('cnpj')}
                          helperText={errors.includes('cnpj') ? 'Campo incorreto!' : ''}
                        />
                      )}
                    </InputMask>
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <TextField
                      size="small"
                      value={situacao}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      label={("Situação")}
                      inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                      onChange={e => {
                          setSituacao(e.target.value)
                          setInitialValues({...initialValues, 'situacao': e.target.value})
                        }
                      }
                      error={errors.includes('fornecedoresCredoresSituacaoId')}
                      helperText={errors.includes('fornecedoresCredoresSituacaoId') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>
                    
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <TextField
                      size="small"
                      value={nomeRazaoSocialNomeFantasia}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      label={("Razão Social/Nome Fantasia")}
                      inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                      onChange={e => {
                          setNomeRazaoSocial(e.target.value)
                          setInitialValues({...initialValues, 'nomeRazaoSocial': e.target.value})
                        }
                      }
                      error={errors.includes('nomeRazaoSocial')}
                      helperText={errors.includes('nomeRazaoSocial') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      size="small"
                      value={sociosGroup}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      label={("Sócios")}
                      inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                      onChange={e => {
                          // setSociosGroup(e.target.value)
                          // setInitialValues({...initialValues, 'sociosGroup': e.target.value})
                        }
                      }
                      error={errors.includes('sociosGroup')}
                      helperText={errors.includes('sociosGroup') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>
                  
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <SelectComponent
                      disabled={!testCNPJ(cnpj)}
                      nLabel="Espécie"
                      newWidth="100%"
                      variant="outlined"
                      apiConst="especies"
                      nDisabled={!testCNPJ(cnpj)}
                      onSelectData={handleEspecie}
                      nRequired="true"
                      error={errors.includes('especiesId')}
                      errorMessage='Campo obrigatório!'
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    {especiesId && especiesId.id === "a35fce1c-11e9-11ec-833c-dc41a970c8d9" ?
                    <SelectComponent
                      disabled={!testCNPJ(cnpj)}
                      nLabel="Tipo Comprovante de Despesas"
                      newWidth="100%"
                      variant="outlined"
                      apiConst="tipos-comprovantes-de-despesas"
                      nDisabled={!testCNPJ(cnpj)}
                      nRequired="true"
                      onSelectData={handleTiposComprovantesDeDespesas}
                      onChange={handleTiposComprovantesDeDespesas} />
                      : <>
                        <TextField
                        size="small"
                        value={tiposComprovantesDeDespesas}
                        disabled={!testCNPJ(cnpj)}
                        required
                        type="tel"
                        focused
                        fullWidth
                        label={("Tipo Comprovante de Despesas")}
                        inputProps={{ style: { textTransform: "uppercase" }, readOnly: true }}
                        error={errors.includes('tiposComprovantesDeDespesasId')}
                        helperText={errors.includes('tiposComprovantesDeDespesasId') ? 'Campo obrigatório!' : ''}
                        // onChange={e => {
                        //     // setSociosGroup(e.target.value)
                        //     // setInitialValues({...initialValues, 'sociosGroup': e.target.value})
                        //   }
                        // }
                        // error={errors.includes('sociosGroup')}
                        // helperText={errors.includes('sociosGroup') ? 'Campo obrigatório!' : ''}
                    />
                      
                      </>}
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <SelectComponent
                      disabled={!testCNPJ(cnpj)}
                      nLabel="Meio de Pagamento"
                      newWidth="100%"
                      variant="outlined"
                      apiConst="meios-de-pagamentos"
                      nDisabled={!testCNPJ(cnpj)}
                      nRequired="true"
                      onSelectData={handleMeiosDePagamentos}
                      error={errors.includes('meiosDePagamentosId')}
                      errorMessage='Campo obrigatório!'
                    />
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>

                  {/*<pre>{JSON.stringify(fornecedoresCredoresId, null, 2)}</pre>*/}
                  {/*<pre>{JSON.stringify(comprovantesDeDespesasProvisoriasId, null, 2)}</pre>*/}
                  
                    <TextField
                      size="small"
                      value={numeroNotaRecibo}
                      // disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      className="valorTotal"
                      label={("Nº Nota Fiscal/Recibo")}
                      inputProps={{ style: { textTransform: "uppercase" }, maxLength: 20}}
                      onChange={e => {
                          setNumeroNotaRecibo(e.target.value)
                          setInitialValues({...initialValues, 'numeroNotaRecibo': e.target.value})
                        }
                      }
                      error={errors.includes('numeroNotaRecibo')}
                      helperText={errors.includes('numeroNotaRecibo') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                    <DatePicker
                      label="Data emissão"
                      value={dataEmissao}
                      disabled={!testCNPJ(cnpj)}
                      onChange={(newValue) => {
                        // @ts-ignore
                        setDataEmissao(newValue);
                        setInitialValues({...initialValues, "dataEmissao": newValue});
                        // errors.pessoaDataNascimento = true;
                        if (newValue) {
                          // @ts-ignore
                          // const formatDate = new Date(newValue);
                          // @ts-ignore
                          // formatDate = formatDate.setDate(formatDate.getDate() + 1);
                          // formatDate = new Date(formatDate);
                          // const newFormatDate = (formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                          // setDataEmissao(newValue);
                          // setInitialValues({...initialValues, "dataEmissao": newValue});
                          // @ts-ignore
                          // initialValues.pessoaDataNascimento = newFormatDate;
                          // errors.pessoaDataNascimento = false;
                        }
                      }}
                      renderInput={(params) =>
                        <TextField
                        {...params}
                        size="small"
                        fullWidth
                        focused
                        required
                        error={errors.includes('dataEmissao')}
                        helperText={errors.includes('dataEmissao') ? 'Campo obrigatório incorreto!' : ''}
                      />}
                    />
                  </LocalizationProvider>
                </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <TextField
                      size="small"
                      value={numeroChequeOrdemPagamento}
                      disabled={!testCNPJ(cnpj)}
                      required
                      type="tel"
                      focused
                      fullWidth
                      className="valorTotal"
                      label={("Nº Cheque/Ordem Pagamento")}
                      inputProps={{ style: { textTransform: "uppercase" }, maxLength: 20 }}
                      onChange={e => {
                          setNumeroChequeOrdemPagamento(e.target.value)
                          setInitialValues({...initialValues, 'numeroChequeOrdemPagamento': e.target.value})
                        }
                      }
                      error={errors.includes('numeroChequeOrdemPagamento')}
                      helperText={errors.includes('numeroChequeOrdemPagamento') ? 'Campo obrigatório!' : ''}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                      <DatePicker
                        label="Data pagamento"
                        value={dataPagamento}
                        disabled={!testCNPJ(cnpj)}
                        onChange={(newValue) => {
                          // @ts-ignore
                          setDataPagamento(newValue);
                          setInitialValues({...initialValues, "dataPagamento": newValue});
                          // errors.pessoaDataNascimento = true;
                          // if (newValue) {
                          //   // @ts-ignore
                          //   // let formatDate = new Date(newValue);
                          //   // @ts-ignore
                          //   // formatDate = formatDate.setDate(formatDate.getDate() + 1);
                          //   // formatDate = new Date(formatDate);
                          //   // const newFormatDate = (formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                          //   setDataPagamento(newValue);
                          //   setInitialValues({...initialValues, "dataPagamento": newValue});
                          //   // @ts-ignore
                          //   // initialValues.pessoaDataNascimento = newFormatDate;
                          //   // errors.pessoaDataNascimento = false;
                          // }
                        }}
                        renderInput={(params) =>
                          <TextField
                          {...params}
                          size="small"
                          fullWidth
                          focused
                          required
                          error={errors.includes('dataPagamento')}
                          helperText={errors.includes('dataPagamento') ? 'Campo obrigatório incorreto!' : ''}
                        />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                    <DatePicker
                      label="Data compensação"
                      value={dataCompensacaoChequeTransferencia}
                      disabled={!testCNPJ(cnpj)}
                      onChange={(newValue) => {
                        // @ts-ignore
                        setDataCompensacaoChequeTransferencia(newValue);
                        setInitialValues({...initialValues, "dataCompensacaoChequeTransferencia": newValue});
                        // errors.pessoaDataNascimento = true;
                        // if (newValue) {
                        //   // @ts-ignore
                        //   // let formatDate = new Date(newValue);
                        //   // @ts-ignore
                        //   // formatDate = formatDate.setDate(formatDate.getDate() + 1);
                        //   // formatDate = new Date(formatDate);
                        //   // const newFormatDate = (formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                        //   // setDataCompensacaoChequeTransferencia(newValue);
                        //   // setInitialValues({...initialValues, "dataCompensacaoChequeTransferencia": newValue});
                        //   // @ts-ignore
                        //   // initialValues.pessoaDataNascimento = newFormatDate;
                        //   // errors.pessoaDataNascimento = false;
                        // }
                      }}
                      renderInput={(params) =>
                        <TextField
                        {...params}
                        size="small"
                        fullWidth
                        focused
                        required
                        error={errors.includes('dataCompensacaoChequeTransferencia')}
                        helperText={errors.includes('dataCompensacaoChequeTransferencia') ? 'Campo obrigatório incorreto!' : ''}
                      />}
                    />
                  </LocalizationProvider>
                </Grid>

                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {/*
                    <CurrencyTextField
                      label="Valor Total"
                      size="small"
                      variant="outlined"
                      value={valorTotal}
                      outputFormat="string"
                      // minimumValue="0"
                      currencySymbol=""
                      decimalCharacter=","
                      digitGroupSeparator="."
                      defaultValue={valorTotal}
                      required
                      fullWidth
                      focused
                      // InputProps={{ style: { color: "#c15165", borderColor: "primary", borderWidth: 2 } }}
                      InputProps={{ style: { backgroundColor: "#E1EBEE" } }}
                      error={errors.includes('valorTotal')}
                      helperText={errors.includes('valorTotal') ? 'Campo obrigatório incorreto!' : ''}
                      disabled={!testCNPJ(cnpj)}
                      onChange={(event, newValue) => {
                        setValorTotal(newValue);
                        setInitialValues({...initialValues, 'valorTotal': newValue});
                      }}
                    />
                    */}
                  </Grid>

              </Grid>
            </>
          {/* <FornecedoresCredoresSocios socios={socios ? socios : []} /> */}
        </Paper>
      </Typography>
    </Container>
  );
}
