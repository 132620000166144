import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import InfoCard from '../InfoCard';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { zeroPad } from "../../utils/functions";

export default function SimplePaper(props) {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 40,
    lineHeight: '40px',
  }));
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'row',
          gridTemplateColumns: 'repeat(7, 1fr)',
          mx: 0,
          gap: 2,
        }}
      >
      {/*<pre>{JSON.stringify(props.data, null, 2)}</pre>*/}
        <Item elevation={2} style={{ backgroundColor: '#e3f2fd' }}>
          <span style={{ color: '#2196f3' }}>
            Saldo Anterior (R$):&nbsp;
          </span>
          <span style={{ color: '#2196f3' }}>
            {props.data.caixas ? zeroPad(props.data.caixas, 2) : '00'}
          </span>
        </Item>
        <Item elevation={2} style={{ backgroundColor: '#f4f0df' }}>
          <span style={{ color: '#d8ad13' }}>
            Termo (R$):&nbsp;
          </span>
          <span style={{ color: '#d8ad13' }}>
            {props.data.caixas ? zeroPad(props.data.totalItens, 2) : '00'}
          </span>
        </Item>
        <Item elevation={2} style={{ backgroundColor: '#ede8f3' }}>
          <span style={{ color: '#883aa8' }}>
            Recursos Próp. (R$):&nbsp;
          </span>
          <span style={{ color: '#883aa8' }}>
            {props.data.termos ? zeroPad(props.data.termos, 2) : '00'}
          </span>
        </Item>
        <Item elevation={2} style={{ backgroundColor: '#dff0dc' }}>
          <span style={{ color: '#2b7e45' }}>
            Rendimentos (R$):&nbsp;
          </span>
          <span style={{ color: '#2b7e45' }}>
            {props.data.total ? props.data.total.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}
          </span>
        </Item>
        <Item elevation={2} style={{ backgroundColor: '#e3f2fd' }}>
          <span style={{ color: '#2196f3' }}>
            Total (R$):&nbsp; 
          </span>
          <span style={{ color: '#2196f3' }}>
            {props.data.despesas ? props.data.despesas.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}
          </span>
        </Item>
        <Item elevation={2} style={{ backgroundColor: '#ffe0e6' }}>
          <span style={{ color: '#ff6384' }}>
            Despesas (R$):&nbsp;
          </span>
          <span style={{ color: '#ff6384' }}>
            {props.data.saldos ? props.data.saldos.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}
          </span>
        </Item>
        <Item elevation={2} style={{ backgroundColor: '#e3f2fd' }}>
          <span style={{ color: '#2196f3' }}>
            Saldo Final (R$):&nbsp;
          </span>
          <span style={{ color: '#2196f3' }}>
            {props.data.saldos ? props.data.saldos.toLocaleString('pt-BR',{ minimumFractionDigits: 2 }) : '0,00'}
          </span>
        </Item>
      </Box>
    </>
  );
}
