import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [disablePrintForm, setDisablePrintForm] = React.useState(true);
  const [disablePrintTermos, setDisablePrintTermos] = React.useState(true);
  const [menuGravar, setMenuGravar] = React.useState(true);
  const [menuGravarFinalizar, setMenuGravarFinalizar] = React.useState(true);
  const [menuImprimir, setMenuImprimir] = React.useState(true);


  useEffect(() => {
    if (props.menuOptions.includes('menuGravar')) {
      setMenuGravar(false);
    }
    if (props.menuOptions.includes('menuGravarFinalizar')) {
      setMenuGravarFinalizar(false);
    }
    if (props.menuOptions.includes('menuImprimir')) {
      setMenuImprimir(false);
    }
  }, [props.menuOptions]);

  const saveForm = () => {
    props.checkErrors();
  }

  const saveFormEnd = () => {
    props.saveFormEnd();
  }

  const printTermos = () => {
    history.push(`/demonstrativo-gerencial-anual-impressao/${props.ano}/${props.ids}`);
  }

  const printDemonstrativoAnual = () => {
    history.push(`/demonstrativo-gerencial-anual-impressao/${props.ano}/${props.ids}`);
  }

  const printDemonstrativoSintetioFnde = () => {
    history.push(`/demonstrativo-sintetico-fnde-impressao/${props.ano}/${props.ids}`);
  }

  useEffect(() => {
    setDisablePrintForm(false);
    setDisablePrintTermos(false);
    if (props.selecteds && props.selecteds.presidentesIds) {
      if (!props.selecteds.presidentesIds.length > 0) {
        setDisablePrintForm(true);
      }
    }
    if (props.selecteds && props.selecteds.caixasEscolaresIds) {
      if (!props.selecteds.caixasEscolaresIds.length > 0) {
        setDisablePrintTermos(true);
      }
    }
  }, [props.selecteds]);


  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon 
        variant="outlined"
        size="small"
      />}
      >
        Ações
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={saveForm} disableRipple disabled={!props.menuOptions.includes('menuGravar')}>
          <SaveAsOutlinedIcon />
            Gravar
        </MenuItem>
        <MenuItem onClick={saveFormEnd} disableRipple  disabled={!props.menuOptions.includes('menuGravarFinalizar')} sx={{ color: '#de4993'}}>
          <SaveOutlinedIcon style={{ color: '#de4993'}} />
            Gravar / Finalizar
        </MenuItem>
        <MenuItem onClick={printDemonstrativoAnual} disableRipple disabled={!props.menuOptions.includes('menuImprimir')}>
          <PrintOutlinedIcon />
            Demonstrativo Anual
        </MenuItem>
        {/*
          <MenuItem onClick={printDemonstrativoSintetioFnde} disableRipple  disabled={!props.menuOptions.includes('menuImprimir')}>
            <PrintOutlinedIcon />
            Demonstrativo Sintético - FNDE
          </MenuItem>
        */}
      </StyledMenu>
      {/* <pre>{JSON.stringify(props.menuOptions, null, 2)}</pre> */}
    </div>
  );
}

