// import React from 'react';
// import PrivateRoutes from './PrivateRoutes';

import React, { useContext, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Context } from '../Context/AuthContext';

import Login from '../pages/Login';
import PaginaInicial from '../pages/PaginaInicial';
import UsuarioEditPostForm from '../pages/Usuarios/UsuarioEditPostForm';

import CaixasEscolaresList from '../pages/CaixasEscolares/CaixasEscolaresList';

import CaixaEscolarEditForm from '../pages/CaixasEscolares/CaixaEscolarEditForm';
import UnidadesEscolaresTable from '../pages/UnidadesEscolares/UnidadesEscolaresTable';
import UnidadeEscolarEditPostForm from '../pages/UnidadesEscolares/UnidadeEscolarEditPostForm';
import ConselhoFiscalEscolarTable from '../pages/ConselhoFiscalEscolar/ConselhoFiscalEscolarTable';
import ConselhoFiscalEscolarTableTodos from '../pages/ConselhoFiscalEscolar/ConselhoFiscalEscolarTableTodos';
import EstatutosAtasList from '../pages/EstatutosAtas';
import EstatutosAtasInsert from '../pages/EstatutosAtas/EstatutosAtasInsert';

import EstatutosAtasRelatorio from '../pages/EstatutosAtas/EstatutosAtasRelatorio';
import FormularioCadastroMembroConselho from '../pages/ConselhoFiscalEscolar/FormularioCadastroMembroConselho';
import FormularioCadastroMembrosCgm from '../pages/ConselhoFiscalEscolar/FormularioCadastroMembrosCgm';
import ConselhoFiscalEscolarEditPostForm from '../pages/ConselhoFiscalEscolar/ConselhoFiscalEscolarEditPostForm';
import TermosDeCooperacaoImpressao from '../pages/TermosDeCooperacao/TermosDeCooperacaoImpressao';
import TermosDeCooperacaoTable from '../pages/TermosDeCooperacao/TermosDeCooperacaoTable';
import TermosDeCooperacaoListCaixasEscolaresEdit from '../pages/TermosDeCooperacao/TermosDeCooperacaoListCaixasEscolaresEdit';
import TermosDeCooperacaoEdit from '../pages/TermosDeCooperacao/TermosDeCooperacaoEdit';
import DadosBancariosList from '../pages/DadosBancarios/DadosBancariosList';
import DadosBancariosEditPost from '../pages/DadosBancarios/DadosBancariosEditPost';
import PerCapitasTable from '../pages/PerCapitas/PerCapitasTable';
import QuantitativoEstudantesTable from '../pages/QuantitativoEstudantes/QuantitativoEstudantesTable';
import QuantitativoEstudantesUnidadesList from '../pages/QuantitativoEstudantesUnidades/QuantitativoEstudantesUnidadesList';
import FornecedoresCredoresList from '../pages/FornecedoresCredores/FornecedoresCredoresList';

import FornecedoresCredoresEditPostForm from '../pages/FornecedoresCredores/FornecedoresCredoresEditPostForm';
import ComprovantesDeDespesasList from '../pages/ComprovantesDeDespesas/ComprovantesDeDespesasList';
import ParcelasEditPost from '../pages/Parcelas/ParcelasEditPostForm';
import ParcelaCorrecao from '../pages/Parcelas/ParcelaCorrecao';
import ParcelaCorrecaoProvisoria from '../pages/Parcelas/ParcelaCorrecaoProvisoria';
// import PrestacoesDeContasLancamentos from '../pages/PrestacaoDeContas/Lancamentos';
import PrestacaoDeContasMenuOpcoes from '../pages/PrestacaoDeContas/PrestacaoDeContasMenuOpcoes';
import PrestacaoDeContasDeliberacaoConselhoEdit from '../pages/PrestacaoDeContas/DeliberacaoConselho/PrestacaoDeContasDeliberacaoConselhoEdit';

import DeliberacaoConselhoImpressao from '../pages/PrestacaoDeContas/DeliberacaoConselho/DeliberacaoConselhoImpressao';

import OrcamentosEdit from '../pages/PrestacaoDeContas/Orcamentos/OrcamentosEdit';



import ComprovantesDeDespesasEditPostForm from '../pages/ComprovantesDeDespesas/ComprovantesDeDespesasEditPostForm';
import ComprovantesDeDespesasEditPostFormProvisorios from '../pages/ComprovantesDeDespesas/ComprovantesDeDespesasEditPostFormProvisorios';
import PresidentesCaixasEscolaresList from '../pages/ConselhoFiscalEscolar/PresidentesCaixasEscolaresList';
import DemonstrativoGerencialAnualEditForm from '../pages/DemonstrativoGerencialAnual/DemonstrativoGerencialAnualEditForm';
import DemonstrativoGerencialAnualImpressao from '../pages/DemonstrativoGerencialAnual/DemonstrativoGerencialAnualImpressao';

import DemonstrativoSinteticoFndeImpressao from '../pages/DemonstrativoGerencialAnual/DemonstrativoSinteticoFndeImpressao';
import OrcamentoEditPostForm from '../pages/OrcamentoEditPostForm';

import AssembleiaEscolarMenuOpcoes from '../pages/AssembleiaEscolar/AssembleiaEscolarMenuOpcoes';
import AtaCaixaEscolarImpressao from '../pages/AssembleiaEscolar/AtaCaixaEscolarImpressao';
import AtaCaixaEscolarOpcoes from '../pages/AssembleiaEscolar/AtaCaixaEscolarOpcoes';
import AtaConselhoEscolarImpressao from '../pages/AssembleiaEscolar/AtaConselhoEscolarImpressao';
import AtaConselhoEscolarOpcoes from '../pages/AssembleiaEscolar/AtaConselhoEscolarOpcoes';
import EditalConvocacaoAssembleiaCaixaEscolar from '../pages/AssembleiaEscolar/EditalConvocacaoAssembleiaCaixaEscolar';
import EditalConvocacaoAssembleiaConselhoEscolar from '../pages/AssembleiaEscolar/EditalConvocacaoAssembleiaConselhoEscolar';
import ListaDePresencaCaixaEscolarImpressao from '../pages/AssembleiaEscolar/ListaDePresencaCaixaEscolarImpressao';
import ListaDePresencaConselhoEscolarImpressao from '../pages/AssembleiaEscolar/ListaDePresencaConselhoEscolarImpressao';
import ListaDePresençaCaixaEscolarOpcoes from '../pages/AssembleiaEscolar/ListaDePresençaCaixaEscolarOpcoes';
import ListaDePresençaConselhoEscolarOpcoes from '../pages/AssembleiaEscolar/ListaDePresençaConselhoEscolarOpcoes';
import FormularioCrcImpressao from '../pages/AssembleiaEscolar/FormularioCrcImpressao';
import FormularioDbeImpressao from '../pages/AssembleiaEscolar/FormularioDbeImpressao';
import FormularioRequerimentoCartorioImpressao from '../pages/AssembleiaEscolar/FormularioRequerimentoCartorioImpressao';

import PlanejamentoFinanceiroAnualEditForm from '../pages/PlanejamentoFinanceiroAnual/PlanejamentoFinanceiroAnualEditForm';
import PlanejamentoFinanceiroAnualImpressao from '../pages/PlanejamentoFinanceiroAnual/PlanejamentoFinanceiroAnualImpressao';
import PlanejamentoDeDespesasList from '../pages/PlanejamentosDeDespesasAdmin/PlanejamentosDeDespesasList';
import PlanejamentoDeDespesasEditForm from '../pages/PlanejamentosDeDespesasAdmin/PlanejamentosDeDespesasEditForm';
import PlanejamentosDeDespesasImpressao from '../pages/PlanejamentosDeDespesasAdmin/PlanejamentosDeDespesasImpressao';

import ProjetoAlemDosMurosEditForm from '../pages/ProjetoAlemDosMuros/ProjetoAlemDosMurosEditForm';
import ProjetoAlemDosMurosImpressao from '../pages/ProjetoAlemDosMuros/ProjetoAlemDosMurosImpressao';
import ProjetoAlemDosMurosList from '../pages/ProjetoAlemDosMuros/ProjetoAlemDosMurosList';


import PrestacaoDeContasImpressao from '../pages/PrestacaoDeContas/PrestacaoDeContasImpressao';


import GraficosVerbaCaixa from '../pages/GraficosVerbasCaixa/';

// const UsuarioEditPostForm = React.lazy(() => import('../pages/Usuarios/UsuarioEditPostForm'));

const Erro404 = React.lazy(() => import('../pages/Erro404'));

function CustomRoute({ isPrivate, ...rest }) {
    const { loading, authenticated } = useContext(Context);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (isPrivate && !authenticated) {
        return <Redirect to="/login" />
    }
    // console.log('isPrivate: ', isPrivate);
    // console.log('authenticated: ', authenticated);

    return <Route {...rest} />;
}

export default function MainRoutes() {
    return (
        <Switch>
        <Suspense fallback={<h3>Carregando...</h3>}>
            {/* <Route path='/login' component={Login} /> */}
            {/* <Route path="*"  render={() => (<Redirect to="/login" component={Login} />)} /> */}

            {/* <Route path="*" component={Erro404} /> */}

            <Route path="/login">
                <Login />
            </Route>

            <Route exact path="/">
                // <Login />
                <Redirect to="/login" />
            </Route>

           <CustomRoute isPrivate exact path="/pagina-inicial">
                <PaginaInicial />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/caixas-escolares-list">
                <CaixasEscolaresList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/caixas-escolares/edit/:id">
                <CaixaEscolarEditForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/caixas-escolares/new">
                <CaixaEscolarEditForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/unidades-escolares-list">
                <UnidadesEscolaresTable />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/unidades-escolares/edit/:id">
                <UnidadeEscolarEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/conselho-fiscal-escolar-list">
                <ConselhoFiscalEscolarTableTodos />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/conselho-fiscal-escolar-list/por-caixa-escolar/:id">
                <ConselhoFiscalEscolarTable />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/conselho-fiscal-escolar-por-escola-list/:id">
                <ConselhoFiscalEscolarTable />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/estatutos-atas-list/por-caixa-escolar/:id">
                <EstatutosAtasList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/estatutos-atas-insert/por-caixa-escolar/:id">
                <EstatutosAtasInsert />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/estatutos-atas-list/relatorio/:caixaEscolarId/:relatorioId">
                <EstatutosAtasRelatorio />
            </CustomRoute>

            {/*<CustomRoute isPrivate exact path="/formulario-cadastro-membro-conselho-pdf/:id">
                <FormularioCadastroMembroConselho />
            </CustomRoute>*/}

            <CustomRoute isPrivate exact path="/formulario-cadastro-cgm-pdf/:ids">
                <FormularioCadastroMembrosCgm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/conselho-fiscal-escolar/:id">
                <ConselhoFiscalEscolarEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/conselho-fiscal-escolar/new/:id">
                <ConselhoFiscalEscolarEditPostForm />
            </CustomRoute> 
            <CustomRoute isPrivate exact path="/termos-de-compromisso-impressao/:id">
                <TermosDeCooperacaoImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/termos-de-cooperacao-list">
                <TermosDeCooperacaoTable />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/termos-de-cooperacao-list/:id">
                <TermosDeCooperacaoTable />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/termos-de-cooperacao/new/caixasEscolaresList">
                <TermosDeCooperacaoListCaixasEscolaresEdit />
            </CustomRoute>


            <CustomRoute isPrivate exact path="/termos-de-cooperacao/edit/:id">
                <TermosDeCooperacaoEdit />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/termos-de-cooperacao/new/principal/:id">
                <TermosDeCooperacaoEdit />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/termos-de-cooperacao/new/aditivo/:id">
                <TermosDeCooperacaoEdit />
            </CustomRoute>


            <CustomRoute isPrivate exact path="/dados-bancarios-list">
                <DadosBancariosList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/dados-bancarios-list/:id">
                <DadosBancariosList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/dados-bancarios/edit/:id">
                <DadosBancariosEditPost />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/dados-bancarios/new">
                <DadosBancariosEditPost />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/percapitas-list/">
                <PerCapitasTable />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/quantitativo-estudantes-list/">
                <QuantitativoEstudantesTable />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/quantitativo-estudantes-unidades-list/">
                <QuantitativoEstudantesUnidadesList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/fornecedores-credores-list/">
                <FornecedoresCredoresList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/fornecedores-credores/edit/:id">
                <FornecedoresCredoresEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/fornecedores-credores/new">
                <FornecedoresCredoresEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/comprovantes-de-despesas-list/">
                <ComprovantesDeDespesasList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/comprovantes-de-despesas/new">
                <ComprovantesDeDespesasEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/comprovantes-de-despesas/edit/:id">
                <ComprovantesDeDespesasEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/comprovantes-de-despesas-provisorios/new">
                <ComprovantesDeDespesasEditPostFormProvisorios />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/comprovantes-de-despesas-provisorios/new/:parcelasId/:prestacaoDeContasId">
                <ComprovantesDeDespesasEditPostFormProvisorios />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/parcelas/edit/:id">
                <ParcelasEditPost />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/parcelas/new">
                <ParcelasEditPost />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/parcelas/correcao/:id">
                <ParcelaCorrecao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/parcelas/correcao-provisoria/:id">
                <ParcelaCorrecaoProvisoria />
            </CustomRoute>
            {/* <CustomRoute isPrivate exact path="/parcelas/prestacao-de-contas/:id">
                <PrestacoesDeContasLancamentos />
                </CustomRoute> */}
            <CustomRoute isPrivate exact path="/parcelas/prestacao-de-contas/:id">
                <PrestacaoDeContasMenuOpcoes />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/parcelas/deliberacao-conselho-edit/:id">
                <PrestacaoDeContasDeliberacaoConselhoEdit />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/parcelas/orcamentos-edit/:id">
                <OrcamentosEdit />
            </CustomRoute>

            

            <CustomRoute isPrivate exact path="/parcelas/deliberacao-conselho-impressao/:id">
                <DeliberacaoConselhoImpressao />
            </CustomRoute>




            <CustomRoute isPrivate exact path="/presidentes-caixas-escolares-list/">
                <PresidentesCaixasEscolaresList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/orcamento-edit-post-form/:id">
                <OrcamentoEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/demonstrativo-gerencial-anual-edit/:id">
                <DemonstrativoGerencialAnualEditForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/demonstrativo-gerencial-anual-impressao/:ano/:ids">
                <DemonstrativoGerencialAnualImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/demonstrativo-sintetico-fnde-impressao/:ano/:ids">
                <DemonstrativoSinteticoFndeImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/usuario/:id">
                <UsuarioEditPostForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/assembleia-escolar-menu-opcoes/:ano/:id">
                <AssembleiaEscolarMenuOpcoes />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/ata-caixa-escolar-impressao/:ano/:id">
                <AtaCaixaEscolarImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/ata-caixa-escolar-opcoes/:ano/:id">
                <AtaCaixaEscolarOpcoes />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/ata-conselho-escolar-impressao/:ano/:id">
                <AtaConselhoEscolarImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/ata-conselho-escolar-opcoes/:ano/:id">
                <AtaConselhoEscolarOpcoes />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/edital-convocacao-assembleia-caixa-escolar/:ano/:id">
                <EditalConvocacaoAssembleiaCaixaEscolar />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/edital-convocacao-assembleia-conselho-escolar/:ano/:id">
                <EditalConvocacaoAssembleiaConselhoEscolar />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/lista-de-presenca-caixa-escolar-impressao/:ano/:id">
                <ListaDePresencaCaixaEscolarImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/lista-de-presenca-conselho-escolar-impressao/:ano/:id">
                <ListaDePresencaConselhoEscolarImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/lista-de-presença-caixa-escolar-opcoes/:ano/:id">
                <ListaDePresençaCaixaEscolarOpcoes />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/lista-de-presença-conselho-escolar-opcoes/:ano/:id">
                <ListaDePresençaConselhoEscolarOpcoes />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/formulario-crc-impressao/:ano/:id">
                <FormularioCrcImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/formulario-dbe-impressao/:ano/:id">
                <FormularioDbeImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/formulario-requerimento-cartorio-impressao/:ano/:id">
                <FormularioRequerimentoCartorioImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/planejamento-financeiro-anual-edit-form/:ano/:id">
                <PlanejamentoFinanceiroAnualEditForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/planejamento-financeiro-anual-impressao/:ano/:id">
                <PlanejamentoFinanceiroAnualImpressao />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/projeto-alem-dos-muros-edit-form/:id">
                <ProjetoAlemDosMurosEditForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/projeto-alem-dos-muros-impressao/:ano/:id">
                <ProjetoAlemDosMurosImpressao />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/projeto-alem-dos-muros-list/:ano/:id">
                <ProjetoAlemDosMurosList />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/planejamento-de-despesas-list/:ano">
                <PlanejamentoDeDespesasList />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/planejamento-de-despesas-anual-edit-form/:ano/:id">
                <PlanejamentoDeDespesasEditForm />
            </CustomRoute>
            <CustomRoute isPrivate exact path="/planejamento-de-despesas-anual-impressao/:ano/:id">
                <PlanejamentosDeDespesasImpressao />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/graficos-verba-caixa/:ano/:id">
                <GraficosVerbaCaixa />
            </CustomRoute>

            <CustomRoute isPrivate exact path="/prestacao-de-contas-impressao">
                <PrestacaoDeContasImpressao />
            </CustomRoute>
            

            


            {/* <Route component = { Erro404 } /> */}
        </Suspense>
        </Switch>
    );
}
