import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const rows = [];

export default function FornecedoresCredoresSocios(props) {
  return (
    <TableContainer component={Paper}>
      {/* <pre>{JSON.stringify(props.socios, null, 2)}</pre> */}
      <Table size="small" aria-label="a table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Sócio(s)</TableCell>
            <TableCell align="left">Data Cadastro</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.socios.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.nome}</TableCell>
              <TableCell align="left">{row.dataCadastroFormatada}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
