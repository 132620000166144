import React, { useEffect } from 'react';
import { useParams, useHistory, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import CircularProgress from '@mui/material/CircularProgress';

import { Pagination } from '@mui/material';

import ClearOutlinedIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

import { Button } from "@mui/material";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import SearchBarTermos from '../../../components/SearchBarTermos';

import EditPrintMenuTermos from '../../../components/EditPrintMenuTermos';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import InfoCardsTermos from '../../../components/InfoCardsTermos';

import Collapse from '@mui/material/Collapse';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabsExample from '../../../components/TablsExample';

import api from '../../../services/api';

const rows = [];

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    cursor: 'pointer'
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") < a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return -1;
  }
  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") > a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'caixaEscolarNome',
    numeric: false,
    disablePadding: true,
    label: 'Caixa Escolar',
    width: '20%',
    align: "left"
  },
  // {
  //   id: 'termoDeCooperacaoAno',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Ano',
  //   width: '3%',
  //   align: "center"
  // },
  {
    id: 'numeroParcelasCusteioDescricao',
    numeric: false,
    disablePadding: true,
    label: 'Parcela',
    width: '2%',
    align: "center"
  },
  {
    id: 'termoDeCooperacaoNumero',
    numeric: false,
    disablePadding: true,
    label: 'Termo',
    width: '10%',
    align: "left"
  },
  {
    id: 'termosTiposDescricao',
    numeric: false,
    disablePadding: true,
    label: 'Tipo',
    width: '15%',
    align: "left"
  },
  {
    id: 'prestacoesDeContasSaldoAnterior',
    numeric: false,
    disablePadding: true,
    label: 'Saldo Anterior',
    width: '8%',
    align: "right"
  },
  {
    id: 'valor',
    numeric: false,
    disablePadding: true,
    label: 'TC',
    width: '8%',
    align: "right"
  },
  {
    id: 'prestacoesDeContasVerbaPropria',
    numeric: false,
    disablePadding: true,
    label: 'Recursos Próprios',
    width: '8%',
    align: "right"
  },
  {
    id: 'prestacoesDeContasRendimentos',
    numeric: false,
    disablePadding: true,
    label: 'Rendimentos',
    width: '8%',
    align: "right"
  },
  {
    id: 'prestacoesDeContasTotalDespesas',
    numeric: false,
    disablePadding: true,
    label: 'Despesas',
    width: '8%',
    align: "right"
  },
  {
    id: 'prestacoesDeContasDevolucao',
    numeric: false,
    disablePadding: true,
    label: 'Devolução',
    width: '8%',
    align: "right"
  },
  {
    id: 'prestacoesDeContasSaldo',
    numeric: false,
    disablePadding: true,
    label: 'Saldo',
    width: '8%',
    align: "right"
  },
  {
    id: '',
    numeric: false,
    disablePadding: true,
    label: 'Status',
    width: '8%',
    align: "center"
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            size="small"
            width={headCell.width}
            align={headCell.align}
            key={headCell.caixaEscolarNome}
            padding='normal'
            // sortDirection={orderBy === headCell.caixaEscolarNome ? order : false}
            sx={{ padding: 1 }}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell>
          <span></span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  negativeItem: {
    color: "#c15165"
  },
  positiveItem: {
    color: "#4a89c8"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EnhancedTable(props) {
  const query = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('caixaEscolarNome');
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);

  const [data, setData] = React.useState([]);

  const [showHideSearchBar, setShowHideSearchBar] = React.useState(false);

  const termosDeCompromissoNew = () => {
    history.push(`/termos-de-cooperacao/new/caixasEscolaresList`)
  }

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const handleChangePage = async (event, value) => {
    setPage(value && value !== "undefined" ? value : 1);
    getData(value && value !== "undefined" ? value : 1);
  };

  const getData = (page) => {
    page = page && page !== "undefined" ? page : 1;
    setLoading(true);
    // console.log('searchData_1: ', JSON.stringify(searchData[0].anos, null, 2))

    const caixasEscolares = query.id ? query.id: searchData[1].caixasEscolares;

    const payload = {
      anos: searchData[0].anos,
      caixasEscolares: caixasEscolares,
      unidadesEscolares: searchData[2].unidadesEscolares,
      tiposTermos: searchData[3].tiposTermos,
      termosTipos: searchData[4].termosTipos,
      status: searchData[5].status,
      numerosParcelas: searchData[6].numerosParcelas,
      analistas: searchData[7].analistas
    }

    const values = api.post(`parcelas/todas-parcelas?page=${page}&size=6`, payload)
     values.then((response) => {
      // console.log('response.data.data: ', response.data.data);
      setData(response.data.data);
      setListValues(response.data.parcelas);
      setNewListValues(response.data.parcelas);
      setTotalPages(response.data.totalPages);
      setTotalValues(response.data.totalItems);
      setLoading(false);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
      setLoading(false);
    })
  }

  const showSearchBar = () => {
    setShowHideSearchBar(!showHideSearchBar)
  }

  useEffect(() => {
    getData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const classes = useStyles();

  const handleSearchItem = () => {
    getData();
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 0;
    // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
     const [searchField, setSearchField] = React.useState('');
     const [searchData, setSearchData] = React.useState([
       {anos: []}, {caixasEscolares: []}, {unidadesEscolares: []}, {tiposTermos: []}, {termosTipos: []}, {status: []}, {numerosParcelas: []}, {analistas: []}
     ]);
     const handleChangeSearch = async (event) => {
      await setSearchField(event.target.value);
     };

     const handleDataSearch = async (data) => {
      //  console.log(data);
      setSearchData(data);
     };

     const onSearchData = async (data) => {
      getData();
     };

     useEffect(() => {
      //Doing filteration on according to select state option
      let newListValue = listValues;
      // console.log('listValues: ', listValues);
      getData(1);

      if (searchData && searchData[0]) {
        // console.log('searchData: ', searchData[0].anos.length);
        // if (searchData[0].anos.length === 0) {
        //   getData(1);
        // }
      }

      // newListValue = listValues.filter(
      //   listValues => listValues.caixaEscolarNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
      //   listValues.numeroParcelasCusteioDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
      //   listValues.termosTiposDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
      //   listValues.tiposTermosDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //     .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
      //     listValues.conferenciasPrestacoesDeContasStatusDescricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      //       .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      // );
      // if ((searchData) && (searchData[0].regioes.length > 0)) {
      //   newListValue = listValues.filter(
      //     listValues => searchData[0].regioes.includes(listValues.regiao_id)
      //   );
      // }
      // if ((searchData) && (searchData[0].regioes.length > 0)) {
      //   newListValue = listValues.filter(
      //     listValues => searchData[0].regioes.includes(listValues.regiao_id)
      //   );
      // }
      // if ((searchData) && (searchData[1].bairros.length > 0)) {
      //   newListValue = listValues.filter(
      //     listValues => searchData[1].bairros.includes(listValues.bairro_id)
      //   );
      // }
      // if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
      //   newListValue = listValues.filter(
      //     listValues => searchData[2].tiposEnsinos
      //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
      //       searchData[2].tiposEnsinos
      //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
      //       searchData[2].tiposEnsinos
      //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
      //       searchData[2].tiposEnsinos
      //       .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
      //   );
      // }

      //set state variable data after filteration
      // setNewListValues(newListValue);
        // console.log(searchData)
      }, [searchData])

      const [isExpanded, setIsExpanded] = React.useState([]);
      const [totalValues, setTotalValues] = React.useState('00');


      const setIsExpandedData = async (id) => {
        let filtered = isExpanded;

        if (isExpanded.includes(id)) {
          filtered = isExpanded.filter(function(value, index, arr){ 
            return value !== id;
          });
        } else {
          filtered.push(id)
        }
        setIsExpanded(filtered)
       };

          useEffect(() => {
        //  setTotalValues(newListValues.length)
        }, [newListValues]);

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}

        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 1 }}>

          <Grid item xs={7}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2, pl: 0 }}>Termos de Compromisso - Listagem</Box>
              </Typography> 
            </Box>
          </Grid>

          <Grid item xs={5} sx={{ pr: 0 }}>
            <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}
              sx={{ pr: 4 }}>

              {/*<div style={{ paddingRight: 20 }}>
                <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 100 }}
                >
                  <TextField
                    // className={classes.input}
                    size="small"
                    id="total"
                    color="primary"
                    label="Total"
                    variant="outlined"
                    value={totalValues ? String(totalValues).padStart(2, '0') : '00'}
                    inputProps={{ style: { textAlign: "center" } }}
                  />
                </Paper>
              </div>*/}
              <div style={{ paddingRight: 40 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Tabela" {...a11yProps(0)} />
                    <Tab label="Gráficos" {...a11yProps(1)} />
                    {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
                  </Tabs>
                </Box>
             </div>

              <div style={{ paddingRight: 15 }}>
                <Button onClick={termosDeCompromissoNew} variant="outlined" color="primary" size="small" style={{ height: 35, maxWidth: '130px', minWidth: '130px' }}>
                  Novo
                </Button>
              </div>

              <div>
                <Button onClick={showSearchBar} variant="outlined" color="primary"  endIcon={<SearchOutlinedIcon />} size="small" style={{ height: 35 }}>
                  Pesquisar
                </Button>
              </div>
            </Box>
          </Grid>

          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 2 }}>
            <Grid item sx={{ flex: '100%', pt: 2 }}>
              <Collapse in={showHideSearchBar}>
                <div>
                  <SearchBarTermos onSelectData={handleDataSearch} onSearchData={onSearchData} newData={data} showHideSearchBar={showHideSearchBar} />
                </div>
              </Collapse>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', pb: 2}}>
            <Grid item sx={{ flex: '100%', pt: 0 }}>
              {/* <div><SearchBar onSelectData={handleDataSearch} /></div> */}
              <InfoCardsTermos data={data} />
            </Grid>
          </Grid>

        </Grid>

        <TabPanel value={value} index={0}>
          <Box sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: '100%', width: '100%', paddingBottom: 2 }}>
            { loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
              <CircularProgress />
            </Box> :
              <Table
                aria-labelledby="tableTitle"
                size='small'
                stickyHeader
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
                />
                <TableBody>
                  {stableSort(newListValues, getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.caixaEscolarNome);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <>
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.caixaEscolarNome)}
                            key={row.id}
                          >
                            <TableCell padding="checkbox" key={row.id}>
                              <IconButton onClick={() => setIsExpandedData(row.id)}>
                                {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>

                            <TableCell>
                              <span align="left" style={{ fontSize: 12 }} sx={{ minWidth: 125, padding: 0 }}>
                                {row.caixaEscolarNome}
                              </span>
                              <br />
                              <span style={{ fontSize: 12, color: '#BEBEBE' }}>{row.unidadeEscolarNomeCompleto}&nbsp;</span>
                            </TableCell>
                            {/*} <TableCell align="center" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.termoDeCooperacaoAno}</TableCell> */}
                            <TableCell align="center" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.numeroParcelasCusteioDescricao}</TableCell>

                            { row.termoDeCooperacaoNumero ? 
                              <TableCell align="left" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.termoDeCooperacaoNumero + 'º - ' + row.termosTiposDescricao}</TableCell> :
                              <TableCell align="left" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.termosTiposDescricao}</TableCell>
                            }
                            <TableCell align="left" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.tiposTermosDescricao}</TableCell>
                            <TableCell align="right" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.prestacoesDeContasSaldoAnterior}</TableCell>

                            <TableCell align="right" className={row.valor} sx={{ minWidth: 50, fontSize: 11, padding: 1 }}>{row.valor}</TableCell>

                            <TableCell align="right" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.prestacoesDeContasVerbaPropria}</TableCell>
                            <TableCell align="right" style={{ fontSize: 11 }} sx={{ minWidth: 50, padding: 1 }}>{row.prestacoesDeContasRendimentos}</TableCell>

                            <TableCell align="right" style={{ fontSize: 11 }} className={classes.negativeItem} sx={{ minWidth: 50, padding: 1 }}>{row.prestacoesDeContasTotalDespesas}</TableCell>
                            <TableCell align="right" style={{ fontSize: 11 }} className={classes.negativeItem} sx={{ minWidth: 50, padding: 1 }}>{row.prestacoesDeContasDevolucao}</TableCell>
                            <TableCell align="right" style={{ fontSize: 11 }} className={parseFloat(row.prestacoesDeContasSaldo.replace('.', ',').replace(',', '.')) < 0 ? classes.negativeItem : classes.positiveItem} sx={{ minWidth: 50, fontSize: 12, padding: 1 }}>{row.prestacoesDeContasSaldo}</TableCell>
                            
                            <TableCell align="center" style={{ fontSize: 11 }} sx={{ minWidth: 100, padding: 1 }}>
                              <LightTooltip placement="top" title={row.conferenciasPrestacoesDeContasStatusDescricao}>
                                <Chip color={row.conferenciasPrestacoesDeContasStatusAbreviacao === 'AP' ? "primary" : row.conferenciasPrestacoesDeContasStatusAbreviacao === 'AA' ? 'warning' : 'error'} variant="outlined" size="small" label={row.conferenciasPrestacoesDeContasStatusAbreviacao} sx={{ fontSize: 10, cursor: 'pointer' }} />
                              </LightTooltip>
                            </TableCell>

                            <TableCell align="center" sx={{ minWidth: 20, padding: 0 }}><EditPrintMenuTermos row={row} /></TableCell>
                          </TableRow>

                          {isExpanded.includes(row.id) && (
                            row.conferenciasPrestacoesDeContas && row.conferenciasPrestacoesDeContas[0].observacoesConferenciasPrestacoesDeContas.length > 0 ? 
                              row.conferenciasPrestacoesDeContas[0].observacoesConferenciasPrestacoesDeContas.map((i, index) => (
                                <TableRow>
                                  <TableCell colSpan={15} align="left" sx={{ minWidth: 50, fontSize: 11, paddingLeft: 5 }}>
                                    <span className={i.corrigido ? classes.positiveItem : classes.negativeItem}>{i.corrigido ? "CORRIGIDO" : "PENDENTE"}</span> - <span>{(index + 1) <= 9 ? '0' + (index + 1) : (index + 1)} - {i.observacao}</span>
                                  </TableCell>
                                </TableRow>
                              ))
                            : <>
                                <TableRow>
                                  <TableCell colSpan={15} align="left" sx={{ minWidth: 50, fontSize: 12, padding: 1, paddingLeft: 5 }}>
                                    <span>NÃO HÁ REGISTROS</span>
                                  </TableCell>
                                </TableRow>
                              </>
                          )}
                        </>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                  <TableCell align="center" colSpan={15}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ flex: '100%', px: 2, pt: 2 }}>
                      <Grid item>
                        <Pagination
                          count={totalPages}
                          variant="outlined"
                          color="primary"
                          showFirstButton
                          showLastButton
                          sx={{ maxHeight: '100%', width: '100%', paddingBottom: 2 }}
                          page={page}
                          onChange={handleChangePage}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                  </TableRow>
                </TableFooter>
              </Table> }
            </TableContainer>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box sx={{ height: '100%', width: '100%' }}>
            <TabsExample />
            </Box>
            </Grid>
        </TabPanel>
















    </Box>
  );
}