import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';

import { visuallyHidden } from '@mui/utils';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import ClearOutlinedIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

import { Button } from "@mui/material";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import SearchCaixasEscolaresBar from '../../../components/SearchCaixasEscolaresBar';
import EditPrintMenuPlanejamentoDespesas from '../../../components/EditPrintMenuPlanejamentoDespesas';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import Chip from '@mui/material/Chip';
import {Link} from 'react-router-dom';

import Collapse from '@mui/material/Collapse';

import TotalShow from '../../../components/TotalShow';

import SpinnerCarregando from '../../../components/SpinnerCarregando';

import AutoCompleteSelectComponent from "../../../components/Controls/AutoCompleteSelectComponent";

import { formatTelefones, formatCEP, formatCNPJ } from '../../../utils/functions';

import ButtomMenuCaixas from '../../../components/ButtomMenuCaixas';

import api from '../../../services/api';

const rows = [];

function descendingComparator(a, b, orderBy) {

  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") < a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return -1;
  }
  if (b && b[orderBy] && b[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "") > a && a[orderBy] && a[orderBy].normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'caixasEscolaresNome',
    numeric: false,
    disablePadding: true,
    label: 'Caixa Escolar',
    width: '25%',
    align: "left"
  },
  {
    id: 'finalizado',
    numeric: false,
    disablePadding: false,
    label: 'Finalizado',
    width: '8%',
    align: "center"
  },
  // {
  //   id: 'nomeCompleto',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Unidade de Ensino',
  //   width: '20%',
  //   align: "left"
  // },
  {
    id: 'percapita',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
    width: '8%',
    align: "center"
  },
  {
    id: 'verbaRecebidaAnoAnterior',
    numeric: false,
    disablePadding: false,
    label: 'Verba (ano anterior - R$)',
    width: '15%',
    align: "right"
  },
  {
    id: 'valorTotalSolicitado',
    numeric: false,
    disablePadding: false,
    label: 'Valor Solicitado (R$)',
    width: '15%',
    align: "right"
  },
  {
    id: 'valorTotalLiberado',
    numeric: false,
    disablePadding: false,
    label: 'Valor Liberado (R$)',
    width: '15%',
    align: "right"
  },
  
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>

        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>

        <TableCell padding="checkbox" align='center'>
            <span>#</span>
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            size="small"
            width={headCell.width}
            align={headCell.align}
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.caixasEscolaresNome ? order : false}
          >
            <TableSortLabel
              width={headCell.width}
              align={headCell.align}
              active={orderBy === headCell.caixasEscolaresNome}
              direction={orderBy === headCell.caixasEscolaresNome ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.caixasEscolaresNome ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <span></span>
        </TableCell>
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable(props) {
  const query = useParams();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('caixasEscolaresNome');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [listValues, setListValues] = React.useState([]);
  const [newListValues, setNewListValues] = React.useState([]);
  const [showHideSearchBar, setShowHideSearchBar] = React.useState(false);
  const [title, setTitle] = React.useState('Planejamento de Despesas');

  const [quantidadeInativas, setQuantidadeInativas] = React.useState(0);

  const [newSelecteds, setNewSelecteds] = React.useState([{
    "presidentesIds": [],
    "caixasEscolaresIds": []
  }]);

  const [backdrop, setBackdrop] = React.useState(false);

  const getInativas = async (values) => {
    // console.log('values: ', values);
    const newListValues = values.filter(
      listValues => listValues.statusCaixasEscolaresId === '02e9f9ce-17ae-11ec-8785-dc41a970c8d9'
    );
    // console.log('newListValues.length: ', newListValues.length);
    setQuantidadeInativas(newListValues.length);
  }

  const getNewData = () => {
    setBackdrop(true);
    const values = api.get("planejamentos-financeiros/por-ano/ef997f88-81f6-11ec-b847-00155d6335ac");
    values.then((response) => {
      setTitle(`Planejamento de Despesas - Ano: ${response.data[0].ano}`)
      setListValues(response.data);
      setNewListValues(response.data);
      getInativas(response.data);
      setBackdrop(false);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
      setBackdrop(false);
    })
  }

  useEffect(() => {
    getNewData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const classes = useStyles();

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  // const setPresidentes = () => {
  //   let news = [];

  //   newListValues.forEach(element => {
  //     if (element.includes(element.id)) {
  //       news.push(element.presidenteId);
  //     }
  //   });
  //   setNewSelecteds(news);
  // }

  useEffect(() => {
    let object = {
      presidentesIds: [],
      caixasEscolaresIds: []
    };
    setNewSelecteds(object);
    newListValues.map((n) => {
      if (selected.includes(n.id)) {
        if (n.presidenteId && n.presidenteId.length === 36) {
          object.presidentesIds.push(n.presidenteId);
        }
          object.caixasEscolaresIds.push(n.caixasEscolaresId);
      }
    });
    setNewSelecteds(object);
  }, [selected]);



  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newListValues.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const showSearchBar = () => {
    setShowHideSearchBar(!showHideSearchBar)
  }

  const setNewRegister = () => {
    history.push(`caixas-escolares/edit/new`)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
     const [searchField, setSearchField] = React.useState('');
     const [searchData, setSearchData] = React.useState([
       {regioes: []}, {bairros: []}, {tiposEnsinos: []}, {situacaoesMandato: []}
     ]);
     const handleChange = async (event) => {
      await setSearchField(event.target.value);
     };

     const handleDataSearch = async (data) => {
      setSearchData(data);
     };

     const setNewSearch = () => {
      //Doing filteration on according to select state option
      let newListValue = listValues;
      newListValue = listValues.filter(
        listValues => listValues.nomeCompleto.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.caixasEscolaresNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase()) ||
        listValues.presidenteNome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(searchField.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      );
      if ((searchData) && (searchData[0].regioes.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[0].regioes.includes(listValues.regiaoId)
        );
      }
      if ((searchData) && (searchData[1].bairros.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[1].bairros.includes(listValues.bairroId)
        );
      }
      if ((searchData) && (searchData[2].tiposEnsinos.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[0]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[1]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[2]) ||
            searchData[2].tiposEnsinos
            .includes(listValues.unidadesEscolaresHasTiposEnsinosCodigos[3])
        );
      }

      if ((searchData) && searchData[3]  && searchData[3].situacaoesMandato && (searchData[3].situacaoesMandato.length > 0)) {
        newListValue = listValues.filter(
          listValues => searchData[3].situacaoesMandato.includes(listValues.presidenteSituacaoMandatoId)
        );
      }

        //set state variable data after filteration
        setNewListValues(newListValue);
        // console.log(searchData)
      }

     useEffect(() => {
      setNewSearch();
     }, [searchField, searchData])

      const [isExpanded, setIsExpanded] = React.useState([]);
      const [totalValues, setTotalValues] = React.useState([]);
      const setIsExpandedData = async (id) => {
        let filtered = isExpanded;
        if (isExpanded.includes(id)) {
          filtered = isExpanded.filter(function(value, index, arr){ 
            return value !== id;
          });
        } else {
          filtered.push(id)
        }
        setIsExpanded(filtered)
       };

      useEffect(() => {
        setTotalValues(Number(newListValues.length) - Number(quantidadeInativas));
      }, [newListValues, quantidadeInativas]);

  return (
    <React.Fragment>
    <SpinnerCarregando open={backdrop} />
    <CssBaseline />
    {/* <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ p: 1, m: 2, display: 'flex', flexDirection: 'column' }}>
          <div> */}

          {/* <pre>{JSON.stringify(quantidadeInativas, null, 2)}</pre> */}

            <Box sx={{ width: '100%', mt: 2 }}>
              {/* <Paper sx={{ width: '100%', mb: 2 }}> */}

                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 2 }}>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{title }</Box>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={8}>
                    <Box
                      component="div"
                      className={`${classes.bottomRightBox} ${classes.box}`}>

                      <div style={{ paddingRight: 35 }}>
                        <ButtomMenuCaixas selecteds={newSelecteds} />
                      </div>

                      <div style={{ paddingRight: 35 }}>
                        <Button onClick={showSearchBar} variant="text" color="primary"  endIcon={<SearchOutlinedIcon />} size="small" style={{ height: 35 }}>
                          Pesquisar
                        </Button>
                      </div>

                      <div style={{ paddingRight: 0 }}>
                        <TotalShow title={'Total: '} data={totalValues ? String(totalValues).padStart(2, '0') : '00'} />
                       </div>
                    </Box>
                  </Grid>
                </Grid>

                <Collapse in={showHideSearchBar}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 2 }}>
                    <Grid item sx={{ flex: '100%', pt: 2, pb: 3 }}>
                      <Paper
                        component="form"
                        sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
                      >
                        <IconButton sx={{ p: '6px' }} aria-label="search">
                          <SearchIcon />
                        </IconButton>
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          size="small"
                          placeholder="Pesquisar ..."
                          inputProps={{ 'aria-label': 'Pesquisar', style: { textTransform: "uppercase", fontSize: 12 } }}
                          onChange={handleChange}
                          value={searchField}
                        />
                        { searchField.length > 0 ? 
                          <IconButton sx={{ p: '6px' }} aria-label="search" onClick={() => setSearchField('')}>
                            <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                          </IconButton>
                          : null }
                      </Paper>
                    </Grid>

                      <Grid item sx={{ flex: '100%', pt: 0, pb: 2 }}>
                        <div><SearchCaixasEscolaresBar onSelectData={handleDataSearch} /></div>
                      </Grid>
                  </Grid>
                </Collapse>

                <TableContainer sx={{ maxHeight: 500, px: 4 }}>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size='small'
                    stickyHeader
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={newListValues && newListValues !== 'undefined' ? newListValues.length : 0}
                    />

                    <TableBody>
                      {stableSort(newListValues, getComparator(order, orderBy))
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <>
                              <TableRow
                                hover
                                key={row.id}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    onClick={(event) => { 
                                      handleClick(event, row.id)
                                    }}
                                  />
                                </TableCell>

                                <TableCell padding="checkbox" key={row.id}>
                                  <IconButton onClick={() => setIsExpandedData(row.id)}>
                                    {isExpanded.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>

                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="normal"
                                  sx={{ fontSize: 13 }}
                                >
                                  <span align="left" style={{ fontSize: 12 }} sx={{ minWidth: 125, padding: 0 }}>
                                    {row.caixasEscolaresNome}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: 12, color: '#BEBEBE' }}>{row.unidadesEscolaresTipoAbreviacao}&nbsp;{row.unidadesEscolaresNome}</span>
                                  { row.statusCaixasEscolaresId === '02e9f9ce-17ae-11ec-8785-dc41a970c8d9' ? 
                                    <span align="left" style={{ fontSize: 12, color: '#de4993' }} sx={{ minWidth: 125, padding: 0 }}>
                                      &nbsp;-&nbsp;{row.statusCaixasEscolaresDescricao}
                                    </span>
                                  : <></>}
                                </TableCell>

                                <TableCell align="center" sx={{ fontSize: 9 }}>
                                  {
                                    Number(row.finalizado) === 1 ? 
                                    <Chip size="small" label='SIM' color="primary" variant="outlined"/>
                                    :
                                    <Chip size="small" label='NÃO' color="error" variant="outlined"/>
                                  }
                                </TableCell>

                                {/*<TableCell align="left" sx={{ fontSize: 13 }}>{row.nomeCompleto}</TableCell>*/}

                                <TableCell align="center" sx={{ fontSize: 9 }}>
                                  {
                                    Number(row.percapita) === 1 && Number(row.finalizado) === 1 ? 
                                    <Chip size="small" label='PERCAPITA' color="secondary" variant="outlined"/>
                                    :
                                    Number(row.percapita) !== 1 && Number(row.finalizado) === 1 ? 
                                    <Chip size="small" label='PLANEJAMENTO' color="success" variant="outlined"/>
                                    : <></>
                                  }
                                </TableCell>

                                <TableCell align="right" sx={{ fontSize: 13 }}>{parseFloat(row.verbaRecebidaAnoAnterior) ? parseFloat(row.verbaRecebidaAnoAnterior).toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00'}</TableCell>

                                <TableCell align="right" sx={{ fontSize: 13, cursor: row.presidenteNome && row.presidenteNome.length > 0 ? 'pointer': 'auto', color: '#4a89c8' }} >
                                  {/*<span onClick={() => history.push(`/conselho-fiscal-escolar/${row.presidenteCaixasEscolaresHasCargosFuncoes}`)}> */}
                                    {
                                      Number(row.percapita) === 1 && Number(row.finalizado) === 1 ?
                                      <span>---</span> :
                                      parseFloat(row.valorTotalSolicitado) ? parseFloat(row.valorTotalSolicitado).toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00'
                                    }
                                  {/* </span> */}
                                </TableCell>

                                <TableCell align="right" sx={{ fontSize: 13, cursor: row.presidenteNome && row.presidenteNome.length > 0 ? 'pointer': 'auto', color: '#4a89c8' }} >
                                  {/*<span onClick={() => history.push(`/conselho-fiscal-escolar/${row.presidenteCaixasEscolaresHasCargosFuncoes}`)}> */}
                                    {parseFloat(row.valorTotalLiberado) ? parseFloat(row.valorTotalLiberado).toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00'}
                                  {/* </span> */}
                                </TableCell>

                                <TableCell align="left" sx={{ fontSize: 13 }}>
                                <EditPrintMenuPlanejamentoDespesas
                                  row={row}
                                  getNewData={getNewData}
                                  />
                                </TableCell>
                              </TableRow>
                              {isExpanded.includes(row.id) && (
                                <>
                                  <TableRow>
                                    <TableCell padding="checkbox" />
                                    <TableCell colSpan={8}>
                                      <span><b>Termo:&nbsp;</b></span><span>{row.termo}&nbsp;-&nbsp;</span>
                                      <span><b>Código Credor:&nbsp;</b></span><span>{row.codigoCredor}</span>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell padding="checkbox" />
                                    <TableCell colSpan={8}><span><b>CNPJ:&nbsp;</b></span><span>{formatCNPJ(row.caixasEscolaresCnpj)}</span></TableCell>
                                  </TableRow>
                                </>
                              )}
                            </>
                          );
                        })}

                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 33 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              {/* </Paper> */}
            </Box>
          {/* </div>
        </Paper>
      </Typography>
    </Container> */}
    </React.Fragment>
  );
}
