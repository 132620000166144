import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';
import api from "../../../services/api";
import { formatCnpj, formatDateEnUs2, getUserData, userRoles } from '../../../utils/functions';
import NumberFormat from 'react-number-format';
import ButtomMenuDemonstrativo from '../../../components/ButtomMenuDemonstrativo';
import { Plus, Minus } from 'mdi-material-ui/light';

import FormControlLabel from '@mui/material/FormControlLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { set, parseISO, format } from "date-fns";
import { convertToLocalTime } from 'date-fns-timezone';

import PlanejamentoFinanceiroOrientacoes from '../../../components/PlanejamentoFinanceiroOrientacoes';
import PlanejamentoFinanceiroAlert from '../../../components/PlanejamentoFinanceiroAlert';
import ButtomMenuPlanejamentoDespesas from '../../../components/ButtomMenuPlanejamentoDespesas';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  valorTotal: {
    backgroundColor : "#F5F5F5",
    borderColor: "primary"
  }
}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix="R$ "
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function DataFunction () {
  const query = useParams();
  const history = useHistory();
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 230,
      },
    },
  };

  const options = [
    "Totais Gerais",
    "Totais Parciais",
    "Não Preencher",
    "Preencher"
  ];

  const [errors, setErrors] = React.useState([]);
  const [finalizado, setFinalizado] = React.useState(1);
  const [menuOptions, setMenuOptions] = React.useState(['menuGravar', 'menuGravarFinalizar', 'menuImprimir']);

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const [selectedOptions, setSelectedOptions] = React.useState(options);

  const [showServicosData, setShowServicosData] = React.useState(true);
  const [showMateriaisConsumoData, setShowMateriaisConsumoData] = React.useState(true);
  const [showPermanentesCapitalData, setShowPermanentesCapitalData] = React.useState(true);
  const [showOpcionaisData, setShowOpcionaisData] = React.useState(true);  
  const [showConsolidadoData, setShowConsolidadoData] = React.useState(true);
  const [showSaldoFinalData, setShowSaldoFinalData] = React.useState(true);
  const [showFndeDebitos, setShowFndeDebitos] = React.useState(true);

  const [showLiberado, setShowLiberado] = React.useState(false);
  const [editLiberado, setEditLiberado] = React.useState(false);
  const [admin, setAdmin] = React.useState(false);
  const [adminEdit, setAdminEdit] = React.useState(false);

  const [showSituacao, setShowSituacao] = React.useState(false);
  const [showObservacaoSeduc, setShowObservacaoSeduc] = React.useState(false);
  const [desabilitaPreenchimento, setDesabilitaPreenchimento] = React.useState(false);
  const [savedData, setSavedData] = React.useState([]);
  const [saveType, setSaveType] = React.useState('saveForm');

  const [openOrientacoes, setOpenOrientacoes] = React.useState(false);
  const [openPlanejamentoFinanceiroAlert, setOpenPlanejamentoFinanceiroAlert] = React.useState(false);

  const [title, setTitle]=useState('PLANEJAMENTO DAS DESPESAS CAIXAS ESCOLARES');
  const [subTitle, setSubTitle]=useState('');
  const [subSubTitle, setSubSubTitle]=useState('');

  const [userData, setUserData] = React.useState({});

  const closeOrientacoes = () => {
    setOpenOrientacoes(false);
  }

  const closePlanejamentoFinanceiroAlert = () => {
    setOpenPlanejamentoFinanceiroAlert(false);
  }

  const openPlanejamentoAlert = () => {
    setOpenPlanejamentoFinanceiroAlert(true);
  }

  const [deferidoList, setDeferidoList] = React.useState([
    { value: 'DEFERIDO', label: 'DEFERIDO' },
    { value: 'INDEFERIDO', label: 'INDEFERIDO' },
    { value: 'ATUALIZADO', label: 'ATUALIZADO' }
  ]);

  const [tiposItensPlanejamentosFinanceirosList, setTiposItensPlanejamentosFinanceirosList] = React.useState([
    {
    value: '48a445f0-95dd-11ec-a4a0-00155de8a36d',
    label: 'SERVIÇOS DE TERCEIROS – PESSOA JURÍDICA E MEI'
    },
    {
      value:'69d653b0-95dd-11ec-a4a0-00155de8a36d',
      label:'MATERIAIS DE CONSUMO'
    },
    {
      value:'7d1b5b30-95dd-11ec-a4a0-00155de8a36d',
      label:'CAPITAL / INVESTIMENTOS / MATERIAIS PERMANENTES'
    }
  ]);

  const [inputs, setInputs] = React.useState({
    id: "",
    ano: "",
    observacoesCaixaEscolar: "",
    observacoesSeduc: "",
    verbaRecebidaAnoAnterior: "0.0",
    percapita: false,
    data: "2022-01-01",
    finalizado: false,

    valorSolicitadoSubtotal01: "0.0",
    valorSolicitadoSubtotal02: "0.0",
    valorSolicitadoSubtotal03: "0.0",
    valorSolicitadoSubtotal04: "0.0",
    valorLiberadoSubtotal01: "0.0",
    valorLiberadoSubtotal02: "0.0",
    valorLiberadoSubtotal03: "0.0",
    valorLiberadoSubtotal04: "0.0",

    planejamentosFinanceirosHasItensId01: "",
    itensPlanejamentosFinanceirosId01: "",
    valorSolicitado01: null,
    valorLiberado01: null,
    itensPlanejamentosFinanceirosDescricao01: "LIMPEZA DE CAIXAS D’ÁGUA",
    situacoesItensSolicitadosId01: "",

    planejamentosFinanceirosHasItensId02: "",
    itensPlanejamentosFinanceirosId02: "",
    valorSolicitado02: null,
    valorLiberado02: null,
    itensPlanejamentosFinanceirosDescricao02: "DEDETIZAÇÃO/DESRATIZAÇÃO",
    situacoesItensSolicitadosId02: "",

    planejamentosFinanceirosHasItensId03: "",
    itensPlanejamentosFinanceirosId03: "",
    valorSolicitado03: null,
    valorLiberado03: null,
    itensPlanejamentosFinanceirosDescricao03: "LIMPEZA DE CAIXAS DE GORDURA",
    situacoesItensSolicitadosId03: "",

    planejamentosFinanceirosHasItensId04: "",
    itensPlanejamentosFinanceirosId04: "",
    valorSolicitado04: null,
    valorLiberado04: null,
    itensPlanejamentosFinanceirosDescricao04: "LIMPEZA DE CALHAS",
    situacoesItensSolicitadosId04: "",

    planejamentosFinanceirosHasItensId05: "",
    itensPlanejamentosFinanceirosId05: "",
    valorSolicitado05: null,
    valorLiberado05: null,
    itensPlanejamentosFinanceirosDescricao05: "SERVIÇOS DE CAPINA E/OU LIMPEZA DE ÁREA EXTERNA DA UNIDADE ESCOLAR",
    situacoesItensSolicitadosId05: "",

    planejamentosFinanceirosHasItensId06: "",
    itensPlanejamentosFinanceirosId06: "",
    valorSolicitado06: null,
    valorLiberado06: null,
    itensPlanejamentosFinanceirosDescricao06: "SERVIÇOS DE MANUTENÇÃO PREDIAL (ALVENARIA, REDE HIDRÁULICA, REDE ELÉTRICA E REDE LÓGICA)",
    situacoesItensSolicitadosId06: "",

    planejamentosFinanceirosHasItensId07: "",
    itensPlanejamentosFinanceirosId07: "",
    valorSolicitado07: null,
    valorLiberado07: null,
    itensPlanejamentosFinanceirosDescricao07: "LOCAÇÃO DE EQUIPAMENTOS DE INFORMÁTICA (INCLUSIVE IMPRESSORAS)",
    situacoesItensSolicitadosId07: "",

    planejamentosFinanceirosHasItensId08: "",
    itensPlanejamentosFinanceirosId08: "",
    valorSolicitado08: null,
    valorLiberado08: null,
    itensPlanejamentosFinanceirosDescricao08: "SERVIÇOS DE IMPRESSÃO E REPROGRAFIA",
    situacoesItensSolicitadosId08: "",

    planejamentosFinanceirosHasItensId09: "",
    itensPlanejamentosFinanceirosId09: "",
    valorSolicitado09: null,
    valorLiberado09: null,
    itensPlanejamentosFinanceirosDescricao09: "SERVIÇOS CONTÁBEIS",
    situacoesItensSolicitadosId09: "",

    planejamentosFinanceirosHasItensId10: "",
    itensPlanejamentosFinanceirosId10: "",
    valorSolicitado10: null,
    valorLiberado10: null,
    itensPlanejamentosFinanceirosDescricao10: "SERVIÇOS DE CHAVEIRO",
    situacoesItensSolicitadosId10: "",

    planejamentosFinanceirosHasItensId11: "",
    itensPlanejamentosFinanceirosId11: "",
    valorSolicitado11: null,
    valorLiberado11: null,
    itensPlanejamentosFinanceirosDescricao11: "TARIFAS BANCÁRIAS",
    situacoesItensSolicitadosId11: "",

    planejamentosFinanceirosHasItensId12: "",
    itensPlanejamentosFinanceirosId12: "",
    valorSolicitado12: null,
    valorLiberado12: null,
    itensPlanejamentosFinanceirosDescricao12: "MATERIAIS DE INFORMÁTICA",
    situacoesItensSolicitadosId12: "",

    planejamentosFinanceirosHasItensId13: "",
    itensPlanejamentosFinanceirosId13: "",
    valorSolicitado13: null,
    valorLiberado13: null,
    itensPlanejamentosFinanceirosDescricao13: "MATERIAIS DE LIMPEZA E HIGIENE",
    situacoesItensSolicitadosId13: "",

    planejamentosFinanceirosHasItensId14: "",
    itensPlanejamentosFinanceirosId14: "",
    valorSolicitado14: null,
    valorLiberado14: null,
    itensPlanejamentosFinanceirosDescricao14: "MATERIAIS DE ESCRITÓRIO",
    situacoesItensSolicitadosId14: "",

    planejamentosFinanceirosHasItensId15: "",
    itensPlanejamentosFinanceirosId15: "",
    valorSolicitado15: null,
    valorLiberado15: null,
    itensPlanejamentosFinanceirosDescricao15: "MATERIAIS ESPORTIVOS",
    situacoesItensSolicitadosId15: "",

    planejamentosFinanceirosHasItensId16: "",
    itensPlanejamentosFinanceirosId16: "",
    valorSolicitado16: null,
    valorLiberado16: null,
    itensPlanejamentosFinanceirosDescricao16: "MATERIAIS PARA MANUTENÇÃO PREDIAL (ALVENARIA, ELÉTRICA E HIDRÁULICA)",
    situacoesItensSolicitadosId16: "",

    planejamentosFinanceirosHasItensId17: "",
    itensPlanejamentosFinanceirosId17: "",
    valorSolicitado17: null,
    valorLiberado17: null,
    itensPlanejamentosFinanceirosDescricao17: "ENXOVAIS (APENAS PARA CEMEI – 0 A 3 ANOS)",
    situacoesItensSolicitadosId17: "",

    planejamentosFinanceirosHasItensId18: "",
    itensPlanejamentosFinanceirosId18: "",
    valorSolicitado18: null,
    valorLiberado18: null,
    itensPlanejamentosFinanceirosDescricao18: "EQUIPAMENTOS ELETROELETRÔNICOS",
    situacoesItensSolicitadosId18: "",

    planejamentosFinanceirosHasItensId19: "",
    itensPlanejamentosFinanceirosId19: "",
    valorSolicitado19: null,
    valorLiberado19: null,
    itensPlanejamentosFinanceirosDescricao19: "EQUIPAMENTOS DE INFORMÁTICA E DE PROCESSAMENTO DE DADOS",
    situacoesItensSolicitadosId19: "",

    planejamentosFinanceirosHasItensId20: "",
    itensPlanejamentosFinanceirosId20: "",
    valorSolicitado20: null,
    valorLiberado20: null,
    itensPlanejamentosFinanceirosDescricao20: "EQUIPAMENTOS DE ÁUDIO E VÍDEO",
    tiposItensPlanejamentosFinanceirosId20: null,
    situacoesItensSolicitadosId20: "",

    planejamentosFinanceirosHasItensId21: "",
    itensPlanejamentosFinanceirosId21: "",
    valorSolicitado21: null,
    valorLiberado21: null,
    itensPlanejamentosFinanceirosDescricao21: "",
    tiposItensPlanejamentosFinanceirosId21: null,
    situacoesItensSolicitadosId21: "",

    planejamentosFinanceirosHasItensId22: "",
    itensPlanejamentosFinanceirosId22: "",
    valorSolicitado22: null,
    valorLiberado22: null,
    itensPlanejamentosFinanceirosDescricao22: "",
    tiposItensPlanejamentosFinanceirosId22: null,
    situacoesItensSolicitadosId22: "",
    
    planejamentosFinanceirosHasItensId23: "",
    itensPlanejamentosFinanceirosId23: "",
    valorSolicitado23: null,
    valorLiberado23: null,
    itensPlanejamentosFinanceirosDescricao23: "",
    tiposItensPlanejamentosFinanceirosId23: null,
    situacoesItensSolicitadosId23: "",
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleInputsChangesShow = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const saveForm = (newFinalizado) => {
    // alert('saveForm');

    setSavedData([]);

    const planejamentosFinanceiros = {
      id: inputs.id,
      observacoesCaixaEscolar: inputs.observacoesCaixaEscolar ? inputs.observacoesCaixaEscolar.toUpperCase() : '',
      observacoesSeduc: inputs.observacoesSeduc ? inputs.observacoesSeduc.toUpperCase() : '',
      percapita: inputs.percapita,
      data: inputs.data,
      finalizado: newFinalizado
    };

    const planejamentosFinanceirosHasItensPlanejamentosFinanceiros = [
      {
        id: inputs.planejamentosFinanceirosHasItensId01,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId01,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado01,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado01,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId01,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId02,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId02,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado02,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado02,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId02,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId03,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId03,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado03,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado03,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId03,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId04,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId04,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado04,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado04,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId04,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId05,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId05,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado05,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado05,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId05,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId06,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId06,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado06,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado06,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId06,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId07,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId07,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado07,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado07,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId07,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId08,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId08,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado08,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado08,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId08,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId09,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId09,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado09,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado09,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId09,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId10,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId10,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado10,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado10,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId10,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId11,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId11,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado11,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado11,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId11,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId12,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId12,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado12,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado12,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId12,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId13,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId13,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado13,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado13,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId13,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId14,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId14,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado14,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado14,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId14,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId15,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId15,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado15,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado15,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId15,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId16,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId16,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado16,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado16,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId16,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId17,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId17,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado17,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado17,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId17,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId18,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId18,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado18,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado18,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId18,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId19,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId19,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado19,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado19,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId19,
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId20,
        planejamentosFinanceirosId: inputs.id,
        itensPlanejamentosFinanceirosId: inputs.itensPlanejamentosFinanceirosId20,
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado20,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado20,
        situacoesItensSolicitadosId: inputs.percapita ? null : inputs.situacoesItensSolicitadosId20,
      }
    ];

    let descricao21 = inputs.itensPlanejamentosFinanceirosDescricao21;
    let descricao22 = inputs.itensPlanejamentosFinanceirosDescricao22;
    let descricao23 = inputs.itensPlanejamentosFinanceirosDescricao23;

    if (!descricao21) {
      descricao21 = '';
    }

    if (!descricao22) {
      descricao22 = '';
    }

    if (!descricao23) {
      descricao23 = '';
    }

    const itensPlanejamentosFinanceirosOpcionais = [
      {
        id: inputs.planejamentosFinanceirosHasItensId21,
        descricao: inputs.percapita ? '' : descricao21.toUpperCase(),
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado21,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado21,
        tiposItensPlanejamentosFinanceirosId: inputs.tiposItensPlanejamentosFinanceirosId21 && inputs.tiposItensPlanejamentosFinanceirosId21.value ? inputs.tiposItensPlanejamentosFinanceirosId21.value : null,
        situacoesItensSolicitadosId: inputs.situacoesItensSolicitadosId21 && inputs.situacoesItensSolicitadosId21.value ? inputs.situacoesItensSolicitadosId21 : null
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId22,
        descricao: inputs.percapita ? '' : descricao22.toUpperCase(),
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado22,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado22,
        tiposItensPlanejamentosFinanceirosId: inputs.tiposItensPlanejamentosFinanceirosId22 && inputs.tiposItensPlanejamentosFinanceirosId22.value ? inputs.tiposItensPlanejamentosFinanceirosId22.value : null,
        situacoesItensSolicitadosId: inputs.situacoesItensSolicitadosId22 && inputs.situacoesItensSolicitadosId22.value ? inputs.situacoesItensSolicitadosId22.value : null
      },
      {
        id: inputs.planejamentosFinanceirosHasItensId23,
        descricao: inputs.percapita ? '' : descricao23.toUpperCase(),
        valorSolicitado: inputs.percapita ? 0 :inputs.valorSolicitado23,
        valorLiberado: inputs.percapita ? 0 :inputs.valorLiberado23,
        tiposItensPlanejamentosFinanceirosId: inputs.tiposItensPlanejamentosFinanceirosId23 && inputs.tiposItensPlanejamentosFinanceirosId23.value ? inputs.tiposItensPlanejamentosFinanceirosId23.value : null,
        situacoesItensSolicitadosId: inputs.situacoesItensSolicitadosId23 && inputs.situacoesItensSolicitadosId23.value ? inputs.situacoesItensSolicitadosId23.value : null
      }
    ];

    setBackdrop(true);
    const values = api.put(`planejamentos-financeiros/${inputs.id}`, planejamentosFinanceiros)
    .then((response) => {
      let newSavedData = savedData;
      newSavedData.push('planejamentosFinanceiros');
      setSavedData(newSavedData);
      setMessage('Dados gravados com sucesso!');
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setSeverity('error');
      setBackdrop(false);
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })

    const valuesPlanejamentosFinanceirosHasItensPlanejamentosFinanceiros = api.patch(`planejamentos-financeiros-has-itens-planejamentos-financeiros`, planejamentosFinanceirosHasItensPlanejamentosFinanceiros)
    .then((response) => {
      let newSavedData = savedData;
      newSavedData.push('planejamentosFinanceirosHasItensPlanejamentosFinanceiros');
      setSavedData(newSavedData);
      setMessage('Dados gravados com sucesso!');
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setSeverity('error');
      setBackdrop(false);
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })

    const valuesItensPlanejamentosFinanceirosOpcionais = api.patch(`itens-planejamentos-financeiros-opcionais`, itensPlanejamentosFinanceirosOpcionais)
    .then((response) => {
      let newSavedData = savedData;
      newSavedData.push('itensPlanejamentosFinanceirosOpcionais');
      setSavedData(newSavedData);
      setMessage('Dados gravados com sucesso!');
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setSeverity('error');
      setBackdrop(false);
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const saveFinalizado = (newFinalizado) => {
  }

  const checkErrors = (type) => {
    if (type === 'save') {
      saveForm(0);
    } else if (type === 'saveEnd') {
      saveFormEnd();
    } else if (type === 'print') {
      printPlanejamento();
    }
  }

  const goBack = () => {
    history.push(`/planejamento-de-despesas-list/${query.ano}`)
  }

  const printPlanejamento = () => {
    history.push(`/planejamento-financeiro-anual-impressao/${query.ano}/${query.id}`)
  }

  const saveFormEnd = () => {
    openPlanejamentoAlert();
    // setFinalizado(1);
    // saveForm(1);
  }

  const handleClickAlert = () => {
    setOpen(true);
  };

  const getShowServicosData = () => {
    setShowServicosData(!showServicosData);
  }
  const getShowMateriaisConsumoData = () => {
    setShowMateriaisConsumoData(!showMateriaisConsumoData);
  }
  const getShowPermanentesCapitalData = () => {
    setShowPermanentesCapitalData(!showPermanentesCapitalData);
  }
  const getShowOpcionaisData = () => {
    setShowOpcionaisData(!showOpcionaisData);
  }
  const getShowConsolidadoData = () => {
    setShowConsolidadoData(!showConsolidadoData);
  }
  const getShowSaldoFinalData = () => {
    setShowSaldoFinalData(!showSaldoFinalData);
  }
  const handleInputsChanges = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  const handleInputsChangesPercapita = (event) => {
    const name = event.target.name;
    const value = !inputs.percapita;
    setInputs(values => ({...values, [name]: value}))
    setDesabilitaPreenchimento(false);
    if (value) {
      setDesabilitaPreenchimento(true);
    }
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const updateValues = () => {
    const valorSolicitadoSubtotal01 = parseFloat(inputs.valorSolicitado01 || 0) + parseFloat(inputs.valorSolicitado02 || 0) + parseFloat(inputs.valorSolicitado03 || 0) + parseFloat(inputs.valorSolicitado04 || 0) + parseFloat(inputs.valorSolicitado05 || 0) + parseFloat(inputs.valorSolicitado06 || 0) + parseFloat(inputs.valorSolicitado07 || 0) + parseFloat(inputs.valorSolicitado08 || 0) + parseFloat(inputs.valorSolicitado09 || 0) + parseFloat(inputs.valorSolicitado10 || 0) +  parseFloat(inputs.valorSolicitado11 || 0);
    const valorLiberadoSubtotal01 = parseFloat(inputs.valorLiberado01 || 0) + parseFloat(inputs.valorLiberado02 || 0) + parseFloat(inputs.valorLiberado03 || 0) + parseFloat(inputs.valorLiberado04 || 0) + parseFloat(inputs.valorLiberado05 || 0) + parseFloat(inputs.valorLiberado06 || 0) + parseFloat(inputs.valorLiberado07 || 0) + parseFloat(inputs.valorLiberado08 || 0) + parseFloat(inputs.valorLiberado09 || 0) + parseFloat(inputs.valorLiberado10 || 0) + parseFloat(inputs.valorLiberado11 || 0);

    const valorSolicitadoSubtotal02 = parseFloat(inputs.valorSolicitado12 || 0) + parseFloat(inputs.valorSolicitado13 || 0) + parseFloat(inputs.valorSolicitado14 || 0) + parseFloat(inputs.valorSolicitado15 || 0) + parseFloat(inputs.valorSolicitado16 || 0) + parseFloat(inputs.valorSolicitado17 || 0);
    const valorLiberadoSubtotal02 = parseFloat(inputs.valorLiberado12 || 0) + parseFloat(inputs.valorLiberado13 || 0) + parseFloat(inputs.valorLiberado14 || 0) + parseFloat(inputs.valorLiberado15 || 0) + parseFloat(inputs.valorLiberado16 || 0) + parseFloat(inputs.valorLiberado17 || 0);

    const valorSolicitadoSubtotal03 = parseFloat(inputs.valorSolicitado18 || 0) + parseFloat(inputs.valorSolicitado19 || 0) + parseFloat(inputs.valorSolicitado20 || 0) ;
    const valorLiberadoSubtotal03  = parseFloat(inputs.valorLiberado18 || 0) + parseFloat(inputs.valorLiberado19 || 0) + parseFloat(inputs.valorLiberado20 || 0) ;

    const valorSolicitadoSubtotal04 = parseFloat(inputs.valorSolicitado21 || 0) + parseFloat(inputs.valorSolicitado22 || 0) +  parseFloat(inputs.valorSolicitado23 || 0);
    const valorLiberadoSubtotal04  = parseFloat(inputs.valorLiberado21 || 0) + parseFloat(inputs.valorLiberado22 || 0) +  parseFloat(inputs.valorLiberado23 || 0);

    const valorTotalGeralSolicitado = parseFloat(valorSolicitadoSubtotal01 || 0)  + parseFloat(valorSolicitadoSubtotal02 || 0) + parseFloat(valorSolicitadoSubtotal03 || 0) + + parseFloat(valorSolicitadoSubtotal04 || 0);

    const valorTotalGeralLiberado = parseFloat(valorLiberadoSubtotal01 || 0)  + parseFloat(valorLiberadoSubtotal02 || 0) + parseFloat(valorLiberadoSubtotal03 || 0) + + parseFloat(valorLiberadoSubtotal04 || 0);

    setInputs(values => ({
      ...values, 
      "valorTotalGeralSolicitado": String(valorTotalGeralSolicitado),
      "valorTotalGeralLiberado": String(valorTotalGeralLiberado),
      "valorSolicitadoSubtotal01": String(valorSolicitadoSubtotal01),
      "valorSolicitadoSubtotal02": String(valorSolicitadoSubtotal02),
      "valorSolicitadoSubtotal03": String(valorSolicitadoSubtotal03),
      "valorSolicitadoSubtotal04": String(valorSolicitadoSubtotal04),
      "valorLiberadoSubtotal01": String(valorLiberadoSubtotal01),
      "valorLiberadoSubtotal02": String(valorLiberadoSubtotal02),
      "valorLiberadoSubtotal03": String(valorLiberadoSubtotal03),
      "valorLiberadoSubtotal04": String(valorLiberadoSubtotal04),
    }))
  };
  React.useEffect(() => {
    updateValues();
   }, [
    inputs.valorSolicitado01,
    inputs.valorSolicitado02,
    inputs.valorSolicitado03,
    inputs.valorSolicitado04,
    inputs.valorSolicitado05,
    inputs.valorSolicitado06,
    inputs.valorSolicitado07,
    inputs.valorSolicitado08,
    inputs.valorSolicitado09,
    inputs.valorSolicitado10,
    inputs.valorSolicitado11,
    inputs.valorSolicitado12,
    inputs.valorSolicitado13,
    inputs.valorSolicitado14,
    inputs.valorSolicitado15,
    inputs.valorSolicitado16,
    inputs.valorSolicitado17,
    inputs.valorSolicitado18,
    inputs.valorSolicitado19,
    inputs.valorSolicitado20,
    inputs.valorSolicitado21,
    inputs.valorSolicitado22,
    inputs.valorSolicitado23,

    inputs.valorLiberado01,
    inputs.valorLiberado02,
    inputs.valorLiberado03,
    inputs.valorLiberado04,
    inputs.valorLiberado05,
    inputs.valorLiberado06,
    inputs.valorLiberado07,
    inputs.valorLiberado08,
    inputs.valorLiberado09,
    inputs.valorLiberado10,
    inputs.valorLiberado11,
    inputs.valorLiberado12,
    inputs.valorLiberado13,
    inputs.valorLiberado14,
    inputs.valorLiberado15,
    inputs.valorLiberado16,
    inputs.valorLiberado17,
    inputs.valorLiberado18,
    inputs.valorLiberado19,
    inputs.valorLiberado20,
    inputs.valorLiberado21,
    inputs.valorLiberado22,
    inputs.valorLiberado23
  ]);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    getAdmin();
  }, [userData]);

  React.useEffect(() => {
    if (savedData.length >= 2) {
      getData();
    }
  }, [savedData]);

  const getAdmin = () => {
    if (userRoles('Comprovantes de Despesas - Admin', userData.userRoles)) {
      setAdmin(true);
      setShowLiberado(true);
      setShowObservacaoSeduc(true);
      setSaveType('finaliza');
    }
    if (userRoles('Comprovantes de Despesas - AdminEdit', userData.userRoles)) {
      setAdminEdit(true);
      setShowLiberado(true);
      setShowObservacaoSeduc(true);
      setSaveType('finaliza');
    }
  }

  const getData = () => {
  setBackdrop(true);
  //@ts-ignore
  const values = api.get(`planejamentos-financeiros/por-caixa-escolar/${query.ano}/${query.id}`)
    .then((response) => {
      const data = response.data[0];
      const title = `PLANEJAMENTO DAS DESPESAS CAIXAS ESCOLARES - ANO: ${data.ano}`;
      setTitle(title);
      const caixasEscolaresCnpj = formatCnpj(data.caixasEscolaresCnpj);
      const caixaEscolarData = `${data.caixasEscolaresNome} - CNPJ: ${caixasEscolaresCnpj}`
      setSubTitle(caixaEscolarData);

      setUserData(getUserData());

      const unidadeEscolar = `${data.unidadesEscolaresTipoAbreviacao} ${data.unidadesEscolaresNome}`;
      setSubSubTitle(unidadeEscolar);

      const planejamentosFinanceiros = data.planejamentosFinanceiros;
      // const percapita = Number(data.percapita) >= 1 ? true : false;
      if (Number(data.percapita) >= 1) {
        setDesabilitaPreenchimento(true);
      }
      setFinalizado(data.finalizado);
      let  newDate = new Date();
      newDate = formatDateEnUs2(newDate);

      if (data.data && data.finalizado) {
        newDate = data.data;
        setDesabilitaPreenchimento(true);
      }

      setInputs(values => ({
        ...values, 
        "id": data.id,
        "ano": data.ano,
        "verbaRecebidaAnoAnterior": data.verbaRecebidaAnoAnterior,
        "observacoesCaixaEscolar": data.observacoesCaixaEscolar,
        "observacoesSeduc": data.observacoesSeduc,
        "percapita": Number(data.percapita) >= 1 ? true : false,
        "data": newDate,
        "finalizado": data.finalizado,
      }))

      planejamentosFinanceiros.map((item) => (
        setInputs(values => ({
          ...values, 
          ["planejamentosFinanceirosHasItensId" + item.itensPlanejamentosFinanceirosOrdem] : item.planejamentosFinanceirosHasItensId,
          ["valorSolicitado" + item.itensPlanejamentosFinanceirosOrdem]: item.valorSolicitado,
          ["valorLiberado" + item.itensPlanejamentosFinanceirosOrdem]: item.valorLiberado,
          ["itensPlanejamentosFinanceirosId" + item.itensPlanejamentosFinanceirosOrdem]: item.itensPlanejamentosFinanceirosId,
          ["itensPlanejamentosFinanceirosDescricao" + item.itensPlanejamentosFinanceirosOrdem]: item.itensPlanejamentosFinanceirosDescricao,
          ["itensPlanejamentosFinanceirosOrdem" + item.itensPlanejamentosFinanceirosOrdem]: item.itensPlanejamentosFinanceirosOrdem,
          ["tiposItensPlanejamentosFinanceirosId" + item.itensPlanejamentosFinanceirosOrdem]: item.tiposItensPlanejamentosFinanceirosId,
          ["tiposItensPlanejamentosFinanceirosDescricao" + item.itensPlanejamentosFinanceirosOrdem]: item.tiposItensPlanejamentosFinanceirosDescricao,
          ["tiposItensPlanejamentosFinanceirosOrdem" + item.itensPlanejamentosFinanceirosOrdem]: item.tiposItensPlanejamentosFinanceirosOrdem,
          ["situacoesItensSolicitadosId" + item.itensPlanejamentosFinanceirosOrdem]: item.situacoesItensSolicitadosId,
          ["situacoesItensSolicitadosDescricao" + item.itensPlanejamentosFinanceirosOrdem]: item.situacoesItensSolicitadosDescricao
        }))
      ))
      // console.log('inputs: ', inputs);
      setBackdrop(false);
    })
    .catch((err) => {
      setMessage('Erro ao obter os dados! Verifique!');
      setSeverity('error');
      setBackdrop(false);
      handleClickAlert();
      console.error("ops! ocorreu um erro" + err);
    })
  }

  return (
    <Container maxWidth="xl">
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
          <SpinnerCarregando open={backdrop} />
          <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
          <PlanejamentoFinanceiroOrientacoes open={openOrientacoes} closeOrientacoes={closeOrientacoes} />
          <PlanejamentoFinanceiroAlert open={openPlanejamentoFinanceiroAlert} closePlanejamentoFinanceiroAlert={closePlanejamentoFinanceiroAlert} type={saveType} saveForm={checkErrors} />
          <Grid container spacing={2} sx={{ mt: 3 , justifyContent: "center" }}>
          {/* <pre>{JSON.stringify(inputs, null, 2)}</pre> */}

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2, justifyContent: "center" }}>{title}</Box>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pb: 0 }}>
            <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}>

              <div>
                <Button
                  onClick={() => {setOpenOrientacoes(true)}}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ height: 35, marginRight: 20, width: 110 }}>
                  ORIENTAÇÕES
                </Button>
              </div>

              { admin || adminEdit ?
                <div>
                  <ButtomMenuPlanejamentoDespesas
                    menuOptions={menuOptions}
                    ano={'809ca722-7541-4899-921d-7d183a0fbe97'}
                    ids={query.id}
                    checkErrors={checkErrors}
                    saveFormEnd={saveFormEnd}
                  />
                </div>
                : <></>
              }

              { !admin && !adminEdit ?
                <div>
                  <Button
                    onClick={() => {checkErrors('print')}}
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={Number(finalizado) !== 1}
                    style={{ height: 35, marginRight: 20, width: 80 }}>
                    Imprimir
                  </Button>
                </div>
                : <></>
              }

              { 
                Number(finalizado) !== 1 && (!admin || !adminEdit) ?
                <div>
                  <Button
                    onClick={() => {checkErrors('save')}}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ height: 35, marginRight: 20, width: 180 }}>
                    GRAVAR PARCIALMENTE
                  </Button>
                </div>
              : <></> }

              { !admin && !adminEdit ?
                <div>
                  <Button
                    onClick={() => {setOpenPlanejamentoFinanceiroAlert(true)}}
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={Number(finalizado) === 1}
                    style={{ height: 35, marginRight: 20, width: 140 }}>
                    GRAVAR E ENVIAR
                  </Button>
                </div>
                : <></>
              }

              { admin || adminEdit ?
                <div>
                  <Button
                    onClick={() => {goBack()}}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ height: 35, marginLeft: 20, marginRight: 20, width: 80 }}>
                    Voltar
                  </Button>
                </div>
                : <></>
              }

            </Box>
            </Grid>

            <Grid item xs={12} sx={{ flex: '100%', pb: 0 }}>
              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 0 }}>{subTitle}</Box>
                </Typography>
              </Box>

              <Box>
                <Typography component="div">
                  <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 0 }}>{subSubTitle}</Box>
                </Typography>
              </Box>
            </Grid>

            <Grid container spacing={2} sx={{ pb: 3, ml: 0, mt: 2 }}>

              <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={`${classes.centerBox} ${classes.box}`}>
                    <Box>
                      <Typography component="div">
                        <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                          VALOR DE REFERÊNCIA (REPASSES REALIZADOS NO ANO DE {Number(inputs.ano) - 1})
                        </Box>
                      </Typography> 
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <TextField
                      id="verbaRecebidaAnoAnterior"
                      label="Valor"
                      value={inputs.verbaRecebidaAnoAnterior || "0.0"}
                      color="primary"
                      size="small"
                      focused 
                      fullWidth
                      autoComplete="off"
                      dir="rtl"
                      name="verbaRecebidaAnoAnterior"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        style: { backgroundColor: "#e3f2fd" }, readOnly: true
                      }}
                      onChange={handleInputsChanges}
                    />
                  </Grid>

                </Grid>
              </Grid>

              <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={`${classes.centerBox} ${classes.box}`}>
                    <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 14, color: '#A9A9A9' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={inputs.percapita}
                            name="percapita"
                            disabled={Number(finalizado) === 1}
                            onChange={handleInputsChangesPercapita}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="DECLARO PARA OS DEVIDOS FINS QUE A CAIXA ESCOLAR OPTA POR RECEBER OS VALORES DOS REPASSES CONFORME QUANTITATIVO DE ESTUDANTES/CRIANÇAS DA ESCOLA/CEMEI (PER CAPITA)" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>



              <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2 }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={`${classes.centerBox} ${classes.box}`}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>


                    <DatePicker
                    label="Data"
                    readOnly
                    size="small"
                    value={parseISO(inputs.data) || null}
                    onChange={(newValue) => {
                      // setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} focused size="small" InputProps={{
                      style: { backgroundColor: "#e3f2fd" }, readOnly: true
                    }}/>}
                  />


                  </LocalizationProvider>
                </Grid>
              </Grid>

              {
                !inputs.percapita ? 
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showServicosData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowServicosData} /> : <></>}
                            {showServicosData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowServicosData} /> : <></>}
                            1.00 - SERVIÇOS DE TERCEIROS – PESSOA JURÍDICA E MEI
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    {
                      showServicosData ?
                      <>
                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.01 - {inputs.itensPlanejamentosFinanceirosDescricao01}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado01"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado01 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado01"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado01"
                                label="Valor Liberado"
                                value={inputs.valorLiberado01 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado01"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId01"
                                value={inputs.situacoesItensSolicitadosId01 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId01": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId01')}
                                    helperText={errors.includes('situacoesItensSolicitadosId01') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.02 - {inputs.itensPlanejamentosFinanceirosDescricao02}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado02"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado02 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado02"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado02"
                                label="Valor Liberado"
                                value={inputs.valorLiberado02 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado02"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId02"
                                value={inputs.situacoesItensSolicitadosId02 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId02": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId02')}
                                    helperText={errors.includes('situacoesItensSolicitadosId02') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.03 - {inputs.itensPlanejamentosFinanceirosDescricao03}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado03"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado03 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado03"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado03"
                                label="Valor Liberado"
                                value={inputs.valorLiberado03 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado03"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId03"
                                value={inputs.situacoesItensSolicitadosId03 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId03": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId03')}
                                    helperText={errors.includes('situacoesItensSolicitadosId03') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.04 - {inputs.itensPlanejamentosFinanceirosDescricao04}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado04"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado04 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado04"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado04"
                                label="Valor Liberado"
                                value={inputs.valorLiberado04 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado04"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId04"
                                value={inputs.situacoesItensSolicitadosId04 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId04": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId04')}
                                    helperText={errors.includes('situacoesItensSolicitadosId04') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.05 - {inputs.itensPlanejamentosFinanceirosDescricao05}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado05"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado05 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado05"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado05"
                                label="Valor Liberado"
                                value={inputs.valorLiberado05 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado05"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId05"
                                value={inputs.situacoesItensSolicitadosId05 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId05": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId05')}
                                    helperText={errors.includes('situacoesItensSolicitadosId05') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.06 - {inputs.itensPlanejamentosFinanceirosDescricao06}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado06"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado06 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado06"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado06"
                                label="Valor Liberado"
                                value={inputs.valorLiberado06 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado06"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId06"
                                value={inputs.situacoesItensSolicitadosId06 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId06": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId06')}
                                    helperText={errors.includes('situacoesItensSolicitadosId06') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.07 - {inputs.itensPlanejamentosFinanceirosDescricao07}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado07"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado07 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado07"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado07"
                                label="Valor Liberado"
                                value={inputs.valorLiberado07 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado07"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId07"
                                value={inputs.situacoesItensSolicitadosId07 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId07": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId07')}
                                    helperText={errors.includes('situacoesItensSolicitadosId07') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.08 - {inputs.itensPlanejamentosFinanceirosDescricao08}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado08"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado08 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado08"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado08"
                                label="Valor Liberado"
                                value={inputs.valorLiberado08 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado08"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId08"
                                value={inputs.situacoesItensSolicitadosId08 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId08": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId08')}
                                    helperText={errors.includes('situacoesItensSolicitadosId08') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.09 - {inputs.itensPlanejamentosFinanceirosDescricao09}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado09"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado09 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado09"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado09"
                                label="Valor Liberado"
                                value={inputs.valorLiberado09 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado09"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId09"
                                value={inputs.situacoesItensSolicitadosId09 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId09": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId09')}
                                    helperText={errors.includes('situacoesItensSolicitadosId09') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.10 - {inputs.itensPlanejamentosFinanceirosDescricao10}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado10"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado10 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado10"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado10"
                                label="Valor Liberado"
                                value={inputs.valorLiberado10 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado10"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId10"
                                value={inputs.situacoesItensSolicitadosId10 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId10": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId10')}
                                    helperText={errors.includes('situacoesItensSolicitadosId10') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>




                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography component="div">
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.11 - {inputs.itensPlanejamentosFinanceirosDescricao11}</Box>
                              </Typography> 
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado11"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado11 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado11"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado11"
                                label="Valor Liberado"
                                value={inputs.valorLiberado11 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado11"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId11"
                                value={inputs.situacoesItensSolicitadosId11 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId11": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId11')}
                                    helperText={errors.includes('situacoesItensSolicitadosId11') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>


















                      </>
                      : <></>
                    }

                    <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, backgroundColor: '#e3f2fd' }}>
                      <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                        <Grid item xs={13} sm={13} md={6} lg={6} xl={6}>
                          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                            <Typography component="div" noWrap>
                              <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>1.00 - SUBTOTAL</Box>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                          <TextField
                            id="valorSolicitadoSubtotal01"
                            label="Valor Solicitado"
                            value={inputs.valorSolicitadoSubtotal01 || "0.0"}
                            color="primary"
                            size="small"
                            focused 
                            fullWidth
                            autoComplete="off"
                            dir="rtl"
                            name="valorSolicitadoSubtotal01"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              style: { backgroundColor: "#e3f2fd" }, readOnly: true
                            }}
                            onChange={handleInputsChanges}
                          />
                        </Grid>

                        { showLiberado ?
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorLiberadoSubtotal01"
                              label="Valor Liberado"
                              value={inputs.valorLiberadoSubtotal01 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorLiberadoSubtotal01"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                          : <></>
                        }

                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showMateriaisConsumoData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowMateriaisConsumoData} /> : <></>}
                            {showMateriaisConsumoData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowMateriaisConsumoData} /> : <></>}
                            2.00 - MATERIAIS DE CONSUMO
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    {
                      showMateriaisConsumoData ?
                      <>
                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>2.01 - {inputs.itensPlanejamentosFinanceirosDescricao12}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado12"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado12 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado12"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado12"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado12 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado12"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId12"
                                  value={inputs.situacoesItensSolicitadosId12 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId12": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId12')}
                                      helperText={errors.includes('situacoesItensSolicitadosId12') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>2.02 - {inputs.itensPlanejamentosFinanceirosDescricao13}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado13"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado13 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado13"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado13"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado13 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado13"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId13"
                                value={inputs.situacoesItensSolicitadosId13 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId13": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId13')}
                                    helperText={errors.includes('situacoesItensSolicitadosId13') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>2.03 - {inputs.itensPlanejamentosFinanceirosDescricao14}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado14"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado14 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado14"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado14"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado14 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado14"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId14"
                                  value={inputs.situacoesItensSolicitadosId14 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId14": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId14')}
                                      helperText={errors.includes('situacoesItensSolicitadosId14') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>2.04 - {inputs.itensPlanejamentosFinanceirosDescricao15}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado15"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado15 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado15"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado15"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado15 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado15"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId15"
                                  value={inputs.situacoesItensSolicitadosId15 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId15": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId15')}
                                      helperText={errors.includes('situacoesItensSolicitadosId15') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>2.05 - {inputs.itensPlanejamentosFinanceirosDescricao16}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado16"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado16 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado16"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado16"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado16 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado16"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId16"
                                  value={inputs.situacoesItensSolicitadosId16 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId16": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId16')}
                                      helperText={errors.includes('situacoesItensSolicitadosId16') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 17, color: '#A9A9A9' }}>2.06 - {inputs.itensPlanejamentosFinanceirosDescricao17}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado17"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado17 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado17"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado17"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado17 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado17"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId17"
                                  value={inputs.situacoesItensSolicitadosId17 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId17": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId17')}
                                      helperText={errors.includes('situacoesItensSolicitadosId17') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>
                      </>
                      : <></>
                    }

                    <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, backgroundColor: '#e3f2fd' }}>
                      <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                        <Grid item xs={13} sm={13} md={6} lg={6} xl={6}>
                          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                            <Typography component="div" noWrap>
                              <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>2.00 - SUBTOTAL</Box>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                          <TextField
                            id="valorSolicitadoSubtotal02"
                            label="Valor Solicitado"
                            value={inputs.valorSolicitadoSubtotal02 || "0.0"}
                            color="primary"
                            size="small"
                            focused 
                            fullWidth
                            autoComplete="off"
                            dir="rtl"
                            name="valorSolicitadoSubtotal02"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              style: { backgroundColor: "#e3f2fd" }, readOnly: true
                            }}
                            onChange={handleInputsChanges}
                          />
                        </Grid>

                        { showLiberado ?
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorLiberadoSubtotal02"
                              label="Valor Liberado"
                              value={inputs.valorLiberadoSubtotal02 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorLiberadoSubtotal02"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                          : <></>
                        }

                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showPermanentesCapitalData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowPermanentesCapitalData} /> : <></>}
                            {showPermanentesCapitalData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowPermanentesCapitalData} /> : <></>}
                            3.00 - CAPITAL / INVESTIMENTOS / MATERIAIS PERMANENTES
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    {
                      showPermanentesCapitalData ?
                      <>

                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>3.01 - {inputs.itensPlanejamentosFinanceirosDescricao18}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado18"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado18 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado18"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado18"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado18 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado18"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId18"
                                  value={inputs.situacoesItensSolicitadosId18 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId18": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId18')}
                                      helperText={errors.includes('situacoesItensSolicitadosId18') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>3.02 - {inputs.itensPlanejamentosFinanceirosDescricao19}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado19"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado19 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado19"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado19"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado19 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado19"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId19"
                                  value={inputs.situacoesItensSolicitadosId19 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId19": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId19')}
                                      helperText={errors.includes('situacoesItensSolicitadosId19') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>


                        <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                          <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <Box>
                                <Typography component="div">
                                  <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>3.03 - {inputs.itensPlanejamentosFinanceirosDescricao20}</Box>
                                </Typography> 
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorSolicitado20"
                                label="Valor Solicitado"
                                value={inputs.valorSolicitado20 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorSolicitado20"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>

                            { showLiberado ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <TextField
                                  id="valorLiberado20"
                                  label="Valor Liberado"
                                  value={inputs.valorLiberado20 || "0.0"}
                                  color="primary"
                                  size="small"
                                  focused 
                                  fullWidth
                                  autoComplete="off"
                                  dir="rtl"
                                  name="valorLiberado20"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                  }}
                                  onChange={handleInputsChanges}
                                />
                              </Grid>
                              : <></>
                            }

                            { showSituacao ?
                              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <Autocomplete
                                  id="situacoesItensSolicitadosId20"
                                  value={inputs.situacoesItensSolicitadosId20 || null}
                                  options={deferidoList}
                                  onChange={(event, newValue) => {
                                    setInputs(values => ({...values, "situacoesItensSolicitadosId20": newValue}))
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Situação"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      focused
                                      required
                                      error={errors.includes('situacoesItensSolicitadosId20')}
                                      helperText={errors.includes('situacoesItensSolicitadosId20') ? 'Campo obrigatório!' : ''}
                                    />
                                  )}
                                />
                              </Grid>
                              : <></>
                            }
                          </Grid>
                        </Grid>















                      </>
                      : <></>
                    }

                    <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, backgroundColor: '#e3f2fd' }}>
                      <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                        <Grid item xs={13} sm={13} md={6} lg={6} xl={6}>
                          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                            <Typography component="div" noWrap>
                              <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>3.00 - SUBTOTAL</Box>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                          <TextField
                            id="valorSolicitadoSubtotal03"
                            label="Valor Solicitado"
                            value={inputs.valorSolicitadoSubtotal03 || "0.0"}
                            color="primary"
                            size="small"
                            focused 
                            fullWidth
                            autoComplete="off"
                            dir="rtl"
                            name="valorSolicitadoSubtotal03"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              style: { backgroundColor: "#e3f2fd" }, readOnly: true
                            }}
                            onChange={handleInputsChanges}
                          />
                        </Grid>

                        { showLiberado ?
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorLiberadoSubtotal03"
                              label="Valor Liberado"
                              value={inputs.valorLiberadoSubtotal03 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorLiberadoSubtotal03"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                          : <></>
                        }
                      </Grid>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box>
                        <Typography component="div">
                          <Box component="div" className={`${classes.centerBox} ${classes.box}`} sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9' }}>
                            {!showOpcionaisData ? <Plus style={{ cursor: 'pointer' }} onClick={getShowOpcionaisData} /> : <></>}
                            {showOpcionaisData ? <Minus style={{ cursor: 'pointer' }} onClick={getShowOpcionaisData} /> : <></>}
                            4.00 - OUTRAS DESPESAS
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>

                    {
                      showOpcionaisData ?
                      <>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                              <Typography component="div" noWrap>
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>4.01 -</Box>
                              </Typography>
                              <TextField
                                id="itensPlanejamentosFinanceirosDescricao21"
                                label=""
                                value={inputs.itensPlanejamentosFinanceirosDescricao21 || ""}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                name="itensPlanejamentosFinanceirosDescricao21"
                                inputProps={{
                                  maxlength: 80,
                                  style: { textTransform: "uppercase", backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado21"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado21 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado21"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado21"
                                label="Valor Liberado"
                                value={inputs.valorLiberado21 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado21"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                              <Autocomplete
                                id="tiposItensPlanejamentosFinanceirosId21"
                                value={inputs.tiposItensPlanejamentosFinanceirosId21 || null}
                                options={tiposItensPlanejamentosFinanceirosList}
                                isOptionEqualToValue={(option, value) => option.value=== value.value}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "tiposItensPlanejamentosFinanceirosId21": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Tipo"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('tiposItensPlanejamentosFinanceirosId21')}
                                    helperText={errors.includes('tiposItensPlanejamentosFinanceirosId21') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId21"
                                value={inputs.situacoesItensSolicitadosId21 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId21": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId21')}
                                    helperText={errors.includes('situacoesItensSolicitadosId21') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:5, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={13} sm={13} md={6} lg={6} xl={6}>
                            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                              <Typography component="div" noWrap>
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>4.02 -</Box>
                              </Typography>
                              <TextField
                                id="itensPlanejamentosFinanceirosDescricao22"
                                label=""
                                value={inputs.itensPlanejamentosFinanceirosDescricao22 || ""}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                name="itensPlanejamentosFinanceirosDescricao22"
                                inputProps={{
                                  maxlength: 80,
                                  style: { textTransform: "uppercase", backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                                error={errors.includes('itensPlanejamentosFinanceirosDescricao22')}
                                helperText={errors.includes('itensPlanejamentosFinanceirosDescricao22') ? 'Campo obrigatório!' : ''}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado22"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado22 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado22"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado22"
                                label="Valor Liberado"
                                value={inputs.valorLiberado22 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado22"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }


                          { showLiberado ? 
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                              <Autocomplete
                                id="tiposItensPlanejamentosFinanceirosId22"
                                value={inputs.tiposItensPlanejamentosFinanceirosId22 || null}
                                options={tiposItensPlanejamentosFinanceirosList}
                                isOptionEqualToValue={(option, value) => option.value=== value.value}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "tiposItensPlanejamentosFinanceirosId22": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Tipo"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('tiposItensPlanejamentosFinanceirosId22')}
                                    helperText={errors.includes('tiposItensPlanejamentosFinanceirosId22') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId22"
                                value={inputs.situacoesItensSolicitadosId22 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId22": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId22')}
                                    helperText={errors.includes('situacoesItensSolicitadosId22') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>


                      <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:5, mb: { xs:25, sm:20, md:2, lg:2, xl:2 } , ml: 2, '&:hover': { backgroundColor: '#e3f2fd' } }}>
                        <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                          <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                              <Typography component="div" noWrap>
                                <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>4.03 -</Box>
                              </Typography>
                              <TextField
                                id="itensPlanejamentosFinanceirosDescricao23"
                                label=""
                                value={inputs.itensPlanejamentosFinanceirosDescricao23 || ""}
                                color="primary"
                                size="small"
                                focused
                                fullWidth
                                autoComplete="off"
                                name="itensPlanejamentosFinanceirosDescricao23"
                                inputProps={{
                                  maxlength: 80,
                                  style: { textTransform: "uppercase", backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Stack>
                          </Grid>

                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorSolicitado23"
                              label="Valor Solicitado"
                              value={inputs.valorSolicitado23 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorSolicitado23"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" }, readOnly: desabilitaPreenchimento
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>

                          { showLiberado ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <TextField
                                id="valorLiberado23"
                                label="Valor Liberado"
                                value={inputs.valorLiberado23 || "0.0"}
                                color="primary"
                                size="small"
                                focused 
                                fullWidth
                                autoComplete="off"
                                dir="rtl"
                                name="valorLiberado23"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  style: { backgroundColor: !admin ? "#e3f2fd" : "#ffffff" }, readOnly: !adminEdit
                                }}
                                onChange={handleInputsChanges}
                              />
                            </Grid>
                            : <></>
                          }

                          { showLiberado ? 
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                              <Autocomplete
                                id="tiposItensPlanejamentosFinanceirosId23"
                                value={inputs.tiposItensPlanejamentosFinanceirosId23 || null}
                                options={tiposItensPlanejamentosFinanceirosList}
                                isOptionEqualToValue={(option, value) => option.value=== value.value}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "tiposItensPlanejamentosFinanceirosId23": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Tipo"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('tiposItensPlanejamentosFinanceirosId23')}
                                    helperText={errors.includes('tiposItensPlanejamentosFinanceirosId23') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }

                          { showSituacao ?
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                              <Autocomplete
                                id="situacoesItensSolicitadosId23"
                                value={inputs.situacoesItensSolicitadosId23 || null}
                                options={deferidoList}
                                onChange={(event, newValue) => {
                                  setInputs(values => ({...values, "situacoesItensSolicitadosId23": newValue}))
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Situação"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    focused
                                    required
                                    error={errors.includes('situacoesItensSolicitadosId23')}
                                    helperText={errors.includes('situacoesItensSolicitadosId23') ? 'Campo obrigatório!' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            : <></>
                          }
                        </Grid>
                      </Grid>

                      </>
                      : <></>
                    }

                    <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:5, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, backgroundColor: '#e3f2fd' }}>
                      <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                        <Grid item xs={13} sm={13} md={6} lg={6} xl={6}>
                          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                            <Typography component="div" noWrap>
                              <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>4.00 - SUBTOTAL</Box>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                          <TextField
                            id="valorSolicitadoSubtotal04"
                            label="Valor Solicitado"
                            value={inputs.valorSolicitadoSubtotal04 || "0.0"}
                            color="primary"
                            size="small"
                            focused 
                            fullWidth
                            autoComplete="off"
                            dir="rtl"
                            name="valorSolicitadoSubtotal04"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              style: { backgroundColor: "#e3f2fd" }, readOnly: true
                            }}
                            onChange={handleInputsChanges}
                          />
                        </Grid>

                        { showLiberado ?
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorLiberadoSubtotal04"
                              label="Valor Liberado"
                              value={inputs.valorLiberadoSubtotal04 || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorLiberadoSubtotal04"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                          : <></>
                        }
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} className={`${classes.centerBox} ${classes.box}`} sx={{ mt:1, mb: { xs:25, sm:20, md:1, lg:1, xl:1 } , ml: 2, backgroundColor: '#e3f2fd' }}>
                      <Grid container className={`${classes.centerBox} ${classes.box}`} spacing={2} sx={{ ml:1, mr: 1, pl: 1, pr: 1 }}>
                        <Grid item xs={13} sm={13} md={6} lg={6} xl={6}>
                          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                            <Typography component="div" noWrap>
                              <Box sx={{ fontWeight: 'light', fontSize: 16, color: '#A9A9A9' }}>TOTAL GERAL</Box>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                          <TextField
                            id="valorTotalGeralSolicitado"
                            label="Valor Solicitado"
                            value={inputs.valorTotalGeralSolicitado || "0.0"}
                            color="primary"
                            size="small"
                            focused 
                            fullWidth
                            autoComplete="off"
                            dir="rtl"
                            name="valorTotalGeralSolicitado"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              style: { backgroundColor: "#e3f2fd" }, readOnly: true
                            }}
                            onChange={handleInputsChanges}
                          />
                        </Grid>

                        { showLiberado ?
                          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                            <TextField
                              id="valorTotalGeralLiberado"
                              label="Valor Liberado"
                              value={inputs.valorTotalGeralLiberado || "0.0"}
                              color="primary"
                              size="small"
                              focused 
                              fullWidth
                              autoComplete="off"
                              dir="rtl"
                              name="valorTotalGeralLiberado"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                style: { backgroundColor: "#e3f2fd" }, readOnly: true
                              }}
                              onChange={handleInputsChanges}
                            />
                          </Grid>
                          : <></>
                        }
                      </Grid>
                    </Grid>
                  </>
                  : <></>
              }

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ flex: '100%', pb: 2 }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Observações Caixa Escolar"
                    multiline
                    fullWidth
                    rows={2}
                    focused
                    name="observacoesCaixaEscolar"
                    value={inputs.observacoesCaixaEscolar}
                    InputProps={{
                      maxlength: 200,
                      style: { textTransform: "uppercase", backgroundColor: desabilitaPreenchimento ? "#e3f2fd" : "#ffffff" },
                      readOnly: desabilitaPreenchimento
                    }}
                    onChange={handleInputsChanges}
                  />
                </Grid>

                { showObservacaoSeduc ?
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ flex: '100%', pb: 2 }}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Observações Seduc"
                      multiline
                      fullWidth
                      rows={2}
                      focused
                      name="observacoesSeduc"
                      value={inputs.observacoesSeduc}
                      inputProps={{ maxlength: 200, style: { textTransform: "uppercase" } }}
                      onChange={handleInputsChanges}
                    />
                  </Grid>
                  : <></>
                }
            </Grid>
          </Grid>
        </Paper>
      </Typography>
    </Container>
  );
}
