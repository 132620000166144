import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import ClearOutlinedIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

import { Button } from "@mui/material";

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { Worker, Viewer, LocalizationMap } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";

import pt_PT from '@react-pdf-viewer/locales/lib/pt_PT.json';
import {Buffer} from 'buffer';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import axios from "axios";

// import demoFile from "../../../pages/EstatutosAtas/EstatutosAtasRelatorio/075b-121680284523.pdf";

import api from '../../../services/api';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const query = useParams();
  const history = useHistory();

    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
  
    // for onchange event
    const [pdfFile, setPdfFile]=useState(null);
    const [pdfFileError, setPdfFileError]=useState('');
    const [title, setTitle]=useState('Controladoria Geral do Município - Dados Cadastrais');
    const [subTitle, setSubTitle]=useState('');


    // const [base64, setBase64]=useState('data:application/pdf;base64,JVBERi0xLjcKCjEgMCBvYmogICUgZW50cnkgcG9pbnQKPDwKICAvVHlwZSAvQ2F0YWxvZwogIC9QYWdlcyAyIDAgUgo+PgplbmRvYmoKCjIgMCBvYmoKPDwKICAvVHlwZSAvUGFnZXMKICAvTWVkaWFCb3ggWyAwIDAgMjAwIDIwMCBdCiAgL0NvdW50IDEKICAvS2lkcyBbIDMgMCBSIF0KPj4KZW5kb2JqCgozIDAgb2JqCjw8CiAgL1R5cGUgL1BhZ2UKICAvUGFyZW50IDIgMCBSCiAgL1Jlc291cmNlcyA8PAogICAgL0ZvbnQgPDwKICAgICAgL0YxIDQgMCBSIAogICAgPj4KICA+PgogIC9Db250ZW50cyA1IDAgUgo+PgplbmRvYmoKCjQgMCBvYmoKPDwKICAvVHlwZSAvRm9udAogIC9TdWJ0eXBlIC9UeXBlMQogIC9CYXNlRm9udCAvVGltZXMtUm9tYW4KPj4KZW5kb2JqCgo1IDAgb2JqICAlIHBhZ2UgY29udGVudAo8PAogIC9MZW5ndGggNDQKPj4Kc3RyZWFtCkJUCjcwIDUwIFRECi9GMSAxMiBUZgooSGVsbG8sIHdvcmxkISkgVGoKRVQKZW5kc3RyZWFtCmVuZG9iagoKeHJlZgowIDYKMDAwMDAwMDAwMCA2NTUzNSBmIAowMDAwMDAwMDEwIDAwMDAwIG4gCjAwMDAwMDAwNzkgMDAwMDAgbiAKMDAwMDAwMDE3MyAwMDAwMCBuIAowMDAwMDAwMzAxIDAwMDAwIG4gCjAwMDAwMDAzODAgMDAwMDAgbiAKdHJhaWxlcgo8PAogIC9TaXplIDYKICAvUm9vdCAxIDAgUgo+PgpzdGFydHhyZWYKNDkyCiUlRU9G');
  
    const [file, setFile]=useState(null);
    // for submit event
    const [viewPdf, setViewPdf]=useState(null);

    React.useEffect(() => {

        // const url = `/estatutos-atas/pdf2/${query.caixaEscolarId}/${query.relatorioId}`;

        const url = process.env.REACT_APP_HOST_LARAVEL + `/api/get-dados-cadastrais`;
        const values = api.get(url, {responseType: 'arraybuffer'})
        .then(
            response => { 

              // let buff = new Buffer(data);
              // let base64data = buff.toString('base64');

                const buffer = Buffer.from(response.data, 'base64');
                // const buffer = Buffer.from(response.data, 'base64');
                // console.log('buffer', buffer);
                // setBase64(buffer);
                setFile(buffer);
            })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        })
    }, []);

  React.useEffect(() => {
    // const values = api.get(`/estatutos-atas/dados-documento/${query.relatorioId}`)
    //  .then((response) => {
    //    console.log('estutos-atas-dados-documents xxxx:', response.data);
    //    const title = response.data.caixasEscolaresNome + ' - ' + response.data.documentosTiposDescricao;
    //    setTitle(title);
    //   //    let subtitle = response.data.documentosTiposDescricao;
    //   //    if (response.data.initialDate) {
    //   //      subtitle += ` - Data Início: ${response.data.initialDate}`;

    //   //    }
    //   //    if (response.data.finalDate) {
    //   //     subtitle += ` - Data Final: ${response.data.finalDate}`;
    //   //    }
    //   //    if (response.data.dataRegistroCartorio) {
    //   //     subtitle += ` - Data Registro Cartório: ${response.data.dataRegistroCartorio}`;
    //   //    }
    //   //    if (response.data.updloadDate) {
    //   //     subtitle += ` - Data Cadastro: ${response.data.updloadDate}`;
    //   //    }
    //   //    setSubTitle(subtitle);
    //   })
    //  .catch((err) => {
    //    console.error("ops! ocorreu um erro" + err);
    //  })
   }, []);


    

    const pdfContentType = 'application/pdf';

    // const base64toBlob = (data: string) => {
    //     // Cut the prefix `data:application/pdf;base64` from the raw base 64
    //     const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

    //     const bytes = atob(base64WithoutPrefix);
    //     let length = bytes.length;
    //     let out = new Uint8Array(length);

    //     while (length--) {
    //         out[length] = bytes.charCodeAt(length);
    //     }

    //     return new Blob([out], { type: pdfContentType });
    // };

    // const url = URL.createObjectURL(base64toBlob(base64));

  return (
    <Box sx={{ width: '100%', mt: 2  }}>

      <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 4, pb: 1 }}>

          <Grid item xs={11}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{title}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={1} sx={{ pb: 0 }}>
              <Box
              component="div"
              className={`${classes.bottomRightBox} ${classes.box}`}>
              <div>
                <Button onClick={() => {history.push(`/caixas-escolares-list`)}} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
                  Voltar
                </Button>
              </div>

              </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <Box>
              <Typography component="div">
                <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9', pt: 2 }}>{subTitle}</Box>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ flex: '100%', pb: 2 }}>
            <div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                <div style={{ height: "720px" }}>
                  {file && <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} localization={pt_PT} />}
                </div>
              </Worker>
              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                <div style={{ height: "720px" }}>
                <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} localization={pt_PT} />
                </div>
              </Worker> */}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}


