import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { minWidth } from '@mui/system';
import MuiAlert from '@mui/material/Alert';

import SpinnerCarregando from '../SpinnerCarregando';

import api from '../../services/api';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AlertDeleteDialog(props) {
  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados excluídos com sucesso!');

  const deleteRegister = () => {
    setBackdrop(true);
    const values = api.delete(`dados-bancarios/${props.data.id}`)
    values.then((response) => {
      setBackdrop(false);
      handleClickAlert();
      // console.log("submit: ", data);
    })
    .catch((err) => {
      setMessage('Erro ao excluir os dados! Verifique!');
      setBackdrop(false);
      setSeverity('error');
      handleClickAlert();
      // setSubmitting(false);
      console.error("ops! ocorreu um erro" + err);
    })
  }

  const handleClickAlert = () => {
    setOpen(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <SpinnerCarregando open={backdrop} />
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        style={{ minWidth: '80%' }}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          Confirma a exclusão do registro?
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom variant="subtitle1">
            {props.data1}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {props.data2}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {props.data3}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick= { () => props.handleCloseDialog() }>Cancelar</Button>
          <Button onClick= { () => props.deleteRegister() } autoFocus>Excluir</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
