import React, { useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Container, Grid, Paper, TextField, Typography } from "@mui/material";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DateAdapter from '@mui/lab/AdapterMoment';
// import DateAdapter from '@mui/lab/AdapterDayjs';
// import DateAdapter from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ptBRLocale from 'date-fns/locale/pt-BR';

import { validarCPF, isDate, formatDateEnUs2, removeItemArray } from '../../../utils/functions'

// import schema from '../schema';
// import { useFormik } from 'formik';

import InputMask from 'react-input-mask';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SpinnerCarregando from '../../../components/Controls/SpinnerCarregando';
// import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';

// import DeleteIcon from '@mui/icons-material/Delete';
// import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';

// import TelefonesTable from '../../../components/TelefonesTable';
import TelefonesEditPostTable from "../../../components/TelefonesEditPostTable";

import api from "../../../services/api";
import { set, parseISO, format } from "date-fns";
import { convertToLocalTime } from 'date-fns-timezone';

import AssinaturaPessoa from "../../../components/AssinaturaPessoa";

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Format a date to a string
 *
 * @param date
 */
export const formatDate = (date) => {
  if (!date) return new Date().toLocaleString();

  // Get the timezone from browser using native methods
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTmp = Date.parse(date.toLocaleString());

  const localDate = convertToLocalTime(dateTmp, {
    timeZone: timezone,
  });

  return format(localDate, DEFAULT_DATE_FORMAT);
};

export default function MembrosConselhoFiscal() {
  const query = useParams();
  // const history = useHistory();
  const location = useLocation();
  // const classes = useStyles();

  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');

  const [initialValues, setInitialValues] = React.useState({});

  const [id, setId] = React.useState('');

  const [caixasEscolaresHasCargosFuncoesId, setCaixasEscolaresHasCargosFuncoesId] = React.useState('');
  const [caixasEscolaresNome , setCaixasEscolaresNome] = React.useState("");
  const [caixasEscolaresId, setCaixasEscolaresId] = React.useState('');
  const [caixasEscolaresList, setCaixasEscolaresList] = React.useState([]);
  const [pessoaId, setPessoaId] = React.useState('');
  const [nome, setPessoaNome] = React.useState("");
  const [pessoaCpf, setPessoaCpf] = React.useState('');
  const [pessoaDocumentoIdentidade, setPessoaDocumentoIdentidade] = React.useState("");
  const [pessoaIdentidadeDataExpedicao, setPessoaIdentidadeDataExpedicao] = React.useState('');
  const [pessoaIdentidadeOrgaoExpedidorId, setPessoaIdentidadeOrgaoExpedidorId] = React.useState('');
  const [orgaosExpedidoresList, setOrgaosExpedidoresList] = React.useState([]);
  const [pessoaEstadosIdExpedicaoIdentidade, setPessoaEstadosIdExpedicaoIdentidade] = React.useState('');

  const [estadosIdExpedicaoIdentidade, setEstadosIdExpedicaoIdentidade] = React.useState('');

  const [pessoaSexoId , setPessoaSexoId] = React.useState('');
  const [sexoList, setSexoList] = React.useState([]);
  const [pessoaDataNascimento, setPessoaDataNascimento] = React.useState('');
  const [pessoaNacionalidadeId, setPessoaNacionalidadeId] = React.useState('');
  const [nacionalidadesList, setNacionalidadeList] = React.useState([]);

  const [pessoaEstadoCivilId , setPessoaEstadoCivilId ] = React.useState('');
  const [estadosCivisList, setEstadosCivisList] = React.useState([]);
  const [pessoaRegimeCasamentoId, setPessoaRegimeCasamentoId ] = React.useState('');
  const [regimesDeCasamentosList, setRegimesDeCasamentosList] = React.useState([]);
  const [pessoaConjugeNome, setPessoaConjugeNome] = React.useState('');
  const [pessoaFiliacao1, setPessoaFiliacao1] = React.useState('');
  const [pessoaFiliacao2, setPessoaFiliacao2] = React.useState('');
  // const [cargoId, setCargoId] = React.useState('');
  const [pessoaCargoId, setPessoaCargoId] = React.useState('');
  const [cargosList, setCargosList] = React.useState([]);
  const [pessoaServidorMatricula, setPessoaServidorMatricula] = React.useState('');
  
  const [pessoaCargoFuncaoId, setPessoaCargoFuncaoId] = React.useState('');
  const [funcoesList, setFuncoesList] = React.useState([]);
  const [pessoaPeriodoDeMandatosDataInicio, setPessoaPeriodoDeMandatosDataInicio] = React.useState('');
  const [pessoaPeriodoDeMandatosDataFinal, setPessoaPeriodoDeMandatosDataFinal] = React.useState('');
  
  const [pessoaCep, setPessoaCep ] = React.useState('');
  const [pessoaLogradouro, setPessoaLogradouro] = React.useState('');
  const [pessoaNumero, setPessoaNumero] = React.useState('');
  const [pessoaComplementos, setPessoaComplementos] = React.useState('');
  
  const [pessoaBairro, setPessoaBairro] = React.useState('');
  const [pessoaCidadeId, setPessoaCidadeId] = React.useState('');

  const [pessoaEstadoDescricao, setPessoaEstadoDescricao] = React.useState('');
  // const [cidadeId, setCidadeId] = React.useState('');

  const [cidadesList, setCidadesList] = React.useState([]);
  const [cidadesResidenciaList, setCidadesResidenciaList] = React.useState([]);

  const [estadosList, setEstadosList] = React.useState([]);

  const [pessoaSituacaoFuncionalId, setPessoaSituacaoFuncionalId] = React.useState('');
  const [situacoesFuncionaisList, setSituacoesFuncionaisList] = React.useState([]);

  const [pessoaCidadeNatalId , setPessoaCidadeNatalId ] = React.useState('');
  const [pessoaEstadoNatalId , setPessoaEstadoNatalId ] = React.useState('');
  // const [todasCidadesNatalList , setTodasCidadesNatalList ] = React.useState([]);
  const [cidadesNatalList , setCidadesNatalList ] = React.useState([]);

  const [cidadesNatalFilteredList, setCidadesNatalFilteredList] = React.useState([]);

  const [showPessoaEmailInstitucional, setShowPessoaEmailInstitucional] = React.useState(false);
  const [pessoaEmailPessoal, setPessoaEmailPessoal] = React.useState('');
  const [pessoaEmailInstitucional, setPessoaEmailInstitucional] = React.useState('');

  const [usuariosId, setUsuariosId] = React.useState('');
  const [usuariosSituacoesId, setUsuariosSituacoesId] = React.useState('');

  const [usuariosSituacoesDescricao, setUsuariosSituacoesDescricao] = React.useState('');
  const [usuariosSituacoesAbreviacao, setUsuariosSituacoesAbreviacao] = React.useState('');

  const [periodoMandatoId, setPeriodoMandatoId] = React.useState('');
  const [tiposDeMandatosId, setTiposDeMandatosId] = React.useState('');
  const [tiposDeMandatosDescricao, setTiposDeMandatosDescricao] = React.useState('');
  const [tiposDeMandatosAbreviacao, setTiposDeMandatosAbreviacao] = React.useState('');
  const [tiposDeMandatosList, setTiposDeMandatosList] = React.useState([]);
  // const [tiposDeMandatos, setTiposDeMandatos] = React.useState('');

  const [checked, setChecked] = React.useState(false);
  const [ativoLabel, setAtivoLabel] = React.useState('');

  const [disabledPessoaServidorMatricula, setDisabledPessoaServidorMatricula] = React.useState(false);

  // const getCidade = (cidadeNome) => {
  //   const cidade = cidadesList.filter(item => item.label.includes(cidadeNome.toUpperCase()));
  //   setPessoaCidadeId(cidade[0]);
  // }

  const getEndereco = (cep) => {
    setPessoaLogradouro('');
    setPessoaBairro('');
    fetch(`https:/viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.logradouro) {
          const logradouro = data && data.logradouro ? data.logradouro.toUpperCase() : '';
          const bairro = data && data.bairro ? data.bairro.toUpperCase() : '';
          // const cidade = getCidade(data.localidade);
          setPessoaLogradouro(logradouro);
          setPessoaBairro(bairro);
        }
      })
  }

  const getCep = () => {
    if (pessoaCep && (pessoaCep.replace(/[^\d]+/g,'')).length === 8) {
      getEndereco(pessoaCep);
    }
   }

   useEffect(() => {
    // @ts-ignore
    if (query.id) {
      // @ts-ignore
      const values = api.get(`estados`)
      .then((response) => {
        setEstadosList(response.data)
        // @ts-ignore
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (query.id) {
      // @ts-ignore
      const values = api.get(`situacoes-funcionais`)
      .then((response) => {
        setSituacoesFuncionaisList(response.data)
        // @ts-ignore
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  // useEffect(() => {
  //   // @ts-ignore
  //   if (query.id) {
  //     // @ts-ignore
  //     const values = api.get(`cidades`)
  //     .then((response) => {
  //       setCidadesNatalList(response.data)
  //       // @ts-ignore
  //     })
  //     .catch((err) => {
  //       console.error("ops! ocorreu um erro" + err);
  //     })
  //   }
  // }, []);

  useEffect(() => {
    // @ts-ignore
    const values = api.get(`cidades`)
    .then((response) => {
      setCidadesList(response.data)
      // @ts-ignore
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  // const cidadesNatalByEstado = (estado) => {
  //   return allCidadesList.filter(item => item.estadosId === estado);
  // }

  useEffect(() => {
    setShowPessoaEmailInstitucional(false);
    setDisabledPessoaServidorMatricula(true);
    if (
      pessoaCargoId && ((pessoaCargoId.id !== "5eda5aba-11ea-11ec-833c-dc41a970c8d9") && pessoaCargoId.id !== "51936fb5-11e9-11ec-833c-dc41a970c8d9")
    ) {
      setShowPessoaEmailInstitucional(true);
      setDisabledPessoaServidorMatricula(false);
    }
  }, [pessoaCargoId]);

  useEffect(() => {
    // @ts-ignore
    setNewRegister();
    setBackdrop(true);
    
      // @ts-ignore
      setBackdrop(true);
      //@ts-ignore
      const values = api.get(`caixas-escolares-has-cargos-funcoes/${query.id}`)
      .then((response) => {

        const data = response.data;
        setInitialValues(data);
        // setInitialValues(data);

        setId(data.id);
        setUsuariosId(data.usuariosId);

        setCaixasEscolaresId(data.caixasEscolaresId);

        setCaixasEscolaresHasCargosFuncoesId(data.id);
        setCaixasEscolaresNome(data.caixasEscolaresNome);

        setPessoaId(data.pessoaId);
        setPessoaCpf(data.pessoaCpf);
        setPessoaNome(data.pessoaNome);
        setPessoaDocumentoIdentidade(data.pessoaIdentidade);
        setPessoaIdentidadeDataExpedicao(data.pessoaIdentidadeDataExpedicao);
        
        setPessoaDataNascimento(data.pessoaDataNascimento);
        setPessoaFiliacao1(data.pessoaFiliacao1);
        setPessoaFiliacao2(data.pessoaFiliacao2);
        setPessoaServidorMatricula(data.pessoaServidorMatricula);

        setPeriodoMandatoId(data.pessoaPeriodoDeMandatosId);
        setPessoaPeriodoDeMandatosDataInicio(data.pessoaPeriodoDeMandatosDataInicio);
        setPessoaPeriodoDeMandatosDataFinal(data.pessoaPeriodoDeMandatosDataFinal);

        // setTiposDeMandatosId(data.tiposDeMandatosId);
        // setTiposDeMandatosDescricao(data.tiposDeMandatosDescricao);
        // setTiposDeMandatosAbreviacao(data.tiposDeMandatosAbreviacao);

        setPessoaCep (data.pessoaCep);

        setPessoaLogradouro(data.pessoaLogradouro);
        setPessoaNumero(data.pessoaNumero);
        setPessoaComplementos(data.pessoaComplementos);
        setPessoaBairro(data.pessoaBairro);
        setPessoaEstadoDescricao(data.pessoaEstadoDescricao);

        setPessoaEstadoNatalId(data.pessoaEstadoNatalId);
        // setCidadesNatalFilteredList(cidadesNatalList.filter(item => item.id === data.pessoaEstadoNatalId));

        const pessoaCidadeNatalId = {
          "id": data.pessoaCidadeNatalId,
          "value": data.pessoaCidadeNatalId,
          "label": data.pessoaCidadeNatalDescricao,
          "codigo": data.pessoaCidadeNatalCodigo,
          "estadosId": data.pessoaEstadoNatalId
        }

        setPessoaCidadeNatalId(pessoaCidadeNatalId);

        // setPessoaCidadeNatalId(cidadesNatalList.filter(item => item.id === data.pessoaCidadeNatalId));

        setPessoaEmailPessoal(data.pessoaEmailPessoal);
        setPessoaEmailInstitucional(data.pessoaEmailInstitucional);

        // console.log('data.pessoaEmailInstitucional: ', data.pessoaEmailInstitucional);

        setPessoaSexoId(data.pessoaSexoId);
        // setPessoaSexoId(sexoList.find(item => item.id === data.pessoaSexoId));
        setPessoaIdentidadeOrgaoExpedidorId(data.pessoaIdentidadeOrgaoExpedidorId);

        setPessoaEstadosIdExpedicaoIdentidade(data.pessoaEstadosIdExpedicaoIdentidade);

        setEstadosIdExpedicaoIdentidade(data.pessoaEstadosIdExpedicaoIdentidade);
        
        // setPessoaIdentidadeOrgaoExpedidorId(orgaosExpedidoresList.find(item => item.id === data.pessoaIdentidadeOrgaoExpedidorId));

        setPessoaConjugeNome(data.pessoaConjugeNome);
        // setPessoaCidadeId(data.pessoaCidadeId);


        setTiposDeMandatosId(data.tiposDeMandatosId);
        const tiposDeMandatosId = {
          "id": data.tiposDeMandatosId,
          "value": data.tiposDeMandatosId,
          "label": data.tiposDeMandatosDescricao,
          "abreviacao": data.tiposDeMandatosAbreviacao
        }
        setTiposDeMandatosId(tiposDeMandatosId);

        setUsuariosSituacoesId(data.usuariosSituacoesId);

        selectedCheck(data.usuariosSituacoesId);

        setUsuariosSituacoesDescricao(data.usuariosSituacoesDescricao);
        setUsuariosSituacoesAbreviacao(data.usuariosSituacoesAbreviacao);

        // if (response.data.tiposDeMandatosId) {
        //   setTiposDeMandatosId({
        //     "abreviacao": response.data.tiposDeMandatosAbreviacao,
        //     "id": response.data.tiposDeMandatosId,
        //     "label": response.data.tiposDeMandatosDescricao,
        //     "value": response.data.tiposDeMandatosId
        //   })
        // }
        // checkChecked();

        setBackdrop(false);
      })
      .catch((err) => {
        setBackdrop(false);
        console.error("ops! ocorreu um erro" + err);
      })

  }, []);

  useEffect(() => {
    setNewRegister();
    // @ts-ignore
    if (query.id && location.pathname.includes('new')) {
      setBackdrop(true);
      // @ts-ignore
      const values = api.get(`caixas-escolares/${query.id}`)
      .then((response) => {
        // setInitialValues(response.data);
        setCaixasEscolaresNome(response.data.caixasEscolaresNome);
        setBackdrop(false);
        // console.log('caixasEscolaresNome: ', response.data.caixasEscolaresNome);
        // setCep(response.data.pessoaCep);
        // @ts-ignore
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (query.id) {
      // @ts-ignore
      const values = api.get(`cidades/por-estado/4ada3eda-11f0-11ec-833c-dc41a970c8d9`)
      .then((response) => {
        setCidadesList(response.data);
        setCidadesResidenciaList(response.data);
        // @ts-ignore
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  // useEffect(() => {
  //   // @ts-ignore
  //   if (query.id) {
  //     // @ts-ignore
  //     const values = api.get(`caixas-escolares-has-cargos-funcoes/${query.id}`)
  //     values.then((response) => {
  //       setInitialValues(response.data);
  //       setPessoaSexoId(response.data.pessoaSexoId);
  //       setPessoaDataNascimento(response.data.pessoaDataNascimento);
  //       setPessoaIdentidadeDataExpedicao(response.data.pessoaIdentidadeDataExpedicao);
  //       setPessoaPeriodoDeMandatosDataInicio(response.data.pessoaPeriodoDeMandatosDataInicio);
  //       setPessoaPeriodoDeMandatosDataFinal(response.data.pessoaPeriodoDeMandatosDataFinal);
  //       console.log('resposta.data.pessoaDataNascimento: ', response.data);
  //       // @ts-ignore
  //     })
  //     .catch((err) => {
  //       console.error("ops! ocorreu um erro" + err);
  //     })
  //   }
  // }, []);

  useEffect(() => {
    setNewRegister();
    // @ts-ignore
    if (!query.id) {
      // @ts-ignore
      const values = api.get(`caixas-escolares/list`)
      .then((response) => {
        setCaixasEscolaresList(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    const values = api.get(`cargos-funcoes`)
    .then((response) => {
      setFuncoesList(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);
  
  useEffect(() => {
    // @ts-ignore
    if (query.id) {
      const values = api.get(`cargos`)
      .then((response) => {
        const cargos = response.data.filter(item => item.id !== '39f8b17f-11ea-11ec-833c-dc41a970c8d9' && item.id !== '343e22d0-11ea-11ec-833c-dc41a970c8d9');
        setCargosList(cargos)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }, []);

  useEffect(() => {
    const values = api.get("sexos")
    .then((response) => {
      setSexoList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    const values = api.get("nacionalidades")
    .then((response) => {
      setNacionalidadeList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);
  
  useEffect(() => {
    const values = api.get("estados-civis")
    .then((response) => {
      setEstadosCivisList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    const values = api.get("tipos-de-mandatos")
    .then((response) => {
      setTiposDeMandatosList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);
  
  useEffect(() => {
    const values = api.get("regimes-casamentos")
    .then((response) => {
      setRegimesDeCasamentosList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);
  
  useEffect(() => {
    const values = api.get("orgaos-expedidores")
    .then((response) => {
      setOrgaosExpedidoresList(response.data);
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    if (pessoaEstadoNatalId) {
      setCidadesNatalFilteredList(cidadesList.filter(item => item.estadosId === pessoaEstadoNatalId.id));
    }
  }, [pessoaEstadoNatalId, cidadesList]);

useEffect(() => {
  setErrors([]);
  if (pessoaCpf && pessoaCpf.replace(/\D/g, '').length === 11 && !validarCPF(pessoaCpf)) {
    setErrors(['pessoaCpf']);
  }
}, [pessoaCpf]);

  useEffect(() => {
    // setPessoaSexoId(sexoList.find(item => item.value === pessoaSexoId));

    // if (pessoaCpf && pessoaCpf.length === 11) {
      setPessoaSexoId(sexoList.find(item => item.id === initialValues.pessoaSexoId));
      setPessoaIdentidadeOrgaoExpedidorId(orgaosExpedidoresList.find(item => item.value === initialValues.pessoaIdentidadeOrgaoExpedidorId));
      setPessoaEstadosIdExpedicaoIdentidade(estadosList.find(item => item.value === initialValues.pessoaEstadosIdExpedicaoIdentidade));
      setPessoaCidadeId(cidadesList.find(item => item.value === initialValues.pessoaCidadeId));
      setPessoaCargoId(cargosList.find(item => item.value === initialValues.pessoaCargoId));
      setPessoaCargoFuncaoId(funcoesList.find(item => item.value === initialValues.pessoaCargoFuncaoId));
      setPessoaSituacaoFuncionalId(situacoesFuncionaisList.find(item => item.value === initialValues.pessoaSituacaoFuncionalId));
      // setCidadeId(cidadesList.find(item => item.value === initialValues.pessoaCidadeId));
      setPessoaNacionalidadeId(nacionalidadesList.find(item => item.value === initialValues.pessoaNacionalidadeId));
      setPessoaEstadoCivilId(estadosCivisList.find(item => item.value === initialValues.pessoaEstadoCivilId));
      setPessoaRegimeCasamentoId(regimesDeCasamentosList.find(item => item.value === initialValues.pessoaRegimeCasamentoId));
      setTiposDeMandatosId(tiposDeMandatosList.find(item => item.value === initialValues.tiposDeMandatosId));
      setPessoaIdentidadeOrgaoExpedidorId(orgaosExpedidoresList.find(item => item.id === initialValues.pessoaIdentidadeOrgaoExpedidorId));
      setPessoaEstadosIdExpedicaoIdentidade(estadosList.find(item => item.id === initialValues.pessoaEstadosIdExpedicaoIdentidade));
      setPessoaEstadoNatalId(estadosList.find(item => item.id === initialValues.pessoaEstadoNatalId));
      // setPessoaCidadeNatalId(cidadesList.find(item => item.id === initialValues.pessoaCidadeNatalId));
  }, [
    sexoList,
    cargosList,
    funcoesList,
    cidadesList,
    nacionalidadesList,
    estadosCivisList,
    regimesDeCasamentosList,
    orgaosExpedidoresList,
    estadosList,
    // cidadesNatalFilteredList,
    situacoesFuncionaisList

    // pessoaSexoId,
    // pessoaCargoId,
    // pessoaCargoFuncaoId,
    // pessoaCargoFuncaoId,
    // pessoaCidadeId,
    // pessoaNacionalidadeId,
    // pessoaEstadoCivilId,
    // pessoaRegimeCasamentoId,
    // pessoaIdentidadeOrgaoExpedidorId,
    // tiposDeMandatosId
  ]);


// useEffect(() => {
//   setPessoaIdentidadeOrgaoExpedidorId(orgaosExpedidoresList.find(item => item.value === pessoaIdentidadeOrgaoExpedidorId));

//   const testeA = orgaosExpedidoresList.find(item => item.value === pessoaIdentidadeOrgaoExpedidorId);
//   console.log("TesteA :", orgaosExpedidoresList);
// }, [orgaosExpedidoresList]);

  const [errors, setErrors] = React.useState([]);

  const handleClickAlert = () => {
    setOpen(true);
  };

  // @ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    // @ts-ignore
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

  const setNewRegister = () => {

  }


  const deleteConjuge = () => {
    if (initialValues.pessoaConjugeId) {
      api.delete(`conjuges/${initialValues.pessoaConjugeId}`)
      .then((response) => {
        // setSubmitting(false);
        setBackdrop(false);
        if (pessoaEstadoCivilId.id !== 'add9d146-282a-11ec-a127-00155db95d0c') {
          handleClickAlert();
        }
        // console.log("submit: ", data);
      })
      .catch((err) => {
        setMessage('Erro ao gravar os dados! Verifique!');
        setBackdrop(false);
        setSeverity('error');
        if (pessoaEstadoCivilId.id !== 'add9d146-282a-11ec-a127-00155db95d0c') {
          handleClickAlert();
        }
        // setSubmitting(false);
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }

  let newUsuariosSituacoesId = '';
  if (!usuariosSituacoesId || !usuariosSituacoesId.length === 36) {
    newUsuariosSituacoesId = '50a9e0f2-3c43-11ec-aa43-00155de398f0';
  } else {
    newUsuariosSituacoesId = usuariosSituacoesId;
  }

  let newUsuario = [{
    'id': usuariosId,
    // 'pessoasId': pessoaId,
    'pessoasId': pessoaId,
    'emailPessoal': pessoaEmailPessoal ? pessoaEmailPessoal.toLocaleUpperCase() : null,
    'emailInstitucional': pessoaEmailInstitucional ? pessoaEmailInstitucional.toLocaleUpperCase() : null,
    'usuariosSituacoesId': newUsuariosSituacoesId
  }]

  if (pessoaCargoId && (pessoaCargoId.id === "5eda5aba-11ea-11ec-833c-dc41a970c8d9" || pessoaCargoId.id === "51936fb5-11e9-11ec-833c-dc41a970c8d9")) {
    newUsuario[0].emailInstitucional = '';
  }

  // if (pessoaId && pessoaId.length === 36 && usuariosId && usuariosId.length === 36) {
  //   newUsuario[0].id = usuariosId;
  //   newUsuario[0].pessoasId = pessoaId;
  // }

let newPeriodosMandatos = {
  'dataInicio': formatDateEnUs2(parseISO(pessoaPeriodoDeMandatosDataInicio)),
  'dataFinal': formatDateEnUs2(parseISO(pessoaPeriodoDeMandatosDataFinal)),
  'tiposDeMandatosId': tiposDeMandatosId && tiposDeMandatosId.id ? tiposDeMandatosId.id : null
}
if (periodoMandatoId && periodoMandatoId.length === 36) {
  newPeriodosMandatos.id = periodoMandatoId;
}

let  newCaixasEscolaresHasCargosFuncoes = [{
  'id': id,
  'caixasEscolaresId': caixasEscolaresId,
  'cargosFuncoesId': pessoaCargoFuncaoId && pessoaCargoFuncaoId.id ? pessoaCargoFuncaoId.id : null,
  'periodosMandatosId': periodoMandatoId ? periodoMandatoId.id: null,
  'periodosMandatos': newPeriodosMandatos
}]

const valorInicial = {
  "id":null,"value":null,"label":null,"abreviacao":null
}

  const saveUpdateData = async () => {
    let payload = {
      'pessoasId': pessoaId,
      'nome': nome.toLocaleUpperCase(),
      'dataNascimento': formatDateEnUs2(parseISO(pessoaDataNascimento)),// (new Date(pessoaDataNascimento).getFullYear()) + "-" + (new Date(pessoaDataNascimento).getMonth() + 1) + "-" + (new Date(pessoaDataNascimento).getDate()),
      'filiacao_1': pessoaFiliacao1 ? pessoaFiliacao1.toLocaleUpperCase() : '',
      'filiacao_2': pessoaFiliacao2 ? pessoaFiliacao2.toLocaleUpperCase() : '',
      'cep': pessoaCep.replace(/\D/g, ''),
      'logradouro': pessoaLogradouro.toLocaleUpperCase(),
      'numero': pessoaNumero.toLocaleUpperCase(),
      'complementos': pessoaComplementos ? pessoaComplementos.toLocaleUpperCase() : '',
      'bairro': pessoaBairro.toLocaleUpperCase(),
      'emailPessoal': pessoaEmailPessoal.toLocaleUpperCase(),

      'emailInstitucional': pessoaEmailInstitucional && pessoaEmailInstitucional.length > 0 ? pessoaEmailInstitucional.toLocaleUpperCase() : '',

      'documentoIdentidade': pessoaDocumentoIdentidade.toLocaleUpperCase(),

      'dataExpedicaoIdentidade': formatDateEnUs2(parseISO(pessoaIdentidadeDataExpedicao)), //(new Date(pessoaIdentidadeDataExpedicao).getFullYear()) + "-" + (new Date(pessoaIdentidadeDataExpedicao).getMonth() + 1) + "-" + (new Date(pessoaIdentidadeDataExpedicao).getDate()),
      'orgaosExpedidoresId': pessoaIdentidadeOrgaoExpedidorId.id,
      'estadosIdExpedicaoIdentidade': pessoaEstadosIdExpedicaoIdentidade.id,
      'cpf': pessoaCpf.replace(/\D/g, ''),
      'servidorMatricula': pessoaServidorMatricula.replace(/\D/g, ''),
      'situacoesFuncionaisId': pessoaSituacaoFuncionalId && pessoaSituacaoFuncionalId.id ? pessoaSituacaoFuncionalId.id : null,
      'sexosId': pessoaSexoId.id,
      'cargosId': pessoaCargoId.id,
      'nacionalidadesId': pessoaNacionalidadeId.id,
      'estadosCivisId': pessoaEstadoCivilId.id,

      'cidadesIdNatal': pessoaCidadeNatalId.id,
      // 'estadoNatalId': pessoaEstadoNatalId.id,

      'regimesCasamentosId': pessoaRegimeCasamentoId ? pessoaRegimeCasamentoId.id : null,
      'conjugeNome': pessoaConjugeNome ? pessoaConjugeNome.toLocaleUpperCase() : '',

      'cidadesId': pessoaCidadeId.id,
      // 'usuarios': newUsuario,
      'caixasEscolaresHasCargosFuncoes': newCaixasEscolaresHasCargosFuncoes
      // 'periodosMandatos': newPeriodoMandato
    }

    // if (pessoaId && pessoaId.length === 36) {
    //   payload.id = pessoaId;
    // }

    if (pessoaCargoId && (pessoaCargoId.id === "5eda5aba-11ea-11ec-833c-dc41a970c8d9" || pessoaCargoId.id === "51936fb5-11e9-11ec-833c-dc41a970c8d9")) {
      payload.emailInstitucional = '';
    }

    setBackdrop(true);
    let values = query.id && !location.pathname.includes('new') && pessoaId ? api.put(`pessoas/${pessoaId}`, payload) : api.post(`pessoas`, payload)
    values.then((response) => {
      // setSubmitting(false);
      setMessage('Dados gravados com sucesso!');
      setSeverity('success');
      setBackdrop(false);
      handleClickAlert();
      setInitialValues({...initialValues, 'pessoaId': response.id});
      // console.log("submit: ", data);
    })
    .catch((err) => {
      setMessage('Erro ao gravar os dados! Verifique!');
      setSeverity('error');
      setBackdrop(false);
      handleClickAlert();
      // setSubmitting(false);
      console.error("ops! ocorreu um erro" + err);
    })

    // let values = query.id && !location.pathname.includes('new') ? api.put(`pessoas/${initialValues.pessoaId}`, payload) : api.post(`pessoas`, payload)
    // values.then((response) => {
    //   // setSubmitting(false);
    //   // setBackdrop(false);
    //   // handleClickAlert();
    //   setInitialValues({...initialValues, 'pessoaId': response.id});
    //   // console.log("submit: ", data);
    // })
    // .catch((err) => {
    //   setMessage('Erro ao gravar os dados! Verifique!');
    //   setBackdrop(false);
    //   setSeverity('error');
    //   // handleClickAlert();
    //   // setSubmitting(false);
    //   console.error("ops! ocorreu um erro" + err);
    // })

    // if (query.id && !location.pathname.includes('new')) {
    //   await deleteConjuge();
    // }

    // if (pessoaEstadoCivilId.id === 'add9d146-282a-11ec-a127-00155db95d0c') {
    //   await insertConjuge();
    // }

    // await periodosMandatos();

    // await caixasEscolaresHasCargosFuncoes();
  }

  const testCPF = (cpf) => {
    return (cpf && cpf.replace(/\D/g, '').length === 11 && validarCPF(cpf));
  }

  const testDataNascimento = (date) => {
    const newDateYear = new Date().getFullYear();
    const dateYear = date.getFullYear();
    return !(
      (Number(newDateYear) <= Number(dateYear)) ||
      (Number(newDateYear) - Number(dateYear) < 11) ||
      (Number(newDateYear) - Number(dateYear) > 100)
    );
  }

  const testPessoaIdentidadeDataExpedicao = (date) => {
    const newDateYear = new Date().getFullYear();
    const dateYear = date.getFullYear();
    return !((Number(dateYear) > Number(newDateYear)) || ((Number(newDateYear) - Number(dateYear)) > 100));
  }

  // const handleChangeSituacao = () => {
  //   // checkChecked();
  //   handleUsuarioSituacao(!checked);
  // }

  const selectedCheck = (usuariosSituacoesId) => {
    if (usuariosSituacoesId === '50a9e0f2-3c43-11ec-aa43-00155de398f0') {
      setAtivoLabel('ATIVO(A)');
    } else if (usuariosSituacoesId === 'a0e26afe-3c43-11ec-aa43-00155de398f0') {
      setAtivoLabel('INATIVO(A)');
    }
  }

  // useEffect(() => {
  //   if (usuariosSituacoesId === '50a9e0f2-3c43-11ec-aa43-00155de398f0') {
  //     setAtivoLabel('ATIVO(A)');
  //   } else if (usuariosSituacoesId === 'a0e26afe-3c43-11ec-aa43-00155de398f0') {
  //     setAtivoLabel('INATIVO(A)');
  //   }
  // }, [checked]);

  const handleChangeSituacao = () => {
    let newUsuariosSituacoesId = '';

    if (usuariosSituacoesId === 'a0e26afe-3c43-11ec-aa43-00155de398f0') {
      newUsuariosSituacoesId = '50a9e0f2-3c43-11ec-aa43-00155de398f0';
    } else {
      newUsuariosSituacoesId = 'a0e26afe-3c43-11ec-aa43-00155de398f0';
    }
    if (usuariosId) {
      setBackdrop(true);
      const payload = {
        'usuariosSituacoesId': newUsuariosSituacoesId
      }
      const values = api.put(`usuarios/situacao-cadastral/${usuariosId}`, payload)
      .then((response) => {
        // setChecked(!checked);
        setUsuariosSituacoesId(response.data.usuariosSituacoesId);
        setMessage('Dados gravados com sucesso!');
        setSeverity('success');
        // checkChecked();
        selectedCheck(response.data.usuariosSituacoesId);
        setBackdrop(false);
        handleClickAlert();
      })
      .catch((err) => {
        setMessage('Erro ao gravar os dados! Verifique!');
        setSeverity('error');
        setBackdrop(false);
        handleClickAlert();
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }

  // const checkChecked = () => {
  //   if (usuariosSituacoesId === '50a9e0f2-3c43-11ec-aa43-00155de398f0') {
  //     setAtivoLabel('ATIVO(A)');
  //     setChecked(true);
  //   } else {
  //     setUsuariosSituacoesId('a0e26afe-3c43-11ec-aa43-00155de398f0');
  //     setAtivoLabel('INATIVO(A)');
  //     setChecked(false);
  //   }

  //       // setChecked(false);
  //       // if (response.data.usuariosSituacoesId === '50a9e0f2-3c43-11ec-aa43-00155de398f0') {
  //       //   setChecked(true);
  //       // }
  // }

  // const testDataMandato = (dataInicial, dataFinal) => {
  //   // console.log('dataInicial: ', dataInicial.toString());
  //   // console.log('dataFinal: ', dataFinal.toString());
  //   if (dataInicial && dataFinal) {
  //     // console.log(new Date(dataFinal).toString() > new Date(dataInicial).toString());
  //     return (new Date(dataFinal).toString() > new Date(dataInicial).toString());
  //   }
  //   return false;
  // }

  const checkCpf = (cpf) => {
    const newCpf = cpf.replace(/\D/g, '');
    setErrors(removeItemArray ('pessoaCpf', errors));
    if (newCpf.length === 11) {
      if (validarCPF(cpf)) {
        // setBackdrop(true);
        const values = api.get(`pessoas/check-cpf-exists/${pessoaId}/${newCpf}`)
        .then((response) => {
          if (response.data.cpf === 'exists') {
            setMessage('Este número de CPF já esta cadastrado na base da dados! Verifique!');
            setErrors(['pessoaCpf']);
            setSeverity('error');
            // setBackdrop(false);
            handleClickAlert();
          }
        })
        .catch((err) => {
          // setBackdrop(false);
          console.error("ops! ocorreu um erro" + err);
        })
      } else {
        setErrors(['pessoaCpf']);
      }
    }
  }

  const checkErrors = () => {
    const dado = [];
    if (!nome || nome.length < 10) {
      dado.push('nome');
    } 
    if (!pessoaCpf || !validarCPF(pessoaCpf)) {
      dado.push('pessoaCpf');
    } 
    if (!pessoaDocumentoIdentidade || pessoaDocumentoIdentidade.length <= 3) {
      dado.push('pessoaDocumentoIdentidade');
    } 
    if (
      !pessoaIdentidadeDataExpedicao ||
      !isDate(pessoaIdentidadeDataExpedicao) ||
      new Date(pessoaIdentidadeDataExpedicao).toLocaleDateString("pt-BR").toString().length !== 10 ||
      !testPessoaIdentidadeDataExpedicao(new Date(pessoaIdentidadeDataExpedicao))
    ) {
      dado.push('pessoaIdentidadeDataExpedicao');
    }
    if (!pessoaIdentidadeOrgaoExpedidorId) {
      dado.push('pessoaIdentidadeOrgaoExpedidorId');
    }     
    if (!pessoaSexoId) {
      dado.push('pessoaSexoId');
    }
    if (
      !pessoaDataNascimento ||
      !isDate(pessoaDataNascimento) ||
      new Date(pessoaDataNascimento).toLocaleDateString("pt-BR").toString().length !== 10 ||
      !testDataNascimento(new Date(pessoaDataNascimento))
    ) {
      dado.push('pessoaDataNascimento');
    }
    if (!pessoaNacionalidadeId) {
      dado.push('pessoaNacionalidadeId');
    } 
    if (!pessoaEstadoCivilId) {
      dado.push('pessoaEstadoCivilId');
    }

    // if (pessoaEstadoCivilId && pessoaEstadoCivilId.id === "a47bc167-282a-11ec-a127-00155db95d0c") {
    //   if (!pessoaRegimeCasamentoId || !pessoaRegimeCasamentoId.id) {
    //     dado.push('pessoaRegimeCasamentoId');
    //   }
    // }

    if (pessoaEstadoCivilId && pessoaEstadoCivilId.id === 'add9d146-282a-11ec-a127-00155db95d0c') {
      if (!pessoaRegimeCasamentoId) {
        dado.push('pessoaRegimeCasamentoId');
      }
      if (!pessoaConjugeNome || pessoaConjugeNome.length < 10) {
        dado.push('pessoaConjugeNome');
      }
    }

    if (!pessoaFiliacao1 || pessoaFiliacao1.length < 10) {
      dado.push('pessoaFiliacao1');
    }
    if (!pessoaCargoId) {
      dado.push('pessoaCargoId');
    }
    if (!pessoaCargoFuncaoId) {
      dado.push('pessoaCargoFuncaoId');
    }     
    if (
      !pessoaPeriodoDeMandatosDataInicio||
      !isDate(pessoaPeriodoDeMandatosDataInicio) ||
      new Date(pessoaPeriodoDeMandatosDataInicio).toLocaleDateString("pt-BR").toString().length !== 10
      // !testDataMandato(pessoaPeriodoDeMandatosDataInicio, pessoaPeriodoDeMandatosDataFinal)
    ) {
      dado.push('pessoaPeriodoDeMandatosDataInicio');
    }
    if (
      !pessoaPeriodoDeMandatosDataFinal ||
      !isDate(pessoaPeriodoDeMandatosDataFinal) ||
      new Date(pessoaPeriodoDeMandatosDataFinal).toLocaleDateString("pt-BR").toString().length !== 10
      // !testDataMandato(pessoaPeriodoDeMandatosDataInicio, pessoaPeriodoDeMandatosDataFinal)
    ) {
      dado.push('pessoaPeriodoDeMandatosDataFinal');
    }
    if (new Date(pessoaPeriodoDeMandatosDataInicio) >= new Date(pessoaPeriodoDeMandatosDataFinal)) {
      dado.push('pessoaPeriodoDeMandatosDataFinal');
    }
    if (!pessoaCep || pessoaCep.replace(/\D/g, '').length !== 8) {
      dado.push('pessoaCep');
    }
    if (!pessoaLogradouro || pessoaLogradouro.length <= 4) {
      dado.push('pessoaLogradouro');
    }
    if (!pessoaNumero) {
      dado.push('pessoaNumero');
    }
    if (!pessoaBairro || pessoaBairro.length <= 4) {
      dado.push('pessoaBairro');
    }
    const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!pessoaEmailPessoal || !reg.test(pessoaEmailPessoal)) {
      dado.push('pessoaEmailPessoal');
    }
    if (!pessoaCidadeId) {
      dado.push('pessoaCidadeId');
    }
    // alert(pessoaCargoId.id);
    if (pessoaCargoId && pessoaCargoId.id !== "5eda5aba-11ea-11ec-833c-dc41a970c8d9" && pessoaCargoId.id !== "51936fb5-11e9-11ec-833c-dc41a970c8d9") {
      // alert('aqui');
      if ((pessoaServidorMatricula.replace(/[^\d]+/g,'')).length < 8) {
      // if (((pessoaServidorMatricula.replace(/[^\d]+/g,'')).length > 0) && ((pessoaServidorMatricula.replace(/[^\d]+/g,'')).length < 8)) {
        dado.push('pessoaServidorMatricula');
      }
      if (!pessoaEmailPessoal || pessoaEmailPessoal.length <= 7) {
        dado.push('pessoaEmailPessoal');
      }
      if (!pessoaEmailInstitucional || !reg.test(pessoaEmailInstitucional) || !(pessoaEmailInstitucional.toUpperCase().includes('CONTAGEM.MG.GOV.BR'))) {
        alert(pessoaEmailInstitucional.includes('CONTAGEM.MG.GOV.BR'));
        dado.push('pessoaEmailInstitucional');
      }
    } else {
      setPessoaServidorMatricula('');
      setPessoaEmailInstitucional(null);
    }

    // if (!tiposDeMandatosId) {
    //   dado.push('tiposDeMandatosId');
    // }

    setErrors(dado);

    if (dado.length === 0) {
      saveUpdateData();
    } else {
      console.log('dado: ', dado);
      // alert('dado');
      setSeverity('error');
      setMessage('Alguns dados estão ausentes ou incorretos!');
      handleClickAlert();
    }
  }

// const filterCidadeNatal = () => {
//   if (pessoaEstadoNatalId && pessoaEstadoNatalId.id) {
//     setCidadesNatalFilteredList(cidadesList.filter(item => item.estadosId === pessoaEstadoNatalId.id));
//   }
// }

  return (
    // initialValues ? 
    <Container maxWidth="xl">

    <SpinnerCarregando open={backdrop} />

    <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>

    {/* <pre>{JSON.stringify(cidadesList, null, 2)}</pre> */}

      {/* Location Path: <b>{location.pathname}</b>
      <p>Location Object: {JSON.stringify(location)}</p> */}
      <Typography component="div" style={{ height: '100vh - 48px' }}>
        <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
          <SpinnerCarregando open={backdrop} />
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
          <Grid container spacing={2} sx={{ mt: 3 }}>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Autocomplete
              id="controlled-caixa-escolar"
              value={pessoaCargoFuncaoId || null}
              options={funcoesList}
              // disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
              onChange={(event, newValue) => {
                // @ts-ignore
                // initialValues.pessoaCargoFuncaoId = null;
                // console.log('initialValues: ', initialValues);
                // @ts-ignore
                setPessoaCargoFuncaoId(newValue);
                // errors.pessoaCargoFuncaoId = true;
                // if (newValue) {
                //   // @ts-ignore
                //   // console.log('newValue: ', newValue.id)
                //   setPessoaCargoFuncaoId(newValue);
                //   // @ ts-ignore
                //   // initialValues.pessoaCargoFuncaoId = newValue.id;
                //   // errors.pessoaCargoFuncaoId = false;
                // }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Função"
                  variant="outlined"
                  size="small"
                  fullWidth
                  focused
                  required
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  InputProps={{
                    style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                  }}
                  error={errors.includes('pessoaCargoFuncaoId')}
                  helperText={errors.includes('pessoaCargoFuncaoId') ? 'Campo obrigatório!' : ''}
                />
              )}
            />
          </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                <DatePicker
                  label="Data Início do Mandato"
                  value={parseISO(pessoaPeriodoDeMandatosDataInicio)}
                  // disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  readOnly
                  onChange={(newValue) => {
                    // @ts-ignore
                    setPessoaPeriodoDeMandatosDataInicio(formatDateEnUs2(newValue));
                    // errors.pessoaPeriodoDeMandatosDataInicio = true;
                    // if (newValue) {
                    //   setPessoaPeriodoDeMandatosDataInicio(newValue);
                    //   // @ts-ignore
                    //   // let formatDate = new Date(newValue);
                    //   // @ts-ignore
                    //   // formatDate = formatDate.setDate(formatDate.getDate() + 1);
                    //   // formatDate = new Date(formatDate);
                    //   const newFormatDate = formatDateEnUs2(newValue); //(formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                    //   // @ts-ignore
                    //   // initialValues.pessoaPeriodoDeMandatosDataInicio = newFormatDate;
                    //   // console.log('initialValues: ' , initialValues);
                    //   // errors.pessoaPeriodoDeMandatosDataInicio = false;
                    // }
                  }}
                  renderInput={(params) =>
                    <TextField
                    {...params}
                    size="small"
                    fullWidth
                    focused
                    required
                    InputProps={{
                      style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                    }}
                    error={errors.includes('pessoaPeriodoDeMandatosDataInicio')}
                    helperText={errors.includes('pessoaPeriodoDeMandatosDataInicio') ? 'Campo obrigatório!' : ''}
                  />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                <DatePicker
                  label="Data Final do Mandato"
                  value={parseISO(pessoaPeriodoDeMandatosDataFinal)}
                  // disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  readOnly
                  onChange={(newValue) => {
                    // @ts-ignore
                    setPessoaPeriodoDeMandatosDataFinal(formatDateEnUs2(newValue));
                    // errors.pessoaPeriodoDeMandatosDataFinal = true;
                    if (newValue) {
                      // @ts-ignore
                      // let formatDate = new Date(newValue);
                      // @ts-ignore
                      // formatDate = formatDate.setDate(formatDate.getDate() + 1);
                      // formatDate = new Date(formatDate);
                      const newFormatDate = formatDateEnUs2(newValue); //(formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                      // @ts-ignore
                      // initialValues.pessoaPeriodoDeMandatosDataFinal = newFormatDate;
                      // console.log('initialValues: ' , initialValues);
                      // errors.pessoaPeriodoDeMandatosDataFinal = false;
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                    {...params}
                    size="small"
                    fullWidth
                    focused
                    required
                    InputProps={{
                      style: { backgroundColor: "#e3f2fd" }, readOnly: true 
                    }}
                    error={errors.includes('pessoaPeriodoDeMandatosDataFinal')}
                    helperText={errors.includes('pessoaPeriodoDeMandatosDataFinal') ? 'Campo obrigatório!' : ''}
                  />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <InputMask
                mask="999.999.999-99"
                value={pessoaCpf}
                onChange={e => {
                  setPessoaCpf(e.target.value)
                  setInitialValues({...initialValues, 'pessoaCpf': e.target.value})
                  checkCpf(e.target.value)
                  }
                }
                onBlur={pessoaCpf}
              >
                {(inputProps: Props): ReactElement => (
                  <TextField
                    {...inputProps}
                    type="tel"
                    required
                    focused
                    fullWidth
                    size="small"
                    label={("CPF")}
                    error={errors.includes('pessoaCpf')}
                    helperText={errors.includes('pessoaCpf') ? 'Campo incorreto!' : ''}
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
              <TextField
                size="small"
                value={nome}
                disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                required
                type="tel"
                focused
                fullWidth
                label={("Nome")}
                inputProps={{ style: { textTransform: "uppercase" }}}
                onChange={e => {
                    setPessoaNome(e.target.value)
                    // setInitialValues({...initialValues, 'nome': e.target.value})
                  }
                }
                error={errors.includes('nome')}
                helperText={errors.includes('nome') ? 'Campo obrigatório!' : ''}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <TextField
                size="small"
                value={pessoaDocumentoIdentidade}
                disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                required
                type="tel"
                focused
                fullWidth
                label={("Carteira de Identidade")}
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={e => {
                  setPessoaDocumentoIdentidade(e.target.value)
                    setInitialValues({...initialValues, 'pessoaDocumentoIdentidade': e.target.value})
                    setPessoaDocumentoIdentidade(e.target.value);
                  }
                }
                error={errors.includes('pessoaDocumentoIdentidade')}
                helperText={errors.includes('pessoaDocumentoIdentidade') ? 'Campo obrigatório!' : ''}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
                <DatePicker
                  label="Data de Expedição"
                  value={parseISO(pessoaIdentidadeDataExpedicao)}
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  onChange={(newValue) => {
                    // @ts-ignore
                    setPessoaIdentidadeDataExpedicao(formatDateEnUs2(newValue));
                    // errors.pessoaIdentidadeDataExpedicao = true;
                    // if (newValue) {
                    //   // @ts-ignore
                    //   // let formatDate = new Date(newValue);
                    //   // @ts-ignore
                    //   // formatDate = formatDate.setDate(formatDate.getDate() + 1);
                    //   // formatDate = new Date(formatDate);
                    //   const newFormatDate = formatDateEnUs2(newValue) //(formatDate.getFullYear()) + "-" + (formatDate.getMonth() + 1) + "-" + (formatDate.getDate());
                    //   // @ts-ignore
                    //   // initialValues.pessoaIdentidadeDataExpedicao = newFormatDate;
                    //   // console.log('initialValues: ' , initialValues);
                    //   // errors.pessoaIdentidadeDataExpedicao = false;
                    // }
                  }}
                  renderInput={(params) =>
                    <TextField
                    {...params}
                    size="small"
                    fullWidth
                    focused
                    required
                    error={errors.includes('pessoaIdentidadeDataExpedicao')}
                    helperText={errors.includes('pessoaIdentidadeDataExpedicao') ? 'Campo obrigatório!' : ''}
                  />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Autocomplete
                id="controlled-caixa-escolar"
                value={pessoaIdentidadeOrgaoExpedidorId || null}
                options={orgaosExpedidoresList}
                disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                onChange={(event, newValue) => {
                  // @ts-ignore
                  // initialValues.pessoaSexoId = null;
                  // @ts-ignore
                  setPessoaIdentidadeOrgaoExpedidorId(null);
                  // errors.pessoaSexoId = true;
                  if (newValue) {
                    // @ts-ignore
                    setPessoaIdentidadeOrgaoExpedidorId(newValue);
                    // @ ts-ignore
                    // initialValues.pessoaSexoId = newValue.id;
                    // errors.pessoaSexoId = false;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Órgão Expedidor"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                    error={errors.includes('pessoaIdentidadeOrgaoExpedidorId')}
                    helperText={errors.includes('pessoaIdentidadeOrgaoExpedidorId') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Autocomplete
                id="controlled-caixa-escolar"
                value={pessoaEstadosIdExpedicaoIdentidade || null}
                options={estadosList}
                disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                onChange={(event, newValue) => {
                  // @ts-ignore
                  // initialValues.pessoaSexoId = null;
                  // @ts-ignore
                  setPessoaEstadosIdExpedicaoIdentidade(null);
                  // errors.pessoaSexoId = true;
                  if (newValue) {
                    // @ts-ignore
                    setPessoaEstadosIdExpedicaoIdentidade(newValue);
                    // @ ts-ignore
                    // initialValues.pessoaSexoId = newValue.id;
                    // errors.pessoaSexoId = false;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Estado Expedidor"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                    error={errors.includes('pessoaEstadosIdExpedicaoIdentidade')}
                    helperText={errors.includes('pessoaEstadosIdExpedicaoIdentidade') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Stack direction="row" spacing={1}>
            <InputMask
              mask="99999-999"
              value={pessoaCep}
              disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
              onChange={e => {
                  setPessoaCep (e.target.value)
                  // getCep(pessoaCep);
                }
              }
              onBlur={pessoaCep}
            >
              {(inputProps: Props): ReactElement => (
                <TextField
                  {...inputProps}
                  type="tel"
                  focused
                  fullWidth
                  size="small"
                  label={("CEP")}
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  error={errors.includes('pessoaCep')}
                  helperText={errors.includes('pessoaCep') ? 'Campo obrigatório!' : ''}
                />
              )}
            </InputMask>
              <div style={{ paddingTop: 8, cursor: 'pointer' }}>
                <SearchIcon onClick= {getCep} disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')} />
              </div>
            </Stack>
          </Grid>

            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              {/* <MyTextField name="pessoaLogradouro" placeholder="Rua/Av." required /> */}
              <TextField
                  size="small"
                  value={pessoaLogradouro}
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  required
                  type="tel"
                  focused
                  fullWidth
                  label={("Rua/Av.")}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={e => {
                      setPessoaLogradouro(e.target.value)
                      // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
                    }
                  }
                  error={errors.includes('pessoaLogradouro')}
                  helperText={errors.includes('pessoaLogradouro') ? 'Campo obrigatório!' : ''}
                />
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              {/* <MyTextField name="pessoaNumero" placeholder="Número" required /> */}
              <TextField
                  size="small"
                  value={pessoaNumero}
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  required
                  type="tel"
                  focused
                  fullWidth
                  label={("Número")}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={e => {
                      setPessoaNumero(e.target.value)
                      // setInitialValues({...initialValues, 'pessoaNumero': e.target.value})
                    }
                  }
                  error={errors.includes('pessoaNumero')}
                  helperText={errors.includes('pessoaNumero') ? 'Campo obrigatório!' : ''}
                />
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              {/* <MyTextField name="pessoaComplementos" placeholder="Complemento" /> */}
              <TextField
                  size="small"
                  value={pessoaComplementos}
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  type="tel"
                  focused
                  fullWidth
                  label={("Complemento")}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={e => {
                      setPessoaComplementos(e.target.value)
                      // setInitialValues({...initialValues, 'pessoaComplementos': e.target.value})
                    }
                  }
                  // error={errors.includes('pessoaComplementos')}
                  // helperText={errors.includes('pessoaComplementos') ? 'Campo obrigatório!' : ''}
                />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              {/* <MyTextField name="pessoaBairro" placeholder="Bairro" required /> */}
              <TextField
                  size="small"
                  value={pessoaBairro}
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  required
                  type="tel"
                  focused
                  fullWidth
                  label={("Bairro")}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={e => {
                      setPessoaBairro(e.target.value)
                      // setInitialValues({...initialValues, 'pessoaBairro': e.target.value})
                    }
                  }
                  error={errors.includes('pessoaBairro')}
                  helperText={errors.includes('pessoaBairro') ? 'Campo obrigatório!' : ''}
                />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                id="controlled-caixa-escolar"
                value={pessoaCidadeId || null}
                options={cidadesResidenciaList}
                disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                onChange={(event, newValue) => {
                  // @ts-ignore
                  initialValues.pessoaCidadeId = null;
                  // @ts-ignore
                  setPessoaCidadeId(null);
                  // errors.pessoaCidadeId = true;
                  if (newValue) {
                    // @ts-ignore
                    setPessoaCidadeId(newValue)
                    // @ ts-ignore
                    initialValues.pessoaCidadeId = newValue.id;
                    // errors.pessoaCidadeId = false;
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    variant="outlined"
                    size="small"
                    fullWidth
                    focused
                    required
                    disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                    error={errors.includes('pessoaCidadeId')}
                    helperText={errors.includes('pessoaCidadeId') ? 'Campo obrigatório!' : ''}
                  />
                )}
              />
            </Grid>

            {/*<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                  size="small"
                  value={pessoaEstadoDescricao}
                  disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                  required
                  type="tel"
                  focused
                  fullWidth
                  label={("Estado")}
                  inputProps={{ style: { textTransform: "uppercase" }, readOnly:true }}
                  onChange={e => {
                      setPessoaEstadoDescricao(e.target.value)
                      // setInitialValues({...initialValues, 'pessoaEstadoDescricao': e.target.value})
                    }
                  }
                  error={errors.includes('pessoaEstadoDescricao')}
                  helperText={errors.includes('pessoaEstadoDescricao') ? 'Campo obrigatório!' : ''}
                />
                </Grid> */}

            {showPessoaEmailInstitucional ?
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                {/* <MyTextFieldReadOnly  placeholder="E-mail" name="pessoaEmail"/> */}
                <TextField
                    size="small"
                    value={pessoaEmailInstitucional}
                    disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                    required
                    type="tel"
                    focused
                    fullWidth
                    label={("E-mail Institucional")}
                    inputProps={{ style: { textTransform: "uppercase" }, readOnly: false}}
                    onChange={e => {
                        setPessoaEmailInstitucional(e.target.value)
                        // setInitialValues({...initialValues, 'pessoaEmail': e.target.value})
                      }
                    }
                    error={errors.includes('pessoaEmailInstitucional')}
                    helperText={errors.includes('pessoaEmailInstitucional') ? 'Campo obrigatório!' : ''}
                  />
              </Grid>
              : <></>
            }

            {showPessoaEmailInstitucional ?
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                {/* <MyTextFieldReadOnly  placeholder="E-mail" name="pessoaEmail"/> */}
                <TextField
                    size="small"
                    value={pessoaEmailPessoal}
                    disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                    required
                    type="tel"
                    focused
                    fullWidth
                    label={("E-mail Pessoal")}
                    onChange={e => {
                        setPessoaEmailPessoal(e.target.value)
                        // setInitialValues({...initialValues, 'pessoaEmail': e.target.value})
                      }
                    }
                    inputProps={{ style: { textTransform: "uppercase" }, readOnly: false }}
                    error={errors.includes('pessoaEmailPessoal')}
                    helperText={errors.includes('pessoaEmailPessoal') ? 'Campo obrigatório!' : ''}
                    />
              </Grid>
              : <></>
            }

            { !showPessoaEmailInstitucional ?
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <MyTextFieldReadOnly  placeholder="E-mail" name="pessoaEmail"/> */}
                <TextField
                    size="small"
                    value={pessoaEmailPessoal}
                    disabled={!testCPF(pessoaCpf) || errors.includes('pessoaCpf')}
                    required
                    type="tel"
                    focused
                    fullWidth
                    label={("E-mail Pessoal")}
                    onChange={e => {
                        setPessoaEmailPessoal(e.target.value)
                        // setInitialValues({...initialValues, 'pessoaEmail': e.target.value})
                      }
                    }
                    error={errors.includes('pessoaEmailPessoal')}
                    helperText={errors.includes('pessoaEmailPessoal') ? 'Campo obrigatório!' : ''}
                    />
              </Grid>
              : <></>
            }
          </Grid>

          {/*<TelefonesTable telefones={initialValues && initialValues.pessoaTelefones ? initialValues.pessoaTelefones : []} />*/}

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            { pessoaId ?
              <TelefonesEditPostTable
                id={pessoaId}
                // numerosTelefones={numerosTelefones}
                path="telefones-pessoas"
                path2="telefones-pessoas/por-pessoa"
                idName="pessoasId"
              />
              : <></>
            }
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AssinaturaPessoa />
          </Grid> */}

        </Paper>
      </Typography>
    </Container>
    // :
    // <Typography component="div" style={{ height: '100vh - 48px' }}><span>Carregando 3...</span></Typography>
  );
}
