import React, { useState } from "react";
import {
  Divider,
  Switch,
  Card,
  CardHeader,
  CardContent,
  IconButton
} from "@mui/material";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { makeStyles } from '@mui/styles';



import CustomCharts from "./CustomCharts";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
  paperCustom: {
    padding: "2rem",
    fontFamily: "roboto",
    margin: "2rem",
    minHeight: window.innerHeight,
    borderRadius: 0
  },
  typographyCustom: {
    marginBottom: "1rem"
  },
  dividerCustom: {
    marginTop: "2rem"
  },
  /* Custom */
  iconButtonMenu: {
    // backgroundColor: theme.palette.grey[300]
  }
}));

export default function App() {
  const classes = useStyles();
  const [darkMode, setDarkMode] = useState(false);

  return (
    <ThemeProvider>
 
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justify="center"
            spacing={1}
            style={{ display: "center" }}
          >
            {/* <Grid item xs={false} /> */}
            <Grid item xs={6} lg={6}>
              {/* Here should all the components come */}

              <Card elevation={10}>
                <CardHeader
                  title={
                    <Typography variant="h5">
                      Largest cities of Massachusetts
                    </Typography>
                  }
                  action={
                    <IconButton className={classes.iconButtonMenu}>
                      <MoreVertIcon />
                    </IconButton>
                  }
                />
                <CardContent style={{ backgroundColor: "#fff" }}>
                  <CustomCharts />
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={false} /> */}
          </Grid>
        </Grid>

    </ThemeProvider>
  );
}
