import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { FormControl } from '@mui/material';

import api from '../../services/api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags() {
  const [listValues, setListValues] = React.useState([{
    id: '',
    descricao: ''
  }]);

  React.useEffect(() => {
   const values = api.get("status-caixas-escolares")
    values.then((response) => {
      setListValues(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);
  
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Autocomplete
        multiple
        limitTags={1}
        size={"small"} 
        id="checkboxes-tags-demo"
        options={listValues}
        disableCloseOnSelect
        getOptionLabel={(option) => option.descricao}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.descricao}
          </li>
        )}
        style={{ width: 200 }}
        renderInput={(params) => (
          <TextField {...params} label="Situação" placeholder="" variant="standard" />
        )}
      />
    </FormControl>
  );
}
