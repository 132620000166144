import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Autocomplete, Box, Button, Container, Grid, Paper, TextField, Typography, FormControl, InputLabel, Select } from "@mui/material";
import { Formik, Form, } from "formik";
import FormikField from '../../../components/Controls/FormikField';
import { validarCNPJ, validaEmail, formatCEP, formatCNPJ } from "../../../utils/functions";

import SpinnerCarregando from '../../../components/SpinnerCarregando';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';

import Backdrop from '@mui/material/Backdrop';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';

//@ts-ignore
import InputMask from 'react-input-mask';

import TelefonesEditPostTable from "../../../components/TelefonesEditPostTable";
import api from "../../../services/api";

const useStyles = makeStyles((theme) => ({
  box: {
    height: 60,
    display: "flex",
    border: "",
    padding: 0
  },
  topLeftBox: {
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  centerBox: {
    justifyContent: "center",
    alignItems: "center"
  },
  bottomRightBox: {
    justifyContent: "flex-end",
    alignItems: "center"
  },
  spreadBox: {
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default function App() {
  const query = useParams();
  const history = useHistory();
  
  const [initialValues, setInitialValues] = React.useState(true);
  const [initialTipos, setInitialTipos] = React.useState([]);
  const [initialRegionais, setInitialRegionais] = React.useState([]);
  const [initialBairros, setInitialBairros] = React.useState([]);
  const classes = useStyles();

  const [errors, setErrors] = React.useState([]);

  const [tipo, setTipo] = React.useState(null);
  const [id, setId] = React.useState('');
  const [caixasEscolaresId, setCaixasEscolaresId] = React.useState('');
  const [caixaEscolarNome, setCaixaEscolarNome] = React.useState('');
  const [cnpj, setCnpj] = React.useState('');
  const [termo, setTermo] = React.useState('');
  const [codigoCredor, setCodigoCredor] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [leiDeCriacao, setLeiDeCriacao] = React.useState('');
  const [cep, setCep] = React.useState('');
  const [logradouro, setLogradouro] = React.useState('');
  const [numero, setNumero] = React.useState('');
  const [complementos, setComplementos] = React.useState('');
  const [bairro, setBairro] = React.useState(null);
  const [bairroId, setBairroId] = React.useState('');
  const [regiao, setRegiao] = React.useState('');
  const [regiaoId, setRegiaoId] = React.useState('');
  const [cidade, setCidade] = React.useState('');
  const [estado, setEstado] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [numerosTelefones, setNumerosTelefones] = React.useState([]);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success');
  const [message, setMessage] = React.useState('Dados gravados com sucesso!');
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

const newRegister = () => {
  setTipo(null);
  setInitialValues({
    //@ts-ignore
    "id": '',
    "caixasEscolaresId": '',
    "nome": '',
    "leiDeCriacao": '',
    "nome_completo": '',
    "cep": '',
    "logradouro": '',
    "numero": '',
    "complementos": '',
    "bairro": '',
    "bairroId":'',
    "cidade": '',
    "estado": '',
    "regiao": '',
    "regiaoId": '',
    "email": '',
    "tipoId": '',
    "tipo_descricao": '',
    "tipo_descricao_abreviada": '',
    "tipo_abreviacao": '',
    "caixaEscolarNome": '',
    "cnpj": '',
    "termo": '',
    "codigoCredor": '',
    "presidente": '',
    "status_caixas_escolares": '',
    "numeros_telefones": [],
    "unidadesEscolaresHasTiposEnsinos": [],
    "unidadesEscolaresHasTiposEnsinosCodigos": []
  })

  setId('');
  setCaixasEscolaresId('');
  setCaixaEscolarNome('');
  setCnpj('');
  setTermo('');
  setCodigoCredor('');
  setNome('');
  setLeiDeCriacao('');
  setCep('');
  setLogradouro('');
  setNumero('');
  setComplementos('');
  //@ts-ignore
  setBairro('');
  setBairroId('');
  setRegiao('');
  setRegiaoId('');
  setCidade('CONTAGEM');
  setEstado('MINAS GERAIS');
  setEmail('');
  setNumerosTelefones([]);
}

const saveUpdateData = async () => {
  const payload = {
    'id': id,
    'nome': nome.toLocaleUpperCase(),
    //@ts-ignore
    'tiposId': tipo.value,
    'cep': cep.replace(/[^\d]+/g,''),
    'logradouro': logradouro,
    'numero': numero,
    'complementos': complementos,
    //@ts-ignore
    'bairrosId': bairroId.id,
    'email': email,
    'leiDeCriacao': leiDeCriacao ? leiDeCriacao.toLocaleUpperCase() : '',
    'cidadesId': 'd22deb05-11f4-11ec-833c-dc41a970c8d9',
    'caixasEscolaresId': caixasEscolaresId,
    "caixasEscolares": {
        "id": caixasEscolaresId,
        "nome": caixaEscolarNome ? caixaEscolarNome.toLocaleUpperCase() : '',
        "cnpj": cnpj.replace(/[^\d]+/g,''),
        "termo": termo,
        "codigoCredor": codigoCredor,
        "statusCaixasEscolaresId": 'f96f5a9e-17ad-11ec-8785-dc41a970c8d9'
    }
  }
  // console.log('payload: ', payload)
  // alert('x');

  // // @ts-ignore
  if (!id) {
    //@ts-ignore
    delete payload.id;
    //@ts-ignore
    delete payload.caixasEscolares.id;
  } 
  const values = id ? api.put(`unidades-escolares/${payload.id}`, payload) : api.post(`unidades-escolares/${id}`, payload)
  .then((response) => {
    setMessage('Dados gravados com sucesso!');
    setSeverity('success');
    setBackdrop(false);
    handleClickAlert();
    setId(response.data.id);
    history.push(`/caixas-escolares/edit/${response.data.id}`);
  })
  .catch((err) => {
    setMessage('Erro ao atualizar os dados! Verifique!');
    setBackdrop(false);
    setSeverity('error');
    handleClickAlert();
    console.error("ops! ocorreu um erro" + err);
  })
}

const getEndereco = () => {
  if (cep && (cep.replace(/[^\d]+/g,'')).length === 8) {
    //@ts-ignore
    setBairroId(null);
    //@ts-ignore
    setRegiaoId(null);
    setLogradouro('');
    fetch(`https:/viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        if (data && data.logradouro) {
          setLogradouro(data.logradouro.toUpperCase());
        }
        if (data && data.bairro) {
          //@ts-ignore
          const result = initialBairros.find(item => item.label === data.bairro.toUpperCase());
          // console.log('result: ', initialBairros);
          if (result) {
            setBairroId(result);
            //@ts-ignore
            const resultRegionais = initialRegionais.find(item => item.id === result.regionalId);
            //@ts-ignore
            setRegiao(resultRegionais.label);
            //@ts-ignore
            setRegiaoId(resultRegionais);
          }
        }
      })
  }
}

const checkErrors = () => {
  const dado = [];
  if (!caixaEscolarNome || caixaEscolarNome.length < 10) {
    dado.push('caixaEscolarNome');
  }
  if (!cnpj || !validarCNPJ(cnpj)) {
    dado.push('cnpj');
  }
  if (!termo) {
    dado.push('termo');
  }
  if (!codigoCredor) {
    dado.push('codigoCredor');
  }
  if (!nome || nome.length < 10) {
    dado.push('nome');
  }
  if (!cep || cep.length < 9 || (cep.substring(0,2)).toString() !== '32') {
    dado.push('cep');
  }
  if (!logradouro || logradouro.length < 4) {
    dado.push('logradouro');
  }
  if (!numero || numero.length < 1) {
    dado.push('numero');
  }
  if (!bairroId || bairroId.length < 36) {
    dado.push('bairro');
  }
  if (!regiaoId) {
    dado.push('regiao');
  }
  if (!email || email.length < 10 || !validaEmail(email)) {
    dado.push('email');
  }
  if (!tipo) {
    dado.push('tipo');
  }
  //@ts-ignore
  setErrors(dado);

  if (dado.length === 0) {
    saveUpdateData();
  }
}

const getData = () => {
  newRegister();
  // @ts-ignore
  const values = api.get(`unidades-escolares/${query.id}`)
  // @ts-ignore
  .then((response) => {
    setInitialValues(response.data)

    // console.log('response.dataxxxx: ', response.data);
    setId(response.data.id);
    setCaixasEscolaresId(response.data.caixasEscolaresId);
    setCaixaEscolarNome(response.data.caixaEscolarNome);
    setCnpj(response.data.cnpj);
    setTermo(response.data.termo);
    setCodigoCredor(response.data.codigoCredor);
    setNome(response.data.nome);
    setLeiDeCriacao(response.data.lei_de_criacao);
    setCep(response.data.cep);
    setLogradouro(response.data.logradouro);
    setNumero(response.data.numero);
    setComplementos(response.data.complementos);
    setBairro(response.data.bairro);
    // setBairroId(response.data.bairroId);
    setRegiao(response.data.regiao);
    setRegiaoId(response.data.regiaoId);
    setCidade(response.data.cidade);
    setEstado(response.data.estado);
    setEmail(response.data.email);
    setNumerosTelefones(response.data.numerosTelefones);
  })
  .catch((err) => {
    console.error("ops! ocorreu um erro" + err);
  })

}

  useEffect(() => {
    // getData();
  }, []);

  useEffect(() => {
   const values = api.get("tipos-unidades-escolares")
    values.then((response) => {
      setInitialTipos(response.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
    })
  }, []);

  useEffect(() => {
    const values = api.get("regionais")
     values.then((response) => {
       setInitialRegionais(response.data)
     })
     .catch((err) => {
       console.error("ops! ocorreu um erro" + err);
     })
   }, []);

   useEffect(() => {
    const values = api.get("bairros")
     values.then((response) => {
       setInitialBairros(response.data)
     })
     .catch((err) => {
       console.error("ops! ocorreu um erro" + err);
     })
   }, []);

  useEffect(() => {
    // @ts-ignore
      const result = initialTipos.find(item => item.value === initialValues.tipoId);
      if (result) {
        // console.log('result 1: ', result);
        setTipo(result);
      }
   }, [initialValues]);

  useEffect(() => {
      // @ts-ignore
      const result = initialBairros.find(item => item.value === initialValues.bairroId);
      if (result) {
        // console.log('result 2: ', result);
        setBairroId(result);
      }
   }, [initialValues]);

   useEffect(() => {
    // @ts-ignore
    const result = initialRegionais.find(item => item.value === initialValues.regiaoId);
    if (result) {
      // console.log('result 2: ', result);
      setRegiaoId(result);
      //@ts-ignore
      setRegiao(result.label);
    }
 }, [initialValues]);

   useEffect(() => {
     // @ts-ignore
    const result = initialTipos.find(item => item.value === initialValues.tipoId);
    if (result) {
      // console.log('result 2: ', result);
      setTipo(result);
    }
   }, [initialTipos]);

   useEffect(() => {
    // @ts-ignore
   const result = initialBairros.find(item => item.value === initialValues.bairroId);
   if (result) {
     // console.log('result 2: ', result);
     setBairroId(result);
     //@ts-ignore
     setRegiao(result.regionalNome);
   }
  }, [initialBairros]);

  useEffect(() => {
    // @ts-ignore
   const result = initialRegionais.find(item => item.value === initialValues.regiaoId);
   if (result) {
     // console.log('result 2: ', result);
     setRegiaoId(result);
   }
  }, [initialRegionais]);

  const goList = () => {
    history.push('/caixas-escolares-list');
  }

  const handleClickAlert = () => {
    setOpen(true);
  };

  //@ts-ignore
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   // @ts-ignore
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

   return (
    <Container maxWidth="xl">
    <Typography component="div" style={{ height: '100vh - 48px' }}>
    <Paper elevation={3}sx={{ px: 5, pb: 5, m: 4, display: 'flex', flexDirection: 'column' }}>
      <SpinnerCarregando open={backdrop} />
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}>
        <Alert
        // onClose={() => {handleClose}}
        //@ts-ignore
        severity={severity}
        sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Grid container spacing={2 } sx={{ mt: 3 }}>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ ml: 2 }}
        >
          <Box>
            <Typography component="div">
              <Box sx={{ fontWeight: 'light', fontSize: 20, color: '#A9A9A9' }}>Cadastro de Caixa Escolar</Box>
            </Typography> 
          </Box>
          <Box
            component="div"
            className={`${classes.bottomRightBox} ${classes.box}`}>
              <div>
                <Button onClick={goList} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20 }}>
                  Cancelar
                </Button>
              </div>

              {/* <div>
                <Button onClick={() => {newRegister(), history.push('new')}} variant="outlined" color="primary" size="small" style={{ height: 35, marginRight: 20 }}>
                  Novo
                </Button>
              </div> */}

              <div>
                {/* APAGAR <Button type="submit" onClick={checkErrors} variant="outlined" color="primary" size="small" style={{ height: 35 }} disabled={false}>
                  Gravar
                  </Button>
                */}
              </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {/* <FormikField name="caixaEscolarNome" label="Caixa Escolar" /> */}
          <TextField
            value={caixaEscolarNome}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Caixa Escolar")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={e => {
                setCaixaEscolarNome(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('caixaEscolarNome')}
            //@ts-ignore
            helperText={errors.includes('caixaEscolarNome') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>

              <TextField
                value={cnpj}
                type="tel"
                required
                focused
                fullWidth
                inputProps={{ readOnly: false }}
                size="small"
                label={("CNPJ")}
                onChange={e => {
                  //@ts-ignore
                  setCnpj(formatCNPJ(e.target.value))
                    // setInitialValues({...initialValues, 'cnpj': e.target.value})
                  }
                }
                //@ts-ignore
                error={errors.includes('cnpj')}
                //@ts-ignore
                helperText={errors.includes('cnpj') ? 'Campo incorreto!' : ''} />


          {/* <FormikField name="cnpj" label="CNPJ" /> */}

          {/*
            <InputMask
                mask="99.999.999/9999-99"
                value={cnpj}
                //@ts-ignore
                onChange={e => {
                    setCnpj(e.target.value)
                    // setInitialValues({...initialValues, 'cnpj': e.target.value})
                  }
                }
                onBlur={cnpj}
              >
                {//@ts-ignore
                (inputProps: Props):
                //@ts-ignore
                ReactElement => (
                  <TextField
                    {...inputProps}
                    type="tel"
                    required
                    focused
                    fullWidth
                    size="small"
                    label={("CNPJ")}
                    //@ts-ignore
                    error={errors.includes('cnpj')}
                    //@ts-ignore
                    helperText={errors.includes('cnpj') ? 'Campo incorreto!' : ''}
                  />
                )}
              </InputMask>
              */}
        </Grid>
        
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
          {/* <FormikField name="termo" label="Termo" required /> */}
          <TextField
            value={termo}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Termo")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={e => {
                setTermo(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('termo')}
            //@ts-ignore
            helperText={errors.includes('termo') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          {/* <FormikField name="codigoCredor" label="Código Credor" required /> */}
          <TextField
            value={codigoCredor}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Código Credor")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={e => {
                setCodigoCredor(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('codigoCredor')}
            //@ts-ignore
            helperText={errors.includes('codigoCredor') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Autocomplete
              // sx={{ mt: 1.25 }}
              id="controlled-demo"
              value={tipo}
              options={initialTipos}
              onChange={(event, newValue) => {
                // @ts-ignore
                setTipo(newValue);
                // console.log('newValue: ', newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo Unidade Escolar"
                  variant="outlined"
                  size="small"
                  fullWidth
                  focused
                  required
                  //@ts-ignore
                  error={errors.includes('tipo')}
                  //@ts-ignore
                  helperText={errors.includes('tipo') ? 'Campo obrigatório!' : ''}
                />
              )}
            />
        </Grid>

        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          {/* <FormikField name="nome" label="Nome" required /> */}
          <TextField
            value={nome}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Nome")}
            inputProps={{ style: { textTransform: "uppercase" }, autoComplete: "off", maxLength: 100 }}
            onChange={e => {
              setNome(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('nome')}
            //@ts-ignore
            helperText={errors.includes('nome') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          {/* <FormikField name="cep" label="CEP" required /> */}
          <Stack direction="row" spacing={1}>

          <TextField
            value={cep}
            type="tel"
            required
            focused
            fullWidth
            inputProps={{ readOnly: false }}
            size="small"
            label={("CEP")}
            onChange={e => {
              //@ts-ignore
              setCep(formatCEP(e.target.value))
                // setInitialValues({...initialValues, 'cnpj': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('cep')}
            //@ts-ignore
            helperText={errors.includes('cep') ? 'Campo incorreto!' : ''} />

            {/*
            <InputMask
            mask="99999-999"
            value={cep}
            //@ts-ignore
            onChange={e => {
                setCep (e.target.value)
              }
            }
            onBlur={cep}
          >
            {//@ts-ignore
            (inputProps: Props): ReactElement => {
              return (
                <TextField
                  {...inputProps}
                  type="tel"
                  focused
                  required
                  fullWidth
                  size="small"
                  label={("CEP")}
                  //@ts-ignore
                  error={errors.includes('cep')}
                  //@ts-ignore
                  helperText={errors.includes('cep') ? 'Campo incorreto!' : ''} />
              );
            }}
            </InputMask>
            */}

              <div style={{ paddingTop: 8, cursor: 'pointer' }}>
                <SearchIcon onClick={getEndereco} />
              </div>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          {/* <FormikField name="logradouro" label="Rua/Av." required /> */}
          <TextField
            value={logradouro}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Rua/Av.")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={e => {
                setLogradouro(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('logradouro')}
            //@ts-ignore
            helperText={errors.includes('logradouro') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          {/* <FormikField name="numero" label="Número" required /> */}
          <TextField
            value={numero}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Número")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={e => {
                setNumero(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('numero')}
            //@ts-ignore
            helperText={errors.includes('numero') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          {/* <FormikField name="complementos" label="Complemento" /> */}
          <TextField
            value={complementos}
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Complemento")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={e => {
                setComplementos(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('complementos')}
            //@ts-ignore
            helperText={errors.includes('complementos') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          {/* <FormikField name="bairro" label="Bairro" required /> */}
            <Autocomplete
              // sx={{ mt: 1.25 }}
              id="controlled-demo"
              value={bairroId}
              options={initialBairros}
              onChange={(event, newValue) => {
                //@ts-ignore
                setBairroId(null);
                setRegiao('');
                if (newValue) {
                  // @ts-ignore
                  setBairroId(newValue);
                  //@ts-ignore
                  setRegiao(newValue.regionalNome);
                  console.log('newValue: ', newValue)
                  // console.log('newValue: ', newValue)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bairro"
                  variant="outlined"
                  size="small"
                  fullWidth
                  focused
                  required
                  //@ts-ignore
                  error={errors.includes('bairro')}
                  //@ts-ignore
                  helperText={errors.includes('bairro') ? 'Campo obrigatório!' : ''}
                />
              )}
            />

        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          {/* <FormikField name="regiao" label="Região" required /> */}
          <TextField
            value={regiao}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Região")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            InputProps={{
              readOnly: true,
            }}
            onChange={e => {
                setRegiao(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('regiao')}
            //@ts-ignore
            helperText={errors.includes('regiao') ? 'Campo obrigatório!' : ''}
          />
            {/* <Autocomplete
              // sx={{ mt: 1.25 }}
              id="controlled-demo"
              value={regiaoId}
              options={initialRegionais}
              onChange={(event, newValue) => {
                // @ts-ignore
                setRegiaoId(newValue);
                // console.log('newValue: ', newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Região"
                  variant="outlined"
                  size="small"
                  fullWidth
                  focused
                  required
                  inputProps={{ readOnly: true }}
                  error={errors.includes('regiao')}
                  helperText={errors.includes('regiao') ? 'Campo obrigatório!' : ''}
                />
              )}
            /> */}
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          {/* <FormikField name="cidade" label="Cidade" required /> */}
          <TextField
            value={cidade}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Cidade")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            InputProps={{
              readOnly: true,
            }}
            onChange={e => {
                setCidade(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('cidade')}
            //@ts-ignore
            helperText={errors.includes('cidade') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          {/* <FormikField name="estado" label="Estado" required /> */}
          <TextField
            value={estado}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("Estado")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={e => {
                setEstado(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            InputProps={{
              readOnly: true,
            }}
            //@ts-ignore
            error={errors.includes('estado')}
            //@ts-ignore
            helperText={errors.includes('estado') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* <FormikField name="email" label="E-mail" required /> */}
          <TextField
            value={email}
            required
            type="tel"
            focused
            fullWidth
            size="small"
            label={("E-mail")}
            inputProps={{ style: { textTransform: "lowercase" } }}
            onChange={e => {
                setEmail(e.target.value)
                // setInitialValues({...initialValues, 'pessoaLogradouro': e.target.value})
              }
            }
            //@ts-ignore
            error={errors.includes('email')}
            //@ts-ignore
            helperText={errors.includes('email') ? 'Campo obrigatório!' : ''}
          />
        </Grid>

        {/*
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ px: 0 }}>
            { id ?
              <TelefonesEditPostTable
                id={id}
                // numerosTelefones={numerosTelefones}
                path="telefones-unidades-escolares"
                path2="telefones-unidades-escolares/por-unidade-escolar"
                idName="unidadesEscolaresId"
              />
              : <></>
            }
          </Grid>
        */}
      
      </Grid>

        {/* <pre>{JSON.stringify(id, null, 2)}</pre> */}
       </Paper>
      </Typography>
      </Container>
  );
}