import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import api from '../../../../services/api';

  export default function ControllableStates(props) {
    const [value, setValue] = React.useState(null);
    const [listValues, setListValues] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const defaultProps = {
      options: listValues
    };

    const setDefaultValue = async (data, selecionado) => {
      setValue(data.find(item => item.pessoasId === selecionado));
      setIsLoading(false);
      props.handleShowGravarButton(false);
    }

    React.useEffect(() => {
      if (props.rowSelected.caixasEscolaresId && props.rowSelected.cargosFuncoesTiposCargosGrupo) {
        api.get(`deliberacoes-conselho/conselho-por-caixa-grupo/${props.rowSelected.caixasEscolaresId}/${props.rowSelected.cargosFuncoesTiposCargosGrupo}`)
        .then((response) => {
          setListValues(response.data);
         //  alert(JSON.stringify(response.data, null, 2));
          setDefaultValue(response.data, props.rowSelected.caixasEscolaresHasCargosFuncoesPessoasId);
        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        })
      }
     }, [props.rowSelected.cargosFuncoesTiposCargosGrupo]);

     React.useEffect(() => {
      props.handleShowGravarButton(true);
      if (props.value) {
        props.handleShowGravarButton(false);
      }
     }, [props.value]);


    return (
      <>
        {/* <pre>{JSON.stringify(value, null, 2)}</pre> */}
            { isLoading ?
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
                :
                <Autocomplete
                  {...defaultProps}
                  id="controlled-demo"
                  value={value}
                  disabled={props.disabled}
                  // defaultValue={props.initialValue}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                  // @ts-ignore
                  props.onSelectData(newValue);
                  setValue(newValue);
                  }}
                  renderInput={(params) => (
                  <TextField
                      {...params}
                      label={props.nLabel}
                      variant="outlined"
                      focused
                      size="small"
                      required={props.nRequired}
                      // defaultValue={props.initialValue}
                      error={props.error}
                      helperText={props.error ? props.errorMessage : ''}
                  />
                  )}
                /> 
            }
      </>
    );
  }
