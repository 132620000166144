import React, { useEffect, useState } from "react";
import { useParams, useHistory, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';

import AlertDeleteDialog from '../AlertDeleteDialog';
import EditDialog from '../EditDialog';
import ConfirmAlert from '../ConfirmAlert';
import CircularProgress from '@mui/material/CircularProgress';

import api from "../../../../services/api";

const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'orcamentosDescricao',
    align: 'left',
    disablePadding: false,
    label: 'Descrição',
  },
  {
    id: 'orcamentosHasOrcamentosItens[0].orcamentosItens.valorTotal',
    align: 'left',
    disablePadding: false,
    label: 'Orçamento Mais Barato',
  },
  {
    id: 'acoesMdeValor',
    align: 'right',
    disablePadding: false,
    label: 'Valor (R$)',
  },
  {
    id: 'acoes',
    align: 'center',
    disablePadding: false,
    label: '#',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
          </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== '#' ? 
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Box sx={{ fontWeight: 'light', fontSize: 14, color: '#A9A9A9'}}>
                {headCell.label}
              </Box>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            : 
              <Box sx={{ fontWeight: 'light', fontSize: 14, color: '#A9A9A9'}}>
                {headCell.label}
              </Box>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
const { numSelected } = props;
const history = useHistory();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
    {/*  {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
      */}
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 2, pb: 1 }}>
          <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
            Listagem de Orçamentos
          </Box>
          <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
            <Button onClick={() => history.push('/dados-bancarios/new')} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
              Novo
            </Button>
          </Box>
        </Grid>
        </Typography>


      {/*})}*/}

     {/*} {numSelected > 0 ? (
        <Tooltip title="Apaga">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Listagem">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [rows, setRows] = React.useState([
    {
      id: null,
      deliberacoesConselhoId: "",
      acoesMdeId: "",
      acoesMdeAcao: "",
      acoesMdePeriodoExecucaoInicial: null,
      acoesMdePeriodoExecucaoFinal: null,
      acoesMdeValor: "0.00",
      acoesMdeDias: "",
    }
  ]);
  const [valorTotal, setValorTotal] = React.useState(0.00);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState('error');
  const [message, setMessage] = React.useState('Preencha todos os dados!');
  const [selectedRow, setSelectedRow] = React.useState("");

  const [isExpanded, setIsExpanded] = React.useState([]);

  const [newAcaoMde, setNewAcaoMde] = React.useState({
    deliberacoesConselhoId: "",
    acoesMdeId: "",
    acoesMdeAcao: "",
    acoesMdePeriodoExecucaoInicial: null,
    acoesMdePeriodoExecucaoFinal: null,
    acoesMdeValor: "0.00",
    acoesMdeDias: "",
  });

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      cursor: 'pointer'
    },
  }));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    // setSelected(newSelected);
  };

  const handleOpenDeleteAlertDialog = (row) => {
    setSelectedRow(row);
    setOpenDeleteAlertDialog(true);
  }

  const handleCloseAlertDialog = () => {
    setOpenDeleteAlertDialog(false);
  }

  const handleClickConfirmDelete = () => {
    deleteData();
  }

  const handleOpenEditDialog = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  }

  const handleClickConfirm = (value) => {
    setSelectedRow(value);
    if (value.id) {
      updateData(value);
    } else {
      saveData(value);
    }
  }

  const handleSaveDialogData = () => {
    setOpenDeleteAlertDialog(false);
  }

  const handleCloseConfirmAlert = () => {
    setOpenAlert(false);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getData = () => {
    setIsLoading(true);
    if (props.data.parcelasId) {
      api.get(`/prestacoes-de-contas-has-orcamentos/por-prestacao-de-contas/${props.data.prestacoesDeContasId}`)
      .then((response) => {
        setRows(response.data.data);
        setValorTotal(response.data.valorTotal);
        setIsLoading(false);
        // props.handleValorTotal(response.data.total);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("ops! ocorreu um erro" + err);
      })
    }
  }

  const updateData = (values) => {
    const payload = {
      acao: values.acoesMdeAcao.toUpperCase(),
      dias: values.acoesMdeDias,
      id: values.id,
      periodoExecucaoFinal: values.acoesMdePeriodoExecucaoFinal,
      periodoExecucaoInicial: values.acoesMdePeriodoExecucaoInicial,
      valor: values.acoesMdeValor
    }
    
    api.put(`/acoes-mde/${values.acoesMdeId}`, payload)
      .then((response) => {
        getData();
        setSeverity('success');
        setMessage('Dados atualizados com sucesso!');
        setOpenAlert(true);
        setOpenEditDialog(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
  }

  const saveData = (values) => {
    const payload = {
      deliberacoesConselhoId: values.deliberacoesConselhoId,
      acoesMde: {
        acao: values.acoesMdeAcao.toUpperCase(),
        dias: values.acoesMdeDias,
        id: values.id,
        periodoExecucaoFinal: values.acoesMdePeriodoExecucaoFinal,
        periodoExecucaoInicial: values.acoesMdePeriodoExecucaoInicial,
        valor: values.acoesMdeValor
      }
    }
    const apiType = api.post(`/deliberacoes-conselho-has-acoes-mde`, payload)
      apiType.then((response) => {
        getData();
        setSeverity('success');
        setMessage('Dados gravados com sucesso!');
        setOpenAlert(true);
        setOpenEditDialog(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
  }

  const deleteData = () => {
    api.delete(`/acoes-mde/${selectedRow.id}`)
      .then((response) => {
        getData();
        setSeverity('error');
        setMessage('Dados excluídos com sucesso!');
        setOpenAlert(true);
        setOpenDeleteAlertDialog(false);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      })
  }
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
      getData();
      setNewAcaoMde({ ...newAcaoMde, 'deliberacoesConselhoId': props.data.id })
    }, [props.data.parcelasId]);

    const history = useHistory();

    const getNewAcaoMde = () => {
      handleOpenEditDialog(newAcaoMde);
    }

  return (
    <Box sx={{ width: '100%' }}>

    {/* <pre>{JSON.stringify(newAcaoMde, null, 2)}</pre>
  <pre>{JSON.stringify(selectedRow.id, null, 2)}</pre> */}

      <AlertDeleteDialog
        openDeleteAlertDialog={openDeleteAlertDialog}
        handleOpenDeleteAlertDialog={handleOpenDeleteAlertDialog}
        handleClickConfirmDelete={handleClickConfirmDelete}
        handleCloseAlertDialog={handleCloseAlertDialog}
        selectedRow={selectedRow}
      />
      <EditDialog
        openEditDialog={openEditDialog}
        handleOpenEditDialog={handleOpenEditDialog}
        handleCloseEditDialog={handleCloseEditDialog}
        handleClickConfirm={handleClickConfirm}
        selectedRow={selectedRow}
      />
      <ConfirmAlert
        openAlert={openAlert}
        severity={severity}
        message={message}
        handleCloseConfirmAlert={handleCloseConfirmAlert}
      />

      <Paper sx={{ width: '100%', mb: 2 }}>
        {/*<EnhancedTableToolbar numSelected={selected.length} /> */}

        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '100%', px: 2, pb: 1 }}>
          <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
            Listagem de Orçamentos
          </Box>
          <Box sx={{ fontWeight: 'light', fontSize: 18, color: '#A9A9A9', pt: 2, pl: 0 }}>
            <Button onClick={() => getNewAcaoMde()} variant="outlined" color="primary" size="small" style={{ height: 35 }}>
              Novo
            </Button>
          </Box>
        </Grid>
        </Typography>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {
              isLoading
              ?
              <>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ minWidth: 750 }}
              >
                <CircularProgress />
              </Grid>
              </>
              :
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.orcamentosDescricao}
                        selected={isItemSelected}
                      >
                      {/*} <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                          </TableCell> */}
                        <TableCell
                          component="th"
                          align="left"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {row.orcamentosDescricao}
                        </TableCell>
                        <TableCell align="left">{row.orcamentosHasOrcamentosItens.length > 0 ? row.orcamentosHasOrcamentosItens[0].orcamentosItens.fornecedoresCredores.nomeRazaoSocial : ""}</TableCell>


                        <TableCell align="right">
                          {row.orcamentosHasOrcamentosItens.length > 0 ? parseFloat(row.orcamentosHasOrcamentosItens[0].orcamentosItens.valorTotal).toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ""} </TableCell>
                        <TableCell align="right" style={{ fontSize: 11 }} sx={{ minWidth: 100, padding: 1 }}>
                            <DeleteOutlinedIcon
                              onClick={() => {handleOpenDeleteAlertDialog(row)}}
                              sx={{ color: "#c02424", cursor: "pointer" }}
                            />
                            <ArticleOutlinedIcon
                              onClick={() => {handleOpenEditDialog(row)}}
                              color="primary"
                              sx={{ cursor: "pointer" }}
                            />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={1}
                  >
                    <TableCell colSpan={2} align="right"><b>Valor Total:</b></TableCell>
                    <TableCell align="right"><b>{valorTotal ? valorTotal.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : "0,00"}</b></TableCell>
                    <TableCell align="center" style={{ fontSize: 12 }} sx={{ minWidth: 100, padding: 1 }}>
                    </TableCell>
                  </TableRow>
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            }
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
