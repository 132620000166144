import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    backdrop: {
      // zIndex: theme.zIndex.drawer + 1,
      color: "yellow"
    }
  }));

export default function SpinnerCarregando(props) {
  // const [open, setOpen] = React.useState(false);
  const classes = useStyles();
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const handleToggle = () => {
//     setOpen(!open);
//   };

  return (
    <div>
      <Backdrop
        invisible
        open={props.open}
        // onClick={handleClose}
      >

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div>
          <CircularProgress color="primary" />
        </div>
        <div>
          <span style={{ color: '#2196f3' }}>Carregando...</span>
        </div>
      </Box>

      </Backdrop>
    </div>
  );
}
